import { BaseDocument, BaseCollection, BaseData } from "../../base";

interface ResponseData extends BaseData {
  // User that must respond to this.
  userId: string;
  status: "open" | "closed";

  // Custom data based on approvalSchema
  data: any;
}

export class Responses extends BaseCollection<ResponseData> {
  response(id: string) {
    return this.doc(id) as Response;
  }
}

type Response = BaseDocument<ResponseData>;