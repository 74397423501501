/**
 *
 * Anything to do with the root level users collection.
 * This is basically used for managing the CURRENT signed in user.
 * To get all the users associated with a project,
 * or otherwise deal with users other than the one signed in, you probably want src/projects/users.
 */

import { BaseCollection, BaseDocument, BaseData } from "../base";
import { db, auth } from "../firebase";
import { Project, ProjectData, Projects } from "../projects/index";
import { BaseQuery } from "../base";
import { Notifications } from "./notifications";
import { Permissions } from "./permissions";
import { Badges } from "../projects/badges";

interface UserData extends BaseData {
  name: {
    first: string;
    last: string;
  };
  email: string;
  companyId: string;
}

export class Users extends BaseCollection<UserData> {
  user(id: string) {
    return new User(id, this);
  }

  currentUser(): User | undefined {
    const { currentUser } = auth.getAuth();
    if (!currentUser) {
      return undefined;
    }
    return this.user(currentUser.uid);
  }
}

export class User extends BaseDocument<UserData> {
  projects: UserProjects;
  notifications: Notifications;
  permissions: Permissions;
  is_admin: boolean;
  form_user: boolean = false;
  badges: Badges;

  constructor(id: string, users: Users) {
    super(users.doc(id).ref);
    this.projects = new UserProjects(this);
    this.notifications = new Notifications(this.collection("notifications"));
    this.permissions = db.collection("permissions").doc(id) as Permissions;
    this.is_admin = false;
    this.permissions.listen(
      (perms: { [key: string]: any }) =>
        (this.is_admin = perms && perms["admin"])
    );
    this.badges = new Badges(this.collection("badges"));
  }
}

class UserProjects extends Projects {
  constructor(user: User) {
    super(
      user.db
        .collection("projects")
        .where("users", "array-contains", user.ref.id).ref
    );
  }
}
