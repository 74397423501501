import React, { useState } from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { PrintableField } from "../fields";
import { useUserLookup } from "../../../../../hooks/users";

import { PDFConstants, PDFStyles } from "../style";

import { RESPONSE_SCHEMA_DEFUALT } from "../../actions/responses";
import PrintQueryHeader from "../../../../print/header";

export default ({ schemaData, response, queryData, projectLogo }) => {
  const [author, authorRef] = useUserLookup(
    queryData ? queryData.creator : undefined
  );

  const responseSchema = schemaData.responseFields ?? RESPONSE_SCHEMA_DEFUALT;
  const [query, setQuery] = useState(queryData ? queryData : { data: {} }); // Guarantee the data prop
  return (
    <Page>
      <PrintQueryHeader
        projectLogo={projectLogo}
        author={author}
        title={schemaData.name}
        id={queryData ? queryData.dynamicId ?? queryData.id : undefined}
        typeTitle="Responses for"
      />
      {/* This is where the body of the approvals start */}
      <View style={PDFStyles.body}>
        <View style={PDFStyles.query}>
          {responseSchema.map((fld) => {
            if (fld.section) {
              return (
                <View key={fld.name} style={PDFStyles.section}>
                  <View style={PDFStyles.queryRow} key={"section-title"}>
                    <View
                      style={[
                        PDFStyles.queryCol,
                        { width: PDFConstants.BODY_WIDTH },
                      ]}
                    >
                      <Text
                        style={[PDFStyles.tableCell, PDFStyles.sectionTitle]}
                      >
                        {fld.name}
                      </Text>
                    </View>
                  </View>
                  {fld.section.map((secfld) => (
                    <View
                      style={PDFStyles.queryRow}
                      key={secfld.id ? secfld.id : secfld.nested[0].id}
                    >
                      {secfld.nested &&
                        secfld.nested.map((nstfld) => (
                          <View
                            key={`${nstfld.id}-wrap`}
                            style={[
                              PDFStyles.queryCol,
                              {
                                width:
                                  PDFConstants.BODY_WIDTH /
                                  secfld.nested.length,
                              },
                            ]}
                          >
                            <PrintableField
                              field={nstfld}
                              fieldData={query.data[nstfld.id]}
                              data={query.data}
                              width={
                                PDFConstants.BODY_WIDTH / secfld.nested.length
                              }
                            />
                          </View>
                        ))}
                      {!secfld.nested && (
                        <View
                          key={secfld.id}
                          style={[
                            PDFStyles.queryCol,
                            { width: PDFConstants.BODY_WIDTH },
                          ]}
                        >
                          <PrintableField
                            field={secfld}
                            fieldData={query.data[secfld.id]}
                            data={query.data}
                            width={PDFConstants.BODY_WIDTH}
                          />
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              );
            } else {
              return (
                <View
                  style={PDFStyles.queryRow}
                  key={fld.nested ? fld.nested[0].name : fld.name}
                >
                  {fld.nested &&
                    fld.nested.map((nstfld) => (
                      <View
                        key={nstfld.id}
                        style={[
                          PDFStyles.queryCol,
                          {
                            width: PDFConstants.BODY_WIDTH / fld.nested.length,
                          },
                        ]}
                      >
                        <PrintableField
                          field={nstfld}
                          fieldData={query.data[nstfld.id]}
                          data={query.data}
                          width={PDFConstants.BODY_WIDTH / fld.nested.length}
                        />
                      </View>
                    ))}
                  {!fld.nested && (
                    <View
                      key={fld.id}
                      style={[
                        PDFStyles.queryCol,
                        { width: PDFConstants.BODY_WIDTH },
                      ]}
                    >
                      <PrintableField
                        field={fld}
                        fieldData={
                          fld.id === "response"
                            ? response &&
                              response[0] &&
                              response[0].data?.response
                            : query.data[fld.id]
                        }
                        data={query.data}
                        width={PDFConstants.BODY_WIDTH}
                      />
                    </View>
                  )}
                </View>
              );
            }
          })}
        </View>
      </View>
      <View style={PDFStyles.footer} fixed>
        <Text style={PDFStyles.footerText}>
          This form was generated by Query-It on{" "}
          {`${
            String(new Date().getDate()).padStart(2, "0") +
            "/" +
            String(new Date().getMonth() + 1).padStart(2, "0") +
            "/" +
            String(new Date().getFullYear())
          }`}
        </Text>
        {/* There's a bottom right place here for another logo */}
      </View>
    </Page>
  );
};
