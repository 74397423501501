/**
 * One particular query (form) that is based on the workflow of a schema.
 */

import { BaseCollection, BaseDocument, BaseData } from "../../base";
import { Approvals } from "./approvals";
import { Tasks } from "./tasks";
import { Comments } from "./comments";
import { Forms } from "./forms";
import { Clarifications } from "./clarifications";
import { Responses } from "./responses";

interface QueryData extends BaseData {
  // ID of the user who created this query.
  creator: string;

  //User who this is assigned to.
  assignedUser?: string;

  // A flexible container for data, based on the schema.
  data: { [key: string]: any };

  //String ID of the schema this query is based on.
  schemaId: string;
  status: string;

  // Array of userIDs who should be notified about changes to the query.
  distributionList: string[];

  // If part of the response workflow, responding userId and flexible data based on responseSchema.
  response?: {
    userId: string;
    data: string;
  };
}

export class Queries extends BaseCollection<QueryData> {
  query(id: string) {
    return new Query(id, this);
  }
}

export class Query extends BaseDocument<QueryData> {
  /** Subcollections for the query. */
  approvals: Approvals;
  responses: Responses;
  tasks: Tasks;
  comments: Comments;
  clarifications: Clarifications;
  forms: Forms;

  constructor(id: string, queries: Queries) {
    super(queries.doc(id).ref);

    this.approvals = new Approvals(this.collection("approvals"));
    this.responses = new Responses(this.collection("responses"));
    this.tasks = new Tasks(this.collection("tasks"));
    this.comments = new Comments(this.collection("comments"));
    this.forms = new Forms(this.collection("forms"));
    this.clarifications = new Clarifications(this.collection("clarifications"));
  }
}
