import React, { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import styled, { css, ThemeContext } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { panelTheme } from "../../themes/mui_theme_panels";
import { materialTheme } from "../../themes/mui_theme";
import { isMobile } from "react-device-detect";
import { Routes, Route, Navigate } from "react-router-dom";

import { Header } from "../header";
import AppAdmin from "./dashboard";
import ProjectAdmin from "./project";
import { useUserPermissions } from "../../hooks/users";
import Edit from "./dashboard/users/edit";

export default ({
  match,

  queryParams,
  setQueryParams,

  projects,
  user,
  userData,
  photoLink,
}) => {
  const permissions = useUserPermissions(user);

  // Check for flag indicating that a user popup should open
  const [searchParams, setSearchParams] = useSearchParams();
  const userID = searchParams.get("user");

  const theme = useContext(ThemeContext);

  if (!permissions) {
    return null;
  }

  if (!userData || (permissions && !permissions.admin)) {
    return <Navigate to="/app" />;
  }

  return (
    <AdminPage>
      <Header
        name={"Administration"}
        userData={userData}
        projects={projects}
        photoLink={photoLink}
        mode={"admin"}
      />
      <AdminContentDiv>
        <Routes>
          <Route
            path="manage/*"
            element={<AppAdmin projects={projects} user={user} />}
          />
          <Route
            path="projects/:projectId/*"
            element={
              <ProjectAdmin
                projects={projects}
                user={user}
                userData={userData}
              />
            }
          />
          <Route path="*" element={<Navigate to="manage" />} />
        </Routes>
      </AdminContentDiv>
      {userID && (
        <MuiThemeProvider theme={panelTheme(materialTheme(theme))}>
          <Edit userID={userID} />
        </MuiThemeProvider>
      )}
    </AdminPage>
  );
};

const AdminPage = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  width: 0;
  flex-grow: 1;

  ${isMobile &&
  css`
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  `}
`;

const AdminContentDiv = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  flex-grow: 1;
  flex-direction: row;

  box-sizing: border-box;

  position: relative;
  overflow: auto;
`;
