// Define all possible query views to pass as keys to the query viewer
export const QUERYVIEWS = {
  // User views
  QUERY: "query",
  EXTERNAL_FORM: "external",
  NEGOTIATOR: "negotiator",
  ACTIONS: "actions",
  EVALUATOR: "evaluator",
  CLARIFICATIONS: "clarifications",
  FIELDS: "fields",
  PRINT: "print",
  QR_CODE: "qrcode",
  DEFAULTS: "defaults",
  REVISIONS: "revisions",
  // Admin views
  ADMIN_DEFAULTS: "admin_defaults",
  ADMIN_FIELDS: "admin_fields",
  ADMIN_DYNAMIC_ID: "admin_dynamic_id",
  ADMIN_VALIDATION_CONTROLS: "admin_validation_controls",
  ADMIN_QUERY_DATA: "admin_query_data",
};

export const SUBQUERYVIEWS = {
  // Actions views
  APPROVAL: "approval",
  TASK: "task",
  RESPONSE: "response",
  // Query views
  DEFAULT: "query",
  QUERYLINK: "querylink",
  DOC: "doc"
}

export const ROUTEKEYS = {
  NEW_QUERY_ID: "nq",
  EXISTING_QUERY_ID: "qq",
  COPY_QUERY_DATA_ID: "cp",
  QUERY_PANE_MAIN_VIEW: "qr",
  QUERY_PANE_SUB_VIEW: "ar",
}


////////////////////
// QUERY METADATA //
////////////////////

export const QUERY_METADATA_FIELDS = {
  status: "status",
  id: "id",
  dynamicId: "dynamicId",
  revisionId: "revisionId",
  creator: "creator",
  createTime: "createTime",
}

////////////////////////
// STATUS DEFINITIONS //
////////////////////////

const WORKFLOW_REQUIREMENTS = {
  response: {
    new: [
      {
        type: "check",
        guideText: "Please select an assignee to create the Query",
        statusMessage: "A selected assignee is required at this stage",
        check: (data: any) => !!data.assignedUser,
      },
      {
        type: "submit",
        guideText:
          "The Query is ready for creation. The assignee will not be notified at this stage",
        buttonText: "Create",
        moveTo: "created",
      },
    ],
    created: [
      {
        type: "submit",
        guideText:
          "The Query is ready to be submitted. The assignee will be notified, and the Query data will not be editable",
        buttonText: "Submit",
        privilege: (data: any, user: any) => data.creator === user.id,
        noPrivilegeText: "The Query User has not submitted this Query yet",
        moveTo: "open",
        confirmation: {
          confirmationText: `You are about to submit a query to the assigned user. This will notify the user & the Query may become uneditable. 
          Are you sure you'd like to proceed?`,
          confirmationTitle: "Confirm Submission",
        },
      },
    ],
    open: [
      {
        type: "check",
        guideText: "The assignee has yet to prepare a response",
        check: (data: any, actions: any) =>
          Object.keys(actions?.response?.data ?? {}).length > 0,
        remindUser: true,
      },
      {
        type: "submit",
        guideText: "Submit your response to close the Query",
        buttonText: "Submit Response",
        privilege: (data: any, user: any) => data.assignedUser === user.id,
        noPrivilegeText:
          "The assignee has written a response, but has not submitted it yet",
        remindUser: true,
        moveTo: "responded",
      },
    ],
    responded: undefined,
  },
  decision: {
    new: [
      {
        type: "submit",
        guideText: "The Query is ready for creation",
        buttonText: "Create",
        moveTo: "created",
      },
    ],
    created: [
      {
        type: "submit",
        guideText: "The Query is ready to be closed",
        buttonText: "Close",
        moveTo: "closed",
      },
    ],
    closed: undefined,
  },
  approval: {
    new: [
      {
        type: "check",
        guideText:
          "Please select at least one approval user to create the Query",
        statusMessage:
          "At least one selected approval user is required at this stage",
        check: (data: any) =>
          data?.approvalsRequired?.length > 0 &&
          !data.approvalsRequired?.some(
            (apprStage: any) =>
              apprStage?.approvers?.length < 1 ||
              apprStage?.approvers?.some((appr: any) => !appr?.main)
          ),
      },
      {
        type: "submit",
        guideText: "The Query is ready for creation",
        buttonText: "Create",
        moveTo: "created",
      },
    ],
    created: [
      {
        type: "check",
        guideText:
          "Please select at least one approval user to submit the Query",
        statusMessage:
          "At least one selected approval user is required at this stage",
        check: (data: any) =>
          data?.approvalsRequired?.length > 0 &&
          !data.approvalsRequired?.some(
            (apprStage: any) =>
              apprStage?.approvers?.length < 1 ||
              apprStage?.approvers?.some((appr: any) => !appr?.main)
          ),
      },
      {
        type: "submit",
        guideText:
          "The Query is ready to be submitted. Approvers will be notified",
        buttonText: "Submit",
        moveTo: "open",
        privilege: (data: any, user: any) => data.creator === user.id,
        noPrivilegeText: "The Query User has not submitted this Query yet",
        confirmation: {
          confirmationText: `You are about to submit a query to the approval user(s). This will notify the user(s) & the Query may become uneditable.
          Are you sure you'd like to proceed?`,
          confirmationTitle: "Confirm Submission",
        },
      },
    ],
    open: [
      {
        type: "approve",
        guideText: "The Query is open for approval by the approval users",
        remindUser: true,
        moveTo: "approved",
      },
    ],
    approved: [
      {
        type: "trigger",
        guideText:
          "Approvals have been completed. Waiting for remaining Action items to close",
        moveTo: "closed",
      },
    ],
    closed: undefined,
    // You can't go anywhere if the schema setting for resubmission is false, but we provide good feedback in this case
    // Otherwise you can move circularly through this query type
    rejected: [
      {
        type: "submit",
        noPrivilegeText: "The Query has been rejected by the approvers",
        moveTo: "created",
        privilege: (q: any, u: any, schema: any) =>
          !!schema?.settings?.allowApprovalResubmission,
        buttonText: "Resubmit",
        confirmation: {
          confirmationText: `You are about to re-open a query for re-submission to the same approval user(s) as reviewed it previously. The Query will be
          set back to the created status for revisions, then you will be prompted to submit again. Approval users will be notified. 
          Are you sure you'd like to proceed?`,
          confirmationTitle: "Confirm re-opening",
        },
      },
    ],
  },
  // lite: {
  //   new: [
  //     {
  //       type: "submit",
  //       guideText: "The Query is ready for creation",
  //       buttonText: "Create",
  //       moveTo: "created",
  //     },
  //   ],
  // },
} as { [schemaType: string]: { [status: string]: any } };

const CancelledStatus = {
  cancelled: {
    status: "cancelled",
    name: "Cancelled",
    advance: { requirements: undefined },
    statusType: "closed",
  },
};

// Define the schema status sets for template schemas (non-customs)
export const TEMPLATE_SCHEMA_STATUSSETS = Object.keys(
  WORKFLOW_REQUIREMENTS
).reduce(
  (acc, schemaType: string) => ({
    ...acc,
    [schemaType]: Object.keys(WORKFLOW_REQUIREMENTS[schemaType]).reduce(
      (acc, status) => ({
        ...acc,
        [status]: {
          status: status,
          name: status.charAt(0).toUpperCase() + status.slice(1),
          ...(WORKFLOW_REQUIREMENTS[schemaType][status] !== undefined ? {advance: { requirements: WORKFLOW_REQUIREMENTS[schemaType][status] }} : {}),
          statusType: [
            "approved",
            "accepted",
            "responded",
            "closed",
            "rejected",
          ].includes(status)
            ? "closed"
            : ["created", "new"].includes(status)
            ? status
            : undefined,
          color: ["approved", "accepted", "responded", "closed"].includes(
            status
          )
            ? "#176d17"
            : ["rejected"].includes(status)
            ? "#b11e1e"
            : ["created", "new"].includes(status)
            ? "#4242ce"
            : "#d28800",
        },
      }),
      { ...CancelledStatus }
    ),
  }),
  {}
) as { [schemaType: string]: { [status: string]: any } };
