import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";

import { saveAnalyticsPDFAsync } from "./print";

import Charts from "./charts.js";
import { Button2 } from "../../ui/buttons.js";
import { UserDataContext } from "../../../App";
import { CurrentProjectContext, ProjectLogoContext } from "..";

export default ({
  onClose,
  data,
  actions,
  chartSchemaSet,
  selectedSchema,
  projectName,
}) => {
  const chartsImages = useRef({});
  const chartBlobCollector = useCallback(
    (name, url) =>
      (chartsImages.current = { ...chartsImages.current, [name]: url }),
    [chartsImages]
  );

  const [loading, setLoading] = useState(true);

  // Also get values for the print
  const user = useContext(UserDataContext);
  const projectLogo = useContext(ProjectLogoContext);
  const projectData = useContext(CurrentProjectContext);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 900);
  }, []);

  return (
    <BackgroundContainer onClick={onClose}>
      <PreviewContainer onClick={(e) => e.stopPropagation()}>
        <ButtonHeading>
          <Button2 label="Cancel" onClick={() => onClose()} />
          <Button2
            disabled={loading}
            label="Confirm Print"
            onClick={() =>
              saveAnalyticsPDFAsync(
                selectedSchema,
                chartsImages.current,
                `${projectName}-${selectedSchema?.name}-analytics.pdf`,
                projectData,
                user,
                projectLogo
              )
            }
          />
        </ButtonHeading>
        <Charts
          data={data}
          actions={actions}
          chartSchemaSet={chartSchemaSet}
          chartBlobCollector={chartBlobCollector}
          panel
        />
      </PreviewContainer>
    </BackgroundContainer>
  );
};

const PreviewContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  border-radius: 8px;

  top: 5%;
  // left: 10%;
  width: 80%;
  min-height: 86%;
  z-index: 1001;
  background-color: #dfdfdf;
  padding: 16px;

  height: fit-content;
`;

const BackgroundContainer = styled.div`
  position: fixed;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;

  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ButtonHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
`;
