import React, { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { UserDataContext } from "../../../App";
import { parse_db_timestamp } from "../../../tools";
import { parse_on_fields } from "../../../tools/forms";

import { Button2 } from "../../ui/buttons";

const BorderStyle = { style: "thin", color: { rgb: "000000" } };
const MetaDataCellStyle = { font: { bold: true } };

const ColumnHeaderStyle = { font: { bold: true } };

export const DownloadQueriesWorkbookButton = ({
  queries,
  projectName,
  schema,
  filename,
}) => {
  const userData = useContext(UserDataContext);

  const [exportableWorkbook, setExportableWorkbook] = useState(undefined);

  useEffect(() => {
    if (!schema || !userData || !queries) {
      return;
    } // We're not ready to render if the requisite info isn't here

    // Generate the necessary components for a happy workbook :)
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([[]], { cellStyles: true });

    // 1. Generate Export Metadata
    XLSX.utils.sheet_add_aoa(ws, [
      // 1.1 Project Name
      [
        { t: "s", v: "Project", s: MetaDataCellStyle },
        { t: "s", v: projectName },
      ],
      // 1.2 Schema Name
      [
        { t: "s", v: "Schema", s: MetaDataCellStyle },
        { t: "s", v: schema.name },
      ],
      // 1.3 Exported By
      [
        { t: "s", v: "Exported By", s: MetaDataCellStyle },
        { t: "s", v: `${userData.name.first} ${userData.name.last}` },
      ],
      // 1.4 Exported On
      [
        { t: "s", v: "Exported On", s: MetaDataCellStyle },
        { t: "s", v: new Date().toLocaleString() },
      ],
    ]);

    // Add a buffer row
    XLSX.utils.sheet_add_aoa(ws, [[]]);

    // INTERLUDE

    // Now we need to generate the scheme that will be used to render the columns of data. We'll do this using the schema
    // As well as some basic properties based on the schema type

    let parsedFields = [];
    parse_on_fields(schema.fields, (fld) => {
      const fieldResult = field_header_parser(fld);
      parsedFields = [...parsedFields, ...fieldResult];
    });

    const columns = [...QUERY_STATIC_HEADINGS, ...parsedFields];

    // CONCLUSION OF INTERLUDE

    // 2. Generate Header Row
    XLSX.utils.sheet_add_aoa(
      ws,
      [columns.map((col) => ({ t: "s", v: col.label, s: ColumnHeaderStyle }))],
      { origin: -1 }
    ); // origin -1 starts at the next undefined row

    // 3. Generate Data Rows
    const dataRows = queries
      ?.filter((q) => q.schemaId === schema.id)
      .map((query) => {
        // We'll iterate over each column to create a list for the horizontal
        return columns.map((col) => {
          // This is a column wrt the specific query, so let's use the index to get the data, then embed the type and return
          if (col.format) {
            return {
              t: col.t,
              v: "", // TODO: We don't have this support yet
            };
          } else {
            // This means index exists!
            const index = col.index.split(".");
            const data = index.reduce((acc, cur) => acc?.[cur], query);
            // if (col.t === 's') { data?.replaceAll("\\r", ""); }
            return {
              t: col.t,
              v: (col.t === "d" ? parse_db_timestamp(data) : data) ?? "",
            };
          }
        });
      });

    // Append to the workbook
    XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: -1 });

    // 4. Apply Column Width Stylings
    ws["!cols"] = [{ wpx: 100 }];

    // Right before the end of generation, let's plug the sheet into the book
    XLSX.utils.book_append_sheet(wb, ws, "Exported_Queries");

    // Now apply that to the visible state!
    setExportableWorkbook(wb);
  }, [queries, schema, userData, projectName]);

  const saveWorkbook = () => {
    XLSX.writeFile(exportableWorkbook, filename + ".xlsx");
  };

  return (
    <Button2 onClick={() => saveWorkbook()} label="Download Queries (Excel)" />
  );
};

const field_header_parser = (field) => {
  // Take in the field, spit out an array of columns & how to produce them from the queryData object
  switch (field.type) {
    case "table":
    case "statictable":
      return (field?.columns ?? [])
        .filter((col) => col.calculation)
        .map((col) => ({
          label: col.text,
          // TODO: Add support for these properly when we come across a modern one?
        }));
    case "tableadv":
      // Here we'll render footer data (usually calculated or aggregate)
      // TODO: Support this once footer is supported :)
      break;
    case "select":
    case "checkbox":
      return [
        {
          label: field.name,
          index: "data." + field.id,
          t: field.type === "date" ? "d" : field.type === "number" ? "n" : "s",
        },
      ];
    case "signature":
    case "files":
      return []; // Some types straight need to be omitted
    default:
      // If it's not a special case, we just return the field name and maybe the type
      return [
        {
          label: field.name,
          index: "data." + field.id,
          t: field.type === "date" ? "d" : field.type === "number" ? "n" : "s",
        },
      ];
  }

  // If we have an oopsie, give nothing back
  return [];
};

const QUERY_STATIC_HEADINGS = [
  { label: "Query ID", index: "dynamicId" },
  { label: "Creator", index: "creator", format: "user" },
  { label: "Created On", index: "createTime", t: "d" },
];
