import * as XLSX from "xlsx-js-style";
import { useSnackbar } from "../../../hooks/system";

export const downloadTableTemplateWorkbook = (tableBodyColumns, tableName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([[]], { cellStyles: true });

  // 1. Layout the headings
  XLSX.utils.sheet_add_aoa(ws, [
    tableBodyColumns.map((col) => ({
      t: "s",
      v: col.title + (col.type === "select" ? " (enter valid option)" : ""),
      s: { font: { bold: true } },
    })),
  ]);

  // 2. Now generate data rows with formatting based on the column datatype
  const dataEntryRow = tableBodyColumns.map((col) => {
    switch (col.type) {
      case "string":
        return { t: "s", v: "" };
      case "number":
        return { t: "n", v: 0 };
      case "date":
        return { t: "d" };
      case "boolean":
        return { t: "b", v: "", s: { font: { italic: true } } };
      case "select":
        return { t: "s", v: "", s: { font: { italic: true } } };
      default:
        return { t: "s", v: "", s: { font: { italic: true } } };
    }
  });

  // 2a. Iterate 100 times to format the blank rows
  for (let i = 0; i < 100; i++) {
    XLSX.utils.sheet_add_aoa(ws, [dataEntryRow], { origin: -1 }); // origin -1 starts at the next undefined row
  }

  // 3. Add small index tables for dropdowns
  let tableColumnIndexer = tableBodyColumns.length + 2;

  tableBodyColumns
    .filter((col) => col.type === "select")
    .forEach((col) => {
      // Add a little table to index the options for the row
      // It'll be a table with a heading and then a row for each option

      // First add the heading
      let miniOptionTable = [];
      miniOptionTable.push({
        t: "s",
        v: col.title + " - Option Set",
        s: { font: { bold: true } },
      });

      // Then iterate and add the options (we'll add their titles and read those back too)
      Object.values(col.options).forEach((option) => {
        miniOptionTable.push({ t: "s", v: option });
      });

      // And finish by appending the whole thing to the book
      XLSX.utils.sheet_add_aoa(
        ws,
        miniOptionTable.map((val) => [val]),
        { origin: { c: tableColumnIndexer, r: 3 } }
      );

      // Increment the table column indexer
      tableColumnIndexer = tableColumnIndexer + 2;
    });

  // 4. Then apply sheet style
  ws["!cols"] = tableBodyColumns.map((col) => ({
    wpx: col.type === "select" ? 180 : 100,
  }));
  // Then do the table column indexers as well
  ws["!cols"] = [
    ...ws["!cols"],
    ...(tableBodyColumns.filter((col) => col.type === "select").length > 0
      ? [{ wpx: 100 }]
      : []),
    ...tableBodyColumns
      .filter((col) => col.type === "select")
      .map((col) => [{ wpx: 100 }, { wpx: 180 }])
      .reduce((a, b) => a.concat(b), []),
  ];

  // Right before the end of generation, let's plug the sheet into the book
  XLSX.utils.book_append_sheet(wb, ws, "Exported_Queries");

  // Finish by savefile'ing the workbook
  XLSX.writeFile(wb, `${tableName.replaceAll(" ", "_")}-template.xlsx`);

  return;
};

export const uploadTableTemplateRows = (
  event,
  tableBodyColumns,
  setChange,
  queryData
) => {
  const { callSnackbar } = useSnackbar();

  // 1. Error checks
  if (event.target?.files?.length < 1) {
    return;
  }

  const file = event.target.files[0];

  if (file.name.split(".").pop() !== "xlsx") {
    return;
  }

  // 2. Setup Reader
  const reader = new FileReader();
  reader.onload = (e) => {
    // Read file
    const data = e.target.result;
    const xlsxFile = XLSX.read(data);

    // Now let's start parsing it and use it to write some data!
    const sheet = xlsxFile.Sheets["Exported_Queries"];
    const rows = XLSX.utils.sheet_to_json(sheet);

    // Let's start iterating over the rows, and we'll collect them in the appropriate body data format
    let tableBodyData = [];
    rows.some((row, rowIndex) => {
      // 1. First, let's check if we have a blank row
      let rowHasData = false;
      tableBodyColumns.forEach((col) => {
        let colLabel =
          col.title + (col.type === "select" ? " (enter valid option)" : "");
        if (
          row[colLabel] !== undefined &&
          row[colLabel] !== "" &&
          row[colLabel] !== 0
        ) {
          rowHasData = true;
        }
      });

      // If we don't have data, stop!
      if (!rowHasData) {
        // We won't read any more rows after this :)
        return true;
      }

      // 2. Now acknowledge row data and add the row
      let rowData = {};
      tableBodyColumns.forEach((col) => {
        let colLabel =
          col.title + (col.type === "select" ? " (enter valid option)" : "");
        if (row[colLabel] !== undefined) {
          // If it's a select field we actually need to resolve it, otherwise just throw the data in :)
          let data;
          if (col.type === "select" && col.dataLinked === undefined) {
            data = Object.keys(col.options).find(
              (opt) => col.options[opt] === row[colLabel]
            );
          } else if (col.type === "date") {
            // Here we convert from Excel date int format to normal date format for JS
            // NOTE: We remove one day fewer than is normal in step 1 because we don't handle fractional days (round up really)
            let date = Math.floor(row[colLabel] - 25568);
            let utcVal = date * 86400 * 1000;
            data = new Date(utcVal);
          } else {
            data = row[colLabel];
          }
          // Now before adding the data, check editability
          const editableConditionFunc = new Function(
            "row",
            col.editableCondition ?? "return true"
          );

          if (
            !(col.editableStatusSet?.includes(queryData.status) ?? true) ||
            !editableConditionFunc(queryData.data?.[rowIndex])
          ) {
            // Then set original data if exists, otherwise return here
            if (queryData.data?.[rowIndex]?.[col.id] !== undefined) {
              data = queryData.data[rowIndex][col.id];
            } else {
              return;
            }
          }
          rowData[col.id] = data;
        }
      });

      // If row data is empty here, then we hit an editability issue in the row, don't append
      if (Object.keys(rowData).length < 1) {
        return false;
      }

      // Then add the row to the bodyData
      tableBodyData.push(rowData);
    });

    // If we're here but the number of rows isn't correct because of an uneditable column, we need to correct that
    // First check if any column isn't status editable
    if (
      tableBodyColumns.some(
        (col) => !col.editableStatusSet?.includes(queryData.status) ?? true
      )
    ) {
      // Then we have an uneditable column! Make sure the number of rows matches the original
      if (tableBodyData.length < queryData.data.length) {
        // Then we need to add rows to the end of the data
        let rowsToAdd = queryData.data.length - tableBodyData.length;
        // Then add the rows from existing data directly
        tableBodyData = tableBodyData.concat(queryData.data.slice(-rowsToAdd));
      } else if (tableBodyData.length > queryData.data.length) {
        // Then we need to remove rows from the end of the data
        let rowsToRemove = tableBodyData.length - queryData.data.length;
        // Then remove the rows from the end of the data
        tableBodyData = tableBodyData.slice(0, -rowsToRemove);
      }
    }

    // Now we have the data from the import, time to push to the setter
    setChange((ex) => ({
      ...ex,
      body: tableBodyData.length < 1 ? ex?.body : tableBodyData,
    }));
  };

  callSnackbar("Table Rows Upload Successful");
  // Trigger the reader
  reader.readAsArrayBuffer(file);
  return;
};
