import styled from "styled-components";
import { Link } from "react-router-dom";

export default styled(Link)`
  text-decoration: none;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  width: 100px;
  height: 100%;

  flex-shrink: 0;

  display: flex;
  color: ${(props) => props.theme.textAlt};
  justify-content: center;
  align-items: center;

  font-family: ${(props) => props.theme.font};
  box-shadow: ${(props) =>
    props.selected ? `inset 0px -5px 0 ${props.theme.titleSub}` : ""};
`;
