import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";

//UI
import { GenericField, SelectField, SlimTextField } from "../../../ui/inputs2";
import { Badge } from "../../../statics2/badges";
import { Commendation } from "../../../statics2/graphics/commend";

import { Typography, IconButton, Tooltip, Box } from "@mui/material";
import { Clear, Close, Check, Edit } from "@mui/icons-material";

// Import the Slate components and React plugin.
import {
  HOTKEYS,
  EditorInteractablesSection,
  Element,
  Leaf,
  toggleMark,
} from "../../../project/query/comments/sidebar";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";

export const AnnouncementEditor = ({ buildPopup, setBuildPopup }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const setAnnouncement = (value) => {
    setBuildPopup((prev) => {
      return {
        ...prev,
        data: value,
      };
    });
  };

  return (
    <>
      <EditorInteractablesSection editor={editor} />
      <AnnouncementDiv>
        <Slate
          editor={editor}
          value={buildPopup.data}
          onChange={setAnnouncement}
        >
          <Editable
            placeholder="Type Announcement..."
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            autoFocus
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                const keys = hotkey.split("+");
                const key = keys[keys.length - 1];
                let mod = false;
                if (keys.length == 2) {
                  mod = true;
                }
                if (
                  event.key == key &&
                  (event.ctrlKey || event.metaKey) == mod
                ) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </Slate>
      </AnnouncementDiv>
    </>
  );
};

export const BadgesEditor = ({ buildPopup, setBuildPopup }) => {
  const [showEditButtonHeader, setShowEditButtonHeader] = useState(false);
  const [editingLabelHeader, setEditingLabelHeader] = useState(false);
  const [header, setHeader] = useState(buildPopup?.header ?? "");

  const [showEditButtonText, setShowEditButtonText] = useState(false);
  const [editingLabelText, setEditingLabelText] = useState(false);
  const [text, setText] = useState(buildPopup?.text ?? "");

  const saveLabelHeader = () => {
    if (header) {
      setBuildPopup((prev) => {
        const dataCopy = prev.data;
        return {
          ...prev,
          data: {
            ...dataCopy,
            header: header,
          },
        };
      });
      setEditingLabelHeader(false);
      setShowEditButtonHeader(false);
    }
  };

  const saveLabelText = () => {
    if (text) {
      setBuildPopup((prev) => {
        const dataCopy = prev.data;
        return {
          ...prev,
          data: {
            ...dataCopy,
            text: text,
          },
        };
      });
      setEditingLabelText(false);
      setShowEditButtonText(false);
    }
  };

  const deleteDisplayBadge = (id) => {
    setBuildPopup((prev) => {
      const dataCopy = prev.data;
      return {
        ...prev,
        data: {
          ...dataCopy,
          badges: dataCopy.badges.filter((badge) => badge.id !== id),
        },
      };
    });
  };

  return (
    <>
      {/* This is the header editor */}
      <Box
        sx={{ display: "flex" }}
        onMouseEnter={() => setShowEditButtonHeader(true)}
        onMouseLeave={() => setShowEditButtonHeader(false)}
      >
        <Typography
          sx={{
            fontSize: "30px",
            display: "flex",
            fontWeight: "bold",
          }}
          component="div"
        >
          {editingLabelHeader ? (
            <SlimTextField
              variant="outlined"
              value={header}
              onChange={(e) => setHeader(e.target.value)}
              size="small"
            />
          ) : (
            buildPopup.data.header
          )}
        </Typography>
        {editingLabelHeader ? (
          <>
            <IconButton
              sx={{ padding: 0, marginLeft: "5px" }}
              onClick={saveLabelHeader}
            >
              <Check fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ padding: 0, marginLeft: "5px" }}
              onClick={() => {
                setEditingLabelHeader(false);
                setShowEditButtonHeader(false);
                setHeader(buildPopup.header ?? "");
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        ) : (
          showEditButtonHeader && (
            <>
              <Tooltip title="Edit Title">
                <IconButton
                  sx={{ padding: 0, marginLeft: "5px" }}
                  onClick={() => setEditingLabelHeader(true)}
                >
                  <Edit sx={{ fontSize: "18px" }} />
                </IconButton>
              </Tooltip>
            </>
          )
        )}
      </Box>
      {/** This is the editor for the text */}
      <Box
        sx={{ display: "flex" }}
        onMouseEnter={() => setShowEditButtonText(true)}
        onMouseLeave={() => setShowEditButtonText(false)}
      >
        <Typography
          sx={{
            fontSize: "16px",
            display: "flex",
          }}
          component="div"
        >
          {editingLabelText ? (
            <SlimTextField
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              size="small"
            />
          ) : (
            buildPopup.data.text
          )}
        </Typography>
        {editingLabelText ? (
          <>
            <IconButton
              sx={{ padding: 0, marginLeft: "5px" }}
              onClick={saveLabelText}
            >
              <Check fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ padding: 0, marginLeft: "5px" }}
              onClick={() => {
                setEditingLabelText(false);
                setShowEditButtonText(false);
                setText(buildPopup.text ?? "");
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        ) : (
          showEditButtonText && (
            <>
              <Tooltip title="Edit Title">
                <IconButton
                  sx={{ padding: 0, marginLeft: "5px" }}
                  onClick={() => setEditingLabelText(true)}
                >
                  <Edit sx={{ fontSize: "18px" }} />
                </IconButton>
              </Tooltip>
            </>
          )
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {Array.isArray(buildPopup?.data?.badges) &&
          buildPopup.data.badges.map((badge, j) => (
            <Box
              key={`${badge.id}-${j}`}
              sx={{
                margin: "10px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Badge
                name={badge.name}
                amountNeeded={0}
                amountObtained={1}
                flair={badge.flair}
                artUrl={badge.url}
                info={badge?.info}
                textColour="black"
              />
              <Tooltip title="Delete Badge">
                <IconButton
                  onClick={() => {
                    deleteDisplayBadge(badge.id);
                  }}
                >
                  <Clear />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
      </Box>
    </>
  );
};

export const CustomEditor = ({ buildPopup, setBuildPopup }) => {
  const handleSourceChange = (event) => {
    const newLink = event.target.value;
    if (newLink.startsWith("https://")) {
      setBuildPopup((prev) => {
        return {
          ...prev,
          data: {
            src: newLink,
          },
        };
      });
    }
  };

  const handleWChange = (event) => {
    const newWidth = event.target.value;
    if (newWidth > 0 && newWidth <= 100) {
      setBuildPopup((prev) => {
        const dataCopy = prev.data;
        return {
          ...prev,
          data: {
            ...dataCopy,
            width: newWidth,
          },
        };
      });
    }
  };

  const handleHChange = (event) => {
    const newHeight = event.target.value;
    if (newHeight > 0 && newHeight <= 100) {
      setBuildPopup((prev) => {
        const dataCopy = prev.data;
        return {
          ...prev,
          data: {
            ...dataCopy,
            height: newHeight,
          },
        };
      });
    }
  };

  return (
    <Box sx={{ height: "400px" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <GenericField
          label="Embedded Link"
          data={buildPopup.data.src}
          onChange={handleSourceChange}
        />
        <GenericField
          label="Width (%)"
          data={buildPopup.data.width}
          onChange={handleWChange}
          number
        />
        <GenericField
          label="Height (%)"
          data={buildPopup.data.height}
          onChange={handleHChange}
          number
        />
      </Box>
      <iframe
        width={`${buildPopup.data.width}%`}
        height={`${buildPopup.data.height}%`}
        src={buildPopup.data.src}
        style={{ border: "none" }}
      ></iframe>
    </Box>
  );
};

export const CommendationEditor = ({
  buildPopup,
  setBuildPopup,
  projectUsers,
}) => {
  const [showEditButtonText, setShowEditButtonText] = useState(false);
  const [editingLabelText, setEditingLabelText] = useState(false);
  const [text, setText] = useState(buildPopup.data.message ?? "");

  const saveLabelText = () => {
    if (text) {
      setBuildPopup((prev) => {
        const dataCopy = prev.data;
        return {
          ...prev,
          data: {
            ...dataCopy,
            message: text,
          },
        };
      });
      setEditingLabelText(false);
      setShowEditButtonText(false);
    }
  };

  const handleUsersSend = (event) => {
    const newUsers = event.target.value;
    setBuildPopup((prev) => {
      const dataCopy = prev.data;
      return {
        ...prev,
        data: {
          ...dataCopy,
          users: newUsers,
        },
      };
    });
  };

  const userOptions = {};
  for (let user of projectUsers) {
    if (user.name && user.name.first && user.name.last) {
      userOptions[user.id] = `${user.name.first} ${user.name.last}`;
    } else {
      // To handle weird render lag issues
      userOptions[user.id] = "Unnamed User";
    }
  }

  return (
    <>
      <SelectField
        label="Select Users to Receive"
        multi={true}
        options={userOptions}
        data={
          Array.isArray(buildPopup.data.users)
            ? buildPopup.data.users.filter((u) =>
                Object.keys(userOptions).includes(u)
              )
            : buildPopup.data.users && buildPopup.data.users !== ""
            ? buildPopup.data.users
            : []
        } // Filter here because we don't allow undefined users!
        onChange={handleUsersSend}
        fill={true}
      />
      <Typography
        sx={{
          fontSize: "30px",
          display: "flex",
          fontWeight: "bold",
        }}
      >
        You Received a Commendation!
      </Typography>
      <Box
        sx={{ display: "flex" }}
        onMouseEnter={() => setShowEditButtonText(true)}
        onMouseLeave={() => setShowEditButtonText(false)}
      >
        <Typography
          sx={{
            fontSize: "16px",
            display: "flex",
          }}
          component="div"
        >
          {editingLabelText ? (
            <SlimTextField
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              size="small"
            />
          ) : (
            buildPopup.data.message
          )}
        </Typography>
        {editingLabelText ? (
          <>
            <IconButton
              sx={{ padding: 0, marginLeft: "5px" }}
              onClick={saveLabelText}
            >
              <Check fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ padding: 0, marginLeft: "5px" }}
              onClick={() => {
                setEditingLabelText(false);
                setShowEditButtonText(false);
                setText(buildPopup.text ?? "");
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        ) : (
          showEditButtonText && (
            <>
              <Tooltip title="Edit Message">
                <IconButton
                  sx={{ padding: 0, marginLeft: "5px" }}
                  onClick={() => setEditingLabelText(true)}
                >
                  <Edit sx={{ fontSize: "18px" }} />
                </IconButton>
              </Tooltip>
            </>
          )
        )}
      </Box>
      <Commendation />
    </>
  );
};

export const PatchEditor = ({ buildPopup }) => {
  return (
    <AnnouncementDiv style={{ width: "75%" }}>
      <object
          data={buildPopup.data.pdf}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ margin: "10px" }}
        ></object>
    </AnnouncementDiv>
  );
};

const AnnouncementDiv = styled.div`
  width: 90%;
  height: 600px;
  margin: 10px;
  padding-left: 8px;
  border: 1px solid grey;
  background-color: #eeeeee;
  border-radius: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
