import React, { useContext, useState, useEffect } from "react";
import { ThemeProvider, ThemeContext } from "styled-components";
import { useParams } from "react-router-dom";
import q from "@queryit/api";
//UI
import { DataTable } from "../../ui/table";
import { ProjectRouteContainer } from "..";
import {
  DialogContent,
  Modal,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { UserModal } from "../../ui/containers";
import ClearIcon from "@mui/icons-material/Clear";
import { Button2, ButtonSection } from "../../ui/buttons";
import { GenericField } from "../../ui/inputs2";
import { materialThemeContrast } from "../../../themes/mui_theme";
import { UserContext } from "../../../App";
import { ConfirmationModal } from "../../ui/modals";
import { useSnackbar } from "../../../hooks/system";
import { ProjectUsersContext } from "..";

export default () => {
  const [addingUser, setAddingUser] = useState(false); // Adding user modal state
  const [newUser, setNewUser] = useState(EMPTYUSER);
  const [users, setUsers] = useState([]);
  const [emailError, setEmailError] = useState(undefined); // Email errors for the textfield in modal
  const [firstError, setFirstError] = useState(undefined); // First name errors
  const [lastError, setLastError] = useState(undefined); // Last name errors

  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [rowState, setRowState] = useState(undefined);

  const currentUser = useContext(UserContext);
  const projectUsers = useContext(ProjectUsersContext);
  const { projectId } = useParams();
  const { callSnackbar } = useSnackbar();
  const theme = useContext(ThemeContext);

  const userTableColumns = [
    { headerName: "Email", index: "email", sortable: true },
    { headerName: "First Name", index: "name.first", sortable: true },
    { headerName: "Last Name", index: "name.last", sortable: true },
    {
      headerName: "Phone Number",
      index: "phone",
      sortable: true,
    },
    {
      headerName: " ",
      index: "delete",
      component: (row) => (
        <Tooltip title="Remove User">
          <IconButton
            style={{ margin: "auto", display: "flex" }}
            onClick={() => handleDeleteIcon(row)}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      ),
      width: 50,
      align: "center",
      headerAlign: "center",
    },
  ];
  const handleDeleteIcon = (row) => {
    setRowState(row);
    setDeleteUserModal(true);
  };

  const onChange = (ind) => (e) => {
    const { value } = e.target;
    setNewUser((ex) => ({ ...ex, [ind]: value }));
  };

  useEffect(() => {
    if (projectUsers) {
      setUsers(
        projectUsers.map((user) => ({ ...user, phone: user.phone || " " }))
      );
    }
  }, [projectUsers]);

  const createUserRequest = async () => {
    // Check if email is valid, and first and last name are provided
    let hasError = false;

    if (!newUser.email) {
      setEmailError("Please provide email address.");
      hasError = true;
    } else if (!isValidEmail(newUser.email)) {
      setEmailError("Please enter a valid email address.");
      hasError = true;
    } else if (isEmailInProject(newUser.email)) {
      setEmailError("User is already on project.");
      hasError = true;
    }

    if (newUser.first === "") {
      setFirstError("Required");
      hasError = true;
    }

    if (newUser.last === "") {
      setLastError("Required");
      hasError = true;
    }

    if (hasError) {
      return; // Return early if any errors were found
    }
    const cleanedNewUser = {
      name: {
        first: newUser.first.trim(),
        last: newUser.last.trim(),
      },
      email: cleanEmail(newUser.email),
      phone: newUser.phone,
      requestedBy: currentUser.ref.id,
      requestedOn: Date(),
    };
    setNewUser(cleanedNewUser);
    try {
      const userData = {
        name: {
          first: newUser.first,
          last: newUser.last,
        },
        email: cleanEmail(newUser.email),
        phone: newUser.phone,
        requestedBy: currentUser.ref.id,
        requestedOn: Date(),
      };
      // Add userData to the userRequest collection under the current project Id
      q.projects.doc(projectId).collection("userRequests").add(userData);
      callSnackbar(`Sharing Project With ${newUser.email}`);
      setNewUser(EMPTYUSER);
    } catch (error) {
      console.log(error);
    }
    setAddingUser(false);
  };

  const isValidEmail = (email) => {
    // Regex pattern to validate an email address format: non-whitespace characters before and after "@" and a period
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Trim whitespace from beginning and end of email then remove any leading or trailing dots from what remains
    return emailFormat.test(email.trim().replace(/^\.+|\.+$/g, ""));
  };
  // Clean up email and check if
  const cleanEmail = (email) => {
    email = email.trim();
    const [localPart, domainPart] = email.split("@"); // Split the email into localPart before the "@" and domainPart after the "@"
    const cleanedLocalPart = localPart.replace(/^\.+/, "").trim(); // Remove leading dots from the local part of the email and trim any whitespace
    const cleanedDomainPart = domainPart.replace(/\.+$/, "").trim(); // Remove trailing dots from the domain part of the email and trim any whitespace
    return `${cleanedLocalPart}@${cleanedDomainPart}`.trim();
  };

  const isEmailInProject = (email) => {
    return projectUsers.some((user) => user.email === email);
  };

  const handleDeleteUser = async () => {
    try {
      const userRef = q.projects
        .doc(projectId)
        .collection("users")
        .doc(rowState._id);
      await userRef.delete();
      callSnackbar(`${rowState.col_email} Removed From Project`);
      setDeleteUserModal(false);
    } catch (error) {
      callSnackbar("Error Removing User");
      console.error("Error removing user: ", error);
      setDeleteUserModal(false);
    }
  };

  return (
    <ProjectRouteContainer>
      {deleteUserModal && (
        <ConfirmationModal
          open={deleteUserModal}
          onCancel={() => setDeleteUserModal(false)}
          onConfirm={() => handleDeleteUser()}
        />
      )}
      <Modal
        open={addingUser}
        onClose={() => {
          setAddingUser(false);
          setNewUser(EMPTYUSER);
        }}
      >
        <DialogContent>
          <UserModal>
            <Typography variant="h6">New Project User</Typography>
            <ThemeProvider theme={materialThemeContrast(theme)}>
              <GenericField
                label={"First Name"}
                data={newUser.first}
                onChange={onChange("first")}
                error={firstError}
              />
              <GenericField
                label={"Last Name"}
                data={newUser.last}
                onChange={onChange("last")}
                error={lastError}
              />
              <GenericField
                label={"Email"}
                data={newUser.email}
                onChange={onChange("email")}
                error={emailError}
              />
              <GenericField
                label={"Phone Number"}
                data={newUser.phone}
                onChange={onChange("phone")}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button2
                  label={"Create"}
                  onClick={createUserRequest}
                  sx={{ width: "fit-content" }}
                />
              </Box>
            </ThemeProvider>
          </UserModal>
        </DialogContent>
      </Modal>
      <ButtonSection>
        <Button2
          label="Add User"
          onClick={() => {
            setAddingUser(true);
          }}
        />
      </ButtonSection>
      {users && <DataTable data={users} columns={userTableColumns} />}
    </ProjectRouteContainer>
  );
};

const EMPTYUSER = {
  first: "",
  last: "",
  email: "",
  phone: "",
};
