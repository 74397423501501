import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Navigate, useSearchParams } from "react-router-dom";
import { Button2 } from "../../ui/buttons";
import { LogoNoNav } from "../../ui/logos";

import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import IdentityCheck from "./identity";
import SetPassword from "./setpassword";
import SetPhone from "./setphone";
import SetLocation from "./setlocation";
import VerifyMobile from "./setmobileauth";
import TextLogo from "../../statics2/logos/logo-color.png";
import { useHotkey } from "../../../hooks/system";

import { EULAFormatted } from "../../account/eula";

import q, { deleteField } from "@queryit/api";

/**
 * This is the "Welcome" process, which comes in several steps.
 *
 * 1. The user is presented with a welcome message and a "Get Started" button
 * 2. The user is either logged in automatically or prompted to log in (verifying your identity with little loading thing on autologin)
 * 3. The user is asked to reset their password (if they were auto-logged in) -> they can also opt to use SSO instead at this point
 *  3.5. If the user is mobile-only, they are asked to confirm their phone number (linkWithPhoneNumber in auth is executed here)
 * 4. The user is asked if they'd like to add a phone number (if one is not already present)
 *  4.5. After this step is over, the "firstTime" flag is wiped from the user's account (they cannot return here)
 * 5. The user is presented with the EULA
 * 6. The user is presented with a final thank you message and a link to our help pages (these don't exist yet)
 */
export default ({ user, userData }) => {
  // First extract params from URL
  const [params] = useSearchParams();
  const token = params.get("tk") ?? undefined;
  const mobileCode = params.get("mo") ?? undefined;

  // Now get state
  const [activeStep, setActiveStep] = useState(0);
  const [leaving, setLeaving] = useState(false);

  const [awaitingLogin, setAwaitingLogin] = useState(false);
  const [SSOCredentialsRejected, setSSOCredentialsRejected] = useState(false);
  const [firstTimeComplete, setFirstTimeComplete] = useState(false);

  useEffect(() => {
    // First reset sso rejected especially if userData is now undefined
    setSSOCredentialsRejected(false);
    // Then run check for advancing step
    if (userData && awaitingLogin) {
      if (Object.keys(userData).length < 2) {
        // This means the user is being welcomed but isn't a Query-It User
        // In this case, reject them and make them try identity step again
        setSSOCredentialsRejected(true);
        return;
      }
      setActiveStep(token !== undefined ? 2 : 3);
    }
  }, [userData, token, awaitingLogin]);

  // "jump" so that some auth processes can skip a step here
  const nextStepFSClear = (jump = 1) => {
    setAwaitingLogin(false);
    setActiveStep((ex) => ex + jump);
    setFirstTimeComplete(true);
    user.update({ firstTime: deleteField(), issuedPassword: deleteField() });
  };

  // Add hotkey
  useHotkey(
    "Enter",
    activeStep === 5
      ? () => setLeaving(true)
      : activeStep === 4 || activeStep === 0
      ? () => setActiveStep((ex) => ex + 1)
      : () => {}
  );

  // This means they don't belong here! (firstTimeComplete is true if they've already worked through this process in this session)
  // NOTE: Realistically this means something messed up happened with their account and they require intervention
  if (
    userData &&
    userData?.firstTime === undefined &&
    !firstTimeComplete &&
    !SSOCredentialsRejected
  ) {
    return <SystemErrorDialog />;
  }

  if (userData && userData?.expired === true) {
    return <ExpiredTokenDialog />;
  }

  if (leaving) {
    q.auth.getAuth().signOut();
    return <Navigate to="/app/login" />;
  }

  return (
    <EulaPage>
      <Container>
        <EscapeDiv>
          <IconButton onClick={() => setLeaving(true)}>
            <Close />
          </IconButton>
          <LogoSection>
            <Logo />
            <LogoText>query-it</LogoText>
          </LogoSection>
        </EscapeDiv>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ overflowY: "auto" }}
        >
          <Step>
            <StepLabel>Welcome</StepLabel>
            <StepContent>
              <Typography style={{ marginBottom: 3 }}>
                Welcome to Query-It! We&apos;re glad you&apos;re here. Before
                you get started with our app, we&apos;d like to go through a few
                first time setup questions. This should only take a few minutes.
              </Typography>
              <Button2
                onClick={() => setActiveStep((ex) => ex + 1)}
                label="Get Started"
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Verify your Identity</StepLabel>
            <StepContent>
              <IdentityCheck
                token={token}
                mobileCode={mobileCode}
                setAwaitingLogin={setAwaitingLogin}
                ssoRejected={SSOCredentialsRejected}
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Set a Password</StepLabel>
            <StepContent>
              {mobileCode === undefined && (
                <SetPassword user={user} nextState={nextStepFSClear} />
              )}
              {mobileCode !== undefined && (
                <VerifyMobile
                  userData={userData}
                  nextState={() => nextStepFSClear(2)}
                />
              )}
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Add a Phone Number</StepLabel>
            <StepContent>
              <SetPhone user={user} nextState={nextStepFSClear} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Add Your Location</StepLabel>
            <StepContent>
              <SetLocation user={user} nextState={nextStepFSClear} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>End-User Lisense Agreement</StepLabel>
            <StepContent>
              <Paragraph>
                <EULAFormatted />
              </Paragraph>
              <Button2
                onClick={() => {
                  user
                    .update({ eula: true })
                    .then((_) => setActiveStep((ex) => ex + 1));
                }}
                label="Accept"
              />
              <Button2
                onClick={() => {
                  setLeaving(true);
                }}
                label="Reject"
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Thank you!</StepLabel>
            <StepContent>
              <Typography style={{ marginBottom: 3 }}>
                Thanks again for choosing Query-It! We hope you enjoy your
                experience with our application, and please don&apos;t hesitate
                to contact us with any questions, issues or feedback. We would
                love to hear what you have to say!
              </Typography>
              <Button2
                onClick={() => setLeaving(true)}
                label="Start Using Query-It"
              />
            </StepContent>
          </Step>
        </Stepper>
      </Container>
    </EulaPage>
  );
};

const ExpiredTokenDialog = () => {
  return (
    <FloatingMiniPage>
      <LogoContainer src={TextLogo} />
      Your welcome token has expired. Please contact your system admin to have
      it re-issued.
    </FloatingMiniPage>
  );
};

const SystemErrorDialog = () => {
  return (
    <FloatingMiniPage>
      <LogoContainer src={TextLogo} />
      Your welcome session has been interrupted and can no longer be completed
      securely. Please contact your system admin if this issue persists a retry.
      <Button2 onClick={() => q.auth.getAuth().signOut()}>Retry</Button2>
    </FloatingMiniPage>
  );
};

const FloatingMiniPage = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;

  align-items: center;
  text-align: center;

  background-color: white;
  border-radius: 4px;

  overflow-y: auto;
  overflow-x: hidden;

  position: absolute;
  top: 20%;

  align-self: center;
`;

const EulaPage = styled.div`
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.text};
  font-family: Roboto;
  background: ${(props) => props.theme.background};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  max-height: 90%;

  background: ${(props) => props.theme.step50};
  border-radius: 4px;
`;

const EscapeDiv = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  // left: -30px;
  top: -10px;
  align-items: center;
  justify-content: flex-start;
`;

// Logo
const Logo = styled(LogoNoNav)`
  position: relative;

  height: 32px;
`; // animation: ${LogoEntry} 3s forwards;

const LogoContainer = styled.img`
  width: 80%;
  margin-bottom: 20px;
`;

const LogoText = styled.div`
  color: white;
  margin-left: 15px;

  // font-size: 3rem;
  font-family: sol, ubuntu;
  font-style: strong;
  font-weight: bold;

  font-size: 26px;
`; // animation: ${LogoTextEntry} 3s forwards;

const LogoSection = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 1rem;
  opacity: 1;
  top: -8px;
  position: relative;
`; // animation: ${LogoSectionEntry} 3s forwards;

// Legal doc should have white background
const Paragraph = styled.div`
  color: black;
  max-height: 400px;
  overflow-y: scroll;
  background: white;
  border-radius: 8px;
  padding: 10px;

  font-family: ${(props) => props.theme.font};

  // -webkit-user-modify: read-write-plaintext-only;
`;
