import React, { useRef, useContext, useState } from "react";
import styled from "@emotion/styled";

import { Menu, MenuItem, MenuList, Box } from "@mui/material";

import { KeyboardArrowDown } from "@mui/icons-material";

import { _TableInput } from "./base";

// Context for SCell
const SelectCellChangeContext = React.createContext(undefined);

export default ({ data, setChange, options, disabled, allowNone }) => {
  // Hook to impose focus
  const elem = useRef(null);
  // const boxRef = useRef(null);
  const setFocus = () => {
    elem.current && elem.current.focus();
  };

  // Render option list
  const [viewOptions, setViewOptions] = useState(false);

  // Data State Management
  const msChange = (value) => {
    setFocus();
    setChange((ex) => value);
    setViewOptions(false);
  };

  // 18px height to match html select for now
  return (
    <SelectCellChangeContext.Provider value={msChange}>
      <FauxTableInput
        onClick={() => {
          if (disabled) return; // Disabled so don't do anything!
          setViewOptions(true);
          setFocus();
        }}
        style={{
          height: "18px",
          marginRight: "20px",
          userSelect: "none",
          cursor: disabled ? "inherit" : "pointer",
        }}
      >
        {/* Hidden input to demonstrate focus */}
        <input
          style={{
            width: 0,
            height: 0,
            border: "unset",
            position: "absolute",
          }}
          ref={elem}
          disabled={disabled}
        />
        {/* Label for caret */}
        <label
          style={{
            position: "absolute",
            right: "0px",
            display: "flex",
            alignItems: "center",
            cursor: "inherit",
          }}
        >
          <KeyboardArrowDown
            sx={{
              fontSize: "19px",
              color: disabled ? "text.disabled" : "text.primary",
              cursor: "inherit",
            }}
          />
        </label>
        <SelectedOptionsList disabled={disabled}>
          {data ? options?.[data] : null}
        </SelectedOptionsList>
      </FauxTableInput>
      {/* Actual options to display on focus */}
      <Menu
        open={viewOptions}
        anchorEl={elem.current}
        onClose={() => setViewOptions(false)}
        autoFocus={false}
      >
        <MenuList dense sx={{ p: 0 }}>
          {allowNone && (
            <MenuItem
              key={"none-selected-whitespace-option"}
              dense
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                msChange("");
                // setViewOptions(false);
              }}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            >
              {""}
            </MenuItem>
          )}
          {Object.keys(options ?? {}).map((key) => (
            <MenuItem
              key={key}
              // selected={data?.includes(key)}
              dense
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                msChange(key);
              }}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            >
              {options[key]}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </SelectCellChangeContext.Provider>
  );
};

const SelectedOptionsList = styled.div`
  ${(props) =>
    props.disabled
      ? "color: " + props?.theme?.palette?.text?.disabled + ";"
      : ""}
`;

const FauxTableInput = styled.div`
  ${_TableInput}

  // display: inline-block;
  position: relative;
`;
