import React, { useCallback, useContext, useEffect, useState } from "react";

// Styles
import styled from "styled-components";
import { QueryContent, LinkedQueryString } from "./query";
import { QUERYVIEWS, ROUTEKEYS } from "../../../common/query";
import { QueryFields } from "./fields";

// Views
import NegotiatorView from "./negotiator";
import EvaluationView from "./evaluation";
import ClarificationsView from "./forms/clarifications";
import QueryAdminData from "./admin/edits";
import QueryQRCode from "./print/qrcode";
import FileRevisionsView from "./revisions";
import { PackageDoc, PackageDocContext } from "./fields/packagedoc";

import { useSearchParams } from "react-router-dom";
import { ProjectLogo } from "./logo";
import { UserContext } from "../../../App";
import { CircularProgress } from "@mui/material";
import {
  useCustomFieldset,
  useSchemaCustomizations,
} from "../../../hooks/projects";
import { LinkedQueryNav, swapRouteEnd } from "./queryc_new";

/**
 * This component is used exclusively in the existing_query imports
 * to add components for which the arguments are unique to existing queries,
 * or the component is only implemented on Queries that are already existing
 */

export default React.memo(
  ({
    queryData,
    schema,
    schemaData,
    projectData,
    customStatus,
    setQueryData,
    queries,
    project,
    query,
    setQueryValidationErrors,
    setAdminEdit,
    setStatusMessage,
    readonly, // For concurrency support!
    creatorOnly,
    approversOnly,
    adminEdit, // For admin editing support!
    // From the Query manager
  }) => {
    // Pull query route param
    const [search] = useSearchParams();
    const queryRoute = search.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW);

    switch (queryRoute) {
      // No view if trying to print
      case QUERYVIEWS.PRINT:
        return null;
      case QUERYVIEWS.QUERY:
        return (
          <ExistingQueryContentPane
            queryData={queryData}
            setQueryData={setQueryData}
            schema={schema}
            schemaData={schemaData}
            customStatus={customStatus}
            queries={queries}
            project={project}
            setStatusMessage={setStatusMessage}
            query={query}
            setQueryValidationErrors={setQueryValidationErrors}
            readonly={readonly}
            creatorOnly={creatorOnly}
            approversOnly={approversOnly}
            adminEdit={adminEdit}
          />
        );
      case QUERYVIEWS.NEGOTIATOR:
        return (
          <NegotiatorView
            query={query}
            schemaData={schemaData}
            projectId={projectData.id}
          />
        );
      case QUERYVIEWS.EVALUATOR:
        return (
          <EvaluationView
            query={query}
            setQueryData={setQueryData}
            projectId={projectData.id}
            schemaData={schemaData}
            evaluations={queryData.evaluations && (queryData.evaluations ?? {})}
            isEvaluationTime={customStatus?.current?.statusType === "evaluate"}
            status={queryData.status}
          />
        );
      case QUERYVIEWS.CLARIFICATIONS:
        return (
          <ClarificationsView
            queryData={queryData}
            projectId={projectData.id}
          />
        );
      case QUERYVIEWS.QR_CODE:
        return (
          <QueryQRCode
            qrCodeLink={`https://queryitapp.com/app/projects/${projectData.id}/queries?qq=${schemaData.id}-${queryData.id}`}
          />
        );
      case QUERYVIEWS.ADMIN_QUERY_DATA:
        return (
          <QueryAdminData queryData={queryData} setAdminEdit={setAdminEdit} />
        );
      case QUERYVIEWS.REVISIONS:
        return (
          <FileRevisionsView queryData={queryData} schemaData={schemaData} />
        );
      default:
        return null;
    }
  }
);

const ExistingQueryContentPane = ({
  queryData,
  setQueryData,
  schema,
  schemaData,
  customStatus,
  queries,
  project,
  setQueryValidationErrors,
  setStatusMessage,
  readonly,
  adminEdit,
  creatorOnly,
  approversOnly,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryRoute = searchParams.get(ROUTEKEYS.EXISTING_QUERY_ID);

  const currentUser = useContext(UserContext);
  const userCustomSchema = useSchemaCustomizations(
    project,
    schema,
    currentUser.ref.id
  );
  const fieldSet = useCustomFieldset(userCustomSchema, schemaData);

  const packageDoc = useContext(PackageDocContext);

  const onFieldChange = useCallback(
    (fieldId) => (val) => {
      setQueryData((ex) => ({ ...ex, data: { ...ex.data, [fieldId]: val } }));
    },
    [setQueryData]
  );

  const setFieldChange = useCallback(
    (callback) => {
      setQueryData((ex) => ({ ...ex, data: callback(ex.data) }));
    },
    [setQueryData]
  );

  if (packageDoc !== undefined) {
    return (
      <PackageDoc
        data={queryData.data?.[packageDoc.fieldId]?.[packageDoc?.doc?.id] ?? {}}
        setQueryData={setQueryData}
        setFieldChange={setFieldChange}
        editabilityConditions={{
          forceEdit: adminEdit,
          readonly: readonly,
          creatorOnly: creatorOnly,
        }}
        status={
          customStatus && customStatus.current
            ? customStatus.current.status
            : queryData.status
        }
        setStatusMessage={setStatusMessage}
      />
    );
  }

  return (
    <QueryContent id="drawer-container">
      <ProjectLogo />

      {/* Read-only info on linked query */}
      {schemaData.linkedQueries && (
        <LinkedQueryString>
          Linked:{" "}
          {queryData.linkedQueries?.from &&
          queryData.linkedQueries?.from.length > 0
            ? queryData.linkedQueries?.from.map((linked, i) => (
                <LinkedQueryNav
                  key={linked.id}
                  onClick={() =>
                    setSearchParams({
                      qq: swapRouteEnd(queryRoute, linked.id),
                    })
                  }
                >
                  {`${linked.dynamicId ?? linked.id}`}
                  {i !== queryData.linkedQueries?.from.length - 1 && ","}
                </LinkedQueryNav>
              ))
            : "None"}
        </LinkedQueryString>
      )}
      {fieldSet ? (
        <QueryFields
          data={queryData.data}
          queryData={queryData}
          setQueryValidationErrors={setQueryValidationErrors}
          onFieldChange={onFieldChange}
          setFieldChange={setFieldChange}
          fieldSet={fieldSet}
          editabilityConditions={{
            forceEdit: adminEdit,
            readOnly: readonly,
            creatorOnly: creatorOnly,
            approversOnly: approversOnly,
          }}
          status={
            customStatus && customStatus.current
              ? customStatus.current.status
              : queryData.status
          }
          queries={queries}
          project={project}
        />
      ) : (
        <Centerer>
          <CircularProgress />
        </Centerer>
      )}
    </QueryContent>
  );
};

const Centerer = styled.div`
  width: 100%;
  height: calc(100% - 127px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
