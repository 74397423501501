import React from "react";
import styled from "styled-components";

import ServiceBackground from "../graphics/service-background.png";

import { forwardRef } from "react";

function Service({}, ref) {
  return (
    <ServiceContainer ref={ref}>
      <SectionTitle>Service Offering Levels</SectionTitle>
      <TierBar>
        <Tier>
          <Title>Pro Bono</Title>
          <UList>
            <LItem>
              For non-profits or other organizations working for a social cause
              & there is alignment of vision & values with us
            </LItem>
            <LItem>Limited data storage & limited customizations</LItem>
            <LItem>One way we demonstrate our social responsibility</LItem>
          </UList>
          <PriceContainer>
            <Price>$0-10</Price>
            <PriceUnit>USER/MONTH</PriceUnit>
          </PriceContainer>
        </Tier>

        <Tier style={{ marginLeft: "30px", marginRight: "30px" }}>
          <Title>Business</Title>
          <UList>
            <LItem>
              For small - medium sized businesses; typically &lt;50 people
            </LItem>
            <LItem>Pricing to adapt based on the value being generated</LItem>
            <LItem>Data storage & customization as required by use-case</LItem>
            <LItem>
              Includes consultation to refine standing work processes or
              standardize with industry best-practice
            </LItem>
          </UList>
          <PriceContainer>
            <Price>$10-50</Price>
            <PriceUnit>USER/MONTH</PriceUnit>
          </PriceContainer>
        </Tier>

        <Tier>
          <Title>Enterprise</Title>
          <UList>
            <LItem>
              For larger & well established organizations with established
              existing work processes; typically &gt;50 users
            </LItem>
            <LItem>Data storage & customization as required by use-case</LItem>
            <LItem>
              Enterprise wide integrated solutions, ERP applications, highly
              customized
            </LItem>
            <LItem>
              May include requested and tailored features for your
              highly-specific workload
            </LItem>
          </UList>
          <PriceContainer>
            <PriceUnit style={{ fontSize: "150%" }}>Contact Us</PriceUnit>
            <PriceUnit>FOR A QUOTE</PriceUnit>
          </PriceContainer>
        </Tier>
      </TierBar>
      <Note>
        Note: Price ranges are notional & may be adjusted to suit the specific
        application(s) - value driven pricing
      </Note>
      <a href="mailto:info@queryitapp.com?subject=Scheduling A Demo&body=Hi, I'd like to schedule a Demo to explore Query-It's features and learn how it can benefit my business. Thanks!">
        <DemoButton>Schedule a Demo</DemoButton>
      </a>
    </ServiceContainer>
  );
}

export default forwardRef(Service);

const ServiceContainer = styled.div`
  min-height: 900px;
  width: 100%;
  background-image: url(${ServiceBackground});
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 600px) {
  }
`;
const SectionTitle = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 300%;
  margin-bottom: -70px;

  @media (max-width: 600px) {
    font-size: 250%;
    margin-top: 70px;
    margin-bottom: 20px;
    width: 90%;
    text-align: center;
  }
`;
const TierBar = styled.div`
  height: 540px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: -5%;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    min-height: 1500px;
  }
`;
const Tier = styled.div`
  height: 100%;
  width: 25%;
  background-color: #e8295d;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) and (min-width: 601px) {
    width: 30%;
  }

  @media (max-width: 600px) and (min-width: 381px) {
    height: 579px;
    width: 353px;
    margin-top: 50px;
  }

  @media (max-width: 380px) {
    height: 579px;
    width: 282.4px;
    margin-top: 50px;
  }
`;
const DemoButton = styled.button`
  height: 57px;
  width: 269px;
  margin-top: -5%;
  color: white;
  border: solid #e8295d;
  background-color: #e8295d;
  border-radius: 6px;
  font-size: 110%;
  user-select: none;

  -webkit-transition-duration: 0.4s; // Safari
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: white;
      color: #e8295d;
    }
  }

  @media (max-width: 600px) {
    margin-top: 70px;
    margin-bottom: 50px;
  }
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 150%;
  margin-top: 75px;
  margin-bottom: -10%;
`;
const UList = styled.ul`
  width: 80%;
`;
const LItem = styled.li`
  margin-top: 5%;
  font-size: 100%;

  @media (max-width: 900px) {
    font-size: 85%;
  }
`;
const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const Price = styled.p`
  font-size: 200%;
  margin: 0;
`;
const PriceUnit = styled.p`
  margin: 0;
`;
const Note = styled.p`
  width: 90%;
  font-size: 110%;
  text-align: center;

  @media (max-width: 600px) {
    margin-top: 50px;
  }
`;
