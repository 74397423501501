import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import q from "@queryit/api";
import { isMobile } from "react-device-detect";
import { SettingDiv, SectionTitle } from "./index";
import { Slide, TextField, Button } from "@mui/material";
import { SelectField, GenericField } from "../ui/inputs2";
import { clean_phone_number } from "../../tools";
import { useHotkey } from "../../hooks/system";
import { UserContext } from "../../App";
import { MobileInput } from "../ui/phone";

const mobileOnlyNotificationPreferences = {
  text: "Text",
  app: "In-App Only",
};

const noPhoneNotificationPreferences = {
  email: "Email",
  app: "In-App Only",
};

const notificationPreferences = {
  email: "Email",
  text: "Text",
  app: "In-App Only",
};

const notificationFrequencies = {
  immediate: "Real-Time (Immediate)",
  halfday: "Twice Daily (9am, 5pm)",
  daily: "Daily (9am)",
  twiceweek: "Twice Weekly (Today, 4 days later)",
  weekly: "Weekly (Today)",
};

export default ({ user, userData }) => {
  // states for verifying phone number
  const [verifyPhoneNumber, setVerifiyPhoneNumber] = useState(false);
  const [tempPhoneNumber, setTempPhoneNumber] = useState(userData?.phone ?? "");
  const [error, setError] = useState({ state: false, message: undefined });
  const [confirmationObject, setConfirmationObject] = useState(undefined); // Necessary for mobile auth

  // animation handling
  const [stage, setStage] = useState(1);

  const tryAdvance = () => {
    setError((ex) => ({ ...ex, state: false }));
    // We'll need to check and try to clean a phone number out of this
    // We're stricter on the requirements here because we don't want to text a rando

    // NOTE: Right now we assume that phone number is NA and polish out the +1
    let phoneCandidate = clean_phone_number(tempPhoneNumber);

    // Now check length is 10 and all numbers
    if (phoneCandidate.length === 10 && phoneCandidate.match(/^[0-9]+$/)) {
      // This is a valid phone number
      setTempPhoneNumber(phoneCandidate);
      setStage(2);
      return;
    }
    // If we're here, there's an error
    setError({ state: true, message: "Invalid phone or email" });
  };

  return (
    <>
      {verifyPhoneNumber ? (
        <VerifyPhoneContainer>
          <Slide
            direction="right"
            in={stage == 1}
            appear={false}
            mountOnEnter
            unmountOnExit
          >
            <VerifyPhoneStageContainer>
              <VerifyPhoneError>
                {error.state ? error.message : ""}
              </VerifyPhoneError>
              <VerifyPhoneStage1
                setError={setError}
                tempPhoneNumber={tempPhoneNumber}
                setTempPhoneNumber={setTempPhoneNumber}
                tryAdvance={tryAdvance}
                setVerifyPhoneNumber={setVerifiyPhoneNumber}
              />
            </VerifyPhoneStageContainer>
          </Slide>
          <Slide
            direction="left"
            in={stage == 2 && confirmationObject === undefined}
            mountOnEnter
            unmountOnExit
          >
            <VerifyPhoneStageContainer>
              <VerifyPhoneError>
                {error.state ? error.message : ""}
              </VerifyPhoneError>
              <VerifyPhoneStage2
                setError={setError}
                tempPhoneNumber={tempPhoneNumber}
                setTempPhoneNumber={setTempPhoneNumber}
                setConfirmationObject={setConfirmationObject}
                goBack={() => setStage(1)}
              />
            </VerifyPhoneStageContainer>
          </Slide>
          <Slide
            direction="left"
            in={stage == 2 && confirmationObject !== undefined}
            mountOnEnter
            unmountOnExit
          >
            <VerifyPhoneStageContainer>
              <VerifyPhoneError>
                {error.state ? error.message : ""}
              </VerifyPhoneError>
              <VerifyPhoneStage3
                setError={setError}
                tempPhoneNumber={tempPhoneNumber}
                confirmationObject={confirmationObject}
                goBack={() => setConfirmationObject(undefined)}
                setVerifiyPhoneNumber={setVerifiyPhoneNumber}
              />
            </VerifyPhoneStageContainer>
          </Slide>
        </VerifyPhoneContainer>
      ) : (
        <>
          <SettingDiv>
            <SectionTitle>Contact Information</SectionTitle>
          </SettingDiv>
          <SectionDivider />
          <SettingDiv>
            <GenericField
              data={userData.email}
              disabled
              label="Email"
              contrast
              fill
              style={{ paddingBottom: 0 }}
            />
            {/* <GenericField
            data={userData.phone ?? tempPhoneNumber}
            onChange={(e) => { const { value } = e.target; setTempPhoneNumber(value) }}
            label='Phone Number'
            contrast
            fill
            disabled={userData.phone ? true : false}
            style={{ paddingBottom: 0 }}
          /> */}
            <MobileInput
              data={userData.phone ?? tempPhoneNumber}
              onChange={(e) => {
                const { value } = e.target;
                setTempPhoneNumber(value);
              }}
              fill
              contrast
              disabled={userData.phone ? true : false}
              style={{ paddingBottom: 0 }}
            />
            {!userData?.phoneVerified && (
              <div style={{ display: "flex" }}>
                <SectionText>
                  Your phone number has not been verified
                </SectionText>
                <SectionText
                  style={{ color: "#C7A5FF", cursor: "pointer" }}
                  onClick={() => setVerifiyPhoneNumber(true)}
                >
                  Verify now
                </SectionText>
              </div>
            )}
          </SettingDiv>
          <SettingDiv>
            <SectionTitle>Notifications</SectionTitle>
          </SettingDiv>
          <SectionDivider />
          <SettingDiv>
            <SelectField
              label="Notification Preference"
              options={
                userData.phone === undefined || userData.phone?.length < 1
                  ? noPhoneNotificationPreferences
                  : isMobile
                  ? mobileOnlyNotificationPreferences
                  : notificationPreferences
              }
              data={userData.notification ? userData.notification : "app"}
              onChange={(e) => {
                const { value } = e.target;
                user.update({ notification: value });
              }}
              contrast
              fill
              allowNone={false}
            />
            {userData.notification && userData.notification !== "app" && (
              <SelectField
                label="Notification Frequency"
                options={notificationFrequencies}
                data={
                  userData?.notifyFrequency
                    ? userData.notifyFrequency
                    : "weekly"
                }
                onChange={(e) => {
                  const { value } = e.target;
                  user.update({ notifyFrequency: value });
                }}
                contrast
                fill
                allowNone={false}
              />
            )}
          </SettingDiv>
        </>
      )}
    </>
  );
};

const VerifyPhoneStage1 = ({
  setError,
  tempPhoneNumber,
  setTempPhoneNumber,
  setVerifyPhoneNumber,
  tryAdvance,
}) => {
  useHotkey("Enter", tryAdvance);

  useEffect(() => {
    setError({ state: false, message: undefined }); // When we arrive here the error state should clear
  }, [setError]);

  return (
    <>
      <MobileInput
        data={tempPhoneNumber}
        onChange={(e) => {
          const { value } = e.target;
          setTempPhoneNumber(value);
        }}
        variant="outlined"
        size="small"
        contrast
      />
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={tryAdvance}
      >
        Next
      </Button>
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={() => setVerifyPhoneNumber(false)}
      >
        Cancel
      </Button>
    </>
  );
};

const VerifyPhoneStage2 = ({
  setError,
  tempPhoneNumber,
  setConfirmationObject,
  goBack,
}) => {
  const [sendCodeReady, setSendCodeReady] = useState(false);

  useLayoutEffect(
    () =>
      q.auth.useRecaptchaComponent(
        "visible-recaptcha",
        () => setSendCodeReady(true),
        () => setSendCodeReady(false)
      ),
    []
  );

  const handleLoginAttempt = () => {
    try {
      q.auth
        .linkWithPhoneNumber(tempPhoneNumber)
        .then((confirmationResult) => {
          // Now we'll need to pass the code, so set the stage to stage 3 and save the confirmation result object for submission
          setConfirmationObject(confirmationResult);
        })
        .catch((err) =>
          setError({ state: true, message: "Invalid Credentials" })
        );
    } catch (err) {
      setError({ state: true, message: "No credentials provided" });
    }
  };

  return (
    <>
      <VerifyPhoneError style={{ height: "48px" }}>
        Complete the reCaptcha to have a code sent to your mobile number <br />
        <b>
          +1 {tempPhoneNumber.slice(0, 3)} {tempPhoneNumber.slice(3, 6)}{" "}
          {tempPhoneNumber.slice(6, 10)}
        </b>
      </VerifyPhoneError>
      <Captcha id="visible-recaptcha"></Captcha>
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={() => handleLoginAttempt()}
        disabled={!sendCodeReady}
      >
        Send Code
      </Button>
      <Button
        style={{ width: "70%", marginTop: "4px" }}
        variant="contained"
        color="default"
        onClick={goBack}
      >
        Go Back
      </Button>
    </>
  );
};

const VerifyPhoneStage3 = ({
  setError,
  confirmationObject,
  tempPhoneNumber,
  goBack,
  setVerifiyPhoneNumber,
}) => {
  const [code, setCode] = useState("");
  const [showLogout, setShowLogout] = useState(false);

  const user = useContext(UserContext);

  useHotkey("Enter", submitCode);
  useHotkey("Escape", goBack);

  const submitCode = () => {
    confirmationObject
      .confirm(parseInt(code))
      .then((res) => {
        // Successful verification
        // Update Phone number
        user.update({ phone: tempPhoneNumber, phoneVerified: true });
        setVerifiyPhoneNumber(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Firebase: Error (auth/requires-recent-login).") {
          setError({
            state: true,
            message:
              "Cannot verify phone number right now, try logging in again",
          });
          setShowLogout(true);
          return;
        } else if (
          err.message ===
          "Firebase: Error (auth/account-exists-with-different-credential)."
        ) {
          setError({
            state: true,
            message: "This phone number is already in use",
          });
          return;
        }
        setError({ state: true, message: "Invalid Code" });
        setCode(""); // We reset code on invalid submission
      });
  };

  return (
    <>
      <VerifyPhoneError style={{ height: "48px" }}>
        A code has been sent to your mobile number <br />
        <b>
          +1 {tempPhoneNumber.slice(0, 3)} {tempPhoneNumber.slice(3, 6)}{" "}
          {tempPhoneNumber.slice(6, 10)}
        </b>
      </VerifyPhoneError>
      <TextField
        variant="outlined"
        label="Enter the code sent to your phone"
        size="small"
        style={{ width: "70%" }}
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={() => submitCode()}
      >
        Confirm
      </Button>
      {showLogout && (
        <Button
          style={{ width: "70%", marginTop: "16px" }}
          variant="contained"
          color="secondary"
          onClick={() => q.auth.signOut()}
        >
          Logout
        </Button>
      )}
      <Button
        style={{ width: "70%", marginTop: "4px" }}
        variant="contained"
        color="default"
        onClick={goBack}
      >
        Go Back
      </Button>
    </>
  );
};

const Captcha = styled.div`
  width: 304px;
  height: 78px;
  margin: auto;
  margin-top: 16px;
`;

const VerifyPhoneError = styled.div`
  text-align: left;
  color: ${(props) => props.theme.textAlt};
  font-family: ${(props) => props.theme.font};
  height: 14px;
  width: 70%;
  margin: 0px 0px 12px 0;
  box-sizing: border-box;
`;

const VerifyPhoneContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 8px;

  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerifyPhoneStageContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // margin-top: 10px;
  flex: 2 2 auto;
  position: relative;
`;

const SectionDivider = styled.div`
  background: ${(props) => props.theme.text};
  height: 1px;
  width: 100%;
  opacity: 0.5;
`;

const SectionText = styled.div`
  color: ${(props) => props.theme.text};
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  display: flex;
  margin-left: 10px;
  padding-bottom: 24px;
`;
