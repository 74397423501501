import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { Button2, ButtonSection } from "../../../ui/buttons";
import { ProjectRouteContainer, SelectedSchemaContext } from "../../../project";

import { InteractiveTable } from "../../../ui/table";
import {
  GenericField,
  OrderController,
  BooleanField,
} from "../../../ui/inputs2";
import {
  generate_tempid,
  strip_tempids,
  strip_undefined,
} from "../../../../tools";
import { SchemaContext } from "..";

export default () => {
  const [tblCols, setTblCols] = useState([]);
  const [columns, setColumns] = useState([]);

  const schema = useContext(SchemaContext);
  const schemaData = useContext(SelectedSchemaContext);

  // Set these columns once
  useEffect(() => {
    setColumns([
      {
        name: "Order",
        index: "order",
        component: (row) => (
          <OrderController
            data={row.order}
            onChange={onChange("order", row.__id)}
          />
        ),
      },
      {
        name: "Name",
        component: (row) => (
          <GenericField
            data={row.headerName}
            onChange={onChange("headerName", row.__id)}
            contrast
          />
        ),
      },
      {
        name: "Query Field",
        component: (row) => (
          <GenericField
            data={row.index}
            onChange={onChange("index", row.__id)}
            contrast
          />
        ),
      },
      {
        name: "Sortable",
        component: (row) => (
          <BooleanField
            data={row.sortable}
            onChange={onChange("sortable", row.__id)}
            contrast
          />
        ),
      },
      {
        name: "Format Function",
        component: (row) => (
          <GenericField
            data={row.format}
            onChange={onChange("format", row.__id)}
            contrast
          />
        ),
      },
      {
        name: "...",
        component: (row) => (
          <Button2 label={"Delete"} onClick={() => deleteColumn(row.__id)} />
        ),
      },
    ]);
  }, []);

  // Update the tblCols to store the current columns of the table as it updates
  useEffect(() => {
    if (schemaData) {
      // Add the "columns" property to the project data if it isn't ready already
      if (!schemaData.tableSchema) {
        schema.update({ tableSchema: defaultColumns });
        return;
      }
      // Update tblCols temp var
      let ind = 0;
      setTblCols(
        schemaData.tableSchema.map((col) => {
          ind++;
          return { ...col, order: ind, __id: generate_tempid() };
        })
      );
    }
  }, [schemaData, schema]);

  function saveTable() {
    let uploadCols = tblCols
      .sort((a, b) => a.order - b.order)
      .map((col) => ({ ...col, order: undefined }))
      .map((col) => strip_undefined(strip_tempids(col)));
    schema.update({ tableSchema: uploadCols });
  }

  const deleteColumn = (id) => {
    setTblCols((ex) => [...ex.filter((el) => el.__id !== id)]);
  };

  const onChange = (ind, id) => (e) => {
    const { value } = e.target;
    setTblCols((ex) => [
      ...ex.map((fl) =>
        fl.__id === id
          ? { ...fl, [ind]: ind == "sortable" ? !fl[ind] : value }
          : fl
      ),
    ]);
  };

  if (!tblCols) {
    return null;
  }

  return (
    <ProjectRouteContainer>
      <Message>
        If you do not resolve the column orders to be unique, you are rolling
        the dice man
      </Message>
      {tblCols.length > 0 && (
        <GenericField
          data={tblCols[0].headerName}
          onChange={onChange("headerName", tblCols[0].__id)}
          contrast
        />
      )}
      <ButtonSection>
        <Button2
          label={`New Column`}
          onClick={() => {
            setTblCols((ex) => [
              ...ex,
              {
                headerName: "New Column",
                index: "column-id",
                __id: generate_tempid(),
                sortable: true,
                order:
                  ex.length > 0
                    ? ex.reduce(
                      (a, n) => {
                        if (a.order > n.order) {
                          return a.order;
                        }
                        return n.order;
                      },
                      { order: 0 }
                    ) + 1
                    : 1,
              },
            ]);
          }}
          key={`new-col`}
        />
        <Button2 label="Save Table Changes" onClick={() => saveTable()} />
      </ButtonSection>
      {/* Now we include a table of existing fields where we allow re-ordering & Sectioning */}
      <InteractiveTable
        data={tblCols}
        columns={columns}
        // CollapseComponent={({row}) => <FieldEditor onChange={onFullChange} row={row} statusSet={STATUSES[schemaData.type]} />}
        allowOrdering={true}
        defaultOrder={{ col: "Order", dir: "desc" }}
      />
      <BlankSpace />
    </ProjectRouteContainer>
  );
};

const defaultColumns = [
  {
    // name: "ID",
    headerName: "ID",
    index: "id",
    format: "return row.dynamicId ? row.dynamicId.toUpperCase() : row.id",
    sortable: true,
  },
  {
    // name: "Due Date",
    headerName: "Due Date",
    index: "dueDate",
    sortable: true,
  },
  {
    // name: "Status",
    headerName: "Status",
    index: "status",
    sortable: true,
    format: "return row.status.toUpperCase()",
  },
  {
    // name: "Pending Approvals",
    headerName: "Pending Approvals",
    index: "pendingApprovals",
    sortable: true,
    format:
      "return row.pendingApprovals !== undefined ? row.pendingApprovals : 'N/A'",
  },
];

const Message = styled.div`
  margin: 12px;
  font-size: 18px;
  color: ${(props) => props.theme.text};
`;

const BlankSpace = styled.div`
  height: 50px;
  width: 100%;
`;

// Schema Modifiable Table Styles
export const CTable = styled.table`
  margin: 12px 0;
  flex-grow: 1;
  overflow: auto;
  border-radius: 4px;
  width: 100%;
  border-collapse: collapse;
`;

export const CTblHead = styled.thead``;

export const CTblBody = styled.tbody``;

export const CTblHeadCell = styled.th`
  padding: 6px;

  background: ${(props) => props.theme.tbl.head};
  & * {
    color: ${(props) => props.theme.text};
  }
`;

export const CTblRow = styled.tr`
  background: ${(props) => props.theme.tbl.row1};
  color: ${(props) => props.theme.text};

  :nth-child(2n) {
    background: ${(props) => props.theme.tbl.row2};
  }

  :hover {
    background: ${(props) => props.theme.tbl.hover};
  }
`;

export const CTblCell = styled.td`
  padding: 6px;
`;

export const CTableDiv = styled.div`
  margin-top: 12px;
  flex-grow: 1;
  overflow: auto;
  border-radius: 4px;

  & .rdt_Table {
    font-family: ${(props) => props.theme.font};
  }

  & .rdt_TableHeadRow {
    background: ${(props) => props.theme.tbl.head};
    & * {
      color: ${(props) => props.theme.text};
    }
  }

  & .rdt_TableRow {
    background: ${(props) => props.theme.tbl.row1};
    color: ${(props) => props.theme.text};

    :nth-child(2n) {
      background: ${(props) => props.theme.tbl.row2};
    }

    :hover {
      background: ${(props) => props.theme.tbl.hover};
    }
  }
`;
