import React from "react";

import { QueryContent, QuerySectionHeader } from "../query";
import FormViewer from "./view";
import FormConfigurator from "./new";

export default ({
  queryData,
  setQueryData,
  query,
  schemaData,
  customStatus,
  isNew,
}) => {
  // First let's grab all the forms
  const forms = schemaData?.formCompositions ?? [];

  // Then we'll determine what the index of issues forms is
  const lastIssued = queryData?.lastFormCompositionIssued ?? -1;

  // Now render for each form!
  return (
    <QueryContent>
      {forms.map((form, index) => {
        let formComponent = null;
        if (index > lastIssued) {
          const setFormData = (callback) => {
            setQueryData((ex) => ({
              ...ex,
              formInvitees: [
                ...(ex.formInvitees ?? OBJECT_ARRAY_PLACEHOLDER).splice(
                  0,
                  index
                ),
                callback(ex.formInvitees?.[index] ?? {}),
                ...(ex.formInvitees ?? OBJECT_ARRAY_PLACEHOLDER).splice(
                  index + 1,
                  forms.length
                ),
              ],
            }));
          };
          formComponent = (
            <FormConfigurator
              formComposition={form}
              invitees={queryData?.formInvitees?.[index] ?? {}}
              setFormData={setFormData}
            />
          );
        } else {
          formComponent = (
            <FormViewer
              formComposition={form}
              compositionIndex={index}
              queryData={queryData}
              query={query}
              schemaData={schemaData}
            />
          );
        }

        return (
          <React.Fragment key={index}>
            <QuerySectionHeader>{form.title} Details</QuerySectionHeader>
            {formComponent}
          </React.Fragment>
        );
      })}
    </QueryContent>
  );
};

// DEFECT: This is a bad solution but I'm in a rush
const OBJECT_ARRAY_PLACEHOLDER = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
