import { BaseCollection, BaseData, BaseDocument } from "../base";

interface InfoSchema extends BaseData {
  version: string, // Version of the app
}

export class SystemInfo extends BaseDocument<InfoSchema> {

  constructor(sysCollection: BaseCollection<BaseData>) {
    super(sysCollection.doc('0').ref); // Fetch the only document in the collection
  }

}