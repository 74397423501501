import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { UserDataContext } from "../../../../App";
import {
  useFormComposite,
  useFormNegotiables,
  useForms,
  useFormSchemaset,
} from "../../../../hooks/forms";

import Messenger from "./messenger";

const SENDER_COLORS = [
  "#D32F2F",
  "#FF4081",
  "#303F9F",
  "#448AFF",
  "#388E3C",
  "#8BC34A",
  "#E64A19",
  "#FF9800",
  "#0288D1",
  "#00BCD4",
  "#AFB42B",
  "#00796B",
  "#7C4DFF",
];

export default ({ query, schemaData, projectId }) => {
  const userData = useContext(UserDataContext);

  const forms = useForms(query);
  const [selectedForm, setSelectedForm] = useState(undefined);

  // 1. When a form is selected, we'll need to pull all the schema data!
  const [formComposite, formData] = useFormComposite(
    projectId,
    query.ref.id,
    selectedForm?.formId
  );
  // 1.5. Pull schemaPaths from the formData's composite (it knows its index and we have the schemaData)
  const [formSchemaPaths, setFormSchemaPaths] = useState(undefined);
  useEffect(() => {
    if (!schemaData || !selectedForm) {
      setFormSchemaPaths(undefined);
      return;
    }
    // Get the formIds
    const formIds =
      schemaData.formCompositions?.[selectedForm.compositionIndex]
        ?.includedForms;
    // Assign Schema Paths state
    setFormSchemaPaths(
      formIds?.map(
        (d) => `projects/${projectId}/schemas/${schemaData.id}/forms/${d}`
      )
    );
  }, [selectedForm, schemaData, projectId]);
  // 2. Then we'll pull the schemas for all the forms in the composite
  const formSchemas = useFormSchemaset(formSchemaPaths);

  // Now we'll pull the negotiables for the form
  const negotiables = useFormNegotiables(formSchemas, schemaData);

  if (!forms) {
    return null;
  }

  return (
    <NegotiatorView>
      <MessengerSelector>
        {forms.map((listedForm, i) => {
          let iconContent = "";
          if (listedForm.user && listedForm.user.name) {
            let sp = listedForm.user.name.toUpperCase().split(" ");
            iconContent = sp[0][0] + (sp.length > 1 ? sp[1][0] : "");
          }
          return (
            <MessengerSelectorTile
              key={`mess-sel-${listedForm.id}`}
              onClick={() =>
                setSelectedForm({
                  formId: listedForm.id,
                  compositionIndex: listedForm.compositionIndex,
                })
              }
            >
              <MessengerSelectorTileIcon
                color={SENDER_COLORS[i % SENDER_COLORS.length]}
              >
                {iconContent}
              </MessengerSelectorTileIcon>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 0,
                }}
              >
                <MessengerName>
                  {listedForm.user && listedForm.user.name}
                </MessengerName>
                <MessengerLastMessage>
                  {listedForm.lastMessage}
                </MessengerLastMessage>
              </div>
            </MessengerSelectorTile>
          );
        })}
      </MessengerSelector>
      <MessengerContainer>
        {selectedForm && (
          <Messenger
            form={formComposite}
            formData={formData}
            messager={{
              name: `${userData.name.first} ${userData.name.last}`,
              id: userData.id,
              side: "queryUser",
            }}
            filePath={`/${projectId}/queries/${query.ref.id}/forms/${selectedForm.formId}/uploads`}
            negotiateFiles={negotiables}
          />
        )}
        {forms.length < 1 && (
          <MessengerContainer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Forms Available. Please select negotiators during Query creation
            to enable form messaging
          </MessengerContainer>
        )}
      </MessengerContainer>
    </NegotiatorView>
  );
};

const NegotiatorView = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  max-height: 100%;
  overflow: hidden;
  flex-grow: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const MessengerSelector = styled.div`
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 0;
  background-color: #f0f0f0;
`;

const MessengerSelectorTile = styled.div`
  border-bottom: 1px solid #d0d0d0;
  height: 47px;
  display: flex;
  flex-direction: row;
  padding: 4px;

  background-color: ${(props) => (props.selected ? "grey" : "unset")};

  &:hover {
    background-color: #d2d2d2;
    cursor: pointer;
  }
`;

const MessengerSelectorTileIcon = styled.div`
  min-width: 40px;
  height: 40px;
  margin: 4px 8px;
  border-radius: 20px;
  background-color: ${(props) => props.color ?? props.theme.chartDefaults[0]};
  color: ${(props) => props.theme.text};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessengerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const MessengerName = styled.div`
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  height: 18px;
`;

const MessengerLastMessage = styled.div`
  color: grey;
  font-size: 12px;

  margin-top: 2px;
`;
