import React, { useState, useEffect, useContext, useMemo } from "react";
import styled from "styled-components";

import { useCurrentUsers } from "../../../../hooks/projects";

import { ProjectRouteContainer, SelectedSchemaContext } from "../../../project";
import { Button, Button2, ButtonSection } from "../../../ui/buttons";
import { BooleanField, GenericField, SelectField } from "../../../ui/inputs2";
import {
  Tile,
  ModalPrompt,
  ModalContent,
  ModalFooter,
} from "../../../ui/containers";
import {
  Modal,
  DialogContent,
  Button as FileButton,
  Button as ModalButton,
  Button as ExportButton,
} from "@mui/material";
import { strip_undefined } from "../../../../tools";
import { InteractiveTable } from "../../../ui/table";
import { useQueries } from "../../../../hooks/queries";
import { UserDataContext } from "../../../../App";
import { SchemaContext } from "..";
import { useFormSchemaset } from "../../../../hooks/forms";
import { QueryStatus } from "../../../../hooks/status";

const requireApprovalSignatureAndSignToApproveTypes = ["approval", "custom"];

export default ({ project }) => {
  const users = useCurrentUsers(project);
  const schema = useContext(SchemaContext);
  const schemaData = useContext(SelectedSchemaContext);

  const userData = useContext(UserDataContext);

  // since we're in Administration, we don't need to worry about anonymizedQueries; set to false
  const queries = useQueries(project, false);
  const [tempSchema, setTempSchema] = useState(schemaData);

  const statusSet = useMemo(() => {
    return QueryStatus.get_droppable_statusset(tempSchema ?? {});
  }, [tempSchema]);

  // Pull the form data for export
  const [formSchemaPaths, setFormSchemaPaths] = useState(undefined);
  useEffect(() => {
    if (!schemaData) {
      setFormSchemaPaths(undefined);
      return;
    }
    // Get the formIds
    const formIds = schemaData.formCompositions?.[0]?.includedForms;
    // Assign Schema Paths state
    setFormSchemaPaths(
      formIds?.map(
        (d) => `projects/${project.ref.id}/schemas/${schemaData.id}/forms/${d}`
      )
    );
  }, [schemaData, project]);
  // 2. Then we'll pull the schemas for all the forms in the composite
  const forms = useFormSchemaset(formSchemaPaths);

  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmQueryModal, setConfirmQueryModal] = useState(false);

  useEffect(() => {
    if (schemaData) {
      setTempSchema(schemaData);
    }
  }, [schemaData]);

  const onPropChange = (ind) => (e) => {
    const { value } = e.target;
    setTempSchema((ex) => ({ ...ex, [ind]: value }));
  };

  const onBoolPropChange =
    (ind, initial = false) =>
    (e) => {
      setTempSchema((ex) => ({
        ...ex,
        settings: {
          ...ex.settings,
          [ind]:
            ex.settings?.[ind] !== undefined ? !ex.settings[ind] : !initial,
        },
      }));
    };

  const onSettingsPropChange = (ind) => (e) => {
    const { value } = e.target;
    setTempSchema((ex) => ({
      ...ex,
      settings: {
        ...ex.settings,
        [ind]: value,
      },
    }));
  };

  // const onDefaultPropChange = (ind) => (e) => {
  //   setTempSchema((ex) => ({
  //     ...ex,
  //     hideDefaults: {
  //       ...ex.hideDefaults,
  //       [ind]:
  //         ex.hideDefaults && ex.hideDefaults[ind] !== undefined
  //           ? !ex.hideDefaults[ind]
  //           : true,
  //     },
  //   }));
  // };

  const toggleSchemaWhitelist = () => {
    setTempSchema((ex) =>
      strip_undefined({ ...ex, whitelist: ex.whitelist ? undefined : [] })
    );
  };

  const toggleWhitelistUser = (userId) => {
    setTempSchema((ex) => ({
      ...ex,
      whitelist: ex.whitelist.includes(userId)
        ? ex.whitelist.filter((wh) => wh !== userId)
        : [...ex.whitelist, userId],
    }));
  };

  const toggleDeleteWhitelist = () => {
    setTempSchema((ex) =>
      strip_undefined({
        ...ex,
        deleteWhitelist: ex.deleteWhitelist ? undefined : [],
      })
    );
  };

  const toggleDeleteWhitelistUser = (userId) => {
    setTempSchema((ex) => ({
      ...ex,
      deleteWhitelist: ex.deleteWhitelist.includes(userId)
        ? ex.deleteWhitelist.filter((wh) => wh !== userId)
        : [...ex.deleteWhitelist, userId],
    }));
  };

  const toggleCancelWhitelist = () => {
    setTempSchema((ex) =>
      strip_undefined({
        ...ex,
        cancelWhitelist: ex.cancelWhitelist ? undefined : [],
      })
    );
  };

  const toggleCancelWhitelistUser = (userId) => {
    setTempSchema((ex) => ({
      ...ex,
      cancelWhitelist: ex.cancelWhitelist.includes(userId)
        ? ex.cancelWhitelist.filter((wh) => wh !== userId)
        : [...ex.cancelWhitelist, userId],
    }));
  };

  const toggleDuplicateWhitelistUser = (userId) => {
    setTempSchema((ex) => ({
      ...ex,
      duplicateWhitelist:
        ex.duplicateWhitelist?.includes(userId) ?? false
          ? ex.duplicateWhitelist?.filter((wh) => wh !== userId)
          : [...(ex.duplicateWhitelist ?? []), userId],
    }));
  };

  const toggleEnableQRCodeGeneration = () => {
    setTempSchema((ex) =>
      strip_undefined({
        ...ex,
        enableQRCodeGeneration: ex.enableQRCodeGeneration ? undefined : [],
      })
    );
  };

  const toggleEnableQRCodeGenerationUser = (userId) => {
    setTempSchema((ex) => ({
      ...ex,
      enableQRCodeGeneration: ex.enableQRCodeGeneration.includes(userId)
        ? ex.enableQRCodeGeneration.filter((wh) => wh !== userId)
        : [...ex.enableQRCodeGeneration, userId],
    }));
  };

  const saveSchema = () => {
    schema.set(tempSchema);
  };

  const saveSchemaRevision = async () => {
    // Now create a new revision
    let revision = await schema.revisions.add({
      schema: tempSchema,
      publisher: userData.id,
      publishedAt: new Date(),
      editor: userData.id,
      editedAt: new Date(),
    });
    // Then update the revisionId in the schema

    // Then save tempSchema as schema
    schema.set({ ...tempSchema, revisionId: revision });
  };

  const newSchema = () => {
    project.schemas.add({
      name: " - New Schema",
      type: "response",
      fields: [],
    });
  };

  const deleteSchema = () => {
    project.schemas.schema(schemaData.id).delete();
  };

  const clearQueries = () => {
    queries
      .filter((q) => q.schemaId === schemaData.id)
      .forEach((q) => {
        project.queries.query(q.id).delete();
      });
  };

  /**
   * Migration related functions
   */

  const [schemaBlueprint, setSchemaBlueprint] = useState({});
  const [formsBlueprint, setFormsBlueprint] = useState([]);
  const [pendingUpload, setPendingUpload] = useState(undefined);
  const [fileData, setFileData] = useState(undefined);
  const [uploadType, setUploadType] = useState(undefined);

  function handleSchemaImportUpload(e) {
    if (e.target.files.length < 1) {
      return;
    } // File must have been uploaded
    let f = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (() => {
      return (e) => {
        setFileData(e.target.result);
        setUploadType("Schema");
      };
    })(f);
    reader.readAsText(f);
  }

  function handleFormImportUpload(e) {
    if (e.target.files.length < 1) {
      return;
    } // File must have been uploaded
    let f = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (() => {
      return (e) => {
        setFileData(e.target.result);
        setUploadType("Form");
      };
    })(f);
    reader.readAsText(f);
  }

  function completeSchemaUpload() {
    // Commit the schema blueprint and clean up
    project.schemas.add(JSON.parse(fileData)).then(() => clearUpload());
  }

  function completeFormUpload() {
    // Commit the form blueprint to the schema and clean up
    let fms = JSON.parse(fileData);
    Promise.all(fms.map(async (fm) => await schema.forms.add(fm))).then(() =>
      clearUpload()
    );
  }

  function clearUpload() {
    setFileData(undefined);
    setPendingUpload(undefined);
    setUploadType(undefined);
  }

  useEffect(() => {
    if (!schemaData) {
      setSchemaBlueprint({});
      return;
    }
    let proposedSchemaBlueprint = {
      fields: schemaData.fields,
      name: schemaData.name,
      settings: schemaData.settings,
      tableSchema: schemaData.tableSchema,
      analyticsSchema: schemaData.analyticsSchema,
      dynamicIdSchema: schemaData.dynamicIdSchema, // NOTE: We neglect dynamicIdMap intentionally because it's deprecated
      type: schemaData.type,
      customStatusSet: schemaData.customStatusSet,
      linkedQueries: schemaData.linkedQueries,
      autoGenerateTask: schemaData.autoGenerateTask,
      formCompositions: schemaData.formCompositions, // I know these Ids will be wrong, but that's okay, we can replace them
      defaultDateLeads: schemaData.defaultDateLeads,
      defaultApprovers: schemaData.defaultApprovers,
      defaultDistribution: schemaData.defaultDistribution,
      enableQRCodeGeneration: schemaData.enableQRCodeGeneration,
      lastEditBy: userData.name
        ? `${userData.name.first} ${userData.name.last}`
        : "Unknown",
    };
    // Then filter out undefined
    setSchemaBlueprint(strip_undefined(proposedSchemaBlueprint));
  }, [schemaData, userData]);

  useEffect(() => {
    if (!forms) {
      return;
    }
    let proposedFormBlueprints = Object.values(forms).map((fs) => {
      return { ...fs, id: undefined };
    });
    // Then filter out undefined
    setFormsBlueprint(proposedFormBlueprints.map((fs) => strip_undefined(fs)));
  }, [forms]);

  useEffect(() => {
    // Handle new file upload for new schema
    if (!fileData) {
      return;
    }
    // And all of this data actually makes up the schema reasonably, so we just use it to make one!
    let blueprint = JSON.parse(fileData);
    setPendingUpload(blueprint);
  }, [fileData]);

  return (
    <ProjectRouteContainer>
      <Modal open={confirmModal} onClose={() => setConfirmModal(false)}>
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>Are you sure?</h2>
              <p>
                This action is permanent, and the entire schema will be lost.
                Are you sure {"you'd"} like to proceed?
              </p>
            </ModalContent>
            <ModalFooter>
              <Button text={"Cancel"} onClick={() => setConfirmModal(false)} />
              <Button
                text={"OK"}
                onClick={() => {
                  setConfirmModal(false);
                  deleteSchema();
                }}
              />
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      <Modal
        open={confirmQueryModal}
        onClose={() => setConfirmQueryModal(false)}
      >
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>Are you sure?</h2>
              <p>
                This action is permanent, and every Query in this Schema will be
                lost. Are you sure {"you'd"} like to proceed?
              </p>
            </ModalContent>
            <ModalFooter>
              <Button
                text={"Cancel"}
                onClick={() => setConfirmQueryModal(false)}
              />
              <Button
                text={"OK"}
                onClick={() => {
                  setConfirmQueryModal(false);
                  clearQueries();
                }}
              />
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      {Object.keys(schemaData ?? {}).length > 0 &&
        Object.keys(tempSchema ?? {}).length > 0 && (
          <Tile>
            <Message>
              Hey this is where you can edit the selected schema properties
            </Message>
            <ButtonSection>
              <Button2 label={"Save Changes"} onClick={saveSchema} />
              <Button2
                label={"Save as Revision"}
                onClick={saveSchemaRevision}
              />
              <Button2
                label={"Delete Schema"}
                onClick={() => setConfirmModal(true)}
              />
              <Button2
                label={"Clear Queries"}
                onClick={() => setConfirmQueryModal(true)}
              />
            </ButtonSection>
            <ButtonSection style={{ marginTop: "10px" }}>
              <ExportButton variant="contained" component="label">
                <ExportA
                  type="button"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(schemaBlueprint)
                  )}`}
                  download={`${schemaData.name.replaceAll(
                    " ",
                    "_"
                  )}_project_blueprint.json`}
                >
                  Export Schema Blueprint
                </ExportA>
              </ExportButton>
              <ExportButton variant="contained" component="label">
                <ExportA
                  type="button"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(formsBlueprint)
                  )}`}
                  download={`${schemaData.name.replaceAll(
                    " ",
                    "_"
                  )}_formset_blueprint.json`}
                >
                  Export Forms Blueprint
                </ExportA>
              </ExportButton>
            </ButtonSection>
            <GenericField
              label="Name"
              contrast
              data={tempSchema.name}
              onChange={onPropChange("name")}
            />
            <SelectField
              label="Type"
              data={tempSchema.type}
              options={schemaTypes}
              allowNone={false}
              contrast
              onChange={onPropChange("type")}
            />
            <GenericField
              label="Order"
              contrast
              data={tempSchema.order}
              onChange={onPropChange("order")}
            />
            {tempSchema.type !== "custom" && (
              <BooleanField
                label="Enable Commenting"
                contrast
                data={tempSchema.settings?.enableComments}
                onChange={onBoolPropChange("enableComments")}
              />
            )}
            <BooleanField
              label="Enable Tasks"
              contrast
              data={tempSchema.settings?.enableTasks}
              onChange={onBoolPropChange("enableTasks")}
            />
            {tempSchema.settings?.enableTasks && (
              <BooleanField
                label="Require Assignee for Tasks"
                contrast
                data={tempSchema.settings?.requireTaskAssignee}
                onChange={onBoolPropChange("requireTaskAssignee")}
              />
            )}
            {requireApprovalSignatureAndSignToApproveTypes.includes(
              tempSchema.type
            ) && (
              <BooleanField
                label="Require Approval Signature"
                contrast
                data={tempSchema.settings?.requireApprovalSignature}
                onChange={onBoolPropChange("requireApprovalSignature")}
              />
            )}
            {requireApprovalSignatureAndSignToApproveTypes.includes(
              tempSchema.type
            ) && (
              <BooleanField
                label="Sign To Approve"
                contrast
                data={tempSchema.settings?.signToApprove}
                onChange={onBoolPropChange("signToApprove")}
              />
            )}
            <BooleanField
              label="Use Auto-Complete for User Select"
              contrast
              data={tempSchema.settings?.selectUserAutoComplete}
              onChange={onBoolPropChange("selectUserAutoComplete")}
            />
            {tempSchema.settings?.enableTasks &&
              tempSchema.type !== "custom" && (
                <BooleanField
                  label="Tasks Block Query Close"
                  contrast
                  data={tempSchema.settings?.tasksBlockQueryClose ?? true}
                  onChange={onBoolPropChange("tasksBlockQueryClose", true)}
                />
              )}
            {tempSchema.settings?.enableTasks && (
              <SelectField
                label="Tasks Available on Status"
                contrast
                data={tempSchema.settings?.tasksAvailableStatus ?? "open"}
                options={statusSet ?? {}}
                onChange={onSettingsPropChange("tasksAvailableStatus")}
              />
            )}
            {tempSchema.type !== "custom" && (
              <BooleanField
                label="Enable Due Date"
                contrast
                data={tempSchema.settings?.enableDueDate ?? true}
                onChange={onBoolPropChange("enableDueDate", true)}
              />
            )}
            <BooleanField
              label="Enable Query Linking"
              contrast
              data={tempSchema.settings?.generateLinkedQuery ?? false}
              onChange={onBoolPropChange("generateLinkedQuery", true)}
            />
            {tempSchema.settings?.generateLinkedQuery && (
              <BooleanField
                label="Prompt Query Link on Open"
                contrast
                data={tempSchema.settings?.linkQueryOnOpen ?? false}
                onChange={onBoolPropChange("linkQueryOnOpen", true)}
              />
            )}
            <BooleanField
              label="Enable Schema Whitelist"
              contrast
              data={tempSchema.whitelist ? true : false}
              onChange={toggleSchemaWhitelist}
            />
            <BooleanField
              label="Enable Delete Whitelist"
              contrast
              data={tempSchema.deleteWhitelist ? true : false}
              onChange={toggleDeleteWhitelist}
            />
            <BooleanField
              label="Enable Cancel Whitelist"
              contrast
              data={tempSchema.cancelWhitelist ? true : false}
              onChange={toggleCancelWhitelist}
            />
            <BooleanField
              label="Enable QR Code Generation"
              contrast
              data={tempSchema.enableQRCodeGeneration ? true : false}
              onChange={toggleEnableQRCodeGeneration}
            />
            {tempSchema.type === "response" && (
              <BooleanField
                label="Enable Assigned Users"
                contrast
                data={tempSchema.settings?.useAssignedUsers ?? true}
                onChange={onBoolPropChange("useAssignedUsers", true)}
              />
            )}
            <BooleanField
              label="Enable Distribution List"
              contrast
              data={tempSchema.settings?.useDistributionList ?? true}
              onChange={onBoolPropChange("useDistributionList", true)}
            />
            <BooleanField
              label="Enable Personal Fields"
              contrast
              data={!!tempSchema.settings?.enablePersonalFields}
              onChange={onBoolPropChange("enablePersonalFields")}
            />
            <BooleanField
              label="Enable Personal Defaults"
              contrast
              data={!!tempSchema.settings?.enablePersonalDefaults}
              onChange={onBoolPropChange("enablePersonalDefaults")}
            />
            <BooleanField
              label="Show Status Progression"
              contrast
              data={!!tempSchema.settings?.showProgression}
              onChange={onBoolPropChange("showProgression")}
            />
            <BooleanField
              label="Restrict Query Duplication"
              contrast
              data={!!tempSchema.settings?.duplicateRestricted}
              onChange={onBoolPropChange("duplicateRestricted")}
            />
          </Tile>
        )}
      <Spacer />
      {tempSchema?.whitelist && users && (
        <Tile>
          <Message>Schema Whitelist</Message>
          <InteractiveTable
            data={users.map((usr) => ({
              ...usr,
              whitelisted: tempSchema.whitelist.includes(usr.id),
            }))}
            columns={whitelistColumns(toggleWhitelistUser)}
          />
        </Tile>
      )}
      <Spacer />
      {tempSchema?.deleteWhitelist && users && (
        <Tile>
          <Message>Delete Whitelist</Message>
          <InteractiveTable
            data={users.map((usr) => ({
              ...usr,
              deleteWhitelisted: tempSchema.deleteWhitelist.includes(usr.id),
            }))}
            columns={deleteWhitelistColumns(toggleDeleteWhitelistUser)}
          />
        </Tile>
      )}
      <Spacer />
      {tempSchema?.cancelWhitelist && users && (
        <Tile>
          <Message>Cancel Whitelist</Message>
          <InteractiveTable
            data={users.map((usr) => ({
              ...usr,
              cancelWhitelisted: tempSchema.cancelWhitelist.includes(usr.id),
            }))}
            columns={cancelWhitelistColumns(toggleCancelWhitelistUser)}
          />
        </Tile>
      )}
      <Spacer />
      {tempSchema?.settings?.duplicateRestricted && users && (
        <Tile>
          <Message>Duplicate Whitelist</Message>
          <InteractiveTable
            data={users.map((usr) => ({
              ...usr,
              duplicateWhitelisted:
                tempSchema.duplicateWhitelist?.includes(usr.id) ?? false,
            }))}
            columns={duplicateWhitelistColumns(toggleDuplicateWhitelistUser)}
          />
        </Tile>
      )}
      <Spacer />
      {tempSchema?.enableQRCodeGeneration && users && (
        <Tile>
          <Message>Enable QR Code Generation</Message>
          <InteractiveTable
            data={users.map((usr) => ({
              ...usr,
              enableQRCodeGeneration:
                tempSchema.enableQRCodeGeneration.includes(usr.id),
            }))}
            columns={enableQRCodeGenerationColumns(
              toggleEnableQRCodeGenerationUser
            )}
          />
        </Tile>
      )}
      <Tile style={{ marginTop: 10 }}>
        <Modal open={pendingUpload !== undefined} onClose={clearUpload}>
          <DialogContent>
            <ModalPrompt>
              <ModalContent>
                <h2>Are you sure?</h2>
                <p>
                  This import wants to create a new {uploadType}{" "}
                  {uploadType === "Schema" && (
                    <>with the name &quot;{pendingUpload?.name}&quot;</>
                  )}
                </p>
              </ModalContent>
              <ModalFooter>
                <ModalButton onClick={clearUpload}>No</ModalButton>
                <ModalButton
                  onClick={
                    uploadType === "Schema"
                      ? completeSchemaUpload
                      : completeFormUpload
                  }
                >
                  Yes
                </ModalButton>
              </ModalFooter>
            </ModalPrompt>
          </DialogContent>
        </Modal>
        <Message>Add Schemas</Message>
        <ButtonSection>
          <Button2 label={"New Schema"} onClick={newSchema} />
          <FileButton variant="contained" component="label">
            New Schema from Blueprint
            <input type="file" onChange={handleSchemaImportUpload} hidden />
          </FileButton>
          <FileButton variant="contained" component="label">
            New Forms from Blueprint (Applied to current Schema)
            <input type="file" onChange={handleFormImportUpload} hidden />
          </FileButton>
        </ButtonSection>
      </Tile>
    </ProjectRouteContainer>
  );
};

const whitelistColumns = (toggleWhitelistUser) => [
  {
    name: "Name",
    index: "name",
    format: "return `${data.name.first} ${data.name.last}`",
  },
  { name: "Email", index: "email" },
  {
    name: "Allow?",
    component: (row) => (
      <BooleanField
        contrast
        data={row.whitelisted}
        onChange={() => toggleWhitelistUser(row.id)}
      />
    ),
  },
];

const deleteWhitelistColumns = (toggleDeleteWhitelistUser) => [
  {
    name: "Name",
    index: "name",
    format: "return `${data.name.first} ${data.name.last}`",
  },
  { name: "Email", index: "email" },
  {
    name: "Allow?",
    component: (row) => (
      <BooleanField
        contrast
        data={row.deleteWhitelisted}
        onChange={() => toggleDeleteWhitelistUser(row.id)}
      />
    ),
  },
];

const cancelWhitelistColumns = (toggleCancelWhitelistUser) => [
  {
    name: "Name",
    index: "name",
    format: "return `${data.name.first} ${data.name.last}`",
  },
  { name: "Email", index: "email" },
  {
    name: "Allow?",
    component: (row) => (
      <BooleanField
        contrast
        data={row.cancelWhitelisted}
        onChange={() => toggleCancelWhitelistUser(row.id)}
      />
    ),
  },
];

const duplicateWhitelistColumns = (toggleDuplicateWhitelistUser) => [
  {
    name: "Name",
    index: "name",
    format: "return `${data.name.first} ${data.name.last}`",
  },
  { name: "Email", index: "email" },
  {
    name: "Allow?",
    component: (row) => (
      <BooleanField
        contrast
        data={row.duplicateWhitelisted}
        onChange={() => toggleDuplicateWhitelistUser(row.id)}
      />
    ),
  },
];

const enableQRCodeGenerationColumns = (toggleEnableQRCodeGenerationUser) => [
  {
    name: "Name",
    index: "name",
    format: "return `${data.name.first} ${data.name.last}`",
  },
  { name: "Email", index: "email" },
  {
    name: "Allow?",
    component: (row) => (
      <BooleanField
        contrast
        data={row.enableQRCodeGeneration}
        onChange={() => toggleEnableQRCodeGenerationUser(row.id)}
      />
    ),
  },
];

const schemaTypes = {
  response: "Response",
  decision: "Decision",
  approval: "Approval",
  // lite: "Lite",
  custom: "Custom",
};

const Message = styled.div`
  margin: 12px;
  font-size: 18px;
  color: ${(props) => props.theme.text};
`;

const Spacer = styled.div`
  min-height: 10px;
  width: 3px;
`;

const ExportA = styled.a`
  text-decoration: none;
  color: white;
`;
