import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  Button as FileButton,
  Button as ModalButton,
  Modal,
  DialogContent,
} from "@mui/material";

import {
  ModalPrompt,
  ModalContent,
  ModalFooter,
  Tile,
} from "../../../ui/containers";

import { CSVButton } from "../../../project/export";
import { ProjectRouteContainer, SelectedSchemaContext } from "../../../project";
import { InteractiveTable } from "../../../ui/table";
import { generate_tempid } from "../../../../tools";

export default ({ project, userData }) => {
  const schemaData = useContext(SelectedSchemaContext);

  const [pendingUpload, setPendingUpload] = useState(false);
  const [fileData, setFileData] = useState(undefined);
  const [fileRead, setFileRead] = useState(undefined);
  const [schemaMeta, setSchemaMeta] = useState(undefined);

  useEffect(() => {
    if (schemaData) {
      // Set schemaMeta
      // fields are the fields specified in step 2.
      // exportObj is for the file export in step 1.
      let meta = {
        name: schemaData.name,
        id: schemaData.id,
        fields: [],
        exportObj: "",
      };
      // Make a function for the repeated bit
      const processField = (singleField) => {
        // Don't include if it's one of these types
        if (["files"].includes(singleField.type)) {
          return;
        }
        // Also don't include if the field is never editable
        if (
          !singleField.editableWhile ||
          singleField.editableWhile.length < 1
        ) {
          return;
        }
        // Now skip if it's a linked selector
        if (singleField.type === "select" && singleField.source) {
          return;
        }
        // Now skip if the type of the field is conditional
        if (singleField.type.conditionalOn) {
          return;
        }
        // Now build the field and give it the push
        let newField = {
          id: singleField.id,
          name: singleField.name,
          type: singleField.type,
        };
        if (["select", "radio", "boxset"].includes(singleField.type)) {
          newField.options = singleField.conditionalOn
            ? Object.values(singleField.options).reduce(
              (acc, nrr) => [...acc, ...Object.keys(nrr)],
              []
            )
            : Object.keys(singleField.options);
        }
        if (!["table", "statictable"].includes(singleField.type)) {
          meta.fields.push(newField);
        } else {
          // We'll include the static table rows length or 1 table row
          // Here we inject the set of fields IN the table
          let rowNum = 1;
          (singleField.rows ? singleField.rows : [1]).forEach((_) => {
            // This is now adding per row
            singleField.columns.forEach((col) => {
              if (col.type === "rowindex") {
                return;
              } // skip rowindex
              // Also skip on a couple standard checks
              // Don't include if it's one of these types
              if (["files"].includes(col.type)) {
                return;
              }
              // Also don't include if the field is never editable
              if (!col.editableWhile || col.editableWhile.length < 1) {
                return;
              }
              let tblField = {
                __id: generate_tempid(), // We need these on table fields because they're non-unique
                id: col.id,
                tableId: singleField.id,
                name: `${singleField.name} (${rowNum}) - ${col.name}`,
                row: rowNum,
                type: col.type,
              };
              if (col.type === "select") {
                tblField.options = col.conditionalOn
                  ? Object.values(col.options).reduce(
                    (acc, nrr) => [...acc, ...Object.keys(nrr)],
                    []
                  )
                  : Object.keys(col.options);
              }
              // Finally add this field to the field meta :)
              meta.fields.push(tblField);
            });
            // Then incriment the counter in case there's more than one row
            rowNum++;
          });
        }
      };
      // Process the schema fields and add to meta
      schemaData.fields.forEach((fld) => {
        if (fld.section) {
          fld.section.forEach((secFld) => {
            if (secFld.nested) {
              secFld.nested.forEach((nestFld) => {
                processField(nestFld);
              });
            } else {
              processField(secFld);
            }
          });
        } else {
          if (fld.nested) {
            fld.nested.forEach((nestFld) => {
              processField(nestFld);
            });
          } else {
            processField(fld);
          }
        }
      });
      // Now use these fields to setup the export object
      meta.exportObj = meta.fields
        .map((fld) => fld.name.replace(/,/g, "_"))
        .join(", ");
      // Now set this to state
      setSchemaMeta(meta);
    }
    // Reset the pending on change to schema
    clearUpload();
  }, [schemaData]);

  useEffect(() => {
    if (fileData) {
      // Read the file for query rows
      let rows = fileData.split("\n");
      let dataPackage = rows.map((rr) => rr.split(","));
      dataPackage.shift(); // Remove the headings
      dataPackage.pop(); // The last will always contain an empty string
      // Now that we've read all possible rows from the file, process
      let potentialQueries = [];
      dataPackage.forEach((dt) => {
        // First a check
        if (dt.length != schemaMeta.fields.length) {
          return;
        }
        // Now we assume all of the data is here and move on to creating a map
        let potquery = {
          creator: userData.id,
          createTime: new Date(),
          status: "created",
          schemaId: schemaMeta.id,
          data: {},
          forms: {},
          distribution: [],
        };
        // Now apply the custom defaults as would occur in any new query

        // Also, the schema may have a default distribution list given the form.
        // If so, assign that.

        const {
          initialDistributionList,
          initialAssignedUser,
          defaultLeadTime,
          customStatusSet,
        } = schemaData;

        if (initialDistributionList) {
          let distributionList;
          // If the distribution list is conditional on some column, use that to set list
          if (initialDistributionList.conditionalOn) {
            distributionList =
              initialDistributionList[
              potquery.data[initialDistributionList.conditionalOn]
              ];
          } else {
            distributionList = initialDistributionList;
          }
          potquery["distribution"] = distributionList.map((val) => val.trim());
        }

        const needsCustomApproval =
          customStatusSet &&
          Object.values(customStatusSet).some(
            (st) =>
              st.advance &&
              st.advance.requirements.some((rq) => rq.type === "approve")
          );

        if (schemaData.type === "approval" || needsCustomApproval) {
          potquery["approvalsRequired"] = needsCustomApproval ? [{}] : [];
        }

        if (initialAssignedUser) {
          let assignedUser;
          // If the distribution list is conditional on some column, use that to set list
          if (initialAssignedUser.conditionalOn) {
            assignedUser =
              initialAssignedUser[
              potquery.data[initialAssignedUser.conditionalOn]
              ];
          } else {
            assignedUser = initialAssignedUser;
          }
          potquery["assignedUser"] = assignedUser;

          if (schemaData.type == "approval")
            potquery["approvalsRequired"] = [assignedUser];
        }

        if (defaultLeadTime) {
          let currdt = new Date();
          potquery["dueDate"] = currdt.setDate(
            currdt.getDate() + defaultLeadTime
          );
          potquery["overdueNotified"] = 0;
        }

        if (customStatusSet && schemaData.type === "custom") {
          potquery.status = Object.values(customStatusSet).find(
            (stt) => stt.statusType === "created"
          ).status;
        }

        // Add empty response object if needed.
        if (schemaData.type === "response") {
          potquery["response"] = {
            userId: null,
            data: {},
          };
        }

        // Update status to closed at this point if decision schema
        if (schemaData.type === "decision") {
          potquery["schema"] = "closed";
        }

        if (schemaData.forms) {
          Object.keys(schemaData.forms).forEach((formId) => {
            potquery[formId] = {
              sendEmails: true,
              invitees: [{}],
              secure: schemaData.forms[formId].secure,
              name: schemaData.forms[formId].name,
              previewQuery: schemaData.forms[formId].previewQuery
                ? schemaData.forms[formId].previewQuery
                : [],
            };
          });
        }

        // Now cover package defaulting
        if (schemaData.package) {
          let tempObj = {};
          schemaData.package.forEach(
            (pk) => (tempObj[pk.id] = { data: "", modified: "Never" })
          );
          potquery.package = tempObj;
        }

        // Add the data now
        let failure = false;
        dt.forEach((fld, i) => {
          // Here we blindly trust that all the data is right
          let field = schemaMeta.fields[i];
          if (field.tableId) {
            if (potquery.data[field.tableId]) {
              potquery.data[field.tableId][field.row - 1] = {
                ...potquery.data[field.tableId][field.row - 1],
                [field.id]: field.type == "number" ? Number(fld) : fld,
              };
            } else {
              potquery.data[field.tableId] = [
                { [field.id]: field.type == "number" ? Number(fld) : fld },
              ];
            }
          } else {
            potquery.data[field.id] =
              field.type == "number" ? Number(fld) : fld;
          }
        });
        // If any of the rows failed checks, dip
        if (failure) {
          return;
        }
        potentialQueries.push(potquery);
      });
      setFileRead(potentialQueries);
      setPendingUpload(true);
    } else {
      setFileRead(undefined);
    }
  }, [fileData, schemaMeta, schemaData, userData]);

  function handleImportUpload(e) {
    if (e.target.files.length < 1) {
      return;
    } // File must have been uploaded
    let f = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (() => {
      return (e) => {
        setFileData(e.target.result);
      };
    })(f);
    reader.readAsText(f);
  }

  function completeUpload() {
    // Commit the file queries
    fileRead.forEach((newQ) => project.queries.add(newQ));
    // Cleanup
    clearUpload();
  }

  function clearUpload() {
    setFileData(undefined);
    setPendingUpload(false);
  }

  if (!schemaData) {
    return null;
  } // Don't render until schema data exists

  return (
    <ProjectRouteContainer>
      <Modal open={pendingUpload} onClose={clearUpload}>
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>Are you sure?</h2>
              <p>
                This import wants to create {fileRead ? fileRead.length : 0} new
                Queries in {schemaMeta ? schemaMeta.name : "Undefined"}. Is this
                count accurate and are you sure you want to proceed?
              </p>
            </ModalContent>
            <ModalFooter>
              <ModalButton onClick={clearUpload}>No</ModalButton>
              <ModalButton onClick={completeUpload}>Yes</ModalButton>
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      {/* <Modal
      isOpen={pendingUpload}
      style={ModalStyle}
      contentLabel="Query Dirty Modal"
    >
      <h2>Are you sure?</h2>
      <p>This import wants to create {fileRead ? fileRead.length : 0} new Queries in {schemaMeta ? schemaMeta.name : 'Undefined'}. 
        Is this count accurate and are you sure you want to proceed?</p>
      <QueryButtons>
        <Button onClick={completeUpload} text={`Yes`}/>
        <Button onClick={clearUpload} text={`No`}/>
      </QueryButtons>
    </Modal> */}
      <SectionHeader>Step 1. Download Template</SectionHeader>
      <CSVButton
        data={schemaMeta ? schemaMeta.exportObj : []}
        filename={`${schemaData.name
          .replace(" ", "_")
          .toLowerCase()}_query_import_template.csv`}
      >
        Download
      </CSVButton>
      <SectionHeader>Step 2. Data Population Guide</SectionHeader>
      <Explanation>
        Please make sure to adhere to the typing rules below. Anything not
        mentioned has no restrictions.<br></br>
        Also note that tables WILL NOT be populated by the import/export and
        should be populated manually in the app.<br></br>
        The same is true of connected fields which reference other queries, as
        well as upload fields.
      </Explanation>
      {schemaMeta && (
        <Tile>
          <InteractiveTable
            columns={[
              {
                name: "Name",
                format:
                  "return (data.tableId) ? `[Table] ${data.name}` : data.name",
              },
              { name: "Type", index: "type" },
              {
                name: "options",
                format:
                  'return (data.options) ? data.options.join(", ") : "N/A"',
              },
            ]}
            data={schemaMeta.fields}
            onRowClick={() => { }} // Added this for the hover effect to make a row easier to see :)
          />
        </Tile>
      )}
      <SectionHeader>Step 3. Upload Data</SectionHeader>
      <FileButton
        variant="contained"
        component="label"
        style={{ width: "fit-content" }}
      >
        Upload Queries
        <input
          type="file"
          onChange={handleImportUpload}
          hidden
        />
      </FileButton>
    </ProjectRouteContainer>
  );
};

const SectionHeader = styled.div`
  font-size: 19px;
  color: ${(props) => props.theme.text};
  margin: 20px;
  margin-left: 0;
`;

const Explanation = styled.p`
  color: ${(props) => props.theme.text};
`;