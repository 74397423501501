import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ThemeProvider } from "styled-components";
import {
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { RecoilRoot } from "recoil";

import Project from "./components/project";

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  useSearchParams,
  Outlet,
} from "react-router-dom";
import { useUser, useUserAuth, useNotifications } from "./hooks/users";

import SiteLanding from "./components/statics2/homepage";
import MoreAbout from "./components/statics2/moreabout";
import Login from "./components/auth/login";
import Welcome from "./components/auth/firsttime";
import Notifications from "./components/notifications";
import Actions from "./components/actions";
import Administration from "./components/admin";
import FormView from "./components/form";
import Eula from "./components/eula";
import Terms from "./components/statics2/termsofservice";
import PrivacyPolicy from "./components/statics2/privacy";
import Account from "./components/account";
import Badges from "./components/statics2/badges";
import Support from "./components/statics2/support";
import { ErrorFeedback404 } from "./components/error/ErrorFeedback404";
import { useProjects } from "./hooks/projects";
import { SnackbarProvider, useHotkey, useUpdateCheck } from "./hooks/system";
import { SnackbarManager } from "./components/ui/popups/snackbar";

import themes from "./themes";
import { materialTheme } from "./themes/mui_theme";

import q from "@queryit/api";
import { APIStorage } from "@queryit/api";
import "./themes/global.css";

// import TestPage from "./testing";
import { Header } from "./components/header";
import { panelTheme } from "./themes/mui_theme_panels";

import EBound from "./components/ui/errorbounds";
import NewQuery from "./components/project/query/new_query";
import ExistingQuery from "./components/project/query/existing_query";
import Search from "./components/search";
import { Button2 } from "./components/ui/buttons";
import { UpdatePrompt } from "./components/ui/update";
import { ROUTEKEYS } from "./common/query";

export const MuiPanelThemeContext = React.createContext(undefined);

// Now introduce user & userdata contexts
export const UserDataContext = React.createContext(undefined);
export const UserContext = React.createContext(undefined);

export default () => {
  // Base handles routing for auth-less routes and no protection required zones of the app,
  // Then we hand things off to different handlers for their areas
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <RecoilRoot>
        <ErrorBoundary>
          <AppDiv>
            <Router>
              <Routes>
                <Route path="form/*" element={<Form />} />
                <Route path="termsofservice" element={<Terms />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="app/*" element={<App />} />
                <Route path="/" element={<SiteLanding />} />
                <Route path="/more-about" element={<MoreAbout />} />
                <Route
                  path="unsubscribe"
                  element={<Navigate to="/app/account" />}
                />
                <Route path="/*" element={<ErrorFeedback404 />} />
              </Routes>
            </Router>
          </AppDiv>
        </ErrorBoundary>
      </RecoilRoot>
    </LocalizationProvider>
  );
};

const App = () => {
  const [user, authAttempted] = useUserAuth();
  const userData = useUser(user);
  const [photoLink, setPhotoLink] = useState(undefined);
  const [userTheme, setUserTheme] = useState(themes.purple);
  const projects = useProjects(user);
  const updateReady = useUpdateCheck();

  // Render profile photo only on head re-render
  useEffect(() => {
    if (userData) {
      setUserTheme(
        userData.theme ? themes[userData.theme] ?? themes.purple : themes.purple
      );
      if (userData.profilePhoto) {
        APIStorage.getDownloadURL(
          q.storage.ref("/profiles/" + userData.profilePhoto)
        ).then((url) => {
          setPhotoLink(url);
        });
      } else {
        setPhotoLink(undefined);
      }
    } else if (userData === undefined) {
      setUserTheme(themes.purple);
    }
  }, [userData]);

  // If we haven't attempted auth yet, don't let main app render!
  if (!authAttempted) {
    return <LoadingBoundary />;
  }

  return (
    <ThemeProvider theme={userTheme}>
      <MuiThemeProvider theme={materialTheme(userTheme)}>
        <MuiPanelThemeContext.Provider
          value={panelTheme(materialTheme(userTheme))}
        >
          <SnackbarProvider>
            {/* Yes this is our second appdiv layered on top of the first, it's for style :) */}
            <AppDiv>
              <Routes>
                <Route
                  path="welcome"
                  element={<Welcome user={user} userData={userData} />}
                />
                <Route element={<NoAuthWall user={user} userData={userData} />}>
                  <Route
                    path="login/*"
                    element={<Login projects={projects} user={user} />}
                  />
                </Route>
                <Route
                  element={
                    <AuthWall
                      user={user}
                      authAttempted={authAttempted}
                      userData={userData}
                    />
                  }
                >
                  <Route
                    path="admin/*"
                    element={
                      <Admin
                        user={user}
                        userData={userData}
                        photoLink={photoLink}
                        projects={projects}
                      />
                    }
                  />
                  <Route
                    path="eula"
                    element={<Eula user={user} userData={userData} />}
                  />
                  {/* If not those, default! */}
                  <Route
                    path="*"
                    element={
                      <Main
                        projects={projects}
                        user={user}
                        userData={userData}
                        photoLink={photoLink}
                      />
                    }
                  />
                </Route>
              </Routes>
              {updateReady && <UpdatePrompt />}
              <SnackbarManager />
            </AppDiv>
          </SnackbarProvider>
        </MuiPanelThemeContext.Provider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

const Main = ({ user, userData, projects, photoLink }) => {
  // We also cache the most recently viewed project here to return to when possible
  const [projectBookmark, setProjectBookmark] = useState(undefined);
  // We also need to set the render state for app search
  const [searchActive, setSearchActive] = useState(false);
  // Then add a hook!
  const notifications = useNotifications(user);
  // Add hotkey
  useHotkey("Escape", () => setSearchActive(false));

  // If projects for the user haven't loaded yet, don't let main app render!
  if (projects === undefined) {
    return <LoadingBoundary />;
  }

  return (
    <MainDiv>
      <ProjectDiv>
        <Header
          name={projectBookmark ? projectBookmark.name : ""}
          mode={"app"}
          projects={projects ? projects : []}
          userData={userData}
          user={user}
          photoLink={photoLink}
          unread={notifications.filter((nt) => nt.read === false).length}
          setSearchActive={setSearchActive}
        />
        <Routes>
          <Route path="support" element={<Support userData={userData} />} />
          <Route path="badges" element={<Badges userData={userData} />} />
          <Route
            path="account"
            element={
              <Account user={user} userData={userData} photoLink={photoLink} />
            }
          />
          <Route
            exact
            path="*"
            element={
              <AppInteractiveComponent
                user={user}
                userData={userData}
                projects={projects}
                notifications={notifications}
                projectBookmark={projectBookmark}
                setProjectBookmark={setProjectBookmark}
                photoLink={photoLink}
              />
            }
          />
        </Routes>
        {searchActive && (
          <Search
            projectData={projectBookmark}
            setSearchActive={setSearchActive}
          />
        )}
      </ProjectDiv>
    </MainDiv>
  );
};

export const TimerContext = React.createContext({
  timer: {
    startTime: Date.now(),
    elapsed: 0,
    queryId: "",
    fieldId: "",
    nestedId: "",
    queryPath: "",
    on: false,
    start: false,
  },
  setTimer: () => {},
});

const AppInteractiveComponent = ({
  user,
  userData,
  projects,
  projectBookmark,
  setProjectBookmark,
  notifications,
  photoLink,
}) => {
  // Now check for new or existing query flag
  const [search] = useSearchParams();
  // Retrieve the flags we care about
  const existingQueryIndex = search.get(ROUTEKEYS.EXISTING_QUERY_ID);
  const newQueryIndex = search.get(ROUTEKEYS.NEW_QUERY_ID);

  // TODO: This should be a hook
  const [timer, setTimer] = useState({
    startTime: Date.now(),
    elapsed: 0,
    queryId: "",
    fieldId: "",
    nestedId: "",
    queryPath: "",
    on: false,
    start: false,
  });
  const timerData = { timer, setTimer };

  if (user && userData && Object.keys(userData).length < 2) {
    // This means that the userData exists but is only an id
    return (
      <ErrorDisplayComponent>
        You do not have access to Query-It with these credentials. <br />
        Please contact your system administrator if you believe this is an
        error.
        <div style={{ margin: "30px" }}>
          <Button2 label="Logout" onClick={() => q.auth.signOut()} />
        </div>
      </ErrorDisplayComponent>
    );
  }

  if (user && !userData.eula) {
    return <Navigate to="/app/eula" />;
  }

  if (user && !userData.welcomed && userData.firstTime !== undefined) {
    return <Navigate to="/app/welcome" />;
  }

  if (projects && projects.length < 1) {
    return (
      <ErrorDisplayComponent>
        No Projects have been shared with your account. <br />
        Please contact your system administrator if you believe this is in
        error.
        <div style={{ margin: "30px" }}>
          <Button2 label="Logout" onClick={() => q.auth.signOut()} />
        </div>
      </ErrorDisplayComponent>
    );
  }

  return (
    <UserDataContext.Provider value={userData}>
      <UserContext.Provider value={user}>
        <TimerContext.Provider value={timerData}>
          <Routes>
            {projects && (
              <Route
                path="projects/:projectId/*"
                element={
                  <Project
                    user={user}
                    userData={userData}
                    setProjectBookmark={setProjectBookmark}
                    photoLink={photoLink}
                  />
                }
              />
            )}
            {/* Project Agnostic Views */}
            <Route
              path="notifications"
              element={<Notifications notifications={notifications} />}
            />
            <Route path="actions" element={<Actions />} />
            {/* This route saves users who are returned to the project page with no project */}
            {/* <Route path="projects" exact element={<Navigate to={`projects/${projectBookmark ? projectBookmark.id : projects[0].id}`} />} /> */}
            {/* This route redirects people who are lost in the app */}
            {projects && projects[0] && (
              <Route
                path="*"
                element={
                  <Navigate
                    to={`projects/${
                      projectBookmark ? projectBookmark.id : projects[0].id
                    }`}
                  />
                }
              />
            )}
          </Routes>
          <EBound clearParams userId={userData?.id} area="Query Pane">
            {newQueryIndex && <NewQuery lookupIndex={newQueryIndex} />}
            {existingQueryIndex && (
              <ExistingQuery lookupIndex={existingQueryIndex} />
            )}
          </EBound>
        </TimerContext.Provider>
      </UserContext.Provider>
    </UserDataContext.Provider>
  );
};

const Admin = ({ user, userData, photoLink, projects }) => {
  if (!projects) {
    return <LoadingBoundary />;
  }

  return (
    <MainDiv>
      <UserDataContext.Provider value={userData}>
        <UserContext.Provider value={user}>
          <Administration
            projects={projects}
            user={user}
            userData={userData}
            photoLink={photoLink}
          />
        </UserContext.Provider>
      </UserDataContext.Provider>
    </MainDiv>
  );
};

const Form = () => {
  return (
    <MainDiv>
      <Routes>
        <Route path=":projectId/:queryId/:formId/*" element={<FormView />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </MainDiv>
  );
};

const AuthWall = ({ user, userData, authAttempted }) => {
  const next = encodeURIComponent(window.location.pathname);

  if (authAttempted && user && !userData) {
    return <LoadingBoundary />;
  }

  if (!user) {
    return (
      <Navigate
        to={`/app/login?next=${next == encodeURIComponent("/app") ? "" : next}`}
      />
    );
  }

  return <Outlet />;
};

const NoAuthWall = ({ user }) => {
  const [params] = useSearchParams();
  const next = params.get("next");

  if (user) {
    return <Navigate to={`${next ? next : "/app"}`} />;
  }

  return <Outlet />;
};

// Include class-based error boundary because functional doesn't exist
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorDisplayComponent theme={themes.purple}>
          An error has occurred. Please refresh &amp; Contact an administrator
          if the error persists.
        </ErrorDisplayComponent>
      );
    }

    return this.props.children;
  }
}

export const LoadingBoundary = () => {
  return (
    <Centerer>
      <CircularProgress />
    </Centerer>
  );
};

export const ErrorDisplayComponent = ({ children, theme }) => {
  return (
    <ThemeProvider theme={theme ?? themes}>
      <ErrorDisplay>
        <div style={{ padding: "5px" }}>{children}</div>
      </ErrorDisplay>
    </ThemeProvider>
  );
};

const Centerer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const AppDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.background};
`;

export const MainDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ProjectDiv = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 100%;

  flex-grow: 1;

  ${isMobile &&
  css`
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  `}
`;

const ErrorDisplay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: fit-content;
  min-height: 50%;
  min-width: fit-content;
  border-radius: 20px;

  font-size: 24px;
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.errorColor};
  background-color: ${(props) => props.theme.step100};
`;
