import React from "react";

import {
  ProjectUsersContext,
  ProjectLogoContext,
  ProjectQueriesContext,
  ProjectReferenceContext,
  CurrentProjectContext,
  UserPermissionsContext,
} from "..";
import { ShowFieldErrorsContext } from "./fields";
import { QueryStatusContext, AutoCompleteUserlistContext } from "./query";
import { QueryBatchContext, NewQueryTasksContext } from "./new_query";

export const QueryContextProvider = ({
  projectUsers,
  projectData,
  projectLogo,
  projectQueries,
  projectReference,
  userPermissions,
  showFieldErrors,
  autoCompleteUserlist,
  queryStatus,
  queryBatch,
  newQueryTasks,
  setNewQueryTasks,
  children,
}) => {
  return (
    <ProjectUsersContext.Provider value={projectUsers}>
      <ProjectLogoContext.Provider value={projectLogo}>
        <ProjectQueriesContext.Provider value={projectQueries}>
          <ProjectReferenceContext.Provider value={projectReference}>
            <CurrentProjectContext.Provider value={projectData}>
              <UserPermissionsContext.Provider value={userPermissions}>
                <ShowFieldErrorsContext.Provider value={showFieldErrors}>
                  <AutoCompleteUserlistContext.Provider
                    value={autoCompleteUserlist}
                  >
                    <QueryStatusContext.Provider value={queryStatus}>
                      <QueryBatchContext.Provider value={queryBatch}>
                        <NewQueryTasksContext.Provider
                          value={{ newQueryTasks, setNewQueryTasks }}
                        >
                          {children}
                        </NewQueryTasksContext.Provider>
                      </QueryBatchContext.Provider>
                    </QueryStatusContext.Provider>
                  </AutoCompleteUserlistContext.Provider>
                </ShowFieldErrorsContext.Provider>
              </UserPermissionsContext.Provider>
            </CurrentProjectContext.Provider>
          </ProjectReferenceContext.Provider>
        </ProjectQueriesContext.Provider>
      </ProjectLogoContext.Provider>
    </ProjectUsersContext.Provider>
  );
};
