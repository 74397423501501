import { createTheme } from "@mui/material/styles";

export const authTheme = createTheme({
  palette: {
    // text: {

    // },
    // grey: {

    // },
    primary: {
      main: "#1c1f3c",
    },
    secondary: {
      main: "#a20eb2",
    },
  },
  overrides: {},
});
