import React from "react";
import { Link } from "react-router-dom";

import { Tooltip } from "@mui/material";

import styled, { useTheme } from "styled-components";
import ICON_LOGO_COLOR from "../statics2/logos/f_logo_no_text.png";
import ICON_LOGO_MONO from "../statics2/logos/fmono_logo_no_text.png";
import ICON_LOGO_TITLE_COLOR from "../statics2/logos/f_logo_text_btm.png";
import ICON_LOGO_TITLE_MONO from "../statics2/logos/fmono_logo_text_btm.png";

export const LogoMark = (props) => {
  const theme = useTheme();

  return (
    <Tooltip title="Home">
      <Link to="/app">
        <LogoMarkImg
          src={theme && theme.logo == "mono" ? ICON_LOGO_MONO : ICON_LOGO_COLOR}
          {...props}
        />
      </Link>
    </Tooltip>
  );
};

export const LogoNoNav = (props) => {
  const theme = useTheme();

  return (
    <LogoMarkImg
      src={theme && theme.logo == "mono" ? ICON_LOGO_MONO : ICON_LOGO_COLOR}
      {...props}
    />
  );
};

export const LogoTitled = (props) => {
  const theme = useTheme();

  return (
    <Link to="/app">
      <LogoMarkImg
        src={
          theme && theme.logo == "mono"
            ? ICON_LOGO_TITLE_MONO
            : ICON_LOGO_TITLE_COLOR
        }
        {...props}
      />
    </Link>
  );
};

const LogoMarkImg = styled.img`
  height: 40px;
  margin-left: 6px;
`;
