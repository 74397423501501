import { useState, useEffect } from "react";

interface Approval {
  id: string;
  stage: number; // The index of the approvalsRequired array
  approver: string; // User ID of the approver
  alternateApprovers?: string[]; // User IDs of the alternate approvers
  status: "assigned" | "pending" | "approved" | "rejected";
  assignedTime: Date;
  completedTime?: Date;
}

export const useApprovals = (query: any) => {
  const [approvals, setApprovals] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (query) {
      const unsub = query.approvals.listen((data: Approval[]) => {
        if (mounted) setApprovals(data);
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [query]);

  return approvals;
};
