import React from 'react';
import { DialogContent, Modal } from '@mui/material';
import { ModalViewFullScreen } from '../ui/containers';
import { PrivacyFormatted } from '../statics2/privacy';


export default ({ open, onClose }) => {
  return (<Modal open={open} onClose={onClose}>
    <DialogContent><ModalViewFullScreen>
      <PrivacyFormatted />
    </ModalViewFullScreen></DialogContent>
  </Modal>);
}