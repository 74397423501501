import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import { addDays, format, differenceInCalendarDays, subDays } from "date-fns";

import {
  CurrentProjectContext,
  ProjectRouteContainer,
  SelectedSchemaContext,
} from "..";
import { IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CalendarToday,
} from "@mui/icons-material";
import { parse_db_timestamp } from "../../../tools";
import { get_query_due_date } from "../../../tools/forms";
import { QueryStatusTag } from "../query/query";
import { useSearchParams } from "react-router-dom";

const QUERY_DATE_FORMAT = "MMM do yyyy";
const ControlIconStyle = { fontSize: "1.2rem" };
const ControlButtonStyle = { padding: "12px 4px" };
const dayIncrements = isMobile ? 1 : 3;

export default ({ queries }) => {
  const schemaData = useContext(SelectedSchemaContext);
  const project = useContext(CurrentProjectContext);

  const [dateFormattedQueries, setDateFormattedQueries] = useState({});
  const [selectedDay, setSelectedDay] = useState(new Date());

  const [dateSelectOpen, setDateSelectOpen] = useState(undefined);

  // Get search param state
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queries && schemaData) {
      const formattedQueries = {};
      queries
        .filter(
          (q) =>
            q.schemaId === schemaData.id && get_query_due_date(q, schemaData)
        )
        .forEach((q) => {
          const date = parse_db_timestamp(get_query_due_date(q, schemaData));
          const formattedDate = format(date, QUERY_DATE_FORMAT);
          if (!formattedQueries[formattedDate]) {
            formattedQueries[formattedDate] = [];
          }
          formattedQueries[formattedDate].push(q);
        });
      setDateFormattedQueries(formattedQueries);
    }
  }, [queries, schemaData]);

  const handleDateChange = (date) => {
    // If we're on mobile we use every day as an individual view, so just set the date!
    if (isMobile) {
      setSelectedDay(date);
      return;
    }
    // check if new date is within 3 days, if so do nothing, else change date to first date in 3 day interval
    let diff = differenceInCalendarDays(date, selectedDay);
    if (diff > 1 || diff < 0) {
      let intervalOf3 = diff % 3;
      if (diff < 0) {
        switch (intervalOf3) {
          case -0:
            return setSelectedDay(addDays(date, intervalOf3));
          case -1:
            return setSelectedDay(addDays(date, intervalOf3 - 1));
          case -2:
            return setSelectedDay(addDays(date, intervalOf3 + 1));
        }
      } else {
        setSelectedDay(subDays(date, intervalOf3));
      }
    }
  };

  const openQuery = useCallback(
    (id) => setSearchParams({ qq: `${project.id}-${id}` }),
    [project, setSearchParams]
  );

  return (
    <ProjectRouteContainer>
      <CalendarOptions>
        <CalendarDayScroller>
          <CDSDay>
            {format(selectedDay, QUERY_DATE_FORMAT) ===
              format(new Date(), QUERY_DATE_FORMAT)
              ? "Today"
              : format(selectedDay, QUERY_DATE_FORMAT)}
          </CDSDay>
          <CDSControls>
            <IconButton
              style={ControlButtonStyle}
              onClick={() =>
                setSelectedDay((ex) => addDays(ex, -dayIncrements))
              }
            >
              <ArrowBackIos style={ControlIconStyle} />
            </IconButton>
            <IconButton
              style={ControlButtonStyle}
              onClick={() => setSelectedDay((ex) => addDays(ex, dayIncrements))}
            >
              <ArrowForwardIos style={ControlIconStyle} />
            </IconButton>
          </CDSControls>
          <div>
            <IconButton
              style={ControlButtonStyle}
              onClick={(e) => setDateSelectOpen(e.target)}
            >
              <CalendarToday style={ControlIconStyle} />
            </IconButton>
            <DatePicker
              open={!!dateSelectOpen}
              onClose={() => setDateSelectOpen(undefined)}
              value={selectedDay}
              onChange={handleDateChange}
              renderInput={(params) => null}
              PopperProps={{
                anchorEl: dateSelectOpen,
                open: !!dateSelectOpen, // Pass ourselves to prevent errors :)
              }}
              sx={{
                "& .MuiPickersToolbar-penIconButton": {
                  display: "none",
                },
              }}
            />
          </div>
        </CalendarDayScroller>
      </CalendarOptions>
      <CalendarData>
        <CalendarDayComponent
          openQuery={openQuery}
          selectedDay={format(selectedDay, QUERY_DATE_FORMAT)}
          queries={
            dateFormattedQueries[format(selectedDay, QUERY_DATE_FORMAT)] ?? []
          }
        />
        {/* Note: Only show 1 day on mobile */}
        {(!isMobile || window.innerHeight < window.innerWidth) && (
          <CalendarDayComponent
            openQuery={openQuery}
            selectedDay={format(addDays(selectedDay, 1), QUERY_DATE_FORMAT)}
            queries={
              dateFormattedQueries[
              format(addDays(selectedDay, 1), QUERY_DATE_FORMAT)
              ] ?? []
            }
          />
        )}
        {(!isMobile || window.innerHeight < window.innerWidth) && (
          <CalendarDayComponent
            openQuery={openQuery}
            selectedDay={format(addDays(selectedDay, 2), QUERY_DATE_FORMAT)}
            queries={
              dateFormattedQueries[
              format(addDays(selectedDay, 2), QUERY_DATE_FORMAT)
              ] ?? []
            }
          />
        )}
      </CalendarData>
    </ProjectRouteContainer>
  );
};

const CalendarDayComponent = ({ selectedDay, queries, openQuery }) => {
  return (
    <CalendarDay>
      {!isMobile && <CalendarDayTitle>{selectedDay}</CalendarDayTitle>}
      <CalendarDayQueries>
        {queries.length ? (
          queries.map((q) => (
            <CalendarDayQueryTile key={q.id} onClick={() => openQuery(q.id)}>
              <CDQTop>
                <CDQTileTitle>{q.dynamicId ? q.dynamicId : q.id}</CDQTileTitle>
                <QueryStatusTag>{q.status.toUpperCase()}</QueryStatusTag>
              </CDQTop>
            </CalendarDayQueryTile>
          ))
        ) : (
          <CalendarDayQueryTile style={{ cursor: "inherit" }}>
            <CDQTop>
              <CDQTileTitle>No Queries Here</CDQTileTitle>
            </CDQTop>
          </CalendarDayQueryTile>
        )}
      </CalendarDayQueries>
    </CalendarDay>
  );
};

const CalendarOptions = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CalendarData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const CalendarDayScroller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  color: ${(props) => props.theme.text};
`;

const CDSDay = styled.div`
  min-width: 100px;
  margin-right: 8px;
`;

const CDSControls = styled.div`
  font-size: 1.2rem;
`;

// DAY COLUMN
const CalendarDay = styled.div`
  width: ${isMobile ? "100%" : "31%"};
  margin-top: 14px;
  margin-bottom: 16px;

  border-right: 1px solid grey;
  color: ${(props) => props.theme.text};

  &:first-of-type {
    border-left: 1px solid grey;
  }
`;

const CalendarDayTitle = styled.div`
  position: relative;
  top: -10px;
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
`;

const CalendarDayQueries = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 96%;
  margin-bottom: 20px;
`;

// DAY QUERY TILE
const CalendarDayQueryTile = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  min-width: 180px;
  height: 45px;
  margin: 0 8px;
  margin-top: 8px;
  padding: 6px;
  border-radius: 3px;

  cursor: pointer;

  background: ${(props) => props.theme.step100};
  color: ${(props) => props.theme.text};
`;

const CDQTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CDQTileTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;
