import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import styled from "styled-components";
import errImg from "../error/exclamation.png";

const DefaultErrorWrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & > div {
    text-align: center;
    color: #382178;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Img = styled.img`
  width: 7%;
  height: auto;
`;

export const ErrorFeedback404 = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/", { replace: true });
  };
  const errMsg = "We cannot find what you are looking for :(";
  return (
    <DefaultErrorWrapper>
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "5px",
        }}
      >
        <Img src={errImg} alt="error image" />
        <Typography style={{ marginBottom: 3, fontSize: "48px" }}>
          Oops!
        </Typography>
        <Typography style={{ marginBottom: 3, fontSize: "24px" }}>
          {errMsg}
        </Typography>
        <Button
          style={{ width: "70%", marginTop: "16px" }}
          variant="contained"
          color="secondary"
          onClick={handleClick}
        >
          return home →
        </Button>
      </div>
    </DefaultErrorWrapper>
  );
};
