import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";

import {
  Routes,
  Route,
  Navigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { Fab } from "@mui/material";
import Icon from "@mui/material/Icon";
import {
  Add,
  BarChart,
  DateRange,
  EmojiEvents,
  FilterList,
  Home,
  ImportExport,
  Leaderboard as LeaderboardIcon,
  ManageAccounts,
} from "@mui/icons-material";

import HomePage from "./home";
import Calendar from "./calendar";
import Analytics from "./analytics";
import Export from "./export";
import Reporting from "./reporting";
import Manage from "./manage";
import NavBar from "../navigator";
import UserPopups from "./popups";
import Leaderboard from "./leaderboard";
import TimeTracker from "./timer";

import FilterSelector from "./filter/panel";
import SchemaTab from "./filter/schemas";
import {
  useProject,
  useCurrentSchemas,
  useCurrentReports,
  useCurrentUsers,
  useSchema,
  useProjectLogo,
  useCurrentActions,
  useSchemaCustomizations,
  useAggregateData,
} from "../../hooks/projects";
import { useQueries } from "../../hooks/queries";

import EBound from "../ui/errorbounds";
import { ErrorDisplayComponent } from "../../App";
import {
  atomicUseFilterableElement,
  FilterManager,
  ModifyFlag,
} from "../../hooks/filters";
import { atom, useSetRecoilState } from "recoil";
import { polish_for_freeze } from "../../tools";
import { useUserBuiltPermissions } from "../../hooks/users";

export const ProjectUsersContext = React.createContext([]);
export const ProjectQueriesContext = React.createContext([]);
export const SelectedSchemaContext = React.createContext(undefined);
export const SchemaSelectorContext = React.createContext(() => {});
export const CurrentProjectContext = React.createContext(undefined);
export const ProjectLogoContext = React.createContext(undefined);
export const UserPermissionsContext = React.createContext({});
export const FilteringActiveContext = React.createContext(false);
// We use this one ONLY in the queries pane but that's okay
export const ProjectReferenceContext = React.createContext(undefined);

// Define atomic state for queries & filtered queries
const QueriesState = atom({
  key: "projectQueries",
  default: [],
});

export const [FilteredQueriesState, queryFilterManager] =
  atomicUseFilterableElement(QueriesState);

export default ({ user, userData, setProjectBookmark, photoLink }) => {
  const { projectId } = useParams();
  const [noPermsRedirect, setNoPermsRedirect] = useState(false);
  const [project, projectData] = useProject(projectId);
  const schemas = useCurrentSchemas(project, user, setNoPermsRedirect);
  const reports = useCurrentReports(project);
  const queries = useQueries(project, projectData?.settings?.isAnonymized);
  const projectUsers = useCurrentUsers(project);
  const [navOptions, setNavOptions] = useState(undefined);

  const projectActions = useCurrentActions(project);

  const filteringActive = queryFilterManager.useFilterActive();

  // Would like to swap this
  const [selectSchema, setSelectedSchema] = useState(schemas?.[0]?.id);
  const [selectedSchema, selectedSchemaData] = useSchema(selectSchema, project);

  const aggregateData = useAggregateData(project, selectedSchema);

  const userCustomSchema = useSchemaCustomizations(
    project,
    selectedSchema,
    userData?.id
  );

  // Now make sure we have permissions
  const userPermissions = useUserBuiltPermissions(
    user,
    selectedSchemaData,
    projectUsers
  );

  // Now get the qq and nq params (to check undefined for mobile UI cleanup)
  const [{ qq, nq }, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (schemas) {
      if (
        !(selectSchema && schemas.find((schema) => schema.id === selectSchema))
      ) {
        setSelectedSchema(schemas[0]?.id);
      }
    }
  }, [schemas, selectSchema]);

  // Filter Setup
  const setQueriesState = useSetRecoilState(QueriesState);
  queryFilterManager.init();
  useEffect(
    () => setQueriesState(polish_for_freeze(queries)),
    [queries, setQueriesState]
  );

  useEffect(() => {
    queryFilterManager.modifyAsync(ModifyFlag.SCHEME);
    if (userCustomSchema !== undefined && selectedSchemaData !== undefined) {
      const customFilters = userCustomSchema?.customFilters;
      queryFilterManager.setFilterScheme(
        ...FilterManager.prepSchema(selectedSchemaData),
        customFilters ?? selectedSchemaData?.defaultFilters ?? []
      );
    }
  }, [selectedSchema, selectedSchemaData, userCustomSchema]);
  // Setup viewstate
  const [filtersPaneVisible, setFiltersPaneVisible] = useState(false);

  useEffect(() => {
    if (reports && user && projectData) {
      setNavOptions([
        ...DEFAULT_PROJECT_NAVS,
        ...(projectData?.settings?.isLeaderboarded === true
          ? LEADERBOARD_PROJECT_NAVS
          : []),
        ...reports
          .filter((rpt) => rpt.users.includes(user.ref.id))
          .map((rpt) => ({
            dest: `reports/${rpt.id}`,
            name: rpt.name,
            icon: <Icon>{rpt.icon}</Icon>,
          })),
      ]);
    }
  }, [reports, user, projectData]);

  useEffect(() => {
    // Reset options on project change
    setNavOptions(undefined);
  }, [project]);

  // Set project bookmark!
  useEffect(() => {
    if (projectData) {
      setProjectBookmark({ id: projectData.id, name: projectData.name });
    }
  }, [projectData, setProjectBookmark]);

  // Now that we have a project selected
  // Preload the icon for display in different views when needed
  const projectLogo = useProjectLogo(projectData);

  if (noPermsRedirect) {
    return <Navigate to="/app" />;
  }

  if (!(projectData && schemas && queries)) {
    return null;
  }

  if (schemas.length < 1) {
    return (
      <ErrorDisplayComponent>
        Project has no Schemas setup yet, check back later.
      </ErrorDisplayComponent>
    );
  }

  return (
    <ProjectUsersContext.Provider value={projectUsers}>
      <SelectedSchemaContext.Provider value={selectedSchemaData}>
        <SchemaSelectorContext.Provider value={setSelectedSchema}>
          <CurrentProjectContext.Provider value={projectData}>
            <ProjectLogoContext.Provider value={projectLogo}>
              <FilteringActiveContext.Provider value={filteringActive}>
                <ProjectContentDiv>
                  {userData?.popups &&
                    Array.isArray(userData.popups) &&
                    userData.popups.length > 0 && (
                      <UserPopups
                        popups={userData?.popups}
                        user={user}
                        userData={userData}
                      />
                    )}
                  <TimeTracker
                    user={user}
                    project={project}
                    userData={userData}
                  />
                  <NavBar
                    options={navOptions}
                    projectData={projectData}
                    user={user}
                  />
                  <ProjectActiveDiv>
                    <SchemaTab schemas={schemas} queries={queries} />
                    <Routes>
                      <Route
                        path={`queries`}
                        element={
                          <EBound area="Home" userId={userData.id}>
                            <HomePage
                              projectActions={projectActions?.map((a) => ({
                                ...a,
                                query: queries.find(
                                  (q) => q.id === a.queriesId
                                ),
                              }))}
                              setFiltersPaneVisible={setFiltersPaneVisible}
                              projectData={projectData}
                              schemas={schemas}
                              filterManager={queryFilterManager}
                              project={project}
                            />
                          </EBound>
                        }
                      />
                      <Route
                        path={`calendar`}
                        element={
                          <EBound area="Calendar" userId={userData.id}>
                            <Calendar queries={queries} />
                          </EBound>
                        }
                      />
                      <Route
                        path={`analytics`}
                        element={
                          <EBound area="Analytics" userId={userData.id}>
                            <Analytics
                              setFiltersPaneVisible={setFiltersPaneVisible}
                              projectActions={projectActions}
                              project={project}
                              userData={userData}
                              schemas={schemas}
                              queries={queries}
                              aggregateData={aggregateData}
                            />
                          </EBound>
                        }
                      />
                      <Route
                        path={`export`}
                        element={
                          <EBound area="Export" userId={userData.id}>
                            <Export
                              project={project}
                              projectData={projectData}
                              queries={queries}
                              filterManager={queryFilterManager}
                            />
                          </EBound>
                        }
                      />{" "}
                      <Route
                        path={`manage`}
                        element={
                          <EBound area="Manage" userId={userData.id}>
                            <Manage />
                          </EBound>
                        }
                      />
                      <Route
                        path={`reports/:reportId`}
                        element={
                          <EBound area="Reporting" userId={userData.id}>
                            <Reporting
                              projectActions={projectActions}
                              reports={reports}
                              setFiltersPaneVisible={setFiltersPaneVisible}
                              queryFilterManager={queryFilterManager}
                            />
                          </EBound>
                        }
                      />
                      {projectData?.settings?.isLeaderboarded && (
                        <Route
                          path={`leaderboard`}
                          element={
                            <EBound area="Leaderboard" userId={userData.id}>
                              <Leaderboard
                                userData={userData}
                                project={project}
                                projectData={projectData}
                                queries={queries}
                                schemas={schemas}
                                photoLink={photoLink}
                              />
                            </EBound>
                          }
                        />
                      )}
                      {/* If we have no direction from here, head to the queries :) */}
                      <Route path="" element={<Navigate to={"queries"} />} />
                    </Routes>
                  </ProjectActiveDiv>
                </ProjectContentDiv>

                {filtersPaneVisible && (
                  <UserPermissionsContext.Provider value={userPermissions}>
                    <ProjectReferenceContext.Provider value={project}>
                      <FilterSelector
                        setOpen={setFiltersPaneVisible}
                        filterManager={queryFilterManager}
                      />
                    </ProjectReferenceContext.Provider>
                  </UserPermissionsContext.Provider>
                )}

                {!nq && !qq && isMobile && (
                  <>
                    <Fab
                      style={{ position: "absolute", bottom: 60, right: 8 }}
                      color="primary"
                      onClick={() =>
                        setSearchParams({
                          nq: `${projectData.id}-${selectedSchemaData?.id}`,
                        })
                      }
                    >
                      <Add />
                    </Fab>
                    <Fab
                      size="small"
                      style={{ position: "absolute", bottom: 130, right: 8 }}
                      color="primary"
                      onClick={() => setFiltersPaneVisible(true)}
                    >
                      <FilterList />
                    </Fab>
                  </>
                )}
              </FilteringActiveContext.Provider>
            </ProjectLogoContext.Provider>
          </CurrentProjectContext.Provider>
        </SchemaSelectorContext.Provider>
      </SelectedSchemaContext.Provider>
    </ProjectUsersContext.Provider>
  );
};

const DEFAULT_PROJECT_NAVS = [
  { dest: "queries", name: "Queries", icon: <Home />, mobile: true },
  // {dest: 'actions', name: 'Action Items', icon: 'description', mobile: true},
  { dest: "calendar", name: "Calendar", icon: <DateRange />, mobile: true },
  { dest: "analytics", name: "Analytics", icon: <BarChart />, mobile: true },
  { dest: "export", name: "Export", icon: <ImportExport /> },
  { dest: "manage", name: "Manage", icon: <ManageAccounts /> },
];

const LEADERBOARD_PROJECT_NAVS = [
  { dest: "leaderboard", name: "Leaderboard", icon: <EmojiEvents /> },
];

// const ProjectDiv = styled.div`
//   display: flex;
//   flex-direction: column;

//   height: 100%;

//   width: 0;
//   flex-grow: 1;

//   ${isMobile &&
//   css`
//     width: 100%;
//     flex-grow: 0;
//     flex-shrink: 0;
//   `}
// `;

const ProjectContentDiv = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  flex-grow: 1;
  flex-direction: row;

  box-sizing: border-box;

  position: relative;
  overflow: auto;
`;

const ProjectActiveDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: hidden;
`;

// const OptionsSpan = styled.div`
//   height: 50px;
//   display: flex;
//   width: 100%;

//   align-items: center;
// `;

export const ProjectRouteContainer = styled.div`
  height: 98%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 3px;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.font};

  overflow-y: auto;
`;
