// Here we define mutliple color palettes for the application
// https://styled-components.com/docs/advanced

// Status colors are in order: OPEN, OVERDUE, STD, TOTAL

export default {
  // default: {
  //     text: '#000',
  //     textAlt: '#fff',
  //     textHeading: '#fff',
  //     textDisabled: '#a1a4b7',
  //     popupText: '',

  //     highlight: '#C4168F',
  //     highlightLow: '#b3b8d7',
  //     titleSub: '#C4168F',

  //     background: '#F3F2F1',
  //     tileBackground: '#fff',
  //     popup: '',
  //     step50: '#464775',
  //     step100: '#34324A',
  //     step150: '#7E7FB0',

  //     gridlines: '#F3F2F1',

  //     tbl: {
  //         head: '#FFFFFF',
  //         row1: '#FFFFFF',
  //         row2: '#FFFFFF',
  //         hover: '#FFFFFF'
  //     },

  //     button: {
  //         active: '#901D81',
  //         inactive: '#2B3046',
  //         diabled: '#fff'
  //     },

  //     statusColors: {
  //         active: '#901D81',
  //         inactive: '#2B3046',
  //         divider: '#2B3046'
  //     },
  //     chartDefaults: ['#003F5C', '#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600'],

  //     navMode: 'horizontal',
  //     logo: 'color'
  // },
  light: {
    text: "#353535",
    textAlt: "#353535",
    textHeading: "#353535",
    textDisabled: "#A1A4B7",
    popupText: "#000",

    highlight: "#5430F9",
    highlightLow: "#5430F9",
    titleSub: "#5430F9",

    background: "#EEEEEE",
    tileBackground: "#E0E0E0",
    popup: "",
    step50: "#C4C4C4",
    step100: "#D7D7D7",
    step100Alt: "#EEEEEE",
    step150: "#FFFFFF",

    gridlines: "#F3F2F1",

    tbl: {
      head: "#BFBFBF",
      row1: "#FFFFFF",
      row2: "#BFBFBF",
      hover: "#A3A3A3",
    },

    button: {
      active: "#5430F9",
      activeText: "#000",
      inactive: "#5430F9",
      inactiveText: "#000",
      disabled: "#fff",
    },

    statusColors: {
      active: "#901D81",
      inactive: "#A5A5A5",
      divider: "#441E5C",
    },
    chartDefaults: [
      "#FF2696",
      "#6269FD",
      "#FFBB32",
      "#00C1FD",
      "#003F5C",
      "#FF6E54",
    ],

    navMode: "horizontal",
    logo: "color",
    font: '"Gotham Pro", Arial, serif',
  },
  // midnight: {
  //     text: '#fff',
  //     textAlt: '#fff',
  //     textHeading: '#fff',
  //     textDisabled: '#a1a4b7',
  //     popupText: '',

  //     highlight: '#2A579A',
  //     highlightLow: '#b3b8d7',
  //     titleSub: '#2A579A',

  //     background: '#0F101D',
  //     tileBackground: '#181A2E',
  //     popup: '',
  //     step50: '#16182A',
  //     step100: '#16182A',
  //     step100Alt: '#3F3C47',
  //     step150: '#242638',

  //     gridlines: '#ABABAB',

  //     tbl: {
  //         head: '#242638',
  //         row1: '#0F101D',
  //         row2: '#0F101D',
  //         hover: '#A3A3A3'
  //     },

  //     button: {
  //         active: '#2A579A',
  //         activeText: '#fff',
  //         inactive: '#3F3C47',
  //         diabled: '#fff'
  //     },

  //     statusColors: {
  //         active: '#2A579A',
  //         inactive: '#3F3C47',
  //         divider: '#2A579A'
  //     },
  //     chartDefaults: ['#FF2696', '#6269FD', '#FFBB32', '#00C1FD', '#003F5C', '#FF6E54'],

  //     navMode: 'vertical',
  //     logo: 'color'
  // },
  purple: {
    text: "#ffffff",
    textAlt: "#ffffff",
    textHeading: "#ffffff",
    textDisabled: "#a1a4b7",
    popupText: "",

    highlight: "#C4168F",
    highlightLow: "#b3b8d7",
    titleSub: "#C4168F",

    background: "#201C2F",
    tileBackground: "#29273D",
    popup: "",
    step50: "#1E1E2D",
    step100: "#2A283E",
    step100Alt: "#3B3E5F",
    step150: "#27293D",
    error: "#A40436",

    errorColor: "#ffffff",

    gridlines: "#DDDDDD",

    tbl: {
      head: "#27293D",
      row1: "#27293D",
      row2: "#27293D",
      hover: "#A3A3A3",
    },

    button: {
      active: "#901D81",
      inactive: "#000000",
      disabled: "#fff",
    },

    statusColors: {
      active: "#901D81",
      inactive: "#000000",
      divider: "#901D81",
    },
    chartDefaults: [
      "#D45087",
      "#003F5C",
      "#2F4B7C",
      "#665191",
      "#A05195",
      "#F95D6A",
      "#FF7C43",
      "#FFA660",
      "#55AB55",
    ],

    navMode: "horizontal",
    logo: "color",
    font: "gordita,Helvetica Neue,Helvetica,sans-serif",
  },
  dark: {
    text: "#efeef3",
    textAlt: "#efeef3",
    textHeading: "#fff",
    textDisabled: "#d4d4d4",
    popupText: "",

    highlight: "#eb0d95",
    highlightLow: "#232427",
    titleSub: "#eb0d95",
    error: "#3d383a",

    errorColor: "#ffffff",

    gridlines: "#1e1f21",

    background: "#121315",
    tileBackground: "#333437",
    popup: "",
    step50: "#1e1f21",
    step100: "#333437",
    step150: "#56575a",

    tbl: {
      head: "#1e1f21",
      row1: "#333437",
      row2: "#1e1f21",
      hover: "#56575a",
    },

    button: {
      active: "#4c48a7",
      inactive: "#333437",
      disabled: "#fff",
    },

    statusColors: {
      active: "#4c48a7",
      inactive: "#4ca17a",
    },
    chartDefaults: [
      "#4c48a7",
      "#6e56cf",
      "#a897e9",
      "#29825c",
      "#4ca17a",
      "#8ad1b3",
    ],

    navMode: "horizontal",
    font: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
};
