import React, { useState, useCallback, useContext } from "react";
import styled from "@emotion/styled";

// Styles
import { QueryContent, LinkedQueryString } from "./query";
import { QUERYVIEWS, ROUTEKEYS, SUBQUERYVIEWS } from "../../../common/query";
import { QueryFields } from "./fields";

// Views
import QueryLinker from "./linking";
import { PackageDoc, PackageDocContext } from "./fields/packagedoc";
import { useSearchParams } from "react-router-dom";
import { ProjectLogo } from "./logo";
import { UserContext } from "../../../App";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import {
  useCustomFieldset,
  useSchemaCustomizations,
} from "../../../hooks/projects";
import { Edit } from "@mui/icons-material";

/**
 * This Query component is imported exclusively for new_query,
 * and its role is to implement components of the Query that are
 * only used by Queries that do not yet exist.
 */
export default React.memo(
  ({
    queryData,
    schema,
    schemaData,
    setQueryData,
    queries,
    project,
    setQueryValidationErrors,
    setStatusMessage,
    // From the Query manager
    viewState,
    ...props
  }) => {
    // Pull query route param
    const [search] = useSearchParams();
    const queryRoute = search.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW);

    switch (queryRoute) {
      case QUERYVIEWS.QUERY:
        return (
          <NewQueryContentPane
            queryData={queryData}
            setQueryData={setQueryData}
            schema={schema}
            schemaData={schemaData}
            queries={queries}
            project={project}
            setQueryValidationErrors={setQueryValidationErrors}
            setStatusMessage={setStatusMessage}
            props={props}
          />
        );
      default:
        return null;
    }
  }
);

export const swapRouteEnd = (str, newEnd) => {
  const parts = str.split("-");
  parts[parts.length - 1] = newEnd;
  return parts.join("-");
};

const NewQueryContentPane = ({
  queryData,
  setQueryData,
  schema,
  schemaData,
  queries,
  project,
  setQueryValidationErrors,
  setStatusMessage,
  props,
}) => {
  // Routing
  const [searchParams, setSearchParams] = useSearchParams();
  const queryRoute = searchParams.get(ROUTEKEYS.NEW_QUERY_ID);
  const querySubView = searchParams.get(ROUTEKEYS.QUERY_PANE_SUB_VIEW);

  // For Query Linking prep
  const [potentialQueryData, setPotentialQueryData] = useState([]);

  const currentUser = useContext(UserContext);
  const userCustomSchema = useSchemaCustomizations(
    project,
    schema,
    currentUser.ref.id
  );
  const fieldSet = useCustomFieldset(userCustomSchema, schemaData);

  const packageDoc = useContext(PackageDocContext);

  // Now add callbacks
  const onFieldChange = useCallback(
    (fieldId) => (val) => {
      setQueryData((ex) => ({ ...ex, data: { ...ex.data, [fieldId]: val } }));
    },
    [setQueryData]
  );

  const setFieldChange = useCallback(
    (callback) => {
      setQueryData((ex) => ({ ...ex, data: callback(ex.data) }));
    },
    [setQueryData]
  );

  const setPromptQueryLinking = useCallback(
    (val) => {
      setSearchParams((ex) => {
        if (val) {
          ex.set(ROUTEKEYS.QUERY_PANE_SUB_VIEW, SUBQUERYVIEWS.QUERYLINK);
        } else {
          ex.set(ROUTEKEYS.QUERY_PANE_SUB_VIEW, SUBQUERYVIEWS.DEFAULT);
        }
        return ex;
      });
    },
    [setSearchParams]
  );

  if (querySubView === SUBQUERYVIEWS.QUERYLINK) {
    return (
      <QueryLinker
        setQueryData={setQueryData}
        project={project}
        queries={queries}
        schemaData={schemaData}
        queryData={queryData}
        setPromptQueryLinking={setPromptQueryLinking}
        potentialQueryData={potentialQueryData}
        setPotentialQueryData={setPotentialQueryData}
      />
    );
  }

  if (packageDoc !== undefined) {
    return (
      <PackageDoc
        data={queryData.data?.[packageDoc.fieldId]?.[packageDoc?.doc?.id] ?? {}}
        setQueryData={setQueryData}
        setFieldChange={setFieldChange}
        status={"new"}
        setStatusMessage={setStatusMessage}
      />
    );
  }

  return (
    <QueryContent>
      <ProjectLogo />

      {schemaData?.linkedQueries && (
        <LinkedQueryString>
          Linked:{" "}
          {queryData.linkedQueries?.from &&
          queryData.linkedQueries?.from.length > 0
            ? queryData.linkedQueries?.from.map((linked, i) => (
                <LinkedQueryNav
                  key={linked.id}
                  href={
                    "/app/projects/" +
                    project.ref.id +
                    "/queries?qq=" +
                    swapRouteEnd(queryRoute, linked.id)
                  }
                  target="_blank"
                  // onClick={() => {
                  //   if (props.dirty) {
                  //     props.setCustomRoute({
                  //       qq: swapRouteEnd(queryRoute, linked.id),
                  //     });
                  //     props.setDirtyModal(true);
                  //   } else {
                  //     setSearchParams({
                  //       qq: swapRouteEnd(queryRoute, linked.id),
                  //     });
                  //   }
                  // }}
                >
                  {`${linked.dynamicId ?? linked.id}`}
                  {i !== queryData.linkedQueries?.from.length - 1 && ","}
                </LinkedQueryNav>
              ))
            : "None"}
          <Tooltip
            title="Edit Linking"
            onClick={() => setPromptQueryLinking(true)}
          >
            <IconButton size="xs">
              <Edit
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
            </IconButton>
          </Tooltip>
        </LinkedQueryString>
      )}

      {/* Data fields for the query. */}
      {fieldSet ? (
        <QueryFields
          data={queryData.data}
          queryData={queryData}
          onFieldChange={onFieldChange}
          setFieldChange={setFieldChange}
          fieldSet={fieldSet}
          setQueryValidationErrors={setQueryValidationErrors}
          status={"new"}
          // queries={queries}
          project={project}
        />
      ) : (
        <Centerer>
          <CircularProgress />
        </Centerer>
      )}
    </QueryContent>
  );
};

export const LinkedQueryNav = styled.a`
  color: #309ceb;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    color: #3292d9;
  }
`;

const Centerer = styled.div`
  width: 100%;
  height: calc(100% - 127px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
