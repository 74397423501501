import { v4 as uuid } from "uuid";
import { DATE_FILTERS } from "../common/dates";

export function noop() {}

export function generate_tempid() {
  return uuid().replaceAll("-", "");
}

export function strip_tempids(obj: {
  [key: string]: any;
}): { [key: string]: any } | [] {
  let cleanObj: { [key: string]: any } | [];
  if (Array.isArray(obj)) {
    cleanObj = [];
    obj.forEach((elem) => {
      if (typeof elem === "undefined") {
        return;
      }
      if (typeof elem === "object" && elem !== null) {
        cleanObj.push(strip_tempids(elem));
      } else {
        cleanObj.push(elem);
      }
    });
  } else {
    cleanObj = {};
    Object.keys(obj).forEach((key) => {
      if (key === "__id") {
        return;
      }
      if (typeof obj[key] === "undefined") {
        return;
      }
      if (typeof obj[key] === "object" && obj[key] !== null) {
        cleanObj[key] = strip_tempids(obj[key]);
      } else {
        cleanObj[key] = obj[key];
      }
    });
  }
  return cleanObj;
}

export function strip_undefined(obj: {
  [key: string]: any;
}): { [key: string]: any } | [] {
  let cleanObj: { [key: string]: any } | [];
  if (Array.isArray(obj)) {
    cleanObj = [];
    obj.forEach((elem) => {
      if (typeof elem === "undefined") {
        return;
      }
      if (typeof elem === "object" && elem !== null) {
        cleanObj.push(strip_undefined(elem));
      } else {
        cleanObj.push(elem);
      }
    });
  } else {
    cleanObj = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "undefined") {
        return;
      }
      if (typeof obj[key] === "object" && obj[key] !== null) {
        cleanObj[key] = strip_undefined(obj[key]);
      } else {
        cleanObj[key] = obj[key];
      }
    });
  }
  return cleanObj;
}

export function parse_db_timestamp(timestamp: any) {
  if (!timestamp) {
    return timestamp;
  }
  if (typeof timestamp === "object" && timestamp.nanoseconds !== undefined) {
    return timestamp.toDate();
  } else if (typeof timestamp === "number") {
    return new Date(timestamp);
  } else {
    return timestamp;
  }
}

export function is_overdue(timestamp: any) {
  // DEFECT: I'm not sure why we need this fucntion? Should be explained here better
  if (!timestamp || !timestamp.getTime) {
    return false;
  } // Should be a valid date object
  // Set time to compare to beginning of day (thats when overdue hits)
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  // Remember to parse before coming here!
  return timestamp.getTime() < today.getTime();
}

export const get_start_end_date = (value: string | [Date, Date]) => {
  // Coming in, value will either be an array with two dates or a string that maps to a date range in DATE_FILTERS
  if (typeof value === "object") {
    return [parse_db_timestamp(value[0]), parse_db_timestamp(value[1])];
  } else {
    let temp = DATE_FILTERS[value];
    return [temp.earliestDate, temp.latestDate];
  }
};

export function format_data_text(text: string, data: any) {
  return text.replace(/{.[^}]*}/g, (match) => {
    let tokenSet = match.replaceAll("{", "").replaceAll("}", "");
    try {
      let finalString = data;
      tokenSet.split(".").forEach((tk) => (finalString = finalString[tk]));
      if (typeof finalString === "string") {
        return finalString;
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  });
}

// Deep parse object and remove any firebase "ref" type objects
export function polish_for_freeze(objArray: { [key: string]: any }[]) {
  // NOTE: This function should be used for display purposes ONLY. Using it to mess with data that is written is BAD!
  return (objArray ?? []).map((ent) => {
    let keysToGo: string[] = [];
    Object.keys(ent).forEach((key) => {
      if (typeof ent[key] === "object" && ent[key] !== null) {
        // Now check if the object is a firebase ref
        if (ent[key].firestore !== undefined && ent[key]._key !== undefined) {
          keysToGo.push(key);
        }
      }
    });
    // Clean keys
    return {
      ...ent,
      ...keysToGo.reduce((acc, key) => {
        acc[key] = undefined;
        return acc;
      }, {} as { [key: string]: any }),
    };
  });
}

export function clean_phone_number(number: string) {
  let cleanNumber = number;
  // if (cleanNumber.includes('+1')) {
  //   cleanNumber = cleanNumber.replace('+1', '');
  // }
  // cleanNumber = cleanNumber.replaceAll('+', '');
  cleanNumber = cleanNumber.replaceAll("-", "");
  cleanNumber = cleanNumber.replaceAll(" ", "");
  cleanNumber = cleanNumber.replaceAll("(", "");
  cleanNumber = cleanNumber.replaceAll(")", "");

  return cleanNumber;
}

export function deep_comparison(obj1: Object, obj2: Object) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function get_simple_object_by_dot_notation(obj: {[key: string]: any}, path: string) {
  return path.split(".").reduce((r, k) => r?.[k], obj);
}

export function set_simple_object_by_dot_notation(obj: {[key: string]: any}, path: string, value: any) {
  let splitPath = path.split(".");
  while (splitPath.length > 1) {
    let val = splitPath.shift();
    if (val === undefined || obj[val] === undefined) {
      obj[val ?? "undefined"] = {};
    }
    obj = obj[val ?? "undefined"];
  }
  obj[splitPath[0]] = value;
}