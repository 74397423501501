import React, { useState, useEffect, useRef, Children } from "react";

// TODO: Support onChange as well :)
export const AdvancedTableDictionaryConverter = React.memo(
  ({ children, data, setChange, targetColumns = ["id", "value"] }) => {
    const isMounted = useRef(false);

    // data here is either undefined, string (undefined), or a dict
    const [tableData, setTableData] = useState(
      data === "" || !data
        ? undefined
        : {
            body: Object.keys(data).map((key) => ({
              [targetColumns[0]]: key,
              [targetColumns[1]]: data[key],
            })),
          }
    );

    // Then we'll pipe data back to the setChange which should be a dictionary :)
    // NOTE: Obviously this means we're just synchronizing two states and not really keeping them linked
    // This is kinda sus if we expect this data to be modified anywhere else without a full component re-render
    useEffect(() => {
      // No need for setChange on initial mount
      if (isMounted.current) {
        if (tableData && tableData.body) {
          setChange((ex) =>
            tableData.body.reduce(
              (acc, row) => ({
                ...acc,
                [row[targetColumns[0]]]: row[targetColumns[1]],
              }),
              {}
            )
          );
        }
      } else {
        isMounted.current = true;
      }
    }, [tableData, setChange, targetColumns]);

    if (Children.count(children) !== 1) {
      throw new Error(
        "Dictionary Converter requires exactly 1 child component"
      );
    }

    return Children.map(children, (child) =>
      React.cloneElement(child, {
        data: tableData,
        setChange: setTableData,
      })
    );
  },
  (prevProps, nextProps) => {
    return Object.keys(prevProps).some((prp) => {
      if (["targetColumns", "setChange"].includes(prp)) return false;
      return prevProps[prp] !== nextProps[prp];
    });
  }
);
