import React, { useCallback, useContext, useState, useMemo } from "react";
import styled from "@emotion/styled";
import { ProjectQueriesContext, ProjectUsersContext } from "../..";
import { useFetchQuery } from "../../../../hooks/queries";
import { Button2 } from "../../../ui/buttons";
import { InteractiveTable } from "../../../ui/table";
import { StatusDot } from "../../../ui/decorations";
import { QueryContent } from "../query";
import { Box, Paper, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import {
  get_simple_object_by_dot_notation,
  set_simple_object_by_dot_notation,
} from "../../../../tools";
import { TabContext, TabPanel } from "../actions";

export default ({
  setQueryData,
  project,
  schemaData,
  queryData,
  setPromptQueryLinking,
  potentialQueryData,
  setPotentialQueryData,
}) => {
  const queries = useContext(ProjectQueriesContext);

  const [savedQueryData, setSavedQueryData] = useState(queryData);
  const [previousCallData, setFetchQueryCallback] = useFetchQuery(project);

  const [selectedRequirement, setSelectedRequirement] = useState(
    schemaData?.linkedQueries?.requirements?.[0] ?? null
  );

  const schemasToLink = useMemo(() => {
    if (selectedRequirement === null) {
      return schemaData.linkedQueries.from ?? [];
    }
    return (
      schemaData.linkedQueries.from?.filter((link) =>
        selectedRequirement.schemas.includes(link.id)
      ) ?? []
    );
  }, [selectedRequirement, schemaData?.linkedQueries]);

  // Lean into memo if it has any data, otherwise we'll get set!
  const [selectedLinkSchema, setSelectedLinkSchema] = useState(
    schemasToLink[0] ?? undefined
  );

  const passReqs = useMemo(() => {
    if (potentialQueryData) {
      if (!schemaData?.linkedQueries?.requirements)
        return potentialQueryData.length > 0;

      return schemaData.linkedQueries.requirements.every((req) => {
        return potentialQueryData.some((q) => req.schemas.includes(q.schemaId));
      });
    }
    return false;
  }, [potentialQueryData, schemaData?.linkedQueries?.requirements]);

  const linkQuery = useCallback(
    (linkedQuery, link) => {
      setFetchQueryCallback(linkedQuery.id, (data) => {
        // Now populate the linked query data using the map
        let queryMap = link?.autoPopulate;
        if (queryMap) {
          let newDataObj = {};
          // Setup the object with current info for the form as well
          Object.keys(queryMap).forEach((toId) => {
            let fromId = queryMap[toId];
            if (toId.split(".")?.[0] == "formInvitees") {
              const formIndex = parseInt(toId.split(".")?.[1] ?? 0);
              let fromIdData = get_simple_object_by_dot_notation(data, fromId);
              if (fromIdData) {
                if (!newDataObj.formInvitees) {
                  newDataObj.formInvitees = [];
                }
                while (newDataObj.formInvitees?.length < formIndex) {
                  newDataObj.formInvitees.push({});
                }
                newDataObj.formInvitees[formIndex] = {
                  body: [
                    ...(newDataObj.formInvitees[formIndex]?.body ?? []),
                    ...(fromIdData?.body ? fromIdData.body : [fromIdData]),
                  ],
                };
              }
            } else {
              let fromIdData = get_simple_object_by_dot_notation(data, fromId);
              if (fromIdData) {
                set_simple_object_by_dot_notation(newDataObj, toId, fromIdData);
              }
            }
          });
          // Now prep a list of "bad" schema IDs which conflict with this setter
          // We assume that we just have to filter out ones that don't match in the requirment of the link
          // const linkReqs = schemaData.linkedQueries.requirements.filter((req) =>
          //   req.schemas.includes(link.id)
          // );
          // For each linkReq, reduce to a list of schemaIds that are NOT the link.id
          // const badSchemaIds = linkReqs.reduce((acc, req) => {
          //   return [
          //     ...acc,
          //     ...req.schemas.filter((schemaId) => schemaId !== link.id),
          //   ];
          // }, []);
          setPotentialQueryData((ex) => [
            ...(link?.multi ? ex : ex.filter((p) => p.schemaId !== link.id)), //.filter((p) => !badSchemaIds.includes(p.schemaId)),
            {
              id: linkedQuery.id,
              dynamicId: linkedQuery.dynamicId,
              _tempPriority: link.priority,
              schemaId: link.id,
              ...newDataObj,
            },
          ]);
        }
      });
    },
    [schemaData, setFetchQueryCallback]
  );

  const unLinkQuery = (linkedQuery) => {
    setPotentialQueryData((ex) =>
      ex.filter((link) => link.id !== linkedQuery.id)
    );
  };

  const handleConfirm = () => {
    const sortedPotential = potentialQueryData.sort(
      (a, b) => b._tempPriority - a._tempPriority
    );

    // after its sorted by priority, lets merge in all the data objects
    let finalDataObj;
    sortedPotential.forEach((data) => {
      finalDataObj = {
        ...finalDataObj,
        ...data,
        data: { ...finalDataObj?.data, ...data.data },
      };
    });
    delete finalDataObj["_tempPriority"];

    setQueryData((ex) => ({
      ...ex,
      formInvitees: [
        ...(ex?.formInvitees ?? []),
        ...(finalDataObj?.formInvitees ?? []),
      ],
      data: { ...ex.data, ...finalDataObj.data },
      linkedQueries: {
        from: sortedPotential.map((potential) => ({
          id: potential.id,
          dynamicId: potential.dynamicId,
          schemaId: potential.schemaId,
        })),
      },
    }));

    // need to write a new dynamic and query id
    setPromptQueryLinking(false);
  };

  return (
    <QueryContent
      style={{ marginTop: 6, display: "flex", flexDirection: "column" }}
    >
      <LinkingExplainers>
        <LinkingInfo>
          <div>
            Select Queries to link from other Schemas to create this Query
          </div>
        </LinkingInfo>
        <div>
          {(schemaData?.linkedQueries?.requirements?.length ?? 0) < 1 && (
            <Button2
              label="Skip"
              style={{ marginRight: 10 }}
              onClick={() => {
                setQueryData(savedQueryData);
                setPromptQueryLinking(false);
              }}
            />
          )}
          <Tooltip
            title={!passReqs ? "Please fill in Query Links to proceed" : ""}
          >
            <span>
              <Button2
                label="Confirm"
                style={{ marginRight: 10 }}
                onClick={handleConfirm}
                disabled={!passReqs}
              />
            </span>
          </Tooltip>
        </div>
      </LinkingExplainers>
      {(schemaData.linkedQueries.requirements?.length ?? 0) > 1 && (
        <Box p={2}>
          This box is for the requirements outline thing from material with
          steps (slim)
        </Box>
      )}
      <Paper elevation={2} sx={{ m: 1, flex: "2 2 100%" }}>
        <TabContext.Provider value={selectedLinkSchema?.id}>
          <Tabs value={selectedLinkSchema.id}>
            {schemasToLink.map((link) => (
              <Tab
                key={link.id}
                label={link.title}
                value={link.id}
                onClick={() => setSelectedLinkSchema(link)}
              />
            ))}
          </Tabs>
          {schemasToLink.map((link) => (
            <SchemaLinkingTable
              key={link.id}
              link={link}
              queries={queries}
              potentialQueryData={potentialQueryData}
              unlinkQuery={unLinkQuery}
              linkQuery={linkQuery}
            />
          ))}
        </TabContext.Provider>
      </Paper>
    </QueryContent>
  );
};

const SchemaLinkingTable = ({
  link,
  queries,
  potentialQueryData,
  unlinkQuery,
  linkQuery,
}) => {
  const users = useContext(ProjectUsersContext);

  // Parse filter function
  const filterFunction = useMemo(() => {
    if (link?.filter) {
      return new Function("data", link.filter);
    }
    return null;
  }, [link]);

  return (
    <TabPanel key={link.id} value={link.id}>
      <InteractiveTable
        columns={QUERY_LINK_DEFAULT_COLS.concat([
          {
            name: "Selected",
            component: (row) => (row._selected ? <StatusDot cmplt /> : null),
          },
        ])}
        data={[
          ...queries.filter(
            (dt) =>
              dt.schemaId == link.id &&
              (filterFunction == null
                ? ["closed", "approved"].includes(dt.status)
                : filterFunction(dt.data))
          ),
        ]
          .sort((a, b) => (a.dynamicId > b.dynamicId ? 1 : -1))
          .map((dt) => ({
            ...dt,
            _selected: potentialQueryData.some((q) => q.id === dt.id),
            user:
              users.find((u) => u.id === dt.creator)?.name ?? "Unknown Creator",
          }))}
        onRowClick={(row) => {
          row?._bin?._selected
            ? unlinkQuery(row._bin)
            : linkQuery(row._bin, link);
        }}
        hover
      />
    </TabPanel>
  );
};

export const QUERY_LINK_DEFAULT_COLS = [
  {
    name: "ID",
    index: "id",
    format: "return (data.dynamicId) ? data.dynamicId : data.id",
  },
  {
    name: "Creator",
    index: "creator",
    format: "return `${data.user.first} ${data.user.last}`",
  },
  {
    name: "Status",
    format: "return data.status.toUpperCase()",
  },
];

const LinkingExplainers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;
`;

const LinkingInfo = styled.div`
  display: flex;
  flex-direction: column;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const LinkingTitle = styled.div`
  font-size: 17px;
  font-weight: 500;
`;
