import { BaseCollection, BaseDocument, BaseData } from "../../base";

interface ConcurrentData extends BaseData {
  id: string;
  by: string; // User id
}

export class Concurrents extends BaseCollection<ConcurrentData> {
  concurrent(id: string) {
    return this.doc(id) as Concurrent;
  }
}

type Concurrent = BaseDocument<ConcurrentData>;