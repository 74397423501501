import { BaseCollection, BaseDocument, BaseData } from "../../base";

interface CommentData extends BaseData {
  userId: string;
  message: string;
  date: Date;
}

export class Comments extends BaseCollection<CommentData> {
  comment(id: string): Comment {
    return this.doc(id) as Comment;
  }
}

type Comment = BaseDocument<CommentData>;