import React, { useContext, useEffect, useCallback } from "react";

import { Snackbar, SnackbarContent, IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { SnackbarContext } from "../../../hooks/system";

export const SnackbarManager = () => {
  const theme = useTheme();
  // Import array of snackbar strings
  const { messages, setMessages } = useContext(SnackbarContext);

  // Function to remove messages from the snackbar but not all of them incase there are multiple snackbars
  const removeSnackbar = (index) => {
    setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          position: "fixed",
          bottom: 20,
          left: 20,
          zIndex: 3500,
        }}
      >
        {messages.map((message, index) => (
          <SnackbarContent
            key={index}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            }}
            message={message}
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  onClick={() => removeSnackbar(index)}
                  style={{
                    color: theme.palette.common.white,
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </>
            }
          />
        ))}
      </Stack>
    </>
  );
};
