import React, { useContext } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { ClarificationList } from "../../project/query/clarification";
import NewClarificationForm from "../../project/query/clarification/new";

import { useClarifications } from "../../../hooks/forms";
import { FormCompositeContext, FormContent, FormDataContext } from ".";

export default () => {
  const { projectId, queryId } = useParams();
  const formData = useContext(FormDataContext);
  const form = useContext(FormCompositeContext);

  const [clarifications, newClarification] = useClarifications(
    projectId,
    queryId,
    formData?.compositeIndex ?? -1
  );

  return (
    <FormContent>
      <ClarificationList
        clarifications={clarifications}
        answering={false}
        updateClarification={() => {}}
        userData={{
          name: formData.user.name,
          email: formData.user.email,
        }}
      />
      {(!formData ||
        !formData?.deliveryDates?.clarificationDate ||
        formData?.deliveryDates?.clarificationDate < new Date()) && (
        <NewClarificationForm
          makeClarification={newClarification}
          form={form} // TODO: This is kinda defective, should lean on context
          formUser={{ name: formData.user.name }}
        />
      )}
    </FormContent>
  );
};

const StatusIndicator = styled.div`
  margin-left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;
