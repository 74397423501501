import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import FormMessenger from "../../project/query/negotiator/messenger";

import { FormCompositeContext, FormContent, FormDataContext } from ".";
import { useFormNegotiables } from "../../../hooks/forms";

const EMPTY_SCHEMA = {};

export default ({ formSchemas }) => {
  const formComposite = useContext(FormCompositeContext);
  const formData = useContext(FormDataContext);

  const { projectId, queryId, formId } = useParams(); // For the filepath

  const negotiableFiles = useFormNegotiables(formSchemas, EMPTY_SCHEMA);

  return (
    <FormContent style={{ height: "70vh" }}>
      <FormMessenger
        form={formComposite}
        formData={formData}
        messager={{
          name: formData.user.name,
          id: "external", // Form User no longer carries a tied up id
          side: "formUser",
        }}
        filePath={`/${projectId}/queries/${queryId}/forms/${formId}/uploads`}
        negotiateFiles={negotiableFiles ?? {}}
      />
    </FormContent>
  );
};
