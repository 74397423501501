import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const InputDiv = styled.div`
  width: 100%;
  margin-bottom: 12px;

  :last-child {
    margin-bottom: 0;
  }
`;

const InputLabel = styled.div`
  color: black;
  font-family: ${(props) => props.theme.font};
  font-size: 12px;
  margin-bottom: 8px;
  ${isMobile && "margin-left: 10px;"}

  ${({ labelStyle }) => labelStyle == "white" && `color: white !important;`}
`;

export const Input = ({
  key,
  children,
  label,
  labelStyle = "",
  withLabel = true,
  ...props
}) => {
  return (
    <InputDiv key={key} {...props}>
      {withLabel && (
        <InputLabel className="label" labelStyle={labelStyle}>
          {label}
        </InputLabel>
      )}
      {children}
    </InputDiv>
  );
};

function evalNumber(val) {
  if (val[val.length - 1] == "." || val[val.length - 1] == "0" || val == "") {
    return val;
  } else {
    let ret = parseFloat(val);
    if (isNaN(ret)) {
      return 0;
    }
    return parseFloat(val);
  }
}

const GeneralInputField = styled.input`
  border: none;
  width: 100%;
  max-width: 350px;

  line-height: 30px;
  background: white;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  min-height: 38px;

  box-sizing: border-box;
  padding: 0px 8px;
  margin-bottom: 8px;
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
`;

// Used exclusively in some niche locations in admin
export const TextInput = ({ onChange, value, ...props }) => {
  return (
    <Input {...props}>
      <GeneralInputField
        value={value}
        onChange={(e) =>
          onChange(
            props.type == "number"
              ? evalNumber(e.currentTarget.value)
              : e.currentTarget.value
          )
        }
        disabled={!props.editable}
        name={props.name}
      />
    </Input>
  );
};
