import { BaseCollection, BaseDocument, BaseData } from "../../../base";

export interface FormData extends BaseData {
  data: { [key: string]: any };
}

export class FormDataSets extends BaseCollection<FormData> {
  form(id: string): FormDataSet {
    return this.doc(id) as FormDataSet;
  }
}

type FormDataSet = BaseDocument<FormData>;