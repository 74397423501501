import React, { useContext, useEffect, useMemo } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

import { TabPanelContainer } from "..";
import { useApprovals } from "../../../../../hooks/actions";
import { QueryStatusContext } from "../../query";

import NewApprovals from "./new";
import ViewApprovals from "./view";

export default ({ queryData, setQueryData, query, readonly, schemaData }) => {
  // Fetch all approvals for the query
  const approvals = useApprovals(query);
  const queryStatus = useContext(QueryStatusContext);

  const approvalStages = useMemo(() => {
    if (!queryStatus) {
      return [];
    }
    return queryStatus.get_approvals_statuses();
  }, [queryStatus]);

  // Determine render rules
  const areApprovalsLoading =
    approvals === undefined ||
    approvals?.filter(
      (a) => a.stage >= queryStatus.relevantApprovals.firstStage
    )?.length < 1;
  const areApprovalsEditable = queryStatus?.is_approvals_editable();
  const approvalStagesAddable = schemaData?.settings?.approvalsMultistage;

  // If we have no approvals data structure, let's generate it!
  useEffect(() => {
    if (!queryData || !queryStatus || !schemaData) {
      return;
    }
    // Now check if queryData has an approvalsRequired data structure
    if (queryData?.approvalsRequired) {
      return;
    }
    // Now we know that the queryStatus info is available and the queryData does not have an approvalsRequired data structure
    // So let's generate it!
    setQueryData((ex) => ({
      ...ex,
      approvalsRequired: queryStatus.get_approvals_default_structure(),
    }));
  }, [queryData, queryStatus]);

  return (
    <TabPanelContainer style={{ flexDirection: "column" }}>
      {areApprovalsEditable ? (
        <NewApprovals
          approvalsRequired={queryData?.approvalsRequired}
          setApprovalsRequired={(callback) => {
            setQueryData((ex) => ({
              ...ex,
              approvalsRequired: callback(ex?.approvalsRequired),
            }));
          }}
          approvalStagesAddable={approvalStagesAddable}
          statuses={approvalStages}
        />
      ) : areApprovalsLoading ? (
        <LoadingApprovals />
      ) : (
        <ViewApprovals
          approvals={approvals}
          approvalSchema={schemaData?.approvalFields}
          queryStatus={queryStatus}
          query={query}
          signToApprove={schemaData?.settings?.signToApprove}
          requireApprovalSignature={
            schemaData?.settings?.requireApprovalSignature
          }
        />
      )}
    </TabPanelContainer>
  );
};

const LoadingApprovals = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <CircularProgress />
      <Typography variant="h6">Approvals are being generated</Typography>
    </Box>
  );
};
