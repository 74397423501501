import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CHART_TYPES } from "../../../../common/charts";
import {
  NewFieldPlaceholder,
  DragHoverPlaceHolder,
  EditableFieldResolver,
} from "./chartfields";

export const ChartEditor = ({
  chartSchemaSet,
  data,
  actions,
  panel,
  theme,
  chartBlobCollector,
  setChartSchemaSetPreview,
}) => {
  const { v4: uuidv4 } = require("uuid");
  const [schemaSet, setSchemaSet] = useState(chartSchemaSet);
  const [inDragState, setInDragState] = useState(false);
  const [dragIsOverDropableArea, setDragIsOverDropableArea] = useState(false);
  const [dropIndex, setDropIndex] = useState(undefined);

  useEffect(() => {
    setChartSchemaSetPreview(schemaSet);
  }, [schemaSet]);

  const handleTypeChange = (index) => {
    return (callback) => {
      let schemaSetCopy = [...schemaSet];

      schemaSetCopy[index] = callback(schemaSetCopy[index]);
      setSchemaSet(schemaSetCopy);
    };
  };

  const handleFieldDelete = (index) => {
    const updatedSchemaSet = schemaSet.filter((_, i) => i !== index);
    setSchemaSet(updatedSchemaSet);
  };

  const handleFieldInsert = (index) => {
    let schemaSetCopy = [...schemaSet];

    let id = uuidv4().replace(/-/g, "").slice(0, 5);
    const newChart = { name: "new chart", id: id, type: "pie", field: "" };
    schemaSetCopy.splice(index, 0, newChart);

    setSchemaSet(schemaSetCopy);
  };

  const handleFieldDuplicate = (index) => {
    let duplicateField = JSON.parse(JSON.stringify(schemaSet[index])); //deep copy
    duplicateField.name = schemaSet[index].name + "_duplicate";
    duplicateField.id = uuidv4().replace(/-/g, "").slice(0, 5);
    let schemaSetCopy = [...schemaSet];
    schemaSetCopy.splice(index + 1, 0, duplicateField);
    setSchemaSet(schemaSetCopy);
  };

  const handleMoveField = (e, data, fieldToMove, oldIndex) => {
    if (e.type === "mousedown") {
      //drag onStart
      setInDragState(true);
    } else if (e.type === "mouseup") {
      // drag onStop
      setInDragState(false);

      if (dragIsOverDropableArea) {
        if (oldIndex === dropIndex) {
          return;
        }

        //delete old chart
        let updatedSchemaSet = schemaSet.filter((_, i) => i !== oldIndex);

        //insert copy at new index
        if (oldIndex < dropIndex) {
          updatedSchemaSet.splice(dropIndex - 1, 0, fieldToMove);
        } else if (oldIndex > dropIndex) {
          updatedSchemaSet.splice(dropIndex, 0, fieldToMove);
        }

        setSchemaSet(updatedSchemaSet);

        //set state for next field dragged
        setDragIsOverDropableArea(false);
      }
    }
  };

  const setName = (newName, index) => {
    let schemaSetCopy = [...schemaSet];
    schemaSetCopy[index].name = newName;
    setSchemaSet(schemaSetCopy);
  };

  const setFormat = (newFormat, index) => {
    let schemaSetCopy = [...schemaSet];
    schemaSetCopy[index].format = newFormat;
    setSchemaSet(schemaSetCopy);
  };

  return (
    <EditChartsLayout>
      {schemaSet.length === 0 && (
        <NewFieldPlaceholder
          hidden={false}
          addFieldCallback={() => handleFieldInsert(0)}
        />
      )}
      {schemaSet?.map((chartSchema, i) => (
        <div
          style={{ display: "flex", flexDirection: "row" }}
          key={chartSchema.id}
        >
          {inDragState ? (
            <DragHoverPlaceHolder
              hidden
              setIsDroppable={setDragIsOverDropableArea}
              setDropData={setDropIndex}
              index={i}
            />
          ) : (
            <NewFieldPlaceholder
              hidden={false}
              addFieldCallback={() => handleFieldInsert(i)}
            />
          )}
          <EditableFieldResolver
            chartSchema={chartSchema}
            data={data}
            actions={actions}
            theme={!panel ? theme : undefined}
            sendBlob={chartBlobCollector}
            index={i}
            types={CHART_TYPES}
            onFieldChange={handleTypeChange(i)}
            onFieldDelete={() => handleFieldDelete(i)}
            onFieldDuplicate={() => handleFieldDuplicate(i)}
            onFieldDrag={handleMoveField}
            isBeingDragged={inDragState}
            setName={setName}
            setFormat={setFormat}
          />
          {i === schemaSet.length - 1 && inDragState && (
            <DragHoverPlaceHolder
              hidden
              setIsDroppable={setDragIsOverDropableArea}
              setDropData={setDropIndex}
              index={i + 1}
            />
          )}
          {i === schemaSet.length - 1 && !inDragState && (
            <NewFieldPlaceholder
              hidden={false}
              addFieldCallback={() => handleFieldInsert(i + 1)}
            />
          )}
        </div>
      ))}
    </EditChartsLayout>
  );
};

const EditChartsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;

  padding: 4px 6px;
  margin-right: 5px;
  min-height: 60px;
`;
