import React from "react";
import styled from "styled-components";

import Vision from "../graphics/vision-image.png";

export default () => {
  return (
    <VisionContainer>
      <TextContainer>
        <H2>Vision & Strategy</H2>
        <P1>'ACCESS TO TECHNOLOGY ISN'T A BARRIER TO IMPROVEMENT'</P1>
        <P2>Our 3 focus areas describe our strategy:</P2>
        <UL>
          <LItem>Query-It Digital Tool</LItem>
          <P3>
            Fully customizable cloud-based platform to digitize and automate
            work processes. Our goal is to help companies in their digital
            transformation journey by implementing Query-It to standardize &
            automate work processes, eliminate paper, empower frontline, and
            gain real-time data insights. We will continue to enhance this
            platform to help companies make better data informed decisions.
          </P3>
          <LItem>Develop standalone tools/utilities</LItem>
          <P3>
            The Query-It digital platform isn’t the ideal solution for certain
            applications.  We will assist clients in creating intuitive, simple
            and application specific digital solutions to increase business
            efficiency and productivity.
          </P3>
          <LItem>Value add projects</LItem>
          <P3>
            Either through our internal think tank, or in partnership with
            organizations we will create technology solutions that add value to
            society.
          </P3>
        </UL>
      </TextContainer>
      <Image src={Vision} />
    </VisionContainer>
  );
};

const VisionContainer = styled.div`
  min-height: 700px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) and (min-width: 601px) {
    margin-top: 50px;
    margin-bottom: 75px;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;
const TextContainer = styled.div`
  margin-right: 2%;
  width: 40%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media (max-width: 600px) {
    width: 85%;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0;
  }
`;
const Image = styled.img`
  // dimensions in the mockup
  height: 530px;
  width: 781px;
  margin-left: 3%;

  @media (max-width: 1500px) and (min-width: 1201px) {
    // scale 80%
    height: 424px;
    width: 624.8px;
  }

  @media (max-width: 1200px) and (min-width: 1001px) {
    // scale 60%
    height: 318px;
    width: 468.6px;
  }

  @media (max-width: 1000px) and (min-width: 601px) {
    // scale 40%;
    height: 212px;
    width: 312.4px;
  }

  @media (max-width: 600px) and (min-width: 381px) {
    // mobile mockup dimensions
    height: 240px;
    width: 353px;
    margin-top: 40px;
    border-radius: 15px;
  }

  @media (max-width: 380px) {
    // scale mobile 80%
    height: 192px;
    width: 282.4px;
    margin-top: 25px;
    border-radius: 15px;
  }
`;
const H2 = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 200%;
  color: #37227a;
  margin-top: 20px;
`;
const P1 = styled.p`
  margin: 0;
  margin-top: 3%;
  font-size: 150%;
  color: #37227a;

  @media (max-width: 600px) {
    text-align: center;
  }
`;
const P2 = styled.p`
  text-align: left;
  color: black;

  @media (max-width: 600px) {
    line-height: 25px;
  }
`;
const P3 = styled.p`
  margin: 0;

  @media (max-width: 600px) {
    line-height: 25px;
  }
`;
const UL = styled.ul`
  padding-left: 2.5%;
  margin-top: -10px;
  @media (max-width: 600px) {
    margin-bottom: 40px;
    margin-top: -5px;
  }
`;
const LItem = styled.li`
  margin-top: 3%;
  color: #37227a;
  font-weight: 900;
`;
