import React, { useContext } from "react";
import { ProjectLogoContext } from "..";
import { Logo, LogoDiv } from "./query";

import { CircularProgress } from "@mui/material";

export const ProjectLogo = () => {
  const logo = useContext(ProjectLogoContext);

  if (logo === undefined) {
    return <CircularProgress size={62} />;
  }

  if (logo === null) {
    return null;
  }

  return (
    <LogoDiv>
      <Logo src={logo} />
    </LogoDiv>
  );
};
