import React from "react";
import styled from "styled-components";

export default ({ text, image }) => {
  return (
    <Box>
      <Triangle />
      <Image src={image} />
      <Caption>{text}</Caption>
    </Box>
  );
};

const Box = styled.div`
  height: 236px;
  width: 368px;
  background-color: #37227a;
  color: white;
  border-radius: 7px;
  position: relative;

  @media (max-width: 1700px) and (min-width: 651px) {
    // scale 80%
    height: 190px;
    width: 294px;
  }

  @media (max-width: 650px) and (min-width: 381px) {
    // box's mobile mockup dimensions
    height: 226px;
    width: 353px;
    margin-top: 30px;
  }

  @media (max-width: 380px) {
    // scale mobile 80%
    height: 180.8px;
    width: 282.4px;
    margin-top: 30px;
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  // mobile dimensions ----------------------------
  border-bottom: 204px solid transparent;
  border-left: 238px solid #e8295d;
  // ---------------------------------------------
  position: absolute;
  top: 0;
  left: 0;

  @media ((max-width: 1700px) and (min-width: 651px)), (max-width: 380px) {
    // scale 80%
    border-top: 0px solid transparent;
    border-bottom: 163px solid transparent;
    border-left: 190px solid #e8295d;
  }
`;
const Caption = styled.h2`
  position: absolute;
  width: 50%;
  top: 106px;
  left: 166px;
  margin: 0;

  @media ((max-width: 1700px) and (min-width: 651px)), (max-width: 380px) {
    // scale 80%
    top: 85px;
    left: 133px;
    font-size: 100%;
  }

  @media (max-width: 600px) {
  }
`;
const Image = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 100px;

  @media ((max-width: 1700px) and (min-width: 651px)), (max-width: 380px) {
    // scale 80%
    height: 80px;
  }

  @media (max-width: 650px) {
  }
`;
