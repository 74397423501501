import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { ConfirmationModal } from "../../../ui/modals";

import {
  Box,
  FormGroup,
  FormLabel,
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Close as CloseIcon,
  PlayArrow,
  AccessTime,
  Stop,
  Publish,
  Edit,
  Cancel,
} from "@mui/icons-material";
import { useSchema } from "../../../../hooks/projects";

import {
  GenericField,
  SelectField as SelectField2,
  BooleanField,
  FileField,
  PhotosField,
  LocalityField,
} from "../../../ui/inputs2";
import {
  FFTableRow,
  FFTableCell,
  FFTableFooterRow,
  FFTableHead,
  FFTable,
  FFTableIconButton,
  FFTableCellCenterContent,
  FFTableBody,
  FFTableCellPartition,
  FFTableCellPartitionWrapper,
} from "../../../ui/table";
import { FieldGenerator } from "./generator";

import { generate_tempid, strip_undefined } from "../../../../tools";
import q, { APIStorage } from "@queryit/api";

import {
  ProjectQueriesContext,
  ProjectReferenceContext,
  ProjectUsersContext,
} from "../..";

import { TimerContext } from "../../../../App";

import { log_error } from "../../../../tools/logger";
import { AutoCompleteUserlistContext, QueryStatusContext } from "../query";
import { formatTime } from "../../timer";
import { useParams, useSearchParams } from "react-router-dom";

export const DocLink = React.memo(({ data, label, withLabel }) => {
  // data here is a FileMetaData object setup in the backend
  // NOTE: There is an old version of this feature where "data" is just the "url" property
  const [doc, setDoc] = useState(undefined); // State of retrieved file

  // Fetch project from context
  const project = useContext(ProjectReferenceContext);

  useEffect(() => {
    if (data) {
      const isNewFormat = typeof data === "object";
      if (isNewFormat) {
        APIStorage.getDownloadURL(q.storage.ref(data.location)).then(setDoc);
      } else {
        const hasUrl = data?.url !== undefined;
        APIStorage.getDownloadURL(
          q
            .getProjectStorage("projects")
            .ref(`${project.ref.id}/statics/${hasUrl ? data.url : data}`)
        ).then((url) => {
          setDoc(url);
        });
      }
    }
  }, [data, project]);

  if (!data) {
    return;
  }

  return (
    <FormGroup>
      {withLabel && <FormLabel>{label}</FormLabel>}
      {doc && (
        <PrettyLink href={doc} target="_blank">
          {data.name ?? data}
        </PrettyLink>
      )}
      {!doc && "loading..."}
    </FormGroup>
  );
});

export const StaticImageField = React.memo(({ data, label, withLabel }) => {
  // data here is a FileMetaData object setup in the backend
  const [doc, setDoc] = useState(undefined); // State of retrieved file

  useEffect(() => {
    if (data) {
      APIStorage.getDownloadURL(q.storage.ref(data.location)).then(setDoc);
    }
  }, [data]);

  if (!data) {
    return;
  }

  return (
    <FormGroup>
      {withLabel && <FormLabel>{label}</FormLabel>}
      {doc ? (
        <img
          src={doc}
          srcSet={doc}
          loading="lazy"
          alt={label ?? "Static Image in Query"}
          style={{ maxWidth: "100%", height: "auto" }}
        />
      ) : (
        <CircularProgress />
      )}
    </FormGroup>
  );
});

export const SelectForm = ({
  data,
  field,
  onChange,
  editable,
  withLabel,
  multi,
  error,
  maxWidth,
}) => {
  const [options, setOptions] = useState({});

  // Get queries (this field depends on them)
  const queries = useContext(ProjectQueriesContext);
  const thisData = data[field.id];
  const conditionalData = field.conditionalOn
    ? data[field.conditionalOn.split(".").pop()]
    : undefined;

  useEffect(() => {
    if (!field.source) {
      // Handle the static options case
      setOptions(
        field.conditionalOn
          ? field.options[conditionalData]
            ? field.options[conditionalData]
            : {}
          : field.options
      );
    }
  }, [field, conditionalData]);

  // We broke this down to maintain difference dependency lists
  // NOTE: These are MUCH more expensive as of now for renders
  useEffect(() => {
    if (field.source) {
      // TODO: Should be redone
      if (field.source.internal) {
        const [projectId, schemaId] = field.source.internal.split("/");
        try {
          // Protect the app from these user functions
          const nameFunc = field.source.tag
            ? new Function("data", "query", field.source.tag)
            : undefined;
          const indFunc = field.source.index
            ? new Function("data", "query", field.source.index)
            : undefined;
          // Now build the options list
          let newOptions = {};
          queries
            .filter((q) => q.schemaId === schemaId)
            .forEach((dt) => {
              let lbl = nameFunc ? nameFunc(dt, data) : dt.id;
              let nd = indFunc ? indFunc(dt, data) : dt.id;
              // Now if there is a naming function, it can act as a filter by
              // returning undefined to remove rows from the dropdown
              if (lbl) {
                newOptions[nd] = nameFunc ? nameFunc(dt, data) : dt.id;
              }
            });
          setOptions(newOptions);
        } catch (ex) {
          log_error("Evaluating Internal sourcing failed", ex);
          setOptions({});
        }
      }
      // TODO: Support other cases of sourced data
    }
  }, [field, queries, data]);

  // Now that we know what the options look like, begin component render
  return (
    <SelectField2
      label={withLabel ? field.name : ""}
      data={thisData}
      options={
        options &&
        Object.keys(options)
          .sort()
          .reduce((acc, k) => ({ ...acc, [k]: options[k] }), {})
      } // We sort this here, but it's not guaranteed to work
      onChange={onChange}
      disabled={!editable}
      error={error}
      fill={true}
      multi={multi}
      maxWidth={maxWidth}
    />
  );
};

export const AdhocForm = ({
  data,
  field,
  onChange,
  editable,
  withLabel,
  error,
  schema,
  project,
}) => {
  const [schemaRef, schemaData] = useSchema(schema, project);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    if (schemaRef) {
      setLoading(true);
      const fetchAdhoc = async () => {
        const dbOptions = await schemaRef.adhoc.doc(field.id).get();
        setOptions(dbOptions?.options ? dbOptions.options : []);
      };
      fetchAdhoc();
      setLoading(false);
    }
  }, [field.id, schemaRef]);

  const handleFilter = (options, params) => {
    let filtered = [];
    if (params.inputValue !== "" && !options.includes(params.inputValue)) {
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}" as option`,
      });
    }

    options.forEach((option) => {
      if (option.toLowerCase().includes(params.inputValue.toLowerCase())) {
        filtered.push(option);
      }
    });

    return filtered;
  };

  const handleChange = async (event, newValues) => {
    let valuesCopy = newValues;
    let adHocValue;

    if (Array.isArray(valuesCopy)) {
      // Handle array logic
      const latest = valuesCopy.slice(-1)[0];
      adHocValue = latest?.inputValue;
    } else {
      // Handle string logic
      adHocValue = valuesCopy?.inputValue;
    }

    if (adHocValue) {
      setLoading(true);

      if (options && options.length === 0) {
        await schemaRef.adhoc
          .doc(field.id)
          .set({ options: [...options, adHocValue] });
      } else {
        await schemaRef.adhoc
          .doc(field.id)
          .update({ options: [...options, adHocValue] });
      }

      setOptions((ex) => [...ex, adHocValue]);
      // If it's an array, replace the last element if it doesn't exist, otherwise pop.
      // If string, just set it.
      if (Array.isArray(valuesCopy)) {
        if (!valuesCopy.includes(adHocValue)) {
          valuesCopy[valuesCopy.length - 1] = adHocValue;
        } else {
          valuesCopy.pop();
        }
      } else {
        valuesCopy = adHocValue;
      }

      onChange({ target: { value: valuesCopy } });
      setLoading(false);
    } else {
      onChange({ target: { value: valuesCopy } });
    }
  };

  const handleDeleteClick = (event, option) => {
    event.stopPropagation();
    setOptionToDelete(option);
    setOpenConfirm(true);
  };

  const confirmDelete = async () => {
    if (optionToDelete) {
      const newOptions = options.filter((opt) => opt !== optionToDelete);
      await schemaRef.adhoc.doc(field.id).set({ options: newOptions });
      setOptions(newOptions);
      setOpenConfirm(false);
      setOptionToDelete(null); // Reset the option to delete after deletion
    }
  };

  let value = data[field.id];
  if (field?.multi) {
    value = value ? (Array.isArray(value) ? value : [value]) : [];
  } else {
    // If field is not multi but value is an array, convert to string with ', ' delimiter
    value = value ? (Array.isArray(value) ? value.join(", ") : value) : null;
  }

  return (
    <div style={{ width: "95%" }}>
      <ConfirmationModal
        open={openConfirm}
        onConfirm={confirmDelete}
        onCancel={() => {
          setOpenConfirm(false);
          setOptionToDelete(null);
        }}
        body={`Are you sure you want to delete the "${optionToDelete}" option? 
          It will be deleted for all queries in this schema.`}
      />
      <Autocomplete
        sx={{ width: "95%", padding: "10px" }}
        loading={loading}
        value={value}
        disableCloseOnSelect={field?.multi ? true : false}
        multiple={field?.multi}
        disabled={!editable}
        limitTags={5}
        onChange={handleChange}
        filterOptions={handleFilter}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === "string" || typeof option === "number") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              {option?.title ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  }}
                >
                  <AddIcon />
                  <b>{option?.title}</b>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      flexGrow: 1,
                    }}
                  >
                    {field?.multi && <Checkbox checked={selected} />}
                    {option}
                  </Box>
                  <Tooltip
                    title="Delete Option"
                    placement="right"
                    onClick={(event) => handleDeleteClick(event, option)}
                  >
                    <IconButton size="small">
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            label={withLabel ? field.name : ""}
            {...params}
            variant="outlined"
            placeholder="Type to search or add option..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {error && error.length > 0 && (
        <MuiFormHelperText error={error}>{error}</MuiFormHelperText>
      )}
    </div>
  );
};

export const TableRadioCells = ({ data, options, onFieldChange, editable }) => {
  if (!options) {
    return null;
  }

  return (
    <FFTableCellPartitionWrapper>
      {Object.keys(options)
        .sort()
        .map((optKey) => (
          <FFTableCellPartition key={`${optKey}-cell`}>
            <BooleanField
              data={data === optKey}
              value={optKey}
              onChange={onFieldChange}
              disabled={!editable}
              radio
              useIndex
            />
          </FFTableCellPartition>
        ))}
    </FFTableCellPartitionWrapper>
  );
};

// New Calc Fields

export const TableCalcField = ({ data, field, onChange }) => {
  return <>Calculation Cell</>;
};

export const DataCalcField = ({ data, field, withLabel, disabled }) => {
  return (
    <GenericField
      label={withLabel ? field.name : undefined}
      data={data[field.id]}
      disabled={disabled}
    />
  );
};

export const TableField2 = ({
  field,
  data,
  onFieldChange,
  staticTable,
  editable,
  status,
  editabilityConditions,
  queryid,
  error,
  ...props
}) => {
  /* Little note on philosophy
    These tables are a nightmare because the components want to update all the time
    We want to prevent that, but maintain a single data object for the query.
    To do that, we embed IDs in our tables to make everything referential
  */
  const tableData = data[field.id];

  const tableDataOnChange = (rowId) => (colId) => (val) => {
    onFieldChange({
      target: {
        value: [
          ...tableData.map((row) =>
            row.id == rowId ? { ...row, [colId]: val } : row
          ),
        ],
      },
    });
  };

  const tableDataAddRow = (rowId) => {
    let sp = [...tableData];
    sp.splice(tableData.findIndex((r) => r.id == rowId) + 1, 0, {
      id: generate_tempid(),
    });
    onFieldChange({ target: { value: sp } });
  };

  const tableDataDeleteRow = (rowId) => {
    onFieldChange({
      target: {
        value:
          tableData.length > 1
            ? tableData.filter((r) => r.id !== rowId)
            : [{ id: generate_tempid() }],
      },
    });
  };

  useEffect(() => {
    if (!tableData) {
      // REMOVE: I think this code is unused?
      if (field.rows) {
        // Well then this is a static table that needs rows!
        // TODO: There's probably a check needed here for conditional defaults
        onFieldChange({ target: { value: field.rows } });
      } else {
        // If there's really nothing nothing here, add a single pity row
        onFieldChange({ target: { value: [{ id: generate_tempid() }] } });
      }
    }
  }, [tableData, field, onFieldChange]);

  if (!tableData) {
    return null;
  }

  return (
    <TableContainer>
      {field.name && field.name.length > 0 && <div>{field.name}</div>}
      <FFTable style={field.width ? { maxWidth: field.width } : {}}>
        <FFTableHead>
          <tr>
            {field?.columns?.map((column) => (
              <TableHeader
                key={`table-header-${column.id}`}
                colSpan={column.span ? column.span : 1}
                style={column.type == "rowindex" ? { width: "100px" } : {}}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {column.name}
                  {column.type === "tableradio" && column.options && (
                    <FFTableCellPartitionWrapper>
                      {Object.keys(column.options)
                        .sort()
                        .map((k) => column.options[k])
                        .map((opt) => (
                          <FFTableCellPartition key={`cell-opt-${opt}`}>
                            {opt}
                          </FFTableCellPartition>
                        ))}
                    </FFTableCellPartitionWrapper>
                  )}
                </div>
              </TableHeader>
            ))}
            {!staticTable && (
              <TableHeader style={{ width: "50px" }}></TableHeader>
            )}
          </tr>
        </FFTableHead>
        <FFTableBody>
          {/* First we add the data rows */}
          {tableData.map((tableRow, ind) => {
            if (!tableRow.id) {
              tableRow.id = generate_tempid();
            }
            return (
              <FFTableRow
                key={tableRow.id ? tableRow.id : `tbl-row-unindexed-${ind}`}
              >
                {/* First let's add all the columns for the data */}
                {field?.columns?.map((col) => {
                  if (col.type == "rowindex") {
                    return (
                      <FFTableCell key={`${tableRow.id}-cell-rowindex`}>
                        <FFTableCellCenterContent
                          key={`${tableRow.id}-cell-${col.id}`}
                        >
                          {ind + 1}
                        </FFTableCellCenterContent>
                      </FFTableCell>
                    );
                  } else {
                    return (
                      <FFTableCell
                        key={`${tableRow.id}-cell-${col.id}`}
                        colSpan={col.span ? col.span : 1}
                        width={col.width}
                        center={col.type === "checkbox"}
                      >
                        <FieldGenerator
                          field={col}
                          data={tableRow}
                          onChangeGenerator={tableDataOnChange(tableRow.id)}
                          withLabel={false}
                          status={status}
                          editabilityConditions={{
                            ...editabilityConditions,
                            readonly:
                              editable === false
                                ? true
                                : editabilityConditions?.readonly,
                          }}
                          queryid={queryid}
                          embedded
                        />
                      </FFTableCell>
                    );
                  }
                })}
                {/* Now if it's not static, add the row management buttons */}
                {!staticTable && editable && (
                  <FFTableCell key="row-cell-manage">
                    <FFTableCellCenterContent>
                      <FFTableIconButton
                        key={"add"}
                        onClick={() => {
                          tableDataAddRow(tableRow.id); // The row preceeding the one we add
                        }}
                        style={{ fontSize: 10 }}
                      >
                        <AddIcon />
                      </FFTableIconButton>
                      <FFTableIconButton
                        key={"delete"}
                        onClick={() => {
                          tableDataDeleteRow(tableRow.id);
                        }}
                      >
                        <DeleteIcon />
                      </FFTableIconButton>
                    </FFTableCellCenterContent>
                  </FFTableCell>
                )}
              </FFTableRow>
            );
          })}
          {/* Then calculation Rows */}
          {field?.columns?.some((col) => (col.calculation ? true : false)) && (
            <FFTableRow key={"calc-row"}>
              {field.columns.map((col) => {
                const NoCalc = (
                  <FFTableCell
                    key={`calc-col-${col.id}`}
                    colSpan={col.span ? col.span : 1}
                  ></FFTableCell>
                );
                if (!col.calculation) {
                  return NoCalc;
                }
                // Poll for calc
                let calcs = Array.isArray(col.calculation)
                  ? col.calculation
                  : col.calculation[data[field.conditionalOn]];
                // Confirm that a calculation was resolved from the polling
                if (!calcs) {
                  return NoCalc;
                }
                // Now add the beautiful calc
                return (
                  <FFTableCell key={`calc-col-${col.id}`}>
                    {calcs.map((calc) => {
                      let sm, calcFormat, dataValue;
                      switch (calc) {
                        case "sum":
                          sm = 0;
                          if (data[field.id] != "" && data[field.id]) {
                            sm = data[field.id].reduce((acc, row) => {
                              let dt = parseFloat(row[col.id]);
                              return acc + (!isNaN(dt) ? dt : 0);
                            }, 0);
                          }
                          return (
                            <FFTableFooterRow key={`calc-col-${col.id}-sum`}>
                              Sum: {sm}
                            </FFTableFooterRow>
                          );
                        default:
                          try {
                            calcFormat = new Function("val", calc.format);
                            if (!data) {
                              return null;
                            }
                            dataValue = calcFormat(
                              data[field.id] == ""
                                ? []
                                : data[field.id]
                                ? data[field.id]
                                : []
                            );
                            return (
                              <FFTableFooterRow
                                key={`calc-col-${col.id}-calculated`}
                              >
                                {calc.text}: {isNaN(dataValue) ? 0 : dataValue}
                              </FFTableFooterRow>
                            );
                          } catch (ex) {
                            log_error("Table footer calculation failed", ex);
                            return null;
                          }
                      }
                    })}
                  </FFTableCell>
                );
              })}
            </FFTableRow>
          )}
        </FFTableBody>
      </FFTable>
      {error && error.length > 0 && (
        <MuiFormHelperText error={error}>{error}</MuiFormHelperText>
      )}
    </TableContainer>
  );
};

export const TableCell = ({ children, center, ...props }) => {
  return (
    <TableCellT {...props}>
      <TableCellCont center={center}>{children}</TableCellCont>
    </TableCellT>
  );
};

export const UsersSelectInput = ({
  data,
  onChange,
  multi,
  editable,
  withLabel,
  label,
  users,
  ...props
}) => {
  const projectUsers = useContext(ProjectUsersContext);
  const autoComplete = useContext(AutoCompleteUserlistContext);

  if (!projectUsers && !users) {
    return null;
  }

  const iteratedUsers = users ? users : projectUsers;
  const userOptions = {};
  for (let user of iteratedUsers) {
    if (user.name && user.name.first && user.name.last) {
      userOptions[user.id] = `${user.name.first} ${user.name.last}`;
    } else {
      // To handle weird render lag issues
      userOptions[user.id] = "Unnamed User";
    }
  }

  return (
    <SelectField2
      label={withLabel || withLabel === undefined ? label : ""}
      multi={multi}
      options={userOptions}
      data={
        Array.isArray(data)
          ? data.filter((u) => Object.keys(userOptions).includes(u))
          : data && data !== ""
          ? data
          : []
      } // Filter here because we don't allow undefined users!
      onChange={onChange}
      disabled={!editable}
      autocomplete={autoComplete}
      fill={true}
      {...props}
    />
  );
};

export const TimeTrackerField = ({
  field,
  label,
  data,
  onFieldUpdate,
  demonstratedError,
  queryid,
  editable,
  status,
  nestedId,
}) => {
  const { timer, setTimer } = useContext(TimerContext);
  const projectId = useParams()["*"].split("/")[1];
  const noTimer =
    (timer?.on && timer?.queryId && timer?.queryId !== queryid) ||
    (timer?.on && timer?.fieldId && timer?.fieldId !== field.id);
  const [method, setMethod] = useState(
    timer?.on && !noTimer ? "timer" : "manual"
  );

  // useEffect on mount to match any disconnects from the timer entry widget and time within the pane
  // this basically occurs when the timer is stopped in the widget, and then the query is opened
  useEffect(() => {
    if (
      timer &&
      timer.queryId === queryid &&
      timer.fieldId === field.id &&
      !timer.start
    ) {
      const startTime = new Date(timer.startTime);
      onFieldUpdate({
        target: {
          value: {
            ...data,
            start: startTime,
            end: new Date(startTime.getTime() + timer.elapsed * 1000),
          },
        },
      });
      setTimer((prev) => ({
        ...prev,
        start: false,
        on: false,
        startTime: Date.now(),
        elapsed: 0,
        queryId: "",
        fieldId: "",
        nestedId: "",
        queryPath: "",
      }));
    }
  }, []);

  const handleStartChange = (event) => {
    onFieldUpdate({
      target: {
        value: {
          ...data,
          start: event,
          end: event,
        },
      },
    });
  };

  const handleEndChange = (event) => {
    if (data?.start && new Date(event) < new Date(data.start)) {
      // end is less in time then reset end to start
      onFieldUpdate({
        target: {
          value: {
            ...data,
            end: data?.start,
          },
        },
      });
    } else {
      onFieldUpdate({
        target: {
          value: {
            ...data,
            end: event,
          },
        },
      });
    }
  };

  const handleMethodChange = (event, newMethod) => {
    if (newMethod !== null) {
      setMethod(newMethod);
    }
  };

  const convertTimestampToDate = (timestamp) => {
    if (timestamp && timestamp.seconds != null) {
      return new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      );
    }
    return timestamp;
  };

  const calculateTimeDifference = () => {
    const start =
      data?.start && typeof data.start === "object"
        ? "seconds" in data.start
          ? new Date(data.start.seconds * 1000)
          : new Date(data.start)
        : new Date();

    const end =
      data?.end && typeof data.end === "object"
        ? "seconds" in data.end
          ? new Date(data.end.seconds * 1000)
          : new Date(data.end)
        : new Date(start);

    return formatTime(
      (start && end && !timer.start) || timer.fieldId !== field.id
        ? (end.getTime() - start.getTime()) / 1000
        : timer.elapsed
    );
  };

  let durationInHours = null;
  if (data?.start && data?.end) {
    const startTime = convertTimestampToDate(data.start);
    const endTime = convertTimestampToDate(data.end);

    const durationInMs = endTime.getTime() - startTime.getTime();
    durationInHours = (durationInMs / (1000 * 60 * 60)).toFixed(2);
  }

  return (
    <Box>
      <Typography>{label}</Typography>
      <TimeTrackerContainer>
        {editable && status && status !== "new" && (
          <ToggleButtonGroup
            color="primary"
            value={method}
            exclusive
            orientation="vertical"
            onChange={handleMethodChange}
            sx={{
              marginRight: "10px",
              marginLeft: "5px",
              marginTop: data?.start ? "0px" : "20px",
            }}
            disabled={!editable}
          >
            <ToggleButton value="manual">
              <Tooltip title="Manual Mode" placement="left">
                <AddIcon fontSize="small" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="timer">
              <Tooltip title="Timer" placement="left">
                <AccessTime fontSize="small" />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Box>
          {method === "manual" ? (
            <Tooltip
              title={durationInHours ? `${durationInHours} Hours` : ""}
              placement="right"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& > :not(style)": { padding: 0 },
                  alignItems: "center",
                }}
              >
                <LocalityField
                  data={data?.start}
                  onChange={handleStartChange}
                  label={"Start Time"}
                  useDate={true}
                  useTime={true}
                  error={demonstratedError}
                  fill={true}
                  disabled={
                    (timer?.start && timer.fieldId === field?.id) || !editable
                  } //when the timer is running, dont let them manually enter time
                />
                <LocalityField
                  data={data?.end}
                  onChange={handleEndChange}
                  label={"End Time"}
                  useTime={true}
                  error={demonstratedError}
                  fill={true}
                  disabled={
                    !data?.start ||
                    (timer?.start && timer.fieldId === field?.id) ||
                    !editable
                  }
                />
              </Box>
            </Tooltip>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minHeight: "130px",
              }}
            >
              <Typography
                variant="h4"
                sx={{ margin: "5px", marginTop: data?.start ? "0px" : "20px" }}
              >
                {calculateTimeDifference()}
              </Typography>
              {noTimer ? (
                <Typography
                  sx={{ color: "grey", fontSize: "14px", paddingLeft: "5px" }}
                >
                  Ongoing timer in another field.
                  <br />
                  Please close it before starting a new timer.
                </Typography>
              ) : timer.start ? (
                <Tooltip
                  title="Stop Time Entry"
                  onClick={() => {
                    const startTime = new Date(timer.startTime);
                    onFieldUpdate({
                      target: {
                        value: {
                          ...data,
                          start: startTime,
                          end: new Date(
                            startTime.getTime() + timer.elapsed * 1000
                          ),
                        },
                      },
                    });
                    setTimer((prev) => ({
                      ...prev,
                      start: false,
                      on: false,
                      startTime: Date.now(),
                      elapsed: 0,
                      queryId: "",
                      fieldId: "",
                      nestedId: "",
                      queryPath: "",
                    }));
                  }}
                >
                  <span>
                    <StyledIconButton
                      disabled={!editable}
                      sx={{ marginTop: data?.start ? "0px" : "20px" }}
                    >
                      <Stop sx={{ fontSize: "24px" }} />
                    </StyledIconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Start New Time Entry"
                  onClick={() => {
                    setTimer((prev) => ({
                      ...prev,
                      startTime: Date.now(),
                      on: true,
                      queryId: queryid,
                      queryPath: `${projectId}-${queryid}`,
                      fieldId: field.id,
                      nestedId: nestedId,
                      start: true,
                      elapsed: 0,
                    }));
                  }}
                >
                  <span>
                    <StyledIconButton
                      disabled={!editable}
                      sx={{ marginTop: data?.start ? "0px" : "15px" }}
                    >
                      <PlayArrow sx={{ fontSize: "24px" }} />
                    </StyledIconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
      </TimeTrackerContainer>
    </Box>
  );
};

// this is the file field with revisions enabled
export const QueryFileFieldRevisions = ({
  label,
  bucket,
  onFieldUpdate,
  data,
  disabled,
  error,
  field,
}) => {
  const project = useContext(ProjectReferenceContext);
  const queryStatus = useContext(QueryStatusContext);

  const versionCanCommit = useRef(false);

  useEffect(() => {
    // If file is not committed, increment file version to show next version correctly
    if (data && data?.commited === false) {
      setVersionNumber((ex) => {
        let incrementDone = false;
        return ex.includes(".")
          ? ex
              .split(".")
              .map((pos, ind) => {
                if (incrementDone) return 0;
                if (
                  Object.keys(positionReducedVersionMap[ind]).includes(
                    queryStatus.currentStatusId
                  )
                ) {
                  // Set the last incremented position (if not already set)
                  incrementDone = true;
                  // Then increment this one!
                  return (
                    parseInt(pos) +
                    positionReducedVersionMap[ind][queryStatus.currentStatusId]
                  );
                }
                return parseInt(pos);
              })
              .join(".")
          : ex;
      });
    }
  }, []);

  // No Map if null (migration) otherwise usable version map
  const positionReducedVersionMap = useMemo(() => {
    if (!field?.versionMap || !Array.isArray(field.versionMap)) {
      return null;
    }
    // Return array where each position is the "augmentPosition" with a map of status to autoAugment
    return (
      field?.versionMap
        .reduce((acc, row) => {
          // Check that positions before current acc are filled
          for (let i = 0; i <= row.augmentPosition; i++) {
            if (!acc[i]) {
              acc[i] = {};
            }
          }
          acc[row.augmentPosition][row.status] = row.autoAugment;
          return acc;
        }, [])
        // We reverse it because an admin setting these up will read it right to left
        .reverse()
    );
  }, [field?.versionMap]);

  // Get existing map or generate new
  const [versionNumber, setVersionNumber] = useState(
    (data?.revisions || []).slice(-1)[0]?.version ??
      (positionReducedVersionMap === null
        ? "No File Versions Configured"
        : positionReducedVersionMap
            .map((row) =>
              Object.keys(row).some((st) => st === queryStatus?.currentStatusId)
                ? row[queryStatus?.currentStatusId]
                : 0
            )
            .join("."))
  );

  const commitVersion = () => {
    onFieldUpdate({
      target: {
        value: strip_undefined({
          ...data,
          data: data.uncommittedFiles ?? [],
          revisions: [
            ...(data?.revisions ?? []),
            { version: versionNumber, data: data.uncommittedFiles ?? [] },
          ],
          commited: true,
          uncommittedFiles: undefined,
        }),
      },
    });
  };

  // When instigating revision, roll over to the next auto version number
  const handleRevision = () => {
    // Flag version ready false
    versionCanCommit.current = false;
    // Now update the data field (migrate existing files to uncommitted)
    onFieldUpdate({
      target: {
        value: {
          ...data,
          commited: false,
          uncommittedFiles: data.data,
        },
      },
    });
    // Increment version in only the position where the current status matches
    setVersionNumber((ex) => {
      let incrementDone = false;
      return ex.includes(".")
        ? ex
            .split(".")
            .map((pos, ind) => {
              if (incrementDone) return 0;
              if (
                Object.keys(positionReducedVersionMap[ind]).includes(
                  queryStatus.currentStatusId
                )
              ) {
                // Set the last incremented position (if not already set)
                incrementDone = true;
                // Then increment this one!
                return (
                  parseInt(pos) +
                  positionReducedVersionMap[ind][queryStatus.currentStatusId]
                );
              }
              return parseInt(pos);
            })
            .join(".")
        : ex;
    });
  };

  const cancelRevision = () => {
    onFieldUpdate({
      target: {
        value: {
          ...data,
          uncommittedFiles: undefined,
        },
      },
    });
    // And revert the version number by retrieving the last version
    setVersionNumber(
      (data?.revisions || []).slice(-1)[0]?.version ??
        (positionReducedVersionMap === null
          ? "ERROR: No file versions"
          : positionReducedVersionMap
              .map((row) =>
                Object.keys(row).some(
                  (st) => st === queryStatus?.currentStatusId
                )
                  ? row[queryStatus?.currentStatusId]
                  : 0
              )
              .join("."))
    );
  };

  if (positionReducedVersionMap === null) {
    return (
      <Typography>
        Please ask the admin to migrate to the new file field format.
      </Typography>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <FileField
        label={label}
        bucket={bucket}
        onChange={(e) => {
          onFieldUpdate({
            target: {
              value: {
                ...data,
                uncommittedFiles: e,
              },
            },
          });
          // Also flag version commitable if not already
          versionCanCommit.current = true;
        }}
        files={data.uncommittedFiles ?? data.data}
        disabled={disabled || data?.commited}
        error={error}
        path={project ? `/${project.ref.id}/uploads` : "/orphaned/uploads"}
      />
      <Box display="flex" flexDirection="row" alignItems="center" mt={-3}>
        <Typography pl={1}>
          <b>Version: </b>
          {versionNumber}
        </Typography>
        {data &&
          !disabled &&
          (data.commited ? (
            <Tooltip title="New Files Revision" onClick={handleRevision}>
              <IconButton size="small">
                <Edit fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : versionCanCommit.current ? (
            <Tooltip title="Commit Files Revision" onClick={commitVersion}>
              <IconButton size="small">
                <Publish fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : (
            (data.uncommittedFiles ?? []).length > 0 && (
              <Tooltip title="Cancel Revision" onClick={cancelRevision}>
                <IconButton size="small">
                  <Cancel fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )
          ))}
      </Box>
    </Box>
  );
};

// this is the regular file field, which is just a ui file field
export const QueryFileField = ({
  label,
  bucket,
  onFieldUpdate,
  data,
  error,
  disabled,
}) => {
  const project = useContext(ProjectReferenceContext);

  const onChange = (event) => {
    onFieldUpdate({
      target: {
        value: {
          ...data,
          data: event,
        },
      },
    });
  };

  return (
    <FileField
      label={label}
      bucket={bucket}
      onChange={onChange}
      disabled={disabled}
      files={data?.data}
      path={project ? `/${project.ref.id}/uploads` : "/orphaned/uploads"}
      error={error}
    />
  );
};

export const QueryPhotoField = (props) => {
  // Basically a wrapper for PhotosField where we resolve path using the project context

  const project = useContext(ProjectReferenceContext);

  return (
    <PhotosField
      {...props}
      path={project ? `/${project.ref.id}/uploads` : "/orphaned/uploads"}
    />
  );
};

export const ParagraphField = styled.div`
  // color: ${(props) => props.theme.text};
  margin-bottom: 10px;
  white-space: pre-wrap;

  font-family: ${(props) => props.theme.font};
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;

  @media (max-width: 700px) {
    overflow-x: scroll;
  }
`;

const TimeTrackerContainer = styled.div`
  display: flex;
  padding-right: 5px;
  align-items: center;
`;

export const TableInputDiv = styled.table`
  width: 98%;
  table-layout: fixed;
  margin-right: 10px;
`;

export const TableHeader = styled.th`
  font-size: 12px;
  font-family: ${(props) => props.theme.font};
  text-align: left;
  font-weight: 500;
  box-sizing: border-box;
  padding: 6px;
  background: #e4e4e4;

  width: ${(props) => props.width ?? "unset"};
`;

const TableCellT = styled.td`
  font-size: 12px;
  font-family: ${(props) => props.theme.font};
  text-align: left;
  font-weight: 500;
  box-sizing: border-box;
  padding: 6px;
  background: #f1f1f1;

  & .label {
    display: none;
  }

  white-space: nowrap;
`;

const TableCellCont = styled.div`
  display: flex;
  white-space: pre-wrap;
  ${(props) => (props.center ? "justify-content: center;" : "")}

  & * {
    max-width: 100% !important;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
`;

const PrettyLink = styled.a`
  color: black;
`;

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const primaryColor = theme.palette.primary.main;
  const rgbaColor = (hexColor) => {
    const [r, g, b] = hexColor.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, 0.2)`;
  };

  return {
    background: rgbaColor(primaryColor),
    "&:hover": {
      background: rgbaColor(primaryColor),
    },
    width: "40px",
    height: "40px",
    marginLeft: "10px",
  };
});

export const MuiFormHelperText = styled.div`
  color: ${(props) => (props.error ? "#d32f2f" : "#ffffff")};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
`;
