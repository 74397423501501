import React from "react";
import { Svg, Path } from "@react-pdf/renderer";

export const SVGElementToPrint = ({ refId, ...props }) => {
  const svgRef = document.getElementById(refId);

  if (!svgRef || svgRef.tagName !== "svg") {
    return null;
  }

  return (
    <Svg
      {...props}
      width={svgRef.getAttribute("width")}
      height={svgRef.getAttribute("height")}
    >
      {Array.from(svgRef.children).map((child, i) => (
        <HTMLChildToPDF key={i} child={child} />
      ))}
    </Svg>
  );
};

// NOTE: This is incomplete because time is hard and QRCode only uses path lol
const HTMLChildToPDF = ({ child }) => {
  switch (child.tagName) {
    case "path":
      return (
        <Path
          d={child.getAttribute("d")}
          fill={child.getAttribute("fill")}
          transform={child.getAttribute("transform")}
        />
      );
    default:
      return null;
  }
};
