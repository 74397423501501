/**
 * Each schema describes a particular workflow (aka a particular form)
 * Each project has its own set of schemas which follow a common structure, but are independent of eachother.
 */

import { BaseCollection, BaseDocument, BaseData } from "../../base";
import { Project } from "..";
import { Revisions } from "./revisions";
import { FormSchemas } from "./forms";
import { SchemaUsers } from "./users";
import { AdhocOptions } from "./adhoc";

export interface SchemaData extends BaseData {
  // An array of all fields within the schema. See CREATING_PROJECTS.md for more details.
  fields: Field[];

  // Initial data in the form of an object where key is data field ID, value is data value (not to be confused with data name, which is only user shown)
  initialData?: { [key: string]: any };

  // Required if type="response", same structure a fields,initialData, but for the response.
  responseSchema?: {
    fields: Field[];
    initialData?: { [key: string]: any };
  };

  // Required if type="approvals", same as above.
  approvalSchema?: {
    fields: Field[];
    initialData?: { [key: string]: any };
  };

  // For now actions only have text based description and response. If you want customizability in the future, implement this like the other ones above (cmd-F will help :) )
  // actionSchema?: {
  //   fields: Field[];
  //   initialData?: { [key: string]: any };
  // };

  // Whether this query can be assigned  (has Assigned User field)
  assignable: boolean;

  // The basic type of workflow associated with this query.
  type: "response" | "approval";

  // The name of the query.
  name: string;

  /*
   * Needed if dynamic numbering is desired. Say you want dynamic with fields "pipeline" and "owner". You'd make this
   * dynamicIdSchema: "pipeline-owner" with any number of fields.
   * dynamicIdMap, just make sure you initialize it as {} to let the automated numbering system take over.
   */
  dynamicIdSchema: string;
  dynamicIdMap: { [key: string]: number };
}

/** The base field interface, in reality other fields add more properties. */
export interface Field {
  name: string;
  type: string;
}

export class Schemas extends BaseCollection<SchemaData> {
  schema(id: string) {
    return new Schema(id, this);
  }
}

export class Schema extends BaseDocument<SchemaData> {
  revisions: Revisions;
  forms: FormSchemas;
  users: SchemaUsers;
  adhoc: AdhocOptions;

  constructor(id: string, schemas: Schemas) {
    super(schemas.doc(id).ref);

    this.revisions = new Revisions(this.collection("revisions"));
    this.forms = new FormSchemas(this.collection("forms"));
    this.users = new SchemaUsers(this.collection("users"));
    this.adhoc = new AdhocOptions(this.collection("adhoc"));
  }
}
