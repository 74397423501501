import React, { useCallback, useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { materialTheme } from "../../themes/mui_theme";
import themes from "../../themes";

import { FloatingPage } from "../ui/containers";

import {
  useFormComposite,
  useFormDatasets,
  useFormSchemaset,
  useFormUser,
} from "../../hooks/forms";

import StatusBar from "./headers/statusbar";
import NavBar from "./headers/navbar";
import FormSubmission, { SUBMISSIONVIEWS } from "./submission";

import { CircularProgress } from "@mui/material";
import TextLogo from "../statics2/logos/logo-color.png";
import {
  LoadingBoundary,
  MainDiv,
  ProjectDiv,
  UserDataContext,
} from "../../App";
import { useParams, useSearchParams } from "react-router-dom";
import { log_warning } from "../../tools/logger";

// The path for forms is now of the format /form/PROJECTID/QUERYID/FORMID?token=USERPASSWORD&name=SUBMITTERNAME

const AUTH_PROCEED_STATES = ["ready", "blindready"];

export default () => {
  // URL Args
  const { projectId, queryId, formId } = useParams();
  // Now get the search params
  const [search] = useSearchParams();
  const token = search.get("token");

  const [authState, setAuthState] = useFormUser(
    projectId,
    queryId,
    formId,
    token
  );

  // This is a root page, so we'll need to inject providers here just like in base app
  return (
    <ThemeProvider theme={themes.purple}>
      <MuiThemeProvider theme={materialTheme(themes.purple)}>
        {!AUTH_PROCEED_STATES.includes(authState) && (
          <FloatingPage>
            {/* Auth resolution views */}
            {authState === "loading" && <UserLoginLoader />}
            {authState === "error" && <UserLoginFailed />}
          </FloatingPage>
        )}
        {/* The actual form view */}
        {AUTH_PROCEED_STATES.includes(authState) && (
          <FormView
            projectId={projectId}
            queryId={queryId}
            formId={formId}
            setAuthState={setAuthState} // So we can set perms failures when querying the form
          />
        )}
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

const FormView = ({ projectId, queryId, formId, setAuthState }) => {
  // Before anything, setup a callback for the no perms case
  const noPermsCallback = useCallback(
    () => setAuthState((ex) => (ex === "blindready" ? "blinderror" : "error")),
    [setAuthState]
  );

  // Now let's do a form lookup (we know we have some auth here)
  // 1. Get composite
  const [formComposite, formData] = useFormComposite(
    projectId,
    queryId,
    formId,
    noPermsCallback
  );
  // 2. Pull the datasets for reading (we'll allow each pane to pull its own for writing later)
  const formDatasets = useFormDatasets(formComposite);
  // 2.5. Pull schemaPaths from the formDatasets
  const [formSchemaPaths, setFormSchemaPaths] = useState(undefined);
  useEffect(() => {
    // Assign Schema Paths state
    setFormSchemaPaths(formDatasets?.map((d) => d.schemaPath));
  }, [formDatasets]);
  // 3. Pull the form schemas
  const formSchemas = useFormSchemaset(formSchemaPaths); // This is a dict :)

  // Add a useEffect to navigate the user to the completed screen
  // If the status changes to submitted (or we load a submitted form)
  useEffect(() => {
    if (formData?.status === "submitted") {
      // TODO: Implement this
      log_warning("SUBMITTED!!!");
    }
  }, [formData?.status]);

  // Manage navbar states
  const [navbarOptions, setNavbarOptions] = useState([]);

  useEffect(() => {
    if (formData && formSchemas) {
      const newNavOptions = [];
      // Resolve the relevant navbar options
      switch (formData.status) {
        case "pending":
          newNavOptions.push(...[SUBMISSIONVIEWS.ACKNOWLEDGEMENT]);
          break;
        case "acknowledged":
          newNavOptions.push(
            ...[
              SUBMISSIONVIEWS.SUBMISSION,
              SUBMISSIONVIEWS.PREVIEW,
              SUBMISSIONVIEWS.NEGOTIATOR,
              SUBMISSIONVIEWS.CLARIFICATION,
              SUBMISSIONVIEWS.SETTINGS,
            ]
          );
          break;
        case "submitted":
          newNavOptions.push(
            ...[SUBMISSIONVIEWS.COMPLETED, SUBMISSIONVIEWS.SETTINGS]
          );
          break;
        case "rejected":
          newNavOptions.push(...[SUBMISSIONVIEWS.REJECTION]);
          break;
      }
      setNavbarOptions(newNavOptions);
    } else {
      setNavbarOptions([]);
    }
  }, [formData, formSchemas]);

  if (!formData || !formSchemas) {
    return <LoadingBoundary />;
  } // Not ready yet

  // Now let's start rendering the external forms app frame (AppDiv is the parent div at this point)
  return (
    <MainDiv>
      <ProjectDiv>
        {/* TODO: Put form user name here without superfluous rerender */}
        <UserDataContext.Provider
          value={{
            id: "External Form User (Anon)",
            name: { first: "External", last: "Form User" },
          }}
        >
          <StatusBar formData={formData} />
          <NavBar options={navbarOptions} newClarifications={false} />
          <FormSubmission
            navOptions={navbarOptions}
            formData={formData}
            formComposite={formComposite}
            formDatasets={formDatasets}
            formSchemas={formSchemas}
          />
        </UserDataContext.Provider>
      </ProjectDiv>
    </MainDiv>
  );
};

const UserLoginLoader = () => {
  return (
    <FloatingMiniPage>
      <LogoContainer src={TextLogo} />
      Your identity is being verified, please wait...
      <CircularProgress style={{ marginTop: "20px" }} />
    </FloatingMiniPage>
  );
};

const UserLoginFailed = () => {
  return (
    <FloatingMiniPage>
      <LogoContainer src={TextLogo} />
      Your identity could not be verified, please contact the form&apos;s
      originator to resolve this issue.
    </FloatingMiniPage>
  );
};

const FloatingMiniPage = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;

  align-items: center;
  text-align: center;

  background-color: white;
  border-radius: 4px;

  overflow-y: auto;
  overflow-x: hidden;
`;

const LogoContainer = styled.img`
  width: 80%;
  margin-bottom: 20px;
`;

export const ButtonSection = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
