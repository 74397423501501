import React, { useEffect, useState, useContext } from "react";
import { Typography, FormLabel, IconButton, Tooltip } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import { UserContext } from "../../../../../App";
import {
  useSchema,
  useSchemaCustomizations,
} from "../../../../../hooks/projects";
import { QueryContent, QuerySection, QuerySectionHeader } from "../../query";
import { GenericField, Inputs, LocalityField } from "../../../../ui/inputs2";
import { SaveQueryBar } from "../../workflow";
import { InputPartition } from "../defaults/defaults";

import { parse_on_fields } from "../../../../../tools/forms";
import { funcGenerator, isConstraintValid, VALIDATIONS_BY_TYPE } from "./tools";

export default ({ project, schemaId, setStatusMessage }) => {
  const [schema, schemaData] = useSchema(schemaId, project);
  const [schemaValidations, setSchemaValidations] = useState(undefined);
  const [joinedFields, setJoinedFields] = useState(undefined);

  const user = useContext(UserContext);
  const userCustomSchema = useSchemaCustomizations(
    project,
    schema,
    user.ref.id
  );

  useEffect(() => {
    let joined = [];
    if (schemaData?.fields) {
      joined = joined.concat(schemaData.fields);
    }
    if (
      schemaData?.settings?.enablePersonalFields &&
      userCustomSchema?.customFields
    ) {
      joined = joined.concat(userCustomSchema.customFields);
    }
    setJoinedFields(joined);
  }, [schemaData, userCustomSchema]);

  useEffect(() => {
    if (joinedFields) {
      // We'll need to map all validations into "schemaValidations" object
      const validations = {};
      parse_on_fields(joinedFields, (fld) => {
        if (fld.required?.validations) {
          validations[fld.id] = fld.required.validations;
        }

        // move legacy characterLimit attribute into validations to streamline process
        // characterLimit will be deleted on save
        if (
          ["number", "string", "textarea"].includes(fld.type) &&
          fld.characterLimit != null
        ) {
          validations[fld.id] = {
            ...(fld.required?.validations ?? {}),
            character_range: {
              ...(fld.required?.validations?.character_range ?? {}),
              constraint: {
                ...(fld.required?.validations?.character_range?.constraint ??
                  {}),
                max: fld.characterLimit,
              },
            },
          };
        }
      });
      setSchemaValidations(validations);
    }
  }, [joinedFields]);

  const onFieldChange = (callback, fieldId, validationId) => {
    setSchemaValidations((ex) => ({
      ...ex,
      [fieldId]: {
        ...(ex[fieldId] ?? {}),
        [validationId]: callback(ex[fieldId]?.[validationId]),
      },
    }));
  };

  const saveChanges = () => {
    const addValidationToField = (field) => {
      const validations = cleanValidations[field.id];
      if (validations) {
        const finalValidations = Object.keys(validations).reduce((acc, key) => {
          const errorMessage = VALIDATIONS_BY_TYPE[field.type]?.[key]?.message;
          return {
            ...acc,
            [key]: {
              ...validations[key],
              func: funcGenerator(key, validations[key].constraint, field.type),
              ...(errorMessage && { message: errorMessage }),
            },
          };
        }, {});

        // delete legacy characterLimit field (it should already be in validations)
        if (
          ["number", "string", "textarea"].includes(field.type) &&
          field.characterLimit != null
        ) {
          delete field.characterLimit;
        }

        return {
          ...field,
          required: {
            ...(field?.required ?? {}),
            validations: finalValidations,
          },
        };
      } else {
        // No default allowed
        if (field?.required?.validations) {
          delete field?.required?.validations;
        }
        return field;
      }
    };

    const generateUpdatedFields = (fields) => {
      // PLEASE REFER TO DEFAULTS SAVING SINCE I EXTRACTED ADDVALIDATIONTOFIELD
      // SOME OF THE ATTRIBUTE DELETIONS USE A DIFFERENT VARIABLE INSTEAD OF THE ONE I AM PASSING IN
      return fields?.map((fld) => {
        let fldFinal = { ...fld };
        if (fld.section) {
          // We need to write through the section
          fldFinal.section = fld.section.map((secFld) => {
            let secFldFinal = { ...secFld };
            if (secFld.nested) {
              secFldFinal.nested = secFld.nested.map((nestedFld) => {
                // This is a regular field and we can update the default
                return addValidationToField(nestedFld);
              });
              return secFldFinal;
            } else {
              // This is a regular field and we can update
              return addValidationToField(secFldFinal);
            }
          });
          return fldFinal;
        } else if (fld.nested) {
          // Then we'll write through the nested values
          fldFinal.nested = fldFinal.nested.map((nestedFld) => {
            // This is a regular field and we can update the default
            return addValidationToField(nestedFld);
          });
          return fldFinal;
        } else {
          // This is a regular field and we can update the default
          return addValidationToField(fld);
        }
      });
    };

    // Now we'll need to go the opposite way across the schema fields,
    // reconstructing them and adding functions
    let cleanValidations = Object.keys(schemaValidations).reduce((acc, key) => {
      const validation = schemaValidations[key];
      let cleanValidation = Object.keys(validation).reduce(
        (innerAcc, innerKey) => {
          // remove if constraint doesn't exist, is undefined, is null, is empty array or object
          const constraint = validation[innerKey]?.constraint;
          if (
            !constraint ||
            (Array.isArray(constraint) && constraint.length === 0) ||
            (typeof constraint === "object" &&
              Object.keys(constraint).length === 0) ||
            !isConstraintValid(innerKey, constraint)
          ) {
            return innerAcc;
          }

          // NOTE: generating the validation function occurs during field traversal
          return { ...innerAcc, [innerKey]: validation[innerKey] };
        },
        {}
      );

      // remove if clean validation is empty {}
      if (Object.keys(cleanValidation).length === 0) {
        return acc;
      }
      return { ...acc, [key]: cleanValidation };
    }, {});

    // We'll use this object to update the validations on every field obj
    const updatedFields = generateUpdatedFields(schemaData.fields);
    schema.update({ fields: updatedFields });
    if (
      schemaData?.settings?.enablePersonalFields &&
      userCustomSchema?.customFields
    ) {
      schema.users.user(user.ref.id).set(
        {
          customFields: generateUpdatedFields(userCustomSchema.customFields),
        },
        { merge: true }
      );
    }
    setStatusMessage("Changes Saved!");
  };

  // The view for the fields is the same as query defaults
  const fields = [];
  const sections = [];

  if (!joinedFields) {
    return null;
  }

  // Now build the view
  for (let field of joinedFields) {
    // In our schema, a nested array means they should appear side by side.
    // No section heading means to be included in the "Fields" header
    if (field.section) {
      // Skip form sections
      if (field.formId) {
        continue;
      }
      // Otherwise go to a regular section
      sections.push(
        <QuerySection
          key={`sec-${field.name.toLowerCase().replaceAll(" ", "-")}`}
        >
          <QuerySectionHeader>{field.name}</QuerySectionHeader>
          {field.section.map((fld) => {
            return (
              <Inputs
                key={`inp-wrapper-${
                  fld.nested ? `nst-${fld.nested[0].id}` : fld.id
                }`}
                style={{ flexWrap: "wrap", alignItems: "flex-start" }}
              >
                {(fld.nested ? fld.nested : [fld]).map((_field) => (
                  <ResolveFieldValidation
                    key={`query-field-${_field.id}`}
                    field={_field}
                    schemaValidations={schemaValidations}
                    onFieldChange={onFieldChange}
                  />
                ))}
              </Inputs>
            );
          })}
        </QuerySection>
      );
    } else {
      fields.push(
        <Inputs
          key={`inp-wrapper-${
            field.nested ? `nst-${field.nested[0].id}` : field.id
          }`}
          style={{ flexWrap: "wrap", alignItems: "flex-start" }}
        >
          {(field.nested ? field.nested : [field]).map((_field) => (
            <ResolveFieldValidation
              key={`query-field-${_field.id}`}
              field={_field}
              schemaValidations={schemaValidations}
              onFieldChange={onFieldChange}
            />
          ))}
        </Inputs>
      );
    }
  }
  if (fields.length > 0) {
    sections.unshift(
      <QuerySection key="sec-fields-general">
        <QuerySectionHeader>Fields</QuerySectionHeader>
        {fields}
      </QuerySection>
    );
  }
  return (
    <>
      <QueryContent>{sections}</QueryContent>
      <SaveQueryBar saveQuery={saveChanges} />
    </>
  );
};

const ResolveFieldValidation = ({
  field,
  schemaValidations,
  onFieldChange,
}) => {
  let readyComponent;

  const updateTextField = (value, validationId) => {
    onFieldChange(
      (validation) => {
        return { ...(validation || {}), constraint: value };
      },
      field.id,
      validationId
    );
  };

  const updateMinMaxConstraints = (value, type, validationId) => {
    onFieldChange(
      (validation) => {
        return {
          ...(validation || {}),
          constraint: {
            ...(validation?.constraint || {}),
            [type]: value == 0 ? undefined : value,
          },
        };
      },
      field.id,
      validationId
    );
  };

  const updateDateTimeRanges = (callback, validationId) => {
    onFieldChange(
      (validation) => {
        return {
          ...(validation || {}),
          constraint: callback(validation),
        };
      },
      field.id,
      validationId
    );
  };

  // Can possibly refactor using VALIDATIONS_BY_TYPE (add more attributes such as label, instructions, etc.)
  switch (field.type) {
    case "number":
      readyComponent = (
        <>
          <InputPartition>
            <Typography>
              {"Allowed operators: <, <=, >, >=, OR, AND"}
            </Typography>
          </InputPartition>
          <GenericField
            label="Number Range"
            onChange={(e) => updateTextField(e.target.value, "number_range")}
            data={schemaValidations[field.id]?.number_range?.constraint}
          />
        </>
      );
      break;
    case "string":
    case "textarea":
      readyComponent = (
        <div style={{ display: "flex" }}>
          <GenericField
            label="Minimum Length"
            onChange={(e) =>
              updateMinMaxConstraints(e.target.value, "min", "character_range")
            }
            data={schemaValidations[field.id]?.character_range?.constraint?.min}
            maxWidth="160px"
            number
          />
          <GenericField
            label="Maximum Length"
            onChange={(e) =>
              updateMinMaxConstraints(e.target.value, "max", "character_range")
            }
            data={schemaValidations[field.id]?.character_range?.constraint?.max}
            maxWidth="160px"
            number
          />
        </div>
      );
      break;
    case "date":
      readyComponent = (
        <>
          <DateTimeRangeBuilder
            validation={schemaValidations[field.id]?.date_range}
            updateRanges={updateDateTimeRanges}
            type="date"
            validationId="date_range"
          />
          <div style={{ display: "flex" }}>
            <GenericField
              label="Minimum days in future"
              onChange={(e) =>
                updateMinMaxConstraints(e.target.value, "min", "date_limit")
              }
              data={schemaValidations[field.id]?.date_limit?.constraint?.min}
              maxWidth="160px"
              number
            />
            <GenericField
              label="Maximum days in future"
              onChange={(e) =>
                updateMinMaxConstraints(e.target.value, "max", "date_limit")
              }
              data={schemaValidations[field.id]?.date_limit?.constraint?.max}
              maxWidth="160px"
              number
            />
          </div>
        </>
      );
      break;
    case "time":
      readyComponent = (
        <DateTimeRangeBuilder
          validation={schemaValidations[field.id]?.time_range}
          updateRanges={updateDateTimeRanges}
          type="time"
          validationId="time_range"
        />
      );
      break;
    case "multiselect":
    case "boxset":
      readyComponent = (
        <div style={{ display: "flex" }}>
          <GenericField
            label="Minimum Selections"
            onChange={(e) =>
              updateMinMaxConstraints(e.target.value, "min", "ms_count_range")
            }
            data={schemaValidations[field.id]?.ms_count_range?.constraint?.min}
            maxWidth="160px"
            number
          />
          <GenericField
            label="Maximum Selections"
            onChange={(e) =>
              updateMinMaxConstraints(e.target.value, "max", "ms_count_range")
            }
            data={schemaValidations[field.id]?.ms_count_range?.constraint?.max}
            maxWidth="160px"
            number
          />
        </div>
      );
      break;
    case "table":
    case "tableadv":
      readyComponent = (
        <div style={{ display: "flex" }}>
          <GenericField
            label="Minimum Entries"
            onChange={(e) =>
              updateMinMaxConstraints(e.target.value, "min", "tbl_entry_range")
            }
            data={schemaValidations[field.id]?.tbl_entry_range?.constraint?.min}
            maxWidth="160px"
            number
          />
          <GenericField
            label="Maximum Entries"
            onChange={(e) =>
              updateMinMaxConstraints(e.target.value, "max", "tbl_entry_range")
            }
            data={schemaValidations[field.id]?.tbl_entry_range?.constraint?.max}
            maxWidth="160px"
            number
          />
        </div>
      );
      break;
    case "files":
    case "photos":
      // maybe photos needs some sort of special thing cause it has limited types
      readyComponent = (
        <>
          <InputPartition>
            <Typography>
              {
                "Separate allowed extensions with commas (ex. jpg, pdf). Do not include periods."
              }
            </Typography>
          </InputPartition>
          <GenericField
            label="File Extension"
            onChange={(e) =>
              updateTextField(e.target.value, "allowed_extensions")
            }
            data={schemaValidations[field.id]?.allowed_extensions?.constraint}
          />
        </>
      );
      break;
    case "userlist":
      readyComponent = field.multi ? (
        <div style={{ display: "flex" }}>
          <GenericField
            label="Minimum Selections"
            onChange={(e) =>
              updateMinMaxConstraints(
                e.target.value,
                "min",
                "userlist_count_range"
              )
            }
            data={
              schemaValidations[field.id]?.userlist_count_range?.constraint?.min
            }
            maxWidth="160px"
            number
          />
          <GenericField
            label="Maximum Selections"
            onChange={(e) =>
              updateMinMaxConstraints(
                e.target.value,
                "max",
                "userlist_count_range"
              )
            }
            data={
              schemaValidations[field.id]?.userlist_count_range?.constraint?.max
            }
            maxWidth="160px"
            number
          />
        </div>
      ) : (
        <InputPartition>
          <Typography>
            No validation available for singular (non-multi) userselect lists.
          </Typography>
        </InputPartition>
      );
      break;
    case "hidden":
      return null;
    default:
      readyComponent = (
        <InputPartition>
          <Typography>No validation available for this field type.</Typography>
        </InputPartition>
      );
  }

  return (
    <InputPartition>
      <FormLabel style={{ marginBottom: 4 }}>{field.name}</FormLabel>
      {readyComponent}
    </InputPartition>
  );
};

const DateTimeRangeBuilder = ({
  validation,
  type,
  updateRanges,
  validationId,
}) => {
  // range is an array of {start, end} date/time ranges where each range is OR'd
  const onChange = (startOrEnd, value, rangeIndex) => {
    updateRanges((ex) => {
      return [
        ...(ex?.constraint || []).slice(0, rangeIndex),
        { ...(ex?.constraint?.[rangeIndex] || {}), [startOrEnd]: value },
        ...(ex?.constraint || []).slice(rangeIndex + 1),
      ];
    }, validationId);
  };

  const addRange = () => {
    updateRanges((ex) => {
      return [...(ex?.constraint || []), {}];
    }, validationId);
  };

  const deleteRange = (index) => {
    updateRanges((ex) => {
      return [
        ...(ex?.constraint || []).slice(0, index),
        ...(ex?.constraint || []).slice(index + 1),
      ];
    }, validationId);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <InputPartition>
          <Typography>{`${
            type.charAt(0).toUpperCase() + type.slice(1)
          } ranges chosen are inclusive. Multiple ranges are ORed together.`}</Typography>
        </InputPartition>
        <Tooltip title="Add range">
          <IconButton size="small" color="primary" onClick={() => addRange()}>
            <Add />
          </IconButton>
        </Tooltip>
      </div>
      {Array.isArray(validation?.constraint) &&
        validation.constraint.map((range, i) => (
          <div
            key={`ranges-${i}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <LocalityField
              label="Range Start"
              data={range.start}
              useDate={type == "date"}
              useTime={type == "time"}
              onChange={(value) => onChange("start", value, i)}
            />
            <LocalityField
              label="Range End"
              data={range.end}
              useDate={type == "date"}
              useTime={type == "time"}
              onChange={(value) => onChange("end", value, i)}
            />
            <Tooltip title="Delete range">
              <IconButton
                size="small"
                color="primary"
                onClick={() => deleteRange(i)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        ))}
    </>
  );
};
