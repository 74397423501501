/**
 * API
 * -------------------------
 * This file is designed to expose to the frontend any additional functions
 * that would come from firebase or be otherwise triggered using the resources in the API
 * 
 * Specifically, these functions are not exact database requests from collection objects,
 * but most other things
 * 
 */

import { 
  Auth, GoogleAuthProvider, OAuthProvider, onAuthStateChanged, 
  Persistence, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPhoneNumber,
  signInAnonymously,
  signInWithPopup, signOut, updatePassword, RecaptchaVerifier, linkWithPhoneNumber, getAdditionalUserInfo
} from "firebase/auth";

// Extend window's global namespace with firebase addins
declare global {
  interface Window { recaptchaVerifier: RecaptchaVerifier; }
}


export class APIAuthentication {
  /**
   * This class acts as a bridge between the firebase authentication api and our application
   */

  private auth: Auth;
  private worker: Auth;

  private static QUERYIT_MOBILESIGNUP_EMAIL = 'mobileonly';

  // Providers
  private googleProvider: GoogleAuthProvider;
  private microsoftProvider: OAuthProvider;

  constructor(auth: Auth, worker: Auth) {
    this.auth = auth;
    this.worker = worker;

    // Init providers
    this.googleProvider = new GoogleAuthProvider();
    this.microsoftProvider = new OAuthProvider('microsoft.com');
  }

  // Recaptcha preparation
  useRecaptchaComponent(elementId: string, callback: (token: string) => void = () => {}, expiredCallback: () => void = () => {}) {
    window.recaptchaVerifier = new RecaptchaVerifier(elementId, {
      callback: callback,
      expiredCallback: expiredCallback
    }, this.auth);
    window.recaptchaVerifier.render();
  }

  // Sign in
  signInWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email.trim().toLowerCase(), password);
  }

  signInWithPhoneNumber(phoneNumber: string) {
    return signInWithPhoneNumber(this.auth, phoneNumber.trim(), window.recaptchaVerifier);
  }

  signInWithGoogle() {
    return signInWithPopup(this.auth, this.googleProvider);
  }

  signInWithMicrosoft() {
    return signInWithPopup(this.auth, this.microsoftProvider);
  }

  signInAnonymously() {
    return signInAnonymously(this.auth);
  }

  sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this.auth, email.trim());
  }

  updatePassword(password: string) {
    if (this.auth.currentUser) {
      return updatePassword(this.auth.currentUser, password);
    } else { return Promise.reject('No user logged in'); }
  }

  linkWithPhoneNumber(phoneNumber: string) {
    // NOTE: For this function to work, captcha is required
    // NOTE 2: This function returns the confirmation promise the same way signInWithPhoneNumber does
    if (!this.auth.currentUser) { return; }
    return linkWithPhoneNumber(this.auth.currentUser, phoneNumber.trim(), window.recaptchaVerifier);
  }

  // Sign out
  signOut() {
    return signOut(this.auth);
  }

  // Supporting Functions
  onAuthStateChanged(callback: (user: any) => void) {
    onAuthStateChanged(this.auth, callback);
  }

  checkResultIsFirstTimeLogin(result: any) {
    return getAdditionalUserInfo(result)?.isNewUser;
  }

  setPersistence(persistence: Persistence) {
    return this.auth.setPersistence(persistence);
  }

  // getters
  getAuth() {
    return this.auth;
  }

  getWorker() {
    return this.worker;
  }

  // Props
  static getMobileOnlyEmail(emailCode: string) {
    return `${APIAuthentication.QUERYIT_MOBILESIGNUP_EMAIL}+${emailCode}@queryitapp.com`;
  }
}