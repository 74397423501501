/**
 * A place to perform all firebase configuration. Necessary firebase objects are exported from here, again abstracting away this part of the app.
 */

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  // enableIndexedDbPersistence,
  collection,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
} from "firebase/auth";

import { BaseDatabase, BaseCollection } from "./base";
import { APIStorage } from "./storage";
import { APIAuthentication } from "./authentication";

const firebaseConfigDev = {
  apiKey: "AIzaSyCeU7ooVgE889DP7OYJRkeTRgHHsO-FGPI",
  authDomain: "queryit-30aea.firebaseapp.com",
  databaseURL: "https://queryit-30aea.firebaseio.com",
  projectId: "queryit-30aea",
  storageBucket: "queryit-30aea.appspot.com",
  messagingSenderId: "96756775905",
  appId: "1:96756775905:web:efa5eb883da7a90bb270a0",
};

const firebaseConfigStaging = {
  apiKey: "AIzaSyDVu7PPLiyB0HhKkEb0kzZwe8DGdjOwhS4",
  authDomain: "queryit-staging.firebaseapp.com",
  projectId: "queryit-staging",
  storageBucket: "queryit-staging.appspot.com",
  messagingSenderId: "692646153322",
  appId: "1:692646153322:web:53e4f61b598be0dee24e13",
  measurementId: "G-17XBPGXCCD",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyBsJg6cvhyPLF90wbBbn_fMUF4XIlPibdo",
  authDomain: "queryit-production.firebaseapp.com",
  projectId: "queryit-production",
  storageBucket: "queryit-production.appspot.com",
  messagingSenderId: "715948451578",
  appId: "1:715948451578:web:76c02fc966b9d762314b43",
  measurementId: "G-S0RD34RW4T",
};

const app = initializeApp(
  process.env.DEPLOYPROD && process.env.DEPLOYPROD === "staging"
    ? firebaseConfigStaging
    : process.env.NODE_ENV && process.env.NODE_ENV.trim() === "production"
    ? firebaseConfigProd
    : firebaseConfigDev
);

// Define a separate worker
const worker = initializeApp(app.options, "worker");

// Then define the initial firebase objects
const firebaseDb = getFirestore(app);
const firebaseAuth = getAuth(app);

// Now support emulation
if (process.env.DEPLOYPROD === "emulate") {
  console.log("---------------------Enabling Emulation---------------------");
  connectFirestoreEmulator(firebaseDb, "localhost", 8080);
  // connectAuthEmulator(firebaseAuth, "http://localhost:9099");
}

// Now build the exports
export const auth = new APIAuthentication(firebaseAuth, getAuth(worker));
export const db = new BaseDatabase(firebaseDb);
export const storage = new APIStorage(app);
export const projectStorage = (key: string) =>
  new APIStorage(
    app,
    `gs://queryit-${
      process.env.DEPLOYPROD && process.env.DEPLOYPROD === "staging"
        ? "staging"
        : process.env.NODE_ENV && process.env.NODE_ENV.trim() === "production"
        ? "production"
        : "30aea"
    }-` + key
  );

// Remove worker persistence
auth.getWorker().setPersistence(inMemoryPersistence);

// Define persistence options
export const PERSISTENCE = {
  local: browserLocalPersistence,
  session: browserSessionPersistence,
};

// We'll also need to enable persistences
// enableIndexedDbPersistence(db.ref).catch((err) => {
//   if (err.code === "failed-precondition") {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     console.log(
//       "Persistence is already enabled in another tab. Disabling here."
//     );
//   } else if (err.code === "unimplemented") {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     console.log(
//       "Your browser does not support firebase persistence! You may experience longer loads"
//     );
//   }
// });

setPersistence(auth.getAuth(), PERSISTENCE.local)
  .then(() => {
    // May need to return a sign in option?
  })
  .catch((err) => {
    // An error occurred, but we don't care
  });
