import { BaseCollection, BaseDocument, BaseData } from "../base";

interface CompanyData extends BaseData {
  name: string
}

export class Companies extends BaseCollection<CompanyData> {

  company(id: string) {
    return this.doc(id) as Company;
  }
}

type Company = BaseDocument<CompanyData>;