import { BaseCollection, BaseDocument, BaseData } from "../../base";
import { Project } from "../index";

/** Data for a form schema */
interface FormSchemaData extends BaseData {
  name: string;
  // Other fields should go here
}

export class FormSchemas extends BaseCollection<FormSchemaData> {
  form(id: string) {
    return this.doc(id) as FormSchema;
  }
}

export type FormSchema = BaseDocument<FormSchemaData>;