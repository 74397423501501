import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";

import { Notifications, AddBox } from "@mui/icons-material";

import { UserDataContext } from "../../../App";
import { useResponse, useTasks } from "../../../hooks/queries";
import { useForms } from "../../../hooks/forms";
import { useApprovals } from "../../../hooks/actions";
import { Button2 as Button } from "../../ui/buttons";
import { QueryStatusContext } from "./query";
import { TimerContext } from "../../../App";

import { useSearchParams } from "react-router-dom";

import {
  QUERYVIEWS,
  ROUTEKEYS,
  SUBQUERYVIEWS,
  TEMPLATE_SCHEMA_STATUSSETS,
} from "../../../common/query";

// Workflow prompter (bottom button bar)
export default ({
  query,
  createQuery,
  queryData,
  schemaData,
  setConfirmationModal,
  setStatusMessage,
  beginClose,
  viewState,
  setShowErrors,
  queryValidationErrors,
  saveQuery,

  isNew,
}) => {
  // query will be defined on existing queries, and createQuery will be defined for new queries
  // We use boolean "isNew" to determine "new" status as its not a recorded status
  // setConfirmationModal is an inherited property because this is a child of the Query component

  const userData = useContext(UserDataContext);
  const queryStatus = useContext(QueryStatusContext);
  const forms = useForms(query);
  const { timer, setTimer } = useContext(TimerContext);

  // Also pull in all the actions
  const approvals = useApprovals(query);
  const tasks = useTasks(query);
  const [response, setResponse] = useResponse(query);

  // Now grap search state
  const [searchParams, setSearchParams] = useSearchParams();

  // And set render requirement values
  const [activeRequirement, setActiveRequirement] = useState(undefined);
  const [submitRequirements, setSubmitRequirements] = useState(undefined);
  const [takeMeThere, setTakeMeThere] = useState(undefined);
  const [selfRemind, setSelfRemind] = useState(true); // Prevent remind from rendering for self

  // Extract route info for re-rendering take me there if we're already there :)
  const queryRoute = searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW);
  const actionsRoute = searchParams.get(ROUTEKEYS.QUERY_PANE_SUB_VIEW);

  // We'll also use a state to determine if the user has already attempted to submit
  // This allows us to hold off on some of our workflow checks until after the user tries to submit
  // Which avoids random errors from being thrown at the user before they even try to submit
  const [submitAttempted, setSubmitAttempted] = useState(false);

  // When submit is attempted trigger the showing of errors if enabled
  useEffect(() => {
    if (submitAttempted && setShowErrors) {
      setShowErrors(true);
    }
  }, [submitAttempted, setShowErrors]);

  // Then define the "update query" function, which is run for submissions
  const updateQuery = async (newStatus, closeQuery = true) => {
    // First save the query
    const result = await saveQuery();

    if (result === false) {
      // Then we had an error saving the query, don't advance
      return;
    }

    // Then update status
    let addins = {};
    if (newStatus === "closed") {
      addins = { closeTime: new Date() };
    }
    // Update the status & autosave the query
    query.update({ status: newStatus, ...addins });

    setStatusMessage("Query Updated");

    // Then close if we're going to do that
    if (closeQuery) {
      beginClose();
    }
  };

  // add a "generateLinkedQuery" flag to the Query Data of the approved query (with the schemaId as the attribute)
  const addGenerateLinkedQueryFlag = () => {
    query.update({
      generateLinkedQuery: queryData.schemaId,
      linkedQueryCreator: userData.id,
    });
  };

  const addNotifyUserFlag = () => {
    query.update({ notifyUser: true });
  };

  useEffect(() => {
    // Obviously if our data isn't here, quit
    if (!schemaData || !queryData) {
      setActiveRequirement(undefined);
      return;
    }

    const requirementSet = queryStatus?.get_requirements_map();

    // check for linked query setting
    if (schemaData.settings?.generateLinkedQuery) {
      // check for correct status
      if (
        (schemaData.type !== "custom" &&
          ["approved", "accepted", "closed"].includes(queryData.status)) ||
        (schemaData.type === "custom" &&
          ["closed", "vended"].includes(queryData.status))
      ) {
        let requirement = { linkedQueryPrompt: true };
        setActiveRequirement(requirement);
        return;
      }
    }

    // This is a list of requirements to advance
    const statusRequirements = isNew
      ? requirementSet?.["new"]
      : requirementSet?.[queryData.status];

    // If there are no requirements, then we're done
    if (!statusRequirements) {
      setActiveRequirement(undefined);
      return;
    }

    // Before checking requirements, prepend the validation requirement (it's always run first)
    const checkedRequirements = [
      ...(submitAttempted
        ? [
            {
              type: "check",
              check: (data, actions) =>
                !Object.values(queryValidationErrors).some((q) => q !== false),
              guideText: "Some Query data is currently invalid",
            },
          ]
        : []),
      ...statusRequirements,
    ];

    // Otherwise it's time to select the requirement that is active
    let submitButtons;
    let nextRequirement = checkedRequirements.find((requirement) => {
      if (requirement.type === "submit") {
        // Then this is the current requirement

        // Search for subsequent submit requirements as well for button grouping!
        submitButtons = checkedRequirements.filter(
          (req) => req.type === "submit"
        );

        return true;
      } else if (requirement.type === "check") {
        // Then we need to check if the requirement is met
        // If the requirement check returns false, the find should return true!
        // Check is also allowed to query existing actions
        return !requirement.check(queryData, {
          response: response,
          tasks: [],
          approvals: [],
        });
      } else {
        // We don't support this requirement on the front-end which means it is not passed!
        return true;
      }
    });

    // Check that we found one (we should always)
    if (!nextRequirement) {
      setActiveRequirement(undefined);
      return;
    }

    // Set the active requirement
    setActiveRequirement(nextRequirement);
    // And set the submit requirements
    setSubmitRequirements(submitButtons);
  }, [
    queryData,
    schemaData,
    isNew,
    queryValidationErrors,
    queryStatus,
    submitAttempted,
    response,
  ]);

  useEffect(() => {
    // DEFECT: This needs to be applied dynamically to custom schemas (maybe through an activeRequirement takemethere flag?)
    // See implementation for details on that <3
    if (schemaData?.type === "custom") {
      return;
    }
    // Whether or not we want to show the "Take me There" button. There are a few cases
    if (activeRequirement?.type === "approve") {
      // Approvals case
      let approvalUsers = approvals
        ?.filter((item) => item.status === "assigned")
        ?.map((item) => item.assignedTo);
      if (approvalUsers?.includes(userData.id)) {
        setTakeMeThere({ qr: QUERYVIEWS.ACTIONS, ar: SUBQUERYVIEWS.APPROVAL });
        setSelfRemind(approvalUsers.length === 1);
      } else {
        setTakeMeThere(undefined);
        setSelfRemind(false);
      }
    } else if (
      schemaData?.type === "response" &&
      queryData?.status === "open" &&
      activeRequirement?.type === "check"
    ) {
      // Response case
      if (response?.assignedTo === userData.id) {
        setTakeMeThere({ qr: QUERYVIEWS.ACTIONS, ar: SUBQUERYVIEWS.RESPONSE });
        setSelfRemind(true);
      } else {
        setTakeMeThere(undefined);
        setSelfRemind(false);
      }
    } else if (activeRequirement?.type === "trigger") {
      // Tasks blocking case
      const taskUsers = tasks?.map((item) =>
        item.status === "open" ? item.assignedTo : undefined
      );
      setTakeMeThere(
        taskUsers?.includes(userData.id)
          ? { qr: QUERYVIEWS.ACTIONS, ar: SUBQUERYVIEWS.TASK }
          : undefined
      );
    }
    // NOTE: The Form data missing case is handled in the same section that sets the error
  }, [queryData, schemaData, activeRequirement, approvals, tasks, response]);

  if (activeRequirement === undefined) {
    return null;
  }

  // NOTE: The Workflow manager should not render when printing (this is passed down from the query wrapper)
  //       It also should not render for tasks
  if (
    searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW) === QUERYVIEWS.PRINT ||
    (viewState === QUERYVIEWS.ACTIONS &&
      searchParams.get(ROUTEKEYS.QUERY_PANE_SUB_VIEW) === "task")
  ) {
    return null;
  }

  // Kind of a quirk of the workflow manager is that we can see viewstate
  // Which is useful to add an avoidance list
  // Because some viewstates don't need to be able to advance the Query
  if (
    [
      QUERYVIEWS.ADMIN_DEFAULTS,
      QUERYVIEWS.ADMIN_FIELDS,
      QUERYVIEWS.FIELDS,
      QUERYVIEWS.DEFAULTS,
      QUERYVIEWS.ADMIN_VALIDATION_CONTROLS,
      QUERYVIEWS.ADMIN_DYNAMIC_ID,
    ].includes(viewState)
  ) {
    return null;
  }

  // Some subviews also shouldn't display the workflow bar to advance the Query
  if (
    [SUBQUERYVIEWS.QUERYLINK].includes(
      searchParams.get(ROUTEKEYS.QUERY_PANE_SUB_VIEW)
    )
  ) {
    return null;
  }

  const userIsPrivileged =
    activeRequirement?.privilege?.(queryData, userData, schemaData) ?? true;

  return (
    <QueryWorkflowBar>
      <QueryWorkflowText>
        {userIsPrivileged
          ? activeRequirement.guideText
          : activeRequirement.noPrivilegeText}
      </QueryWorkflowText>
      <QueryWorkflowButtons>
        {activeRequirement.remindUser && !selfRemind && (
          <Button
            label="Remind User"
            startIcon={<Notifications />}
            onClick={() => {
              setStatusMessage("User Notified");
              addNotifyUserFlag();
            }}
          />
        )}
        {activeRequirement.linkedQueryPrompt && (
          <Button
            label="Generate Query"
            startIcon={<AddBox />}
            onClick={() => {
              setStatusMessage("Linked Query Generated");
              addGenerateLinkedQueryFlag();
            }}
          />
        )}
        {activeRequirement.type === "submit" &&
          userIsPrivileged &&
          submitRequirements.map((req, i) => (
            <Button
              key={i}
              label={req.buttonText}
              style={{ marginLeft: 10 }}
              onClick={() => {
                // First and foremost, they open the floodgate to submission validation here
                setSubmitAttempted(true);
                // Before we do any actual submission, check query validation
                if (
                  Object.values(queryValidationErrors).some((q) => q !== false)
                ) {
                  setStatusMessage(
                    `${req.buttonText} failed because some Query fields are invalid`
                  );
                  // Also want to trigger the takeMeThere button here.
                  setTakeMeThere({ qr: QUERYVIEWS.QUERY });
                  return;
                } // If it fails, cancel, and the workflow is updated
                // First define the function to run here
                const submitFunction = isNew
                  ? () => createQuery()
                  : () => updateQuery(req.moveTo, req.closeOnSubmit ?? true);
                // Now check if we need to use a confirmation modal
                if (req.confirmation) {
                  setConfirmationModal({
                    open: true,
                    title: req.confirmation.confirmationTitle,
                    message: req.confirmation.confirmationText,
                    confirm: () => {
                      // Add status change event to all forms
                      submitFunction();
                      // forms?.map((formData) => {
                      //   const form = query.forms.form(formData.id);
                      //   form.messages.add({
                      //     type: "formStatusChange",
                      //     message: `Form status changed to ${req.moveTo}`,
                      //     sentTime: new Date(),
                      //     viewed: false,
                      //   });
                      // });
                    },
                  });
                } else {
                  submitFunction();
                  // forms?.map((formData) => {
                  //   const form = query.forms.form(formData.id);
                  //   form.messages.add({
                  //     type: "formStatusChange",
                  //     message: `Form status changed to ${req.moveTo}`,
                  //     sentTime: new Date(),
                  //     viewed: false,
                  //   });
                  // });
                }
                // Finally, if for some reason there is a timer widget instance open, lets reset it
                if (timer.on && timer.queryId === queryData?.id) {
                  setTimer((prev) => ({
                    ...prev,
                    start: false,
                    on: false,
                    startTime: Date.now(),
                    elapsed: 0,
                  }));
                }
              }}
            />
          ))}
        {takeMeThere &&
          activeRequirement.type !== "submit" &&
          ((takeMeThere.qr && takeMeThere.qr !== queryRoute) ||
            (takeMeThere.ar && takeMeThere.ar !== actionsRoute)) && (
            <Button
              style={{ marginLeft: 10 }}
              label="Take Me There!"
              onClick={() =>
                setSearchParams((ex) => {
                  ex.set(
                    ROUTEKEYS.QUERY_PANE_MAIN_VIEW,
                    takeMeThere[ROUTEKEYS.QUERY_PANE_MAIN_VIEW]
                  );
                  if (takeMeThere[ROUTEKEYS.QUERY_PANE_SUB_VIEW]) {
                    ex.set(
                      ROUTEKEYS.QUERY_PANE_SUB_VIEW,
                      takeMeThere[ROUTEKEYS.QUERY_PANE_SUB_VIEW]
                    );
                  }
                  return ex;
                })
              }
            />
          )}
      </QueryWorkflowButtons>
    </QueryWorkflowBar>
  );
};

// This is also a good place for a little component that uses the workflow bar as a floating save
// Primarily for use in administrative components in the Query pane
export const SaveQueryBar = ({ saveQuery, buttonOn }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <QueryWorkflowBar>
      <QueryWorkflowText></QueryWorkflowText>
      <QueryWorkflowButtons>
        <Button
          label="Cancel"
          onClick={() => {
            setSearchParams((ex) => {
              ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.QUERY);
              return ex;
            });
          }}
          sx={{ marginRight: 8 }}
        />
        <Button label="Save Changes" onClick={() => saveQuery()} />
      </QueryWorkflowButtons>
    </QueryWorkflowBar>
  );
};

const QueryWorkflowBar = styled.div`
  width: 100%;
  min-height: ${isMobile ? "72px" : "60px"};
  position: relative;
  display: flex;

  justify-content: space-between;
  align-items: center;

  bottom: 0;
  left: 0;

  background-color: #f2f2f2;
  border-top: 1px solid #c2c2c2;
`;

const QueryWorkflowText = styled.div`
  display: flex;
  flex-wrap: wrap;
  maxwidth: 50%;

  margin-left: 10px;
`;

const QueryWorkflowButtons = styled.div`
  display: flex;
  flex-direction: row;

  height: ${isMobile ? "56px" : "40px"};

  margin-right: 10px;
`;
