import React from "react";
import styled from "styled-components";

import Meeting from "../graphics/meeting.png";
import Hands from "../graphics/hands-symbol.png";
import Results from "../graphics/results-symbol.png";
import Innovation from "../graphics/innovation-symbol.png";
import Service from "../graphics/service-symbol.png";
import Fun from "../graphics/fun-symbol.png";

export default () => {
  {
    /* Note to self: re-write this in a less repetitive way */
  }
  return (
    <TheCompanyContainer>
      <TextContainer>
        <TextBuffer>
          <TitleSection>
            <H2>The Company</H2>
            <P1>WE ARE A PROUD ALBERTA-BASED CANADIAN DIGITAL START-UP.</P1>
            <P2>Company ethos/fundamentals:</P2>
          </TitleSection>
          <CompanySnippetsSection>
            <CompanySnippetFirst>
              <Img src={Hands} />
              <P3Desktop>
                Relationship based — internal as well as external
              </P3Desktop>
              <P3Mobile>Relationship based</P3Mobile>
              <P3Mobile>Internal as well as External</P3Mobile>
              <P4>We practice highly professional and ethical work culture</P4>
            </CompanySnippetFirst>
            <CompanySnippet>
              <Img src={Results} />
              <P3Desktop>Result Oriented — commitments matter</P3Desktop>
              <P3Mobile>Result Oriented</P3Mobile>
              <P3Mobile>Commitments Matter</P3Mobile>
              <P4>Deliver exceptional results on schedule</P4>
            </CompanySnippet>
            <CompanySnippet>
              <Img src={Innovation} />
              <P3Desktop>Continuous Improvement and Innovation</P3Desktop>
              <P3Mobile>Continuous Improvement and Innovation</P3Mobile>
              <P4>Explore better ways to add value; always improve</P4>
            </CompanySnippet>
            <CompanySnippet>
              <Img src={Service} />
              <P3Desktop>Service over reward — Value over revenue</P3Desktop>
              <P3Mobile>Service over reward</P3Mobile>
              <P3Mobile>Value over revenue</P3Mobile>
              <P4>
                'Service' based outlook when exploring opportunities. Focus on
                adding value
              </P4>
            </CompanySnippet>
            <CompanySnippet>
              <Img src={Fun} />
              <P3Desktop>Have fun — enjoy the present</P3Desktop>
              <P3Mobile>Have fun</P3Mobile>
              <P3Mobile>Enjoy the present</P3Mobile>
              <P4>
                Things never go 100% the way you want, and that's okay, keep
                moving forward
              </P4>
            </CompanySnippet>
          </CompanySnippetsSection>
        </TextBuffer>
      </TextContainer>
    </TheCompanyContainer>
  );
};

const TheCompanyContainer = styled.div`
  min-height: 1100px;
  width: 100%;
  background-color: white;
  background-image: url(${Meeting});
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1600px) and (min-width: 1201px) {
    min-height: 900px;
  }

  @media (max-width: 1200px) and (min-width: 601px) {
    min-height: 700px;
  }

  @media (max-width: 600px) {
    background-size: 300%;
    background-position: top;
  }
`;
const TextContainer = styled.div`
  color: white;
  background-image: linear-gradient(to right, #37227a, #601e80);
  min-height: 450px;
  width: 85%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) and (min-width: 601px) {
    width: 95%;
    min-height: 500px;
  }

  @media (max-width: 600px) and (min-width: 381px) {
    // mobile mockup dimensions
    width: 353px;
  }

  @media (max-width: 380px) {
    // scale mobile 80%
    width: 282.4px;
  }
`;
const TextBuffer = styled.div`
  height: 80%;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: flex-start;
    margin-bottom: 30px;
  }
`;
const TitleSection = styled.div`
  margintop: 2%;

  @media (max-width: 600px) {
    margin: 20px;
  }
`;
const CompanySnippetsSection = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
const CompanySnippet = styled.div`
  width: 15%;
  height: 100%;

  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;
const CompanySnippetFirst = styled.div`
  width: 15%;
  height: 100%;

  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Img = styled.img`
  margin-bottom: 2px;
`;
const H2 = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 200%;
`;
const P1 = styled.p`
  margin: 0;
  margin-top: 15px;
  font-size: 150%;

  @media (max-width: 600px) {
    font-size: 100%;
  }
`;
const P2 = styled.p`
  margin-top: 0.5%;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;
const P3Desktop = styled.p`
  margin: 0;
  margin-top: 0.5%;
  font-weight: bold;

  @media (max-width: 600px) {
    display: none;
  }
`;
const P3Mobile = styled.p`
  margin: 0;
  margin-top: 0.5%;
  font-weight: bold;
  text-align: center;

  @media (min-width: 601px) {
    display: none;
  }
`;
const P4 = styled.p`
  margin: 0;

  @media (max-width: 600px) {
    text-align: center;
  }
`;
