import { BaseCollection, BaseDocument, BaseData } from "../../../base";

export interface RequestData extends BaseData {
  token: string;
  requester: string;
}

export class Requests extends BaseCollection<RequestData> {
  request(id: string): Request {
    return this.doc(id) as Request;
  }
}

type Request = BaseDocument<RequestData>;