import React, { useContext, useState } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import { isMobile } from "react-device-detect";

import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Tooltip, TextField, Box, Typography } from "@mui/material";

import { InteractiveTable } from "./table";
import * as utils from "../project/analytics/utils";
import { ProjectUsersContext } from "../project";
import EBound from "./errorbounds";

import panelTheme from "../../themes/index_panel";
import { log_error, log_warning } from "../../tools/logger";
import { format } from "date-fns";
import { useTheme } from "@mui/material/styles";

import Heatmap from "./heatmap";
import { default as WorldMap } from "../statics2/graphics/worldmap.svg";
const lonlat = require("../../../lonlat.json");
import { SelectField } from "./inputs2";

let colorArray = ["#fcbb6d", "#d8737f", "#ab6c82", "#685d79", "#475c7a"]; // defaulting
const monthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

/** This is the head honcho function that resolves what chart we're rendering & sends us there */
export const ChartResolver = React.memo(
  ({
    chartSchema,
    data,
    aggregateData,
    actions,
    theme,
    sendBlob,
    isEditMode,
    setName,
    setFormat,
    index,
  }) => {
    // Note: data is the primary dataset. "actions" is available as a secondary data set only when explicitely selected

    //EDITOR TOOLS
    const chartName = () => {
      return (
        <>
          {isEditMode ? (
            <TextField
              onChange={(e) => setName(e.target.value, index)}
              defaultValue={chartSchema.name}
              label="Chart Name"
              placeholder={chartSchema.name}
              style={{ padding: "5px" }}
            />
          ) : (
            <DataTitle>{chartSchema.name}</DataTitle>
          )}
        </>
      );
    };

    const formatEditing = () => {
      return (
        <>
          {isEditMode ? (
            <TextField
              onChange={(e) => setFormat(e.target.value, index)}
              defaultValue={chartSchema.format}
              label="Format"
              placeholder={chartSchema.format}
              style={{ padding: "5px" }}
            />
          ) : null}
        </>
      );
    };

    // We need to resolve default chart theme if none is provided
    if (!theme) {
      theme = panelTheme;
    } else if (!theme.noOverride && theme.text !== "#ffffff") {
      theme = {
        ...theme,
        text: "#E0E0E0", // override text colour only for charts
      };
    }

    colorArray = theme.chartDefaults;

    let readyComponent;

    // Set the data type
    // We were using a ref here, but it caused render issues...
    // const chartData = useRef(chartSchema.dataType === 'actions' ? actions : data);
    const chartData = chartSchema.dataType === "actions" ? actions : data;

    switch (chartSchema.type) {
      // Chart types
      case "pie":
        readyComponent = (
          <ChartPie
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            theme={theme}
            colorSet={colorArray}
            sendBlob={sendBlob}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      case "line":
        readyComponent = (
          <ChartLine
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            theme={theme}
            colorSet={colorArray}
            sendBlob={sendBlob}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      case "bar":
        readyComponent = (
          <ChartBar
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            theme={theme}
            colorSet={colorArray}
            sendBlob={sendBlob}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      case "multibar":
        readyComponent = (
          <ChartMultibar
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            theme={theme}
            colorSet={colorArray}
            sendBlob={sendBlob}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      // Non-chart graphing types
      case "table":
        readyComponent = (
          <ChartTable
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            theme={theme}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      case "matrix":
        readyComponent = (
          <ChartMatrix
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            theme={theme}
            colorSet={colorArray}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      case "number":
        readyComponent = (
          <ChartNumber
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      case "heat":
        readyComponent = (
          <ChartHeat
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      case "location":
        readyComponent = (
          <ChartLocation
            chartSchema={chartSchema}
            data={chartData}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
        break;
      default:
        readyComponent = (
          <ChartComingSoon
            chartSchema={chartSchema}
            data={chartData}
            aggregateData={aggregateData}
            theme={theme}
            chartName={chartName}
            formatEditing={formatEditing}
          />
        );
    }

    return (
      <ThemeProvider theme={theme}>
        <Chart>
          <EBound area="Rendered Chart">{readyComponent}</EBound>
        </Chart>
      </ThemeProvider>
    );
  }
);

/** Now we're into the charts! */

const ChartPie = ({
  data,
  aggregateData,
  chartSchema,
  theme,
  colorSet,
  sendBlob,
  chartName,
  formatEditing,
}) => {
  const users = useContext(ProjectUsersContext);

  let customLabels;
  let isDateOrdering;
  // Manage the Data first
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);

  if (chartSchema.labelFormat) {
    customLabels = resolveChartLabels(chartSchema, labels, users, {});
  }

  let orderLex = Object.keys(labels).sort().reverse();

  const dataset = {
    labels: chartSchema.labelFormat ? Object.keys(customLabels) : orderLex,
    datasets: [
      {
        data: chartSchema.labelFormat
          ? Object.keys(labels).map((lbl) => labels[lbl])
          : orderLex.map((lbl) => labels[lbl]),
        backgroundColor: chartSchema.customColors
          ? orderLex.map((lbl) => chartSchema.customColors[lbl])
          : colorSet,
        borderColor: theme.text,
        width:
          300 + 100 * Math.floor(orderLex.map((lbl) => labels[lbl]).length / 5),
        key: Date.now(),
      },
    ],
  };

  // Show n-1 labels with highest counts and "Other" which aggregates everything else
  if (
    chartSchema.labelLimit != null &&
    Object.keys(labels).length > chartSchema.labelLimit
  ) {
    const orderByCount = Object.keys(labels).sort(
      (a, b) => -(labels[a] - labels[b])
    );
    const orderCustomByCount = Object.keys(customLabels).sort(
      (a, b) => -(customLabels[a] - customLabels[b])
    );

    const reducedLabels = orderByCount.slice(0, chartSchema.labelLimit - 1);
    dataset.labels = [
      ...(chartSchema.labelFormat
        ? orderCustomByCount.slice(0, chartSchema.labelLimit - 1)
        : reducedLabels),
      "Other (AUTO)",
    ];
    dataset.datasets[0].data = [
      ...reducedLabels.map((lbl) => labels[lbl]),
      orderByCount
        .slice(chartSchema.labelLimit)
        .reduce((acc, lbl) => acc + labels[lbl], 0),
    ];
  }

  const labelCount = chartSchema.labelLimit ?? Object.keys(labels).length;
  if (labelCount > 6) {
    const numExtraCols = Math.floor(labelCount / 6);
    dataset.datasets[0].width += 200 * numExtraCols;
  }

  return (
    <>
      {chartName()}
      {formatEditing()}
      <div>
        <Doughnut
          key={dataset.datasets[0].key}
          data={dataset}
          width={dataset.datasets[0].width}
          options={{
            maintainAspectRatio: false,
            animation: {
              duration: 0, // general animation time
              onComplete: (e) =>
                sendBlob
                  ? sendBlob(chartSchema.name, e.chart.toBase64Image())
                  : null,
            },
            legend: {
              position: "left",
              labels: {
                fontFamily: "Ubuntu",
                fontColor: theme.text,
                fontSize: labelCount > 6 ? 13 : 12,
              },
              fontColor: theme.text,
            },
          }}
        />
      </div>
    </>
  );
};

const ChartLine = ({
  data,
  aggregateData,
  chartSchema,
  theme,
  colorSet,
  sendBlob,
  chartName,
  formatEditing,
}) => {
  const users = useContext(ProjectUsersContext);

  let customLabels;
  let isDateOrdering;
  // Manage the Data first
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);

  if (chartSchema.labelFormat) {
    customLabels = resolveChartLabels(chartSchema, labels, users, {});
  }

  const dataset = {
    labels: chartSchema.labelFormat
      ? Object.keys(customLabels)
      : Object.keys(labels),
    datasets: [
      {
        data: chartSchema.labelFormat
          ? Object.values(customLabels)
          : Object.values(labels),
        pointBackgroundColor: chartSchema.customColors
          ? chartSchema.customColors[0]
          : colorSet[0],
        borderColor: chartSchema.customColors
          ? chartSchema.customColors[0]
          : colorSet[0],
      },
    ],
  };

  const maxValue = Math.max(...dataset.datasets[0].data);

  return (
    <>
      {chartName()}
      {formatEditing()}
      <div>
        <Line
          data={dataset}
          options={{
            ...lineChartStyle(theme),
            animation: {
              onComplete: (e) =>
                sendBlob
                  ? sendBlob(chartSchema.name, e.chart.toBase64Image())
                  : null,
            },
          }}
        />
      </div>
    </>
  );
};

const ChartBar = ({
  data,
  aggregateData,
  chartSchema,
  theme,
  colorSet,
  sendBlob,
  chartName,
  formatEditing,
}) => {
  const users = useContext(ProjectUsersContext);

  let customLabels;
  let isDateOrdering;
  // Manage the Data first
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);

  if (chartSchema.labelFormat) {
    customLabels = resolveChartLabels(chartSchema, labels, users, {});
  }

  const finalLabels = chartSchema.labelFormat
    ? Object.keys(customLabels)
    : Object.keys(labels);
  const dataset = {
    labels: finalLabels,
    datasets: [
      {
        data: Object.values(labels),
        backgroundColor: chartSchema.customColors
          ? chartSchema.customColors
          : scaledColors(finalLabels.length),
      },
    ],
  };

  return (
    <>
      {chartName()}
      {formatEditing()}
      <div>
        <Bar
          data={dataset}
          options={{
            ...lineChartStyle(theme),
            animation: {
              onComplete: (e) =>
                sendBlob
                  ? sendBlob(chartSchema.name, e.chart.toBase64Image())
                  : null,
            },
          }}
        />
      </div>
    </>
  );
};

const ChartMultibar = ({
  data,
  aggregateData,
  chartSchema,
  theme,
  colorSet,
  sendBlob,
  chartName,
  formatEditing,
}) => {
  const users = useContext(ProjectUsersContext);

  let customLabels;
  let isDateOrdering;
  // Manage the Data first
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);

  if (chartSchema.labelFormat) {
    customLabels = resolveChartLabels(chartSchema, labels, users, {});
  }

  var fullLabels = [];
  let i = -1;
  Object.keys(customLabels ? customLabels : labels).forEach((l) =>
    Object.keys((customLabels ? customLabels : labels)[l]).forEach((z) =>
      fullLabels.includes(z) ? [] : fullLabels.push(z)
    )
  );
  const dataset = {
    labels:
      chartSchema.mainLabels == "monthly"
        ? [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ]
        : fullLabels,
    datasets: Object.keys(customLabels ? customLabels : labels).map((lbl) => {
      i++;
      return {
        label: lbl,
        data: fullLabels.map(
          (flb) => (customLabels ? customLabels : labels)[lbl][flb]
        ), // TODO: Redo format function
        backgroundColor: chartSchema.customColors
          ? chartSchema.customColors[i]
          : colorSet[i],
      };
    }),
  };

  return (
    <>
      {chartName()}
      {formatEditing()}
      <div>
        <Bar
          data={dataset}
          options={{
            ...lineChartStyle(theme),
            animation: {
              onComplete: (e) =>
                sendBlob
                  ? sendBlob(chartSchema.name, e.chart.toBase64Image())
                  : null,
            },
          }}
        />
      </div>
    </>
  );
};

// Non-Chart Charts

const ChartTable = ({
  data,
  aggregateData,
  chartSchema,
  theme,
  colorSet,
  chartName,
  formatEditing,
}) => {
  const users = useContext(ProjectUsersContext);

  let customLabels;
  let isDateOrdering;
  // Manage the Data first
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  isDateOrdering = false; // TODO: Temporary, this date ordering thing is a bit quirky here I think
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);

  if (chartSchema.labelFormat) {
    customLabels = resolveChartLabels(chartSchema, labels, users, {});
  }

  var chartData = [];
  // Before we build the table with its columns, run filtering if necessary
  var tableQueries = [...orderedData];
  if (chartSchema.prefilter) {
    tableQueries = tableQueries.filter(
      new Function("dt", chartSchema.prefilter)
    );
  }

  if (!chartSchema.columns) {
    return (
      <>
        {chartName()}
        {formatEditing()}
        <Typography>
          The chart type you have chosen does not
          <br />
          work with the current data.
          <br />
          Please try changing the type or entering
          <br />
          different data.
        </Typography>
      </>
    );
  }

  chartSchema.columns
    .sort((a, b) => (a.groupby ? -1 : b.groupby ? 1 : 0))
    .forEach((col) => {
      // Note: We've prioritized the "groupby" column here if it's available
      // Check that either an organizer or reducer is present
      if (!col.organizer && !col.reducer) {
        return;
      }
      if (col.groupby) {
        // We're generating the identifying set and
        // including the ids of the data if available
        // This way the data is grouped by the organizers, and can be used
        // to calculate the others in that way
        let colOrganizer = new Function("dt", "users", col.organizer);
        tableQueries.forEach((q) => {
          let key = colOrganizer(q, users);
          key = key ? key : "None";
          let existingRow = chartData.find((row) => row[col.index] == key);
          if (existingRow) {
            existingRow.sourceDataRows.push(q);
          } else {
            chartData.push({
              [col.index]: key,
              sourceDataRows: [q],
            });
          }
        });
      } else if (col.organizer) {
        // Then for each of the groupby rows, run the organizer and populate data
        // NOTE: Organizers will "ASSUME" that the data is like for all sourceDataRows
        try {
          let colOrganizer = new Function("dt", "users", col.organizer);
          chartData = chartData.map((row) => ({
            ...row,
            [col.index]: colOrganizer(row.sourceDataRows[0], users),
          }));
        } catch (e) {
          log_error(
            "The following error occured while building the chart table ancillary columns" +
              e
          );
        }
      } else {
        try {
          let colReducer = new Function("acc", "dt", col.reducer);
          chartData = chartData.map((row) => ({
            ...row,
            [col.index]: row.sourceDataRows.reduce(
              colReducer,
              col.reducerDefault
            ),
          }));
        } catch (e) {
          log_error(
            "The following error occured while building the chart table ancillary columns" +
              e
          );
        }
      }
    });
  const dataset = {
    labels: "",
    datasets: chartData.length < 1 ? [] : [""], // Appease the generalized empty checker
    rows: chartData,
    columns: chartSchema.columns.sort((a, b) =>
      a.groupby ? -1 : b.groupby ? 1 : 0
    ),
  };

  return (
    <>
      {chartName()}
      {formatEditing()}
      <TableWrap numCols={dataset.columns.length}>
        <InteractiveTable
          data={dataset.rows}
          columns={dataset.columns ? dataset.columns : []}
          allowOrdering={true}
        />
      </TableWrap>
    </>
  );
};

const ChartMatrix = ({
  data,
  aggregateData,
  chartSchema,
  theme,
  colorSet,
  chartName,
  formatEditing,
}) => {
  const users = useContext(ProjectUsersContext);

  let isDateOrdering;
  // Manage the Data first
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);

  const chartData = {}; // This will be an NxN dict that we'll map to a matrix at the end
  var uniqueLabels = [];
  var xLabels = [];
  var yLabels = [];
  var max = 0;
  // Run a check that all the config is defined
  if (chartSchema.field?.element1 && chartSchema.field?.element2) {
    orderedData.forEach((q) => {
      let dt1 = q;
      let dt2 = q;
      chartSchema.field.element1
        .split(".")
        .forEach(
          (pt) =>
            (dt1 = dt1[pt]
              ? Array.isArray(dt1[pt])
                ? dt1[pt][0]
                : dt1[pt]
              : "")
        );
      chartSchema.field.element2
        .split(".")
        .forEach(
          (pt) =>
            (dt2 = dt2[pt]
              ? Array.isArray(dt2[pt])
                ? dt2[pt][1]
                : dt2[pt]
              : "")
        );
      // Check that data is valid for both
      if (dt1 === "" || dt2 === "") {
        return;
      }
      // Now we have the data points for the query
      // Track the labels and add to the matrix
      if (!xLabels.includes(dt1)) {
        xLabels.push(dt1);
        chartData[dt1] = {};
        yLabels.forEach((k) => (chartData[dt1][k] = 0));
      }
      if (!yLabels.includes(dt2)) {
        yLabels.push(dt2);
        xLabels.forEach((k) => (chartData[k][dt2] = 0));
      }
      // Now add this data point!
      chartData[dt1][dt2]++;
    });
    // Now that all the data is collected, see about a label format
    if (chartSchema.labelFormat) {
      if (chartSchema.labelFormat.element1) {
        switch (chartSchema.labelFormat.element1) {
          case "months":
            log_warning("'Months' LabelFormat is not supported at this time");
            break;
          case "users":
            if (users) {
              const newLabels = [];
              users.forEach((usr) => {
                var newLabel = usr.name
                  ? `${usr.name.first} ${usr.name.last}`
                  : "Unrecognized User";
                newLabels.push(newLabel);
                chartData[newLabel] = chartData[usr.id];
                delete chartData[usr.id];
                // Then throw the key in the label set
                xLabels = [
                  ...xLabels.filter((lbl) => lbl !== usr.id),
                  newLabel,
                ];
              });
              // If there are any hidden users, remove them from xLabels
              // Any hidden users will not be in newLabels because hidden users are not included in our users variable
              xLabels = xLabels.filter((label) => newLabels.includes(label));
            }
            break;
          default:
            // Resolve as a function
            log_warning("Custom Label formatting is not available");
        }
      }
      if (chartSchema.labelFormat.element2) {
        log_warning("Custom Label formatting is not available (table/matrix)");
      }
    } else if (chartSchema.labelMap) {
      // Then see about a less common label map
      if (chartSchema.labelMap.element1) {
        Object.keys(chartSchema.labelMap.element1).forEach((mapKey) => {
          if (xLabels.includes(mapKey)) {
            chartData[chartSchema.labelMap.element1[mapKey]] =
              chartData[mapKey];
            delete chartData[mapKey];
            // Then replace the key in the label set
            xLabels = [
              ...xLabels.filter((lbl) => lbl !== mapKey),
              chartSchema.labelMap.element1[mapKey],
            ];
          }
        });
      }
      if (chartSchema.labelMap.element2) {
        Object.keys(chartSchema.labelMap.element2).forEach((mapKey) => {
          if (yLabels.includes(mapKey)) {
            Object.keys(chartData).forEach((x) => {
              chartData[x][chartSchema.labelMap.element2[mapKey]] =
                chartData[x][mapKey];
              delete chartData[x][mapKey];
            });
            yLabels = [
              ...yLabels.filter((lbl) => lbl !== mapKey),
              chartSchema.labelMap.element2[mapKey],
            ];
          }
        });
      }
    }
    // Format data
    switch (chartSchema.dataFormat) {
      case "frequency":
        // to find the most frequent element for a label, we first collect the values into an array.
        // then we find the max value
        yLabels.map((l) => {
          let values = [];
          Object.keys(chartData).map((key) => {
            if (chartData[key]) values = [...values, chartData[key][l]];
          });
          max = Math.max(...values);
        });
        break;
      default:
    }
  } else {
    return (
      <>
        {chartName()}
        {formatEditing()}
        <Typography>
          The chart type you have chosen does not
          <br />
          work with the current data.
          <br />
          Please try changing the type or entering
          <br />
          different data.
        </Typography>
      </>
    );
  }
  // Now do a quick sort
  xLabels.sort();
  yLabels.sort();
  // And return :)
  const dataset = {
    labels: [xLabels, yLabels], // Will be two here,
    datagrid: chartData,
    datasets: chartData.length < 1 ? [] : [""], // Appease the generalized empty checker
    max: max,
  };

  return (
    <>
      {chartName()}
      {formatEditing()}
      <TableWrap numCols={dataset.labels.length - 3}>
        <MatrixContainer>
          <tbody>
            <MatrixRow>
              <MatrixCell />
              {dataset.labels[0].map(
                (lb) =>
                  dataset.datagrid[lb] && (
                    <MatrixCell head key={lb}>
                      {lb}
                    </MatrixCell>
                  )
              )}
            </MatrixRow>
            {dataset.labels[1].map((l2) => (
              <MatrixRow key={l2}>
                <MatrixCell head key={l2}>
                  {l2}
                </MatrixCell>
                {dataset.labels[0].map(
                  (l1) =>
                    dataset.datagrid[l1] && (
                      <Tooltip
                        key={l1}
                        title={
                          chartSchema.dataFormat === "frequency"
                            ? dataset.datagrid[l1][l2]
                            : ""
                        }
                      >
                        <MatrixCell
                          key={`${l1}-${l2}`}
                          color={
                            chartSchema.dataFormat === "frequency" &&
                            dataset.datagrid[l1][l2] === dataset.max &&
                            dataset.max != 0
                              ? colorSet[0]
                              : theme.tileBackground
                          }
                        >
                          {chartSchema.dataFormat === "frequency"
                            ? ""
                            : dataset.datagrid[l1][l2]}
                        </MatrixCell>
                      </Tooltip>
                    )
                )}
              </MatrixRow>
            ))}
          </tbody>
        </MatrixContainer>
      </TableWrap>
    </>
  );
};

const ChartNumber = ({
  data,
  aggregateData,
  chartSchema,
  chartName,
  formatEditing,
}) => {
  let isDateOrdering;
  // Manage the Data first
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);
  const number = typeof labels === "number" ? labels : labels[undefined] ?? 0;

  // Determine font size based on how big the number is
  const getFontSize = (number) => {
    if (number < 1000000) {
      return "12vh"; // larger font for smaller numbers
    } else if (number >= 1000000 && number < 1000000000) {
      return "6vh"; // medium font for medium numbers
    } else {
      return "4vh"; // smaller font for larger numbers
    }
  };

  return (
    <>
      {chartName()}
      {formatEditing()}
      <NumberChartView style={{ fontSize: getFontSize(number) }}>
        {number}
      </NumberChartView>
    </>
  );
};

/**
 * Aggregates all data[i].data[chartSchema.field] (if location data is available) and renders as points on a heatmap.
 */
const ChartLocation = ({ data, chartSchema, chartName, formatEditing }) => {
  const [locationField, setLocationField] = useState(chartSchema.field || "");
  const users = useContext(ProjectUsersContext);

  // 1. EXTRACT LOCATION DATA
  // TODO: Dropdown of fields where type is location
  // `location` type: { country : string, state : string, city? : string }

  let orderedData = data.filter((q) => q.status !== "deleted");
  // console.log(orderedData);

  // const labels = synthesizeOrderedData(orderedData, chartSchema);

  // 2. AGGREGATE LOCATION OCCURRENCES
  const locationAggregate = (orderedData ?? [{ "": 0 }]).reduce((ex, d) => {
    // Extract location string
    const location = d?.["data"]?.[locationField];
    if (!location) return ex;

    // Increment existing value
    const val = ex[location] ?? 0;
    // console.log('%s: %d', location, val+1);
    return {
      ...ex,
      [location]: val + 1,
    };
  }, {});

  // { "valuestring" : "Display String" }
  const fieldOptions =
    orderedData && orderedData.length && orderedData[0]
      ? Object.entries(orderedData[0].data)
          ?.filter((field) => typeof field[1] == "string")
          .reduce(
            (ex, f) => ({
              ...ex,
              [`${f[0]}`]: `${f[0]}`,
            }),
            {}
          )
      : [];

  // 3. RETURN DIV WITH REF CALLBACK INSTANTIATING THE HEATMAP
  // TODO: Renders over div but not img
  return (
    <div
      style={
        {
          // display: "flex",
          // flex: 1,
          // justifyContent: "center",
          // alignItems: 'center'
        }
      }
    >
      {chartName()}
      {formatEditing()}
      {chartSchema.field && chartSchema.field !== "" ? null : (
        <SelectField
          label="Location Field"
          data={locationField}
          options={fieldOptions}
          onChange={(e) => {
            setLocationField(e.target.value || "");
            // console.log('Location Field: "%s"', e.target.value);
          }}
          autocomplete
          contrast
        />
      )}
      <div style={{ width: "100%", minHeight: "160px" }}>
        <Heatmap
          getDataFromDimensions={(xpx, ypx) => {
            // 3. CONVERT TO HEATMAP DATA - `{ x : number, y : number, value : number } []`
            return Object.keys(locationAggregate)
              .map((c) => {
                // location found in longitude/latitude data?
                const ll = lonlat[c];
                if (!ll) return;

                // console.log(ll);

                // TODO: Convert latitude/longitude [-90,90] deg to pixel coordinates
                return {
                  // [-180,180] to [0,1]
                  x: Math.round((ll.longitude / 360 + 0.5) * xpx),
                  // [-90,90] to [1,0]
                  y: Math.round((1 - (ll.latitude / 180 + 0.5)) * ypx),
                  value: locationAggregate[c],
                };
              })
              .filter((a) => a !== undefined);
          }}
        >
          <WorldMap
            viewBox={"-50 50 2820 1420"}
            preserveAspectRatio={"none"}
            style={{
              maxWidth: "100%",
              // maxHeight: '100%',
              // position:'absolute',
              // top:'0px',
              // left:'0px',
            }}
          />
        </Heatmap>
      </div>
    </div>
  );
};

const ChartHeat = ({
  data,
  aggregateData,
  chartSchema,
  chartName,
  formatEditing,
}) => {
  const theme = useTheme();
  const users = useContext(ProjectUsersContext);
  let customLabels;
  let isDateOrdering;
  let dataOrder = chartSchema.chartSort
    ? chartSchema.chartSort.split(".")
    : ["createTime"];
  if (data.length > 0) {
    let q = data[0];
    dataOrder.forEach((v) => (q = q[v]));
    isDateOrdering = !isNaN(Date.parse(q));
  }
  let orderedData = data
    .filter((q) => q.status !== "deleted")
    .sort((a, b) => {
      let af, bf;
      af = a;
      bf = b;
      dataOrder.forEach((v) => {
        af = af[v];
        bf = bf[v];
      });
      return (
        (isDateOrdering ? Date.parse(af) - Date.parse(bf) : af - bf) ||
        a.createTime - b.createTime
      );
    });

  const labels = resolveLabels(aggregateData, orderedData, chartSchema);

  chartSchema.labelFormat = "monthly";
  customLabels = resolveChartLabels(chartSchema, labels, users, {});

  const myDataSet = Array.apply(0, new Array(365)).map((i) => 0);

  const getDayOfYear = (date) => {
    var start = new Date(date.getFullYear(), 0, 0);
    var diff = date - start;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);
    return day;
  };

  let now = new Date(Date.now());

  orderedData.map((k, i) => {
    let date = new Date(orderedData[i]?.createTime.seconds * 1000);
    if (!(date.getFullYear() < now.getFullYear() - 1)) {
      if (
        !(
          date.getFullYear() === now.getFullYear() - 1 &&
          date.getMonth() < now.getMonth()
        )
      ) {
        const dayOfYear = getDayOfYear(date);
        myDataSet[dayOfYear - 1] += 1;
      }
    }
  });

  const dayOfYearToday = getDayOfYear(now);

  const dayOfWeekToday = now.getDay();

  const daysOfYear = Array.from({ length: 365 }, (_, i) => {
    const date = new Date();
    date.setMonth(0);
    date.setDate(i + 1);

    const formattedDate = format(date, "MMM do'");

    return formattedDate;
  });

  let dateLabels = Array.from(
    { length: 365 },
    (_, i) => daysOfYear[i % 365] || ""
  );

  for (let i = 0; i < dayOfYearToday; i++) {
    let removed = myDataSet.shift();
    myDataSet.push(removed);

    let removed2 = dateLabels.shift();
    dateLabels.push(removed2);
  }

  let endingWeek = [];
  for (let i = 0; i < dayOfWeekToday + 1; i++) {
    endingWeek.push({ frequency: 0, datePrefix: "" });
  }

  for (let i = endingWeek.length; i > 0; i--) {
    endingWeek[i - 1].frequency = myDataSet[myDataSet.length - i];
    endingWeek[i - 1].datePrefix = dateLabels[dateLabels.length - i];
  }

  endingWeek.reverse();

  // append future days to fill in the week, so the endingWeek isnt dangling and lonely
  // negative frequencies will indicate a future date
  // const fillAmount = 365 - (7 - endingWeek.length);
  // for (let i = 365; i > fillAmount; i--) {
  //   endingWeek.push({
  //     frequency: -1,
  //     datePrefix: dateLabels[dateLabels.length - i],
  //   });
  // }

  let heatChart = [];
  for (let i = 0; i < 52; i++) {
    let row = [];
    for (let j = 0; j < 7; j++) {
      row.push({ frequency: 0, datePrefix: "" });
    }
    heatChart.push(row);
  }

  let counter = 0;
  for (let row = 0; row < 52; row++) {
    for (let col = row === 0 ? endingWeek.length - 1 : 0; col < 7; col++) {
      heatChart[row][col].frequency = myDataSet[counter];
      heatChart[row][col].datePrefix = dateLabels[counter];
      counter++;
    }
  }

  heatChart.shift(); //removes first week, start of week > 1yr ago
  heatChart.push(endingWeek);

  let maxValue = Math.max(...myDataSet);

  const colours = [
    theme.palette.grey[300],
    theme.palette.primary.dark,
    "#862473",
    "#a9348c",
    theme.palette.secondary.light,
  ]; //gradient

  const calcColour = (value, maxValue) => {
    //colour changes at 0%, >0%, >33%, >67% of maxval, and if negative set future date colour
    if (value < 0) {
      return 0;
    }
    let retVal = 1;
    if (value > 0) {
      retVal++;
    }
    if (value > maxValue / 3) {
      retVal++;
    }
    if (value > (maxValue / 3) * 2) {
      retVal++;
    }
    return retVal;
  };

  //The weeks that are have a label, in chronological order
  const labelWeeks = ["", "Mon", "", "Wed", "", "Fri", ""];

  const ReferenceBox = () => {
    return (
      <div style={{ display: "flex", float: "right", paddingTop: "10px" }}>
        <Typography style={{ marginRight: 3, userSelect: "none" }}>
          Less
        </Typography>
        {colours.map((k, i) => {
          if (i !== 0) {
            return (
              <Box
                key={k}
                style={{
                  width: 13,
                  height: 13,
                  backgroundColor: k,
                  marginRight: 3,
                  marginTop: 3,
                  borderRadius: "3px",
                }}
              />
            );
          }
        })}
        <Typography style={{ userSelect: "none" }}>More</Typography>
      </div>
    );
  };

  return (
    <div style={{ paddingTop: "10px", overflowX: "auto" }}>
      {chartName()}
      {formatEditing()}
      <div style={{ display: "inline-block" }}>
        {Object.entries(customLabels).map((k, i) => (
          <Typography
            style={{
              display: "inline",
              fontSize: "14px",
              marginLeft: "45px",
              userSelect: "none",
            }}
            key={i}
          >
            {k.slice(0, -1)}
          </Typography>
        ))}
      </div>
      <div style={{ display: "flex" }}>
        <div>
          {labelWeeks.map((value, i) => (
            <div
              key={i}
              style={{
                flexDirection: "column",
                marginBottom: "-3px",
                paddingRight: "4px",
              }}
            >
              {value ? (
                <Typography style={{ fontSize: "14px", userSelect: "none" }}>
                  {value}
                </Typography>
              ) : (
                <Box
                  style={{
                    width: 13,
                    height: 13,
                    marginLeft: 3,
                    marginBottom: 3,
                    borderRadius: "3px",
                    opacity: "0%",
                  }}
                />
              )}
            </div>
          ))}
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", float: "right" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "20px",
            }}
          >
            {heatChart.map((row, i) => (
              <div key={i} style={{ display: "flex", flexDirection: "column" }}>
                {row?.map((value, j) => (
                  <Tooltip
                    key={j}
                    title={
                      value.datePrefix +
                      ": " +
                      (value.frequency < 0 ? "n/a" : value.frequency)
                    }
                    enterDelay={1000}
                    disableTouchListener={isMobile}
                  >
                    <Box
                      style={{
                        width: 13,
                        height: 13,
                        backgroundColor:
                          colours[calcColour(value.frequency, maxValue)],
                        marginLeft: 3,
                        marginBottom: 3,
                        borderRadius: "3px",
                      }}
                    />
                  </Tooltip>
                ))}
              </div>
            ))}
          </div>
          <ReferenceBox />
        </div>
      </div>
    </div>
  );
};

// No Chart Supporters

const ChartNoData = ({ name, chartName, formatEditing }) => (
  <>
    {chartName()}
    {formatEditing()}
    <ImmitateChart>No data available</ImmitateChart>
  </>
);

const ChartComingSoon = ({ name, chartName, formatEditing }) => (
  <>
    {chartName()}
    {formatEditing()}
    <ImmitateChart>This chart type is coming soon!</ImmitateChart>
  </>
);

// Chart Supporting Functions

const lineChartStyle = (theme, maxValue = undefined) => ({
  legend: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontFamily: "Ubuntu",
          fontColor: theme.text,
          max: maxValue ? maxValue + Math.max(3, maxValue / 10) : undefined,
        },
        gridLines: { color: theme.gridlines },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontFamily: "Ubuntu",
          fontColor: theme.text,
        },
        gridLines: { color: theme.gridlines },
      },
    ],
  },
});

const resolveChartLabels = (chartSchema, labels, users, schemas) => {
  let customLabels = {};
  const d = new Date();
  switch (chartSchema.labelFormat) {
    case "monthly":
      // In this case, the data formatter has already setup the names properly
      // We just need to add 0s for the other labels and map 1-1
      // get current month, create new array of months with current month as the last element of the array
      var month = d.getMonth();
      var firstPart = monthLabels.slice(month + 1, 13);
      var secondPart = monthLabels.slice(0, month + 1);
      var sortedMonthLabels = firstPart.concat(secondPart);
      sortedMonthLabels.forEach((lbl) => {
        customLabels[lbl] = Object.keys(labels).includes(lbl) ? labels[lbl] : 0;
      });
      break;
    case "schemas":
      // Using schema names
      schemas.forEach((sch) => (customLabels[sch.name] = labels[sch.id]));
      break;
    default: // Blank chart if no label data found
      // Here we rely on a custom label formatter to get us there
      // And it helps if there's some chart schema label preset (like users)
      var labelData = chartSchema.label === "users" ? users : schemas;
      if (!labelData) {
        break;
      }
      // Now setup the label formatter as a function if it exists
      var labelFormatter = new Function("val", chartSchema.labelFormat);
      Object.keys(labels).forEach((origLabel) => {
        let newLabel = labelData.length
          ? chartSchema.labelFormat
            ? labelFormatter(
                labelData?.find((val) => val.id == origLabel) ?? {}
              )
            : labelData.filter((val) => val.id == origLabel)[0]
          : labelData[origLabel];
        customLabels[newLabel] = labels[origLabel];
      });
      break;
  }

  return customLabels;
};

const resolveProperty = (query, path, chartSchema) => {
  // Tokenize the nested relationship, e.g. data.nested.nestedAgain => [data, nested, nestedAgain]
  let obj = query;
  // Go through and 'burrow' that deep into the object.
  if (typeof path == "string" && path && path != "") {
    for (let index of path.split(".")) {
      obj = obj?.[index];
    }
  }

  // Apply a custom format to the value if needed. Since the schema is stored in JSON, it is a string...we need to make it a function.
  let format = (val) => val;
  if (chartSchema.format) {
    window.utils = utils;
    try {
      format = new Function("val", chartSchema.format);
    } catch (e) {
      log_error("Error occured loading custom analytics render function", e);
    }
  }
  try {
    return format(obj);
  } catch (e) {
    log_error("Error occured rendering data with custom chart function", e);
    return undefined;
  }
};

const synthesizeOrderedData = (data, chartSchema) => {
  const labels = {};
  data.forEach((sample) => {
    const property = resolveProperty(sample, chartSchema.field, chartSchema);
    if (property === undefined && chartSchema.format) {
      // We don't tally undefined points from custom functions
      return;
    }
    // if we have an array of objects, consider for consolidation
    if (typeof property === "object") {
      if (Array.isArray(property)) {
        property.forEach((val) => {
          if (!labels[val]) {
            labels[val] = 0;
          }
          labels[val] += 1;
        });
      } else {
        Object.keys(property).forEach((key) => {
          if (!labels[key]) {
            labels[key] = 0;
          }
          // We check here that it's an object that isn't a date object
          if (
            typeof property[key] === "object" &&
            !(
              property[key]["seconds"] !== undefined &&
              property[key]["nanoseconds"] !== undefined
            )
          ) {
            // This supports multi-bar case (for now)
            Object.keys(property[key]).forEach((propKey) => {
              if (!labels[key]) {
                labels[key] = {};
              }
              if (!labels[key][propKey]) {
                labels[key][propKey] = 0;
              }
              // We'll do what we can with addition, but salvage what else exists
              try {
                labels[key][propKey] += property[key][propKey];
              } catch (e) {
                log_error("Data could not be aggregated");
              }
            });
          } else {
            labels[key] += property[key];
          }
        });
      }
    } else {
      if (!labels[property]) {
        labels[property] = 0;
      }
      labels[property] += 1;
    }
  });
  return labels;
};

const resolveLabels = (aggregateData, orderedData, chartSchema) => {
  // Tries to use the aggregated data first, if it exists
  if (aggregateData !== undefined && Object.keys(aggregateData).length > 0) {
    // Depending on the chartSchema, the key used in aggregateData could be in several places
    // The order of the conditionals may matter, as we want the first match to return
    const fieldIds = [];
    if (chartSchema?.datasets?.length > 0) {
      fieldIds.push(chartSchema.datasets[0].id.replace(/-/g, "_"));
    }
    if (chartSchema?.id) {
      fieldIds.push(chartSchema.id.replace(/-/g, "_"));
    }
    if (chartSchema?.field) {
      const delimiter = chartSchema.field.indexOf(".");
      fieldIds.push(chartSchema.field.slice(delimiter + 1).replace(/-/g, "_"));
    }

    const id = fieldIds.findIndex((id) => Object.hasOwn(aggregateData, id));
    if (id !== -1) {
      return aggregateData[fieldIds[id]].data;
    }
  }
  return synthesizeOrderedData(orderedData, chartSchema);
};

// Color Helper
const scaledColors = (length) =>
  colorArray.length < length
    ? Array(length)
        .fill("")
        .map((val, ind) => colorArray[ind % colorArray.length])
    : colorArray;

const Chart = styled.div`
  background: ${(props) => props.theme.tileBackground};
  border-radius: 4px;
  padding: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
  box-sizing: border-box;

  height: fit-content;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  min-width: ${(props) =>
    props.width ? props?.width?.toString() + "px" : "300px"};

  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  color: ${(props) => props.theme.text};

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  ${isMobile &&
  css`
    margin-left: 0;
  `}

  @media (orientation:landscape) {
    margin-left: 12px;
  }
`;

const DataTitle = styled.div`
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
  user-select: none;
`;

const ImmitateChart = styled.div`
  height: 150px;
  width: 300px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const NumberChartView = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;

  overflow-wrap: anywhere;

  color: ${(props) => props.theme.text};
`;

const TableWrap = styled.div`
  min-height: 200px;
  min-width: 300px;
  max-width: 100%;
  width: ${(props) =>
    props.numCols > 2 ? `${350 + (props.numCols - 2) * 100}px` : "350px"};
  overflow-y: auto;
`;

const MatrixContainer = styled.table`
  border: 1px solid ${(props) => props.theme.text};
  border-collapse: collapse;
`;

const MatrixRow = styled.tr``;

const MatrixCell = styled.td`
  text-align: center;
  padding: 4px;
  font-weight: ${(props) => (props.head ? 500 : 100)};
  border: 1px solid ${(props) => props.theme.text};
  background: ${(props) => props.color ?? props.theme.tileBackground};
`;
