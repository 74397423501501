import React, { useEffect, useState } from "react";
import { DATE_FILTERS } from "../../../common/dates";

import { SelectField, LocalityField } from "../../ui/inputs2";
import { DATE_FILTER_OPTIONS } from "../../../common/dates";

export default ({
  data, // Data in-out is [startDate, endDate] for the date range (null when unset)
  onChange,
  allowCustom,
}) => {
  const [dateRange, setDateRange] = useState({
    earliestDate: null,
    latestDate: null,
  });

  const [selectedDateRangeValue, setSelectedDateRangeValue] = useState(null);

  const onChangeInternal = (e) => {
    const { value } = e.target;
    // First set the date in our internal state monitor
    setSelectedDateRangeValue(value);
    if (!(value === "custom") && value) {
      // We now want to store the custom dates directly as a string instead of a converted timestamp
      onChange(value);
      return;
    }
  };

  const onChangeCustomDates = (value, index) => {
    // First, initialize a separate variable that we will use in the pass-back (onChange): we don't want to use internal state for it
    let dateSet = dateRange;
    dateSet = { ...dateSet, [index]: value };
    // Once we have our date, let's return it through the onchange :)
    onChange(Object.values(dateSet));
    // Although we are not using the state for the pass-back, we still need to change the state
    setDateRange((ex) => ({ ...ex, [index]: value }));
  };

  useEffect(() => {
    // On first load, parse in the value from the parent and set the date range selection
    if (data) {
      // data coming in will be an array with two dates
      if (typeof data === "object") {
        // Then the data is a custom date
        setDateRange({
          earliestDate: data?.[0],
          latestDate: data?.[1],
        });
        setSelectedDateRangeValue("custom");
      } else {
        // Then the data is a dropdown option other than custom
        setSelectedDateRangeValue(data);
        if (!(data === "all")) {
          const dateSet = DATE_FILTERS[data];
          dateSet.earliestDate.setHours(0);
          dateSet.earliestDate.setMinutes(0);
          dateSet.earliestDate.setSeconds(0);
          dateSet.latestDate.setHours(23);
          dateSet.latestDate.setMinutes(59);
          dateSet.latestDate.setSeconds(59);
          setDateRange({
            earliestDate: dateSet.earliestDate,
            latestDate: dateSet.latestDate,
          });
        }
      }

      return;
    }
    // If no data, then set the date range to "all"
    setSelectedDateRangeValue(Object.keys(DATE_FILTER_OPTIONS)[0]);
  }, [data]);

  return (
    <div>
      <SelectField
        options={{
          ...DATE_FILTER_OPTIONS,
          ...(allowCustom ? { custom: "Custom" } : {}),
        }}
        data={selectedDateRangeValue}
        allowNone={false}
        onChange={onChangeInternal}
        label="Time Period"
      />
      {selectedDateRangeValue === "custom" && (
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <LocalityField
            data={dateRange.earliestDate ?? ""}
            onChange={(value) => onChangeCustomDates(value, "earliestDate")}
            useDate={true}
            label="Start Date"
          />
          <LocalityField
            data={dateRange.latestDate ?? ""}
            onChange={(value) => onChangeCustomDates(value, "latestDate")}
            useDate={true}
            label="End Date"
          />
        </div>
      )}
    </div>
  );
};
