import React, { useState } from "react";
import { Typography } from "@mui/material";

import { Button2 } from "../../ui/buttons";

import { LocationInput } from "../../ui/location";

export default ({ user, nextState }) => {
  const [location, setLocation] = useState(null);

  const makeSetLocation = () => {
    user
      .update({ location })
      .then((res) => nextState())
      // .catch((err) => setAuthError("Phone could not be set"));
  };

  return (
    <>
      {/* {authError && (
        <Typography style={{ color: "red" }}>{authError}</Typography>
      )} */}
      {/* <GenericField
      label="Phone"
      contrast
      data={phone}
      onChange={(e) => { const { value } = e.target; setPhone(value); }}
    /> */}
      <LocationInput
        contrast
        data={location}
        setLocation={setLocation}
      />
      <br />
      <Button2 disabled={!(location && location.country && location.state)} onClick={makeSetLocation} label="Set Location" />
      {/* <Button2 onClick={() => nextState()} label="Skip" /> */}
    </>
  );
};
