import { FirebaseApp } from "firebase/app"
import {
  FirebaseStorage,
  getStorage,
  list,
  listAll,
  ref,
  getDownloadURL as fetchRefURL,
  uploadBytesResumable,
  StorageReference,
  UploadTask,
  uploadBytes,
} from "firebase/storage"

export class APIStorage implements FirebaseStorage {
  app: FirebaseApp
  bucket: string | undefined
  maxUploadRetryTime: number
  maxOperationRetryTime: number
  static refURLCacheTracker: { [key: string]: Promise<String> } = { }

  constructor(app: FirebaseApp, bucket?: string) {
    this.app = app
    this.bucket = bucket
    this.maxUploadRetryTime = 2000
    this.maxOperationRetryTime = 2000
  }

  getBucket() {
    // Time to fetch storage based on config
    let storage
    if (this.bucket) {
      storage = getStorage(this.app, this.bucket)
    } else {
      storage = getStorage(this.app)
    }
    return storage
  }

  listAll(path?: string): Promise<any> {
    const storage = this.getBucket()
    return listAll(ref(storage, path))
  }

  ref(path?: string): any {
    const storage = this.getBucket()
    return ref(storage, path)
  }

  upload(storageRef: any, file: any): any {
    return uploadBytes(storageRef, file)
      .then((snapshot) => {
        return snapshot
      })
      .catch((e) => {
        return false
      })
  }

  compatibleRef(path?: string): any {
    // This is a reference return for use with the old Firebase API
    // (Implemented by some packages)
    const storage = this.getBucket()
    const compatRef: CompatibleStorage = ref(storage, path)
    compatRef["child"] = function (filename: string) {
      const newRef: CompatibleStorage = ref(this, filename)
      newRef["put"] = function (file: any, metadata: any) {
        return uploadBytesResumable(newRef, file, metadata)
      }
      return newRef
    }
    return compatRef
  }

  static getDownloadURL(ref: any) {
    // 1. If: The URL is stored in local storage; Then: return it.
    let url = window.localStorage.getItem(ref.fullPath);
    if(url) return Promise.resolve(url);

    // Else: The URL is not in local storage (yet).
    // Firebase Storage provides ref-URL fetching asynchronously.
    // 2. Check the static URL-Promise cache.
    let promise = APIStorage.refURLCacheTracker[ref.fullPath];

    // 3. If: the URL is not already being fetched; Then: Fetch the URL and cache the promise.
    if(!promise) {
      promise = APIStorage.refURLCacheTracker[ref.fullPath] = fetchRefURL(ref).then(url => {
        window.localStorage.setItem(ref.fullPath, url);
        return url;
      });
    }

    // Finally: Return the URL promise.
    return promise;
  }

  generateDownloadURL(ref: any) {
    return fetchRefURL(ref)
  }
}

interface CompatibleStorage extends StorageReference {
  child?: (filename: string) => StorageReference
  put?: (file: any, metadata: any) => UploadTask
}
