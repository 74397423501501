export function getData(val) {
  // This is a stub. Create custom functions here
  return true;
}

export function getNumber(val) {
  const flt = parseFloat(val);
  const nt = parseInt(val);
  return isNaN(nt) ? 0 : flt == nt ? nt : flt;
}

export function omauto_product_based_total_receipts(data, row) {
  let test = 0;
  let used = 0;
  switch (row.product) {
    case "Standard Petrol":
      if (data["tester"])
        data["tester"]
          .filter((val) => val.testing == "standard")
          .forEach((row) => {
            test += row.litres;
          });
      if (data["totaliser"])
        data["totaliser"]
          .filter((val) => val.product == "standard")
          .forEach((row) => {
            used += row.dispensed;
          });
      return (
        (used - test) * (data["standard-rate"] ? data["standard-rate"] : 0)
      );
    case "Extra Standard Petrol":
      if (data["tester"])
        data["tester"]
          .filter((val) => val.testing == "premium")
          .forEach((row) => {
            test += row.litres;
          });
      if (data["totaliser"])
        data["totaliser"]
          .filter((val) => val.product == "premium")
          .forEach((row) => {
            used += row.dispensed;
          });
      return (used - test) * (data["premium-rate"] ? data["premium-rate"] : 0);
    case "Diesel":
      if (data["tester"])
        data["tester"]
          .filter((val) => val.testing == "diesel")
          .forEach((row) => {
            test += row.litres;
          });
      if (data["totaliser"])
        data["totaliser"]
          .filter((val) => val.product == "diesel")
          .forEach((row) => {
            used += row.dispensed;
          });
      return (used - test) * (data["diesel-rate"] ? data["diesel-rate"] : 0);
    case "Lubricants":
      if (data["lubricants"])
        data["lubricants"].forEach((row) => {
          used += row.rate * row.litres;
        });
      return used;
    case "Debters Received":
      if (data["debters"])
        data["debters"].forEach((row) => {
          used += Number(row.amount);
        });
      return used;
    default:
      return 0;
  }
}
