import React, { useState } from "react";
import styled from "@emotion/styled";

import { Button2 } from "../../../ui/buttons";
import { GenericField } from "../../../ui/inputs2";

export default ({
  clarification,
  userData,
  updateClarification,
  answering,
}) => {
  const [newAnswer, setNewAnswer] = useState({
    text: "",
    time: new Date(),
    author: userData ? { name: userData.name, email: userData.email } : {},
  });

  return (
    <>
      <FormFlow>
        <GenericField
          label={"Question"}
          data={clarification.question}
          disabled
          multiLine
          style={{
            minWidth: "45%",
          }}
        />
        <GenericField
          label={"Answer"}
          onChange={(e) => {
            const { value } = e.target;
            setNewAnswer((ex) => ({ ...ex, text: value }));
          }}
          data={
            clarification.answer
              ? clarification.answer.text
              : answering
              ? newAnswer.text
              : "Not answered yet"
          }
          disabled={!answering || !!clarification.answer}
          multiLine
          style={{
            minWidth: "45%",
          }}
        />
      </FormFlow>
      {answering && !clarification.answer && (
        <Button2
          label={"Submit Answer"}
          style={{ maxWidth: 190 }}
          onClick={() =>
            updateClarification(clarification.id, {
              ...clarification,
              answer: newAnswer,
            })
          }
        />
      )}
    </>
  );
};

const FormFlow = styled.div`
  display: flex;
`;
