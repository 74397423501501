import { StyleSheet } from "@react-pdf/renderer";

const FULL_DIMS = [600, 780];

const BODY_MARGIN = 10;

const HEADER_HEIGHT = 75;
const HEADER_PAD = 10;

const BODY_WIDTH = FULL_DIMS[0] - BODY_MARGIN * 2;

const LINE_MAX_CHARACTERS = 107;

export const PDFConstants = {
  BODY_WIDTH,
  BODY_MARGIN,
  HEADER_HEIGHT,
  LINE_MAX_CHARACTERS,
};

export const PDFStyles = StyleSheet.create({
  header: {
    height: HEADER_HEIGHT,
    width: FULL_DIMS[0] - HEADER_PAD * 2,
    borderBottom: "1px solid black",
    padding: HEADER_PAD,
    paddingBottom: HEADER_PAD / 2,
  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  headerWrap: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  footer: {
    bottom: 0,
    paddingTop: HEADER_PAD / 2,
    paddingBottom: HEADER_PAD / 2,
    paddingLeft: HEADER_PAD,
    paddingRight: HEADER_PAD,
    width: "100%",
    display: "flex",
    position: "absolute",
    backgroundColor: "#fff",
    minHeight: 18,
  },
  footerText: {
    display: "flex",
    fontSize: 8,
  },
  icon: {
    minWidth: "35px",
    // maxWidth: '30%', // This may look really bad without a maxWidth, but simply upload a better logo
    minHeight: "28px",
    maxHeight: "40px",
    width: "auto",
    height: "35px",
  },
  qrCode: {
    marginLeft: "10px",
  },
  body: {
    width: FULL_DIMS[0] - BODY_MARGIN * 2,
    margin: BODY_MARGIN - 3,
  },
  query: {
    display: "table",
    color: "black",
    border: "1px solid black",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  queryRow: {
    margin: "auto",
    flexDirection: "row",
  },
  queryCol: {
    border: "1px solid black",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  queryCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  section: {},
  sectionTitle: {
    fontSize: 12,
    margin: 4,
    marginLeft: 2,
    // Should bold this
  },
  detailText: {
    fontSize: 12,
  },
  label: {
    fontSize: 8,
    marginBottom: 2,
    marginLeft: 2,
  },
});
