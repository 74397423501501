/**
 *
 * Root level /projects in firebase.
 *
 * Projects are the basic building block, and contain a set of users, schemas, queries, etc.
 */

import { BaseCollection, BaseDocument, BaseData } from "../base";
import { Queries } from "./queries";
import { Schemas } from "./schemas";
import { ProjectUsers } from "./users";
import { Actions } from "./actions";
import { Reports } from "./reports";
import { UserRequests } from "./userrequests";
import { Concurrents } from "./concurrents";
import { Badges } from "./badges";
import { Popups } from "./popups";

import { FormSchemas, FormSchema } from "./schemas/forms";

import { CollectionReference } from "firebase/firestore";

export interface ProjectData extends BaseData {
  name: string;
  creator: string;
}

export class Projects extends BaseCollection<ProjectData> {
  project(id: string): Project {
    return new Project(id, this);
  }

  formSchema(schemaPath: string): FormSchema | undefined {
    // First if the path is undefined, return undefined
    if (!schemaPath) return undefined;
    // Split schemaPath into all but the last path and then the last path
    const preForm = schemaPath.split("/").slice(0, -1).join("/");
    const formId = schemaPath.split("/").slice(-1)[0];
    return new FormSchemas(this.db.collection(preForm)).form(formId);
  }

  forEach(callback: (project: Project) => void): () => void {
    return this.listenForSnapshot((snapshot) => {
      snapshot.docs.forEach((doc) =>
        callback(
          new Project(doc.id, this.db.collection("projects") as Projects)
        )
      );
    });
  }
}

export class Project extends BaseDocument<ProjectData> {
  queries: Queries;
  queriesgraveyard: Queries;
  schemas: Schemas;
  reports: Reports;
  userrequests: UserRequests;
  users: ProjectUsers;
  actions: Actions;
  concurrents: Concurrents;
  defaultBadges: Badges;
  popups: Popups;

  constructor(id: string, projects: Projects) {
    // usually we'd use parent here, but it's too dangerous so we'll use the database directly
    // root level privilege amiright?
    super(projects.doc(id).ref);

    this.queries = new Queries(this.collection("queries"));
    this.schemas = new Schemas(this.collection("schemas"));
    this.reports = new Reports(this.collection("reports"));
    this.userrequests = new UserRequests(this.collection("userrequests"));
    this.users = new ProjectUsers(this.collection("users"));
    this.actions = new Actions(this);
    this.concurrents = new Concurrents(this.collection("concurrents"));
    this.defaultBadges = new Badges(this.collection("badges"));
    this.popups = new Popups(this.collection("popups"));

    // In addition to these normal ones, we also have the deleted queries which carry all the "queries" props, but are segragated
    this.queriesgraveyard = new Queries(this.collection("queriesgraveyard"));
  }
}
