import React, { forwardRef } from "react";
import styled from "styled-components";

import DiffsImage from "../graphics/diffs-image.png";
import Check from "../graphics/check.png";
import Connected from "../graphics/connected.png";
import Pricing from "../graphics/pricing.png";
import Refresh from "../graphics/refresh2.png";
import BottomUp from "../graphics/bottom-up.png";

function Diffs({}, ref) {
  {
    /* Note to self: re-write this in a less repetitive way */
  }
  return (
    <DiffsContainer ref={ref}>
      <MainImage src={DiffsImage} />
      <TextContainer>
        <Title1>Why use Query-It?</Title1>
        <Title2>Differentiators</Title2>
        <Point style={{ marginTop: "5%" }}>
          <PointImage src={Check} />
          <PointText>
            <P1>Technology Partner approach</P1>
            <P2>
              We are not an 'off-the-shelf' digital tool. Every client has
              unique needs and pinch points. Our approach is to understand the
              opportunity and work collaboratively with clients to implement
              use-case specific solutions - our platform is designed keeping
              this in mind
            </P2>
          </PointText>
        </Point>
        <Point>
          <PointImage src={Pricing} />
          <PointText>
            <P1>Value Driven Pricing</P1>
            <P2>
              Pricing options aligned with client specific value propopsitions
            </P2>
          </PointText>
        </Point>
        <Point>
          <PointImage src={Refresh} />
          <PointText>
            <P1>Process First, Tool Second</P1>
            <P2>
              Process should inform the tool and not the other way around.
              Query-It is dynamically scalable to suit client needs
            </P2>
          </PointText>
        </Point>
        <Point>
          <PointImage src={BottomUp} />
          <PointText>
            <P1>Bottom-up Approach</P1>
            <P2>
              We see a largely un-tapped opportunity in the industry for small
              to medium-sized companies in accelerating their digitization
              journeys
            </P2>
          </PointText>
        </Point>
      </TextContainer>
    </DiffsContainer>
  );
}

export default forwardRef(Diffs);

const DiffsContainer = styled.div`
  margin: auto;
  min-height: 1000px;
  width: 85%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1200px) and (min-width: 601px) {
    width: 95%;
  }

  // overlap is intentional
  @media (max-width: 1100px) and (min-width: 601px) {
    min-height: 900px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
const TextContainer = styled.div`
  margin-left: 3%;
  width: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media (max-width: 600px) {
    margin: 0;
    width: 100%;
  }
`;
const Point = styled.div`
  display: flex;
  flex-direction: row;
  height: 15%;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const PointImage = styled.img`
  height: 50px;
  width: 50px;

  @media (max-width: 600px) {
    margin: auto;
    margin-bottom: 2px;
  }
`;
const PointText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const MainImage = styled.img`
  height: 893px;
  width: 620px;
  border-radius: 20px;

  @media (max-width: 1100px) and (min-width: 993px) {
    // scale 80%
    height: 714.4px;
    width: 496px;
  }

  @media (max-width: 992px) and (min-width: 751px) {
    // scale 60%
    height: 535.8px;
    width: 372px;
  }

  @media (max-width: 750px) and (min-width: 601px) {
    // scale 40%;
    height: 357.2px;
    width: 248px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
const Title1 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 300%;
  color: #37227a;

  @media (max-width: 1100px) and (min-width: 601px) {
    font-size: 200%;
  }

  @media (max-width: 600px) and (min-width: 381px) {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }

  @media (max-width: 380px) {
    font-size: 200%;
    text-align: center;
    margin-top: 50px;
  }
`;
const Title2 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 300%;
  color: #37227a;

  @media (max-width: 1100px) and (min-width: 601px) {
    font-size: 200%;
  }

  @media (max-width: 600px) and (min-width: 381px) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 380px) {
    font-size: 200%;
    text-align: center;
  }
`;
const P1 = styled.p`
  margin: 0;
  margin-top: 0.5%;
  font-weight: 900;
  color: #37227a;
  line-height: 25px;
`;
const P2 = styled.p`
  margin: 0;
  margin-top: 0.5%;
  line-height: 20px;
`;
