import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import AdvancedTable from "../../../ui/tableadv";
import { Typography } from "@mui/material";
import { SelectedSchemaContext } from "../..";
import { parse_on_fields } from "../../../../tools/forms";
import { QUERY_METADATA_FIELDS } from "../../../../common/query";

export const TableColumnEditor = ({
  columns,
  data,
  setColumnsPreview,
  setDisableConfirm,
}) => {
  const schemaData = useContext(SelectedSchemaContext);
  const [tableData, setTableData] = useState({ columns: columns });
  const [hasDuplicateHeader, setHasDuplicateHeader] = useState(false);

  const decorateOptions = {
    badge: "badge",
    flag: "flag",
    user: "user",
    dueDate: "due date",
  };
  const sortableOptions = { true: "true", false: "false" };

  const queryFields = useMemo(() => {
    if (!schemaData || !schemaData.fields) {
      return [];
    }
    // Now parse and return
    let currentFields = { ...QUERY_METADATA_FIELDS };
    parse_on_fields(schemaData.fields, (fld) => {
      currentFields[`data.${fld.id}`] = "data." + fld.id;
    });
    return currentFields;
  }, [schemaData]);

  useEffect(() => {
    if (!hasDuplicateHeader) {
      setColumnsPreview(tableData.columns);
    }
  }, [tableData.columns, hasDuplicateHeader, setColumnsPreview]);

  const onChange = useCallback((callback) => {
    setTableData((ex) => ({
      ...ex,
      columns: callback({ body: ex?.columns })?.body,
    }));
  }, []);

  const hasDuplicateHeaderNames = (columns) => {
    const headerNames = {};

    for (let i = 0; i < columns.length; i++) {
      const headerName = columns[i].headerName;

      if (headerName in headerNames) {
        return true;
      } else {
        headerNames[headerName] = true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (hasDuplicateHeaderNames(tableData.columns) !== hasDuplicateHeader) {
      setHasDuplicateHeader((prev) => !prev);
      setDisableConfirm((prev) => !prev);
    }
  }, [tableData.columns]);

  return (
    <TableBackground>
      <AdvancedTable
        key="table-columns"
        label="Table Columns"
        schema={{
          body: {
            columns: [
              { id: "headerName", title: "Header Name", type: "text" },
              {
                id: "index",
                title: "Index",
                type: "select",
                options: queryFields,
              },
              {
                id: "sortable",
                title: "Sortable",
                type: "select",
                options: sortableOptions,
              },
              { id: "width", title: "Width", type: "number" },
              {
                id: "decorate",
                title: "Decorate",
                type: "select",
                options: decorateOptions,
              },
              { id: "format", title: "Format", type: "text" },
            ],
          },
          options: { allowImportExport: false },
        }}
        data={tableData ? { body: tableData.columns } : undefined}
        setChange={onChange}
      />
      {hasDuplicateHeader ? (
        <Typography style={{ color: "red", padding: "5px" }}>
          Warning: Do not use duplicate header names. You will not be able to
          save your changes.
        </Typography>
      ) : null}
    </TableBackground>
  );
};

const TableBackground = styled.div`
  display: inline-block;
  background-color: ${(props) => props.theme.palette.grey[400]};
  border-radius: 4px;
`;
