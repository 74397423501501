import React, { useEffect, useState, useRef } from "react";

import { FormControl } from "@mui/material";
import { SelectField } from "./inputs2";

// Import Countries Data
import COUNTRIES from "../../../countries.json";

export const LocationInput = ({
  data: { country = null, state = null, city = null },
  setLocation,
  disabled,
  contrast,
  ...props
}) => {
  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "row",
        marginTop: "6px",
        alignItems: "flex-end",
        flexWrap: "wrap",
      }}
      size="small"
    >
      <SelectField
        label="Country"
        data={country}
        options={Object.keys(COUNTRIES).reduce(
          (ex, c) => ({ ...ex, [c]: c }),
          {}
        )}
        onChange={(e) => {
          setLocation({ country: e.target.value });
        }}
        autocomplete
        contrast
      />
      <SelectField
        label="State"
        data={state}
        options={
          country
            ? Object.keys(COUNTRIES[country]).reduce(
                (ex, c) => ({ ...ex, [c]: c }),
                {}
              )
            : []
        }
        onChange={(e) => {
          setLocation((prev) => ({
            country: prev.country,
            state: e.target.value,
          }));
        }}
        autocomplete
        contrast
      />
      <SelectField
        label="City"
        data={city}
        options={
          country && state
            ? COUNTRIES[country][state].reduce(
                (ex, c) => ({ ...ex, [c]: c }),
                {}
              )
            : []
        }
        onChange={(e) => {
          setLocation((prev) => ({
            country: prev.country,
            state: prev.state,
            city: e.target.value,
          }));
        }}
        autocomplete
        contrast
      />
    </FormControl>
  );
};
