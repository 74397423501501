import React, { useState } from "react";
import { Typography } from "@mui/material";

import { Button2 } from "../../ui/buttons";

import { MobileInput } from "../../ui/phone";

export default ({ user, nextState }) => {
  const [phone, setPhone] = useState(null);
  const [authError, setAuthError] = useState(undefined);

  const makeSetPhone = () => {
    user
      .update({ phone: phone })
      .then((res) => nextState())
      .catch((err) => setAuthError("Phone could not be set"));
  };

  return (
    <>
      {authError && (
        <Typography style={{ color: "red" }}>{authError}</Typography>
      )}
      {/* <GenericField
      label="Phone"
      contrast
      data={phone}
      onChange={(e) => { const { value } = e.target; setPhone(value); }}
    /> */}
      <MobileInput
        contrast
        data={phone}
        onChange={(e) => {
          const { value } = e.target;
          setPhone(value);
        }}
      />
      <br />
      <Button2 onClick={makeSetPhone} label="Set Phone Number" />
      <Button2 onClick={() => nextState()} label="Skip" />
    </>
  );
};
