import React from "react";
import styled from "styled-components";
import Navbar from "./maincomponents/navbar";
import Footer from "./maincomponents/footer";

export default () => {
  const navHomeExternal = () => (window.location.href = "/?v=home");
  const navAboutExternal = () => (window.location.href = "/?v=about");
  const navWhyExternal = () => (window.location.href = "/?v=why");
  const navUseCasesExternal = () => (window.location.href = "/?v=use-cases");
  const navPricingExternal = () => (window.location.href = "./?v=pricing");

  return (
    <>
      <HomePageContainer>
        <Navbar
          navHome={navHomeExternal}
          navAbout={navAboutExternal}
          navWhy={navWhyExternal}
          navUseCases={navUseCasesExternal}
          navPricing={navPricingExternal}
        />
        <br /> <br /> <br /> <br /> <br />
        <div style={{ width: "90%", margin: "auto" }}>
          <PrivacyFormatted />
        </div>
        <br /> <br />
        <Footer
          navHome={navHomeExternal}
          navAbout={navAboutExternal}
          navWhy={navWhyExternal}
          navUseCases={navUseCasesExternal}
          navPricing={navPricingExternal}
        />
      </HomePageContainer>
    </>
  );
};

export const PrivacyFormatted = () => {
  return (
    <>
      <h2>QUERY-IT INC.</h2>
      <h2>PRIVACY POLICY</h2>
      <p>Effective as of December 21, 2021</p>
      <p>
        Query-It Inc. takes your right to privacy seriously and wants you to
        feel comfortable using our website and software. Query-It Inc., (“us”.
        “we”, or “our”) operates the https://queryitapp.com/ website and the
        Query-It software (the “Service”). This page informs you of our policy
        regarding the collection, use, and disclosure of data when you use our
        Service and the choices you have associated with that data (the “Privacy
        Policy”).
      </p>
      <p>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this Privacy Policy. If you do not agree with this
        Privacy Policy, please do not use Service and do not provide us with any
        personal information or other data.
      </p>
      <ol>
        <li>
          <b>Definitions</b>
          <ol type="a">
            <li>
              <b>Usage Data.</b>&nbsp;Usage Data is data collected automatically
              either generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              <b>Cookies.</b>&nbsp;Cookies are small pieces of data stored on a
              User’s device.
            </li>
            <li>
              <b>Data Controller.</b>&nbsp;Data Controller means a person who
              (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any
              personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data.
            </li>
            <li>
              <b>Data Processor (or Service Providers).</b>&nbsp;Data Processor
              (or Service Provider) means any person (other than an employee of
              the Data Controller) who processes the data on behalf of the Data
              Controller. We may use the services of various Service Providers
              in order to process your data more effectively.
            </li>
            <li>
              <b>Data Subject.</b>&nbsp;Data Subject is any living individual
              who is the subject of Personal Data.
            </li>
            <li>
              <b>User.</b>&nbsp;The User is the individual using our Service.
              The User corresponds to the Data Subject, who is the subject of
              Personal Data.
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b>Changes to this Privacy Policy</b>
          <br />
          We may update our Privacy Policy from time to time. We will notify you
          of changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </li>
        <br />
        <li>
          <b>Information Collection</b>
          <br />
          Below are the categories of data we collect and use about our users
          and how we collect it:
          <br />
          <br />
          <TABLE>
            <thead>
              <TR>
                <TH>
                  <b>Categories of personal data</b>
                </TH>
                <TH>
                  <b>Description of category</b>
                </TH>
              </TR>
            </thead>
            <tbody>
              <TR>
                <TD>User data</TD>
                <TD>
                  This is the data that is provided by you or collected by us
                  when you sign up to use the Service including the website, the
                  SaaS platforms and/or any mobile or tablet application. This
                  may include your username, email address, phone number,
                  employee number and other relevant information required from
                  time to time to use the Service.
                </TD>
              </TR>
              <TR>
                <TD>Usage Data</TD>
                <TD>
                  This is the data that is collected when you are accessing
                  and/or using the Service. This can include:
                  <ul>
                    <li>
                      Information about your interactions with the Service
                    </li>
                    <li>
                      User content you upload to the Service, such as images,
                      documents and/or any other files and all information
                      related to the use case/application
                    </li>
                    <li>
                      Certain technical data, which may include:
                      <br />
                      <ul>
                        <li>URL information;</li>
                        <li>
                          Other identifiers including cookie data and IP
                          addresses;
                        </li>
                        <li>
                          Information about the types of devices you are using
                          to access the Service, such as unique device IDs,
                          network connection type, provider, network and device
                          performance, browser type, language, information
                          enabling digital rights management, operating system,
                          and the Service version;
                        </li>
                        <li>
                          Your non-precise location, which may be derived or
                          inferred from certain technical data (e.g., your IP
                          address, language setting of your device)
                        </li>
                      </ul>
                    </li>
                  </ul>
                </TD>
              </TR>
              <TR>
                <TD>Tracking and Cookies Data</TD>
                <TD>
                  We may review and collect text files downloaded to your device
                  when you visit our website, using our mobile app or the SaaS
                  platforms. Information collected may include information about
                  how visitors use our service, for example, how often our
                  website is visited, what parts of the website are people
                  viewing, and how our services is performing. Cookies also
                  allow us to provide services based on information you provide
                  to us, such as remembering your username, how you have
                  customised our services.
                  <br />
                  You can also control the use of cookies at the individual
                  browser level but choosing to disable cookies may limit your
                  use of certain features or functions on our websites and the
                  Service
                </TD>
              </TR>
              <TR>
                <TD>Payment Data</TD>
                <TD>
                  This may include information such as name, date of birth,
                  credit or debit card type, expiration date, and certain digits
                  of your credit card number, postal code, phone number, and
                  payment history. If applicable and if asked to enter such
                  information.
                </TD>
              </TR>
              <TR>
                <TD>Questionnaires or forms</TD>
                <TD>
                  When you complete questionnaires or forms we collect the data
                  you provide.
                </TD>
              </TR>
              <TR>
                <TD>Partner Information</TD>
                <TD>
                  We use your data to share with your partners which may include
                  your employer or other entities.
                </TD>
              </TR>
            </tbody>
          </TABLE>
        </li>
        <br />
        <li>
          <b>Use of Data</b>
          <br />
          When you use or interact with the Service, we use a variety of
          technologies to process the personal data we collect about you for
          various reasons:
          <ul>
            <li>
              <b>to provide our services:</b>&nbsp;to perform our contract with
              you for the use of our services and to fulfill our obligations
              under applicable terms of use/services; when we have not entered
              into a contract with you, we base the processing of your personal
              data on our legitimate interests to operate and administer our
              services and to provide you with content you access and request,
              for example downloading content from our website.
            </li>
            <li>
              <b>to personalize the Services, we provide:</b>&nbsp;to perform
              our contract with you for the use of our services and to fulfill
              our obligations under applicable terms of use/services, and to
              provide you with the best possible services for your particular
              needs.
            </li>
            <li>
              <b>
                to understand, diagnose, troubleshoot, and fix issues with our
                services:
              </b>
              &nbsp;to perform our contract with you for the use of our services
              and to fulfill our obligations under applicable terms of
              use/services.
            </li>
            <li>
              <b>
                to evaluate and develop new features, technologies, and improve
                the Service:
              </b>
              &nbsp;to perform our contract with you for the use of our services
              and to fulfill our obligations under applicable terms of
              use/services and for our legitimate interests to operate and
              administer our services.
            </li>
            <li>
              <b>
                to comply with legal obligations and law enforcement request:
              </b>
              &nbsp;to cooperate with public and government authorities, court
              or regulators in accordance with our legal obligations under
              applicable laws, to protect against the misuse or abuse of our
              services and to pursue any legal remedies available to us.
            </li>
            <li>
              <b>to fulfill contractual obligations with third parties:</b>
              &nbsp;for our legitimate interests to operate and administer our
              services.
            </li>
            <li>
              <b>to conduct business planning, reporting, and forecasting:</b>
              &nbsp;for legitimate interests and to operate and administer our
              services.
            </li>
            <li>
              <b>to conduct research:</b>&nbsp;for legitimate interests and to
              operate, administer and improve our services, to provide data and
              anonymous data regarding trends and procedures.
            </li>
            <li>
              <b>to process your payment:</b>&nbsp;perform our contract with you
              for the use of our services and to fulfill our obligations under
              applicable terms of use/services and to comply with our legal
              obligations.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>Disclosure of Data</b>
          <br />
          Data collected or generated through your use of the Service may be
          shared with:
          <br />
          <br />
          <TABLE>
            <thead>
              <TR>
                <TH>
                  <b>Who we share it with</b>
                </TH>
                <TH>
                  <b>Reasons for sharing</b>
                </TH>
              </TR>
            </thead>
            <tbody>
              <TR>
                <TD>
                  <b>Service providers</b>
                </TD>
                <TD>
                  We work with service providers that work on our behalf which
                  may need access to certain data in order to provide their
                  services to us. These companies include those we’ve hired to
                  provide customer service support, operate technical
                  infrastructure, assist in protecting and securing our systems
                  and services, and to help market Query-It’s products and
                  services.
                </TD>
              </TR>
              <TR>
                <TD>
                  <b>Payment processors</b>
                </TD>
                <TD>
                  We will share your data with our payment processors as
                  necessary to enable them to process your payments, and for
                  anti-fraud purposes.
                </TD>
              </TR>
              <TR>
                <TD>
                  <b>Our Customer and License Holder</b>
                </TD>
                <TD>
                  We may share information regarding your activities, actions,
                  location and other data that you upload with the master
                  license holder who has purchased a subscription and who has
                  provided you with access or identified you as a necessary
                  permitted user.
                </TD>
              </TR>
              <TR>
                <TD>
                  <b>Third Parties</b>
                </TD>
                <TD>
                  We may aggregate data (which may include your data) and sell
                  the aggregated data to government, university, research
                  organizations and or corporations for the purposes of
                  identifying trends, tendencies, research, statistics and other
                  information. Such data however will not include personal data
                  which could identify you specifically.
                </TD>
              </TR>
              <TR>
                <TD>
                  <b>Law enforcement and data protection authorities</b>
                </TD>
                <TD>
                  We share your data in order to comply with legal obligation
                  under applicable law, or respond to valid legal process, such
                  as a search warrant, a court order, or a subpoena.
                </TD>
              </TR>
              <TR>
                <TD>
                  <b>Purchasers of our business</b>
                </TD>
                <TD>
                  We will share your data in those cases when we sell or
                  negotiate to sell our business to a buyer or prospective
                  buyer. In this situation, Query-It will continue to ensure the
                  confidentiality of your data and give you notice before your
                  data is transferred to the buyer or becomes subject to a
                  different privacy policy.
                </TD>
              </TR>
            </tbody>
          </TABLE>
        </li>
        <br />
        <li>
          <b>Retention and Deletion of Data</b>
          <br />
          We keep your data only as long as necessary to provide you with the
          Service and for legitimate and essential business purposes, such as
          maintaining the performance, making data-driven business decisions
          about new features and offerings, complying with our legal
          obligations, and resolving disputes.
        </li>
        <br />
        <li>
          <b>Links to Other Sites</b>
          <br />
          We may display advertisements from third parties and other content
          that links to third-party websites. We cannot control or be held
          responsible for third parties’ privacy practices and content. If you
          click on a third-party advertisement or link, you are leaving the the
          Service and any data you provide will not be covered by this Privacy
          Policy. Please read their privacy policies to find out how they
          collect and process your data.
        </li>
        <br />
        <li>
          <b>Security of Data</b>
          <br />
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your data, we cannot guarantee its absolute security.
        </li>
        <br />
        <li>
          <b>Contact Us</b>
          <br />
          If you have any questions about this Privacy Policy, please contact us
          by email at:{" "}
          <a href="mailto:info@queryitapp.com">info@queryitapp.com</a>
        </li>
      </ol>
    </>
  );
};

const HomePageContainer = styled.div`
  overflow-x: hidden;
  scroll-padding-top: 106px;
  min-height: 100%;
  width: 100%;
  display: inline-block;
  overflow: auto;
  font-family: Red Hat Display;
  background-color: white;

  @media (max-width: 1100px) {
    scroll-padding-top: 87px;
  }
`;
const TABLE = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
`;
const TR = styled.tr`
  border: 1px solid black;
  border-collapse: collapse;
`;
const TD = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
`;
const TH = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
`;
