import { BaseCollection, BaseDocument, BaseData } from "../../base";
import { Project } from "..";

export interface ReportData extends BaseData {
  name: string;
  users: Array<string>;
  // Add other fields maybe?
}

export class Reports extends BaseCollection<ReportData> {
  report(id: string) {
    return this.doc(id) as Report;
  }
}

type Report = BaseDocument<ReportData>;