import React, { useState } from "react";

import {
  Modal,
  DialogContent,
  Button,
  MobileStepper,
  Box,
  IconButton,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import ContentResolver from "../../ui/popups/";
import { ModalPrompt, ModalContent, PopupModal } from "../../ui/containers";

export default ({ popups, user }) => {
  // redundant, but just in case
  if (!popups || popups.length === 0) return null;

  const [open, setOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const closeViewAll = async () => {
    await user.update({ popups: [] });
    setOpen(false);
  };
  const closeViewSingle = async () => {
    setOpen(false);
    const newPopups = popups.filter((_, i) => i !== activeStep);
    await user.update({ popups: newPopups });
  };

  return (
    <Modal open={open} onClose={closeViewSingle}>
      <DialogContent>
        <PopupModal>
          <ModalContent>
            <PopupCarousel
              popups={popups}
              closeViewAll={closeViewAll}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              closeViewSingle={closeViewSingle}
            />
          </ModalContent>
        </PopupModal>
      </DialogContent>
    </Modal>
  );
};

const PopupCarousel = ({
  popups,
  closeViewAll,
  activeStep,
  setActiveStep,
  closeViewSingle,
}) => {
  const theme = useTheme();
  const maxSteps = popups.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box justifyContent="center">
      {activeStep < maxSteps - 1 && (
        <IconButton
          sx={{ position: "absolute", top: 10, right: 10, color: theme.palette.primary.main }}
          onClick={closeViewSingle}
        >
          <Close />
        </IconButton>
      )}
      <Box sx={{ height: "500px", overflowY: "auto", overflowX: "hidden" }}>
        <ContentResolver popup={popups[activeStep]} />
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep === maxSteps - 1 ? (
            <Button size="medium" onClick={closeViewAll}>
              Close
              <Close />
            </Button>
          ) : (
            <Button
              size="medium"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          )
        }
        backButton={
          <Button
            size="medium"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};
