import React, { useContext } from "react";

import { useClarifications } from "../../../../hooks/forms";

import { QueryContent, QuerySectionHeader } from "../query";
import { ClarificationList } from "../clarification/index";
import { UserDataContext } from "../../../../App";

export default ({ queryData, projectId }) => {
  const [clarificationsData, newClarification, updateClarification] =
    useClarifications(projectId, queryData.id);

  const userData = useContext(UserDataContext);

  return (
    <QueryContent>
      <QuerySectionHeader>Clarifications</QuerySectionHeader>
      <ClarificationList
        clarifications={clarificationsData}
        answering={true}
        updateClarification={updateClarification}
        userData={userData}
      />
    </QueryContent>
  );
};
