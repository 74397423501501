import React from "react";
import { Page, View, Text, Image } from "@react-pdf/renderer";

import { PrintableField } from "../fields";
import { useUserLookup } from "../../../../../hooks/users";

import { PDFConstants, PDFStyles } from "../style";
import PrintQueryHeader from "../../../../print/header";

export default ({ schemaData, queryData, projectLogo }) => {
  const [author, authorRef] = useUserLookup(
    queryData ? queryData.creator : undefined
  );

  const visibleFirstRowFields =
    (schemaData.type === "response" ? 1 : 0) +
    !(schemaData.settings?.enableDueDate === false);

  return (
    <Page>
      <PrintQueryHeader
        projectLogo={projectLogo}
        author={author}
        title={schemaData.name}
        id={queryData ? queryData.dynamicId ?? queryData.id : undefined}
        typeTitle="Settings for"
      />
      {/* This is where the body of the approvals start */}
      <View style={PDFStyles.body}>
        <View style={PDFStyles.query}>
          <View style={PDFStyles.queryRow}>
            {schemaData.type === "response" && (
              <View
                style={[
                  PDFStyles.queryCol,
                  { width: PDFConstants.BODY_WIDTH / visibleFirstRowFields },
                ]}
              >
                <PrintableField
                  field={{ type: "userlist", name: "Assignee", id: 1 }}
                  fieldData={queryData.assignedUser}
                  data={queryData}
                  width={PDFConstants.BODY_WIDTH / visibleFirstRowFields}
                />
              </View>
            )}
            {!(schemaData.settings?.enableDueDate === false) && (
              <View
                style={[
                  PDFStyles.queryCol,
                  { width: PDFConstants.BODY_WIDTH / visibleFirstRowFields },
                ]}
              >
                <PrintableField
                  field={{ type: "date", name: "Due Date", id: 3 }}
                  fieldData={queryData.dueDate}
                  data={queryData}
                  width={PDFConstants.BODY_WIDTH / visibleFirstRowFields}
                />
              </View>
            )}
          </View>
          {!(schemaData.settings?.useDistributionList === false) && (
            <View style={PDFStyles.queryRow}>
              <View
                style={[PDFStyles.queryCol, { width: PDFConstants.BODY_WIDTH }]}
              >
                <PrintableField
                  field={{ type: "userlist", name: "Distribution List", id: 2 }}
                  fieldData={queryData.distribution}
                  data={queryData}
                  width={PDFConstants.BODY_WIDTH}
                />
              </View>
            </View>
          )}
        </View>
      </View>
      <View style={PDFStyles.footer} fixed>
        <Text style={PDFStyles.footerText}>
          This form was generated by Query-It on{" "}
          {`${
            String(new Date().getDate()).padStart(2, "0") +
            "/" +
            String(new Date().getMonth() + 1).padStart(2, "0") +
            "/" +
            String(new Date().getFullYear())
          }`}
        </Text>
        {/* There's a bottom right place here for another logo */}
      </View>
    </Page>
  );
};
