import React, { useState, useMemo } from "react";

import { Box, Tooltip, Typography } from "@mui/material";
import { CheckCircleOutline, CancelOutlined } from "@mui/icons-material";

import { Button2 } from "../../../ui/buttons";
import { InteractiveTable } from "../../../ui/table";
import { QuerySection, QuerySectionHeader } from "../query";

import { get_field_by_id } from "../../../../tools/forms";

import q, { APIStorage } from "@queryit/api";

export default ({ queryData, schemaData }) => {
  // Lets find every field with a revisions property
  const RevisionFields = useMemo(() => {
    return Object.keys(queryData.data).reduce((existing, key) => {
      // Regular case
      if (queryData.data[key]?.revisions) {
        existing.push({
          id: key,
          field: get_field_by_id(schemaData.fields, key),
          revisions: queryData.data[key]?.revisions,
        });
      }
      // Search through tables
      if (Array.isArray(queryData.data[key])) {
        queryData.data[key].forEach((item) => {
          Object.keys(item).forEach((nestedKey) => {
            if (item[nestedKey]?.revisions) {
              existing.push({
                id: key,
                rowIndex: nestedKey,
                field: get_field_by_id(schemaData.fields, key).columns[
                  nestedKey
                ],
                revisions: item[nestedKey].revisions,
              });
            }
          });
        });
      }
      return existing;
    }, []);
  }, [queryData, schemaData]);

  const downloadFiles = async (row) => {
    if (row?.data) {
      const downloadPromises = row.data.map((file) => {
        return APIStorage.getDownloadURL(q.storage.ref(file.location)).then(
          async (firebaseUrl) => {
            // fetch the file data
            const response = await fetch(firebaseUrl);
            const blob = await response.blob();

            // Create blob link to download
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          }
        );
      });

      await Promise.all(downloadPromises);
    }
  };

  return (
    <QuerySection style={{ height: "100%" }}>
      <QuerySectionHeader>
        <Typography sx={{ paddingLeft: "10px" }}>
          File Field Revisions
        </Typography>
      </QuerySectionHeader>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "30px",
          overflowY: "auto",
        }}
      >
        {RevisionFields && RevisionFields.length > 0 ? (
          RevisionFields.map((field) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px",
                }}
                key={field.id}
              >
                <Typography>
                  {field.field?.name ?? "Unnamed File Field"}
                </Typography>
                <Box sx={{ marginBottom: "8px", width: "200px" }}>
                  <Button2
                    label={`Download Latest (${
                      field.revisions?.slice(-1)[0].data?.length
                    })`}
                    onClick={() => {
                      downloadFiles(field.revisions?.slice(-1)[0] ?? []);
                    }}
                  />
                </Box>
                <InteractiveTable
                  data={field.revisions}
                  columns={revisionsColumns(downloadFiles)}
                />
              </Box>
            );
          })
        ) : (
          <Typography sx={{ paddingLeft: "10px" }}>
            There are no revisions yet for file fields.{" "}
          </Typography>
        )}
      </Box>
    </QuerySection>
  );
};

const revisionsColumns = (downloadFiles) => [
  { name: "Version", index: "version" },
  {
    name: "Files",
    format: "return data.data.map((file) => {return file.name}).join(', ')",
  },
  {
    name: "Changed",
    format:
      "return (new Date(data.data.slice(-1)[0]?.uploadedAt * 1000)).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })",
  },
  {
    name: "Virus",
    component: (row) => {
      const allScanned = row.data.every((e) => e.virusScanned);
      return (
        <Tooltip title={allScanned ? "Scan Complete" : "Scan Incomplete"}>
          {allScanned ? (
            <CheckCircleOutline sx={{ color: "green" }} />
          ) : (
            <CancelOutlined sx={{ color: "red" }} />
          )}
        </Tooltip>
      );
    },
  },
  {
    name: "Download All",
    component: (row) => (
      <Button2
        label={`Download (${row.data.length})`}
        onClick={() => {
          downloadFiles(row);
        }}
      />
    ),
  },
];
