import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { CircularProgress, Typography } from "@mui/material";
import { GoogleSSO, MicrosoftSSO } from "../../ui/auth";

import { GenericField } from "../../ui/inputs2";
import { Button2 } from "../../ui/buttons";

import q, { APIAuthentication } from "@queryit/api";

export default ({ token, setAwaitingLogin, mobileCode, ssoRejected }) => {
  const [authEmail, setAuthEmail] = useState(null);
  const [authError, setAuthError] = useState(undefined);

  // We only want this to run on first load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAwaitingLogin(true), []);

  const handleLoginAttempt = useCallback(() => {
    q.auth.getAuth().setPersistence(q.PERSISTENCE.session);
    try {
      q.auth
        .signInWithEmailAndPassword(authEmail, token)
        .catch((err) =>
          setAuthError(
            "The Email provided does not match the one invited by this invitation"
          )
        );
    } catch (err) {
      setAuthError("No Email Provided");
    }
  }, [authEmail, token]);

  // Add hotkey
  // useHotkey('Enter', () => handleLoginAttempt());

  // Add a handler for auto-attempting login once mobileCode email is formed
  useEffect(() => {
    if (mobileCode) {
      if (authEmail === null) {
        setAuthEmail(APIAuthentication.getMobileOnlyEmail(mobileCode));
      } else {
        // Time to login
        handleLoginAttempt();
      }
    }
  }, [mobileCode, authEmail, handleLoginAttempt]);

  return (
    <>
      {!token && (
        <>
          {!ssoRejected && (
            <>
              <Typography style={{ marginBottom: 3 }}>
                Your organization enforces Single-Sign-On (SSO) for its users.
                Please select your SSO provider from the selection below to
                verify your identity.
              </Typography>
              {authError && (
                <Typography style={{ color: "red" }}>{authError}</Typography>
              )}
              <AuthHolder>
                <AuthContainer>
                  <GoogleSSO setError={(e) => setAuthError(e.message)} />
                </AuthContainer>
                <AuthContainer>
                  <MicrosoftSSO setError={(e) => setAuthError(e.message)} />
                </AuthContainer>
              </AuthHolder>
            </>
          )}
          {ssoRejected && (
            <>
              <Typography style={{ marginBottom: 3 }}>
                The Single-Sign-On (SSO) authentication that you provided does
                not match any records in our system. Please try a different SSO
                Provider, attempt to sign-in using a different set of
                credentials, or contact your organization's administrator should
                you require further assistance.
              </Typography>
              <Button2 label="OK" onClick={() => q.auth.signOut()} />
            </>
          )}
        </>
      )}
      {token && !mobileCode && (
        <>
          <Typography style={{ marginBottom: 3 }}>
            Please confirm your email address
          </Typography>
          {authError && (
            <Typography style={{ color: "red" }}>{authError}</Typography>
          )}
          <GenericField
            label="Email"
            data={authEmail}
            contrast
            style={{ width: "260px" }}
            onChange={(e) => {
              const { value } = e.target;
              setAuthEmail(value);
            }}
          />
          <br />
          <Button2 onClick={handleLoginAttempt} label="Verify" />
        </>
      )}
      {token && mobileCode && (
        <>
          <Typography style={{ marginBottom: 3 }}>
            Your identity is being confirmed automatically, please wait...
          </Typography>
          <CircularProgress />
        </>
      )}
    </>
  );
};

const AuthHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AuthContainer = styled.div`
  width: 220px;
  margin-right: 20px;
  height: 45px;
`;
