import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import {
  Notifications,
  MoreVert,
  Description,
  Search,
  ExitToApp,
} from "@mui/icons-material";
import {
  IconButton,
  Badge,
  Tooltip,
  Modal,
  DialogContent,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ModalPrompt, ModalContent, ModalFooter } from "../ui/containers";

import Link from "../ui/link";
import { PhotoPreview } from "../account";
import {
  MobileDropdown,
  MobileDropdownRowLink,
  MobileDropdownRowButton,
} from "../ui/mobile";
import { useUserPermissions } from "../../hooks/users";
import { useSnackbar } from "../../hooks/system";

import q from "@queryit/api";
import ViewModal from "../admin/project/popups/view";

export default ({
  userData,
  user,
  photoLink,
  setProjectSelect,
  hideSearch,
  setSearchActive,
  unread = 0,
}) => {
  const [showUserDiv, toggleUserDiv] = useState(false);
  const [logoutModal, toggleLogoutModal] = useState(false);
  const [menu, setMenu] = useState(null);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const permissions = useUserPermissions(user);
  const { callSnackbar } = useSnackbar();

  // If we change the location, hide the user div (for all those juicy links)
  const location = useLocation();

  const navigate = useNavigate();

  const open = Boolean(menu);
  const handleClick = (e) => {
    setMenu(e.currentTarget);
  };
  const handleClose = () => {
    setMenu(null);
  };

  useEffect(() => {
    toggleUserDiv(false);
  }, [location]);

  if (!userData || Object.keys(userData).length < 2) {
    return null;
  }

  const FUN_COLOR_SET = [
    "#D32F2F",
    "#FF4081",
    "#303F9F",
    "#448AFF",
    "#388E3C",
    "#8BC34A",
    "#E64A19",
    "#FF9800",
    "#0288D1",
    "#00BCD4",
    "#AFB42B",
    "#00796B",
    // "#7C4DFF",
  ];
  const nameHash = (userData.name.first + userData.name.last)
    .split("")
    .reduce((acc, c) => acc + c.charCodeAt(0), 0);

  return (
    <NavSection>
      <ViewModal
        open={openFeedbackModal}
        closeView={(submitted) => {
          if (submitted === true) {
            callSnackbar("Feedback submitted, thank you.");
          }
          setOpenFeedbackModal(false);
        }}
        data={{ ...userData, type: "feedback" }}
      />
      <Modal
        open={logoutModal}
        onClose={() => {
          toggleLogoutModal(false);
        }}
      >
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>Logout?</h2>
              <p>Are you sure you would like to logout?</p>
            </ModalContent>
            <ModalFooter>
              <Button onClick={() => toggleLogoutModal(false)}>Cancel</Button>
              <Button onClick={() => q.auth.signOut()}>Logout</Button>
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      {!hideSearch && (
        <Tooltip title="Search">
          <IconButton onClick={() => setSearchActive(true)}>
            <Search />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Action Items">
        <IconButton>
          <Link to="/app/actions">
            <Badge
              color="secondary"
              variant="dot"
              badgeContent={0}
              overlap="circular"
            >
              <Description />
            </Badge>
          </Link>
        </IconButton>
      </Tooltip>
      <Tooltip title="Notifications">
        <IconButton>
          {/* NEED TO CHANGE BADGE CONTENT TO DISPLAY LENGTH OF NOTIFICATIONS COLLECTION ON USER */}
          <Link to="/app/notifications">
            <Badge
              color="secondary"
              variant="dot"
              badgeContent={unread}
              overlap="circular"
            >
              <Notifications />
            </Badge>
          </Link>
        </IconButton>
      </Tooltip>
      {/* This is a Mobile-only selector for projects */}
      {isMobile && (
        <IconButton onClick={() => setProjectSelect(true)}>
          <MoreVert />
        </IconButton>
      )}
      <UserCircle
        color={FUN_COLOR_SET[nameHash % FUN_COLOR_SET.length]}
        onClick={handleClick}
      >
        {userData.profilePhoto && <PhotoPreview src={photoLink} />}
        {!userData.profilePhoto && (
          <>
            {" "}
            {userData.name.first[0]}
            {userData.name.last[0]}{" "}
          </>
        )}
      </UserCircle>
      {!isMobile && (
        <UserNameDiv onClick={handleClick}>
          {userData.name.first} {userData.name.last}
        </UserNameDiv>
      )}
      <Menu
        id="user-menu"
        anchorEl={menu}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          divider
          sx={{ borderBottom: "1px solid grey" }}
          style={{ pointerEvents: "none" }}
        >
          <UserInfoDiv>
            {userData.name.first} {userData.name.last}
          </UserInfoDiv>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/app/support", { replace: true });
          }}
        >
          Get Help
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/app/account", { replace: true });
          }}
        >
          Account Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/app/badges", { replace: true });
          }}
        >
          Badges
        </MenuItem>

        {permissions && permissions.admin && (
          <MenuItem
            to="/app/admin/manage"
            onClick={() => {
              handleClose();
              navigate("/app/admin/manage", { replace: true });
            }}
          >
            Administration{" "}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setOpenFeedbackModal(true);
          }}
        >
          Give Feedback
        </MenuItem>
      </Menu>
      {showUserDiv && isMobile && (
        <MobileDropdown
          title={`${userData.name.first} ${userData.name.last}`}
          close={() => toggleUserDiv(false)}
        >
          <MobileDropdownRowLink to="/app/support" title="Get Help" />
          <MobileDropdownRowLink to="/app/account" title="Account Settings" />
          <MobileDropdownRowButton
            onClick={() => q.auth.signOut()}
            title="Logout"
          />
          {permissions && permissions.admin && (
            <MobileDropdownRowLink
              to="/app/admin/manage"
              title="Administration"
            />
          )}
        </MobileDropdown>
      )}
      {!isMobile && (
        <Tooltip title="Logout">
          <UserLogout onClick={() => toggleLogoutModal(true)}>
            {/* <UserLogoutIcon className="material-icons">logout</UserLogoutIcon> */}
            <ExitToApp />
          </UserLogout>
        </Tooltip>
      )}
    </NavSection>
  );
};

const NavSection = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: ${(props) => props.theme.textHeading};
  font-family: ${(props) => props.theme.font};

  ${isMobile &&
  css`
    justify-content: flex-end;
    overflow: auto;
  `}
`;

const NavLinkMini = styled(Link)`
  flex-shrink: 0;

  margin-top: 10px;
  font-size: 14px;

  display: flex;
  color: ${(props) => props.theme.textHeading};
  justify-content: center;
  align-items: center;

  font-family: ${(props) => props.theme.font};
  box-shadow: ${(props) =>
    props.selected ? `inset 0px -5px 0 ${props.theme.titleSub}` : ""};
`;

const FauxNavLinkMini = styled.div`
  flex-shrink: 0;

  margin-top: 10px;
  font-size: 14px;

  display: flex;
  color: ${(props) => props.theme.textHeading};
  justify-content: center;
  align-items: center;

  font-family: ${(props) => props.theme.font};
  box-shadow: ${(props) =>
    props.selected ? `inset 0px -5px 0 ${props.theme.titleSub}` : ""};
`;

const UserCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 40px;
  overflow: hidden;

  background: ${(props) => props.color};
  user-select: none;
  font-family: ${(props) => props.theme.font};

  &:hover {
    cursor: pointer;
  }
`;

const UserDiv = styled.div`
  position: absolute;
  top: ${isMobile ? "120px" : "54px"};
  right: 44px;
  width: 200px;
  height: ${(props) => (props.admin ? "130" : "100")}px;
  padding: 5px;
  z-index: 100;
  background: ${(props) => props.theme.step50};
  color: ${(props) => props.theme.textHeading};
  font-family: ${(props) => props.theme.font};

  -moz-border-radius: 5px; /* this works only in camino/firefox */
  -webkit-border-radius: 5px; /* this is just for Safari */

  -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${(props) => props.theme.step50};

    right: 20px;
    top: -8px;
  }
`;

const UserInfoDiv = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 5px;
  z-index: 100;
`;

const UserClickHide = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;

  cursor: default;
`;

const UserNameDiv = styled.div`
  margin: 6px;
`;

const UserInfoPanel = styled.div``;

const UserLogout = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserLogoutIcon = styled.div`
  color: ${(props) => props.theme.textHeading};
`;
