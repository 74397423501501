import React, { useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Button2 } from "../../ui/buttons";
import { BooleanField } from "../../ui/inputs2";
import { panelTheme } from "../../../themes/mui_theme_panels";
import { useTheme } from "@mui/material/styles";
import { useHotkey } from "../../../hooks/system";

import CSSTransition from "react-transition-group/CSSTransition";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ThemeProvider,
} from "@mui/material";
import { UsersSelectInput } from "../query/fields/queryfields";
import DateFilterField from "./datefilter";
import { ProjectReferenceContext, UserPermissionsContext } from "..";
import { log_error } from "../../../tools/logger";
import { UserContext } from "../../../App";

import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import { ArrowDropUp } from "@mui/icons-material";

const LOGO_URL =
  "https://firebasestorage.googleapis.com/v0/b/queryit-30aea.appspot.com/o/logo.png?alt=media&token=5dbb2c7d-9d8d-43f8-94bd-ae23dc7c27a3";

// Add filtering provision that undefined columns pass

const FilterSelector = ({ setOpen, filterManager }) => {
  const [anim, setAnim] = useState(false);

  const userPerms = useContext(UserPermissionsContext);
  const project = useContext(ProjectReferenceContext);
  const user = useContext(UserContext);

  // For the Save button
  const [buttonOpen, setButtonOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setButtonOpen((prevOpen) => !prevOpen);
  };

  const handleButtonClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setButtonOpen(false);
  };

  // Run animation on render
  useEffect(() => setAnim(true), []);

  // Filter state info
  const filterScheme = filterManager.useFilterScheme();
  const filterState = filterManager.useFilterState();

  // Add hotkey
  useHotkey("Escape", () => setAnim(false));

  // Also prep a clean function for saving defaults
  const saveDefaultFilters = () => {
    // Here we actually know we have a prefilter in filter state with schemaId, so we can just use that
    const schemaId = filterState.preFilters?.schemaId?.value?.[0];
    if (schemaId === undefined) {
      log_error("No schemaId found in filter state");
      return;
    }
    // Now we have our schemaId, and projectref from context, we can save the filter state
    project.schemas
      .schema(schemaId)
      .update({
        defaultFilters: Object.values(filterState.dataFields).filter(
          (obj) => obj.target !== "overdue"
        ),
      }) // DEFECT: This should clear on empty array but doesn't
      .then(() => {
        setAnim(false);
      });
  };

  const saveCustomFilters = () => {
    // Same initial process as saveDefaultFilters
    const schemaId = filterState.preFilters?.schemaId?.value?.[0];
    if (schemaId === undefined) {
      log_error("No schemaId found in filter state");
      return;
    }
    // Now we have our schemaId, but we save the filter state under the logged-in user's doc under schemas' user collection
    project.schemas
      .schema(schemaId)
      .users.user(user.ref.id)
      .set(
        {
          customFilters: Object.values(filterState.dataFields).filter(
            (obj) => obj.target !== "overdue"
          ),
        },
        { merge: true }
      )
      .then(() => {
        setAnim(false);
      });
  };

  const removeCustomFilters = () => {
    const schemaId = filterState.preFilters?.schemaId?.value?.[0];
    if (schemaId === undefined) {
      log_error("No schemaId found in filter state");
      return;
    }
    project.schemas
      .schema(schemaId)
      .users.user(user.ref.id)
      .set(
        {
          customFilters: null,
        },
        { merge: true }
      )
      .then(() => {
        setAnim(false);
      });
  };

  return (
    <ThemeProvider theme={panelTheme(useTheme())}>
      <FilterDiv>
        <BgBlur
          onClick={() => {
            setAnim(false);
          }}
        />
        <CSSTransition
          in={anim}
          timeout={200}
          classNames="filters"
          onExited={() => setOpen(false)}
          unmountOnExit
        >
          <FilterContainer>
            <BrandLogo src={LOGO_URL} />
            <FilterSpace>
              <FilterPaneSection
                filterManager={filterManager}
                schemeFields={filterScheme.filter(
                  (fld) => fld.category === "General"
                )}
                filterState={filterState}
                category="General"
                accordian={false}
              />
              {[...new Set(filterScheme.map((fld) => fld.category))]
                .filter((fl) => fl !== "General")
                .map((category) => (
                  <FilterPaneSection
                    key={`FilterSection_${category.replaceAll(" ", "_")}`}
                    filterManager={filterManager}
                    schemeFields={filterScheme.filter(
                      (fld) => fld.category === category
                    )}
                    filterState={filterState}
                    category={category}
                  />
                ))}
            </FilterSpace>
            <FilterButtons>
              <Button2
                label="OK"
                onClick={() => {
                  setAnim(false);
                }}
                size="small"
              />
              <Button2
                label="Clear"
                onClick={() => {
                  filterManager.clearDataFilters();
                }}
                size="small"
              />
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button
                  style={{ padding: "4px 10px" }}
                  onClick={saveCustomFilters}
                >
                  Save Personal Filters
                </Button>
                <Button
                  size="small"
                  aria-controls={buttonOpen ? "split-button-menu" : undefined}
                  aria-expanded={buttonOpen ? "true" : undefined}
                  aria-label="select save option"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropUp style={{ color: "#fff" }} />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={buttonOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="top-end"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleButtonClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {userPerms?.canEditSchemaFilters && (
                            <MenuItem
                              key="save-admin-defaults"
                              onClick={saveDefaultFilters}
                            >
                              Save Admin Filters
                            </MenuItem>
                          )}
                          <MenuItem
                            key="remove-custom-filters"
                            onClick={removeCustomFilters}
                          >
                            Clear Personal Filters
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </FilterButtons>
          </FilterContainer>
        </CSSTransition>
      </FilterDiv>
    </ThemeProvider>
  );
};

export default FilterSelector;

const FilterPaneSection = ({
  filterManager,
  schemeFields,
  filterState,
  category,
  accordian = true,
}) => {
  return (
    <Filter>
      <FilterHeader>{category}</FilterHeader>
      <FilterContent>
        {schemeFields.map((field) => {
          let internalComponent = null;
          // Evaluate and return if date, otherwise resolve options
          switch (field.type) {
            case "date":
              internalComponent = (
                <DateFilterField
                  data={filterState.dataFields[field.index]?.value ?? "all"}
                  onChange={(value) => {
                    filterManager.setFilterProperty(field, value);
                  }}
                  label={field.name}
                  allowCustom
                />
              );
              break;
            case "select":
              internalComponent = Object.keys(field.options ?? {}).map(
                (optionKey) => {
                  return (
                    <FilterOption
                      key={`${category.replaceAll(" ", "_")}_${
                        field.index
                      }_${optionKey}`}
                    >
                      <BooleanField
                        data={
                          filterState.dataFields[field.index]?.value?.includes(
                            optionKey
                          ) ?? false
                        }
                        onChange={(e) => {
                          filterManager.setFilterProperty(field, optionKey);
                        }}
                        label={field.options[optionKey]}
                        boxColor="primary"
                        checkbox
                        size="slim"
                      />
                    </FilterOption>
                  );
                }
              );
              break;
            case "user":
              internalComponent = (
                <UsersSelectInput
                  data={filterState.dataFields[field.index]?.value}
                  onChange={(e) => {
                    if (
                      !(e.target.value.length === 1 && e.target.value[0] === "")
                    ) {
                      filterManager.setFilterProperty(field, e.target.value);
                    } else {
                      filterManager.setFilterProperty(field, []);
                    }
                  }}
                  label={field.name}
                  editable
                  multi
                />
              );
              break;
            case "boolean":
              internalComponent = (
                <BooleanField
                  data={Object.keys(filterState.dataFields).includes(
                    field.index
                  )}
                  onChange={(e) => {
                    filterManager.setFilterProperty(
                      field,
                      !filterState.dataFields[field.index]
                    );
                  }}
                  label={field.name}
                  boxColor="primary"
                  checkbox
                  size="slim"
                />
              );
              break;
            default:
              break;
          }

          // Now publish
          if (accordian) {
            return (
              <Accordion
                style={{ width: "100%", color: "black" }}
                key={`${category.replaceAll(" ", "_")}_${field.index}`}
                defaultExpanded={Object.hasOwn(
                  filterState.dataFields,
                  field.index
                )}
              >
                <AccordionSummary>
                  <Typography style={{ color: "black" }}>
                    {field.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexWrap: "wrap" }}>
                  {internalComponent}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return (
              <FilterField
                key={`${category.replaceAll(" ", "_")}_${field.index}`}
              >
                <FilterName style={{ fontSize: 16 }}>{field.name}</FilterName>
                {internalComponent}
              </FilterField>
            );
          }
        })}
      </FilterContent>
    </Filter>
  );
};

const FilterDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const FilterContainer = styled.div`
  position: fixed;

  height: 100%;
  width: ${isMobile ? "100%" : "461px"};
  background: white;

  right: 0;
  top: 0;

  overflow: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  z-index: 1200;

  // ANIMATIONS
  &.filters-enter {
    opacity: 0;
    transform: translateX(32px);
  }
  &.filters-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity 0.3s, transform 0.3s;
  }
  &.filters-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  &.filters-exit-active {
    opacity: 0;
    transform: translateX(32px);
    transition: opacity 0.3s, transform 0.3s;
  }
`;

const FilterSpace = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const BrandLogo = styled.img`
  height: 50px;
  object-fit: contain;
  margin-top: 12px;
`;

const Filter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterHeader = styled.div`
  width: 100%;
  height: 45px;

  display: flex;

  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px 0px rgba(20, 20, 20, 0.1);

  color: black;
  font-size: 20px;
  font-family: ${(props) => props.theme.font};
  font-weight: 500;
`;

const FilterContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  margin: 10px;
`;

const FilterField = styled.div`
  width: 48%;
  margin-right: 2%;
  font-family: ${(props) => props.theme.font};
`;

const FilterName = styled.div`
  font-weight: bold;

  font-size: 18px;
`;

const FilterOption = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0;
  align-items: center;
`;

const FilterButtons = styled.div`
  width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  overflow: auto;
`;

const BgBlur = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 4;
  border: none;
  &:focus {
    outline: none;
  }

  background: rgba(20, 20, 20, 0.7);
`;
