import { Query } from ".";
import { BaseCollection, BaseDocument, BaseData } from "../../base";

export interface ClarificationData extends BaseData {
  askedTime: any, // timestamp
  subject: string,
  question: string,
  answer?: string
}

export class Clarifications extends BaseCollection<ClarificationData> {
  clarification(id: string) {
    return this.doc(id) as Clarification;
  }
}

type Clarification = BaseDocument<ClarificationData>;