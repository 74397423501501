import React from "react";
import styled from "styled-components";

// images
import LandingBackground from "../graphics/landing-background.png";
import Worldwide from "../graphics/worldwide.png";
import Nodes from "../graphics/nodes.png";
import Macbook from "../graphics/Macbook-QueryIt.png";

import { forwardRef } from "react";

function Hero({}, ref) {
  return (
    <HeroContainer ref={ref}>
      <H1>Business Process Automation</H1>
      <H1 style={{ marginTop: "-1%" }}>that's Right for You</H1>
      <H3>Processes evolve, so should your tools.</H3>
      <A href="mailto:info@queryitapp.com?subject=Scheduling A Demo&body=Hi, I'd like to schedule a Demo to explore Query-It's features and learn how it can benefit my business. Thanks!">
        <Button type="button">Schedule a Demo</Button>
      </A>
    </HeroContainer>
  );
}

export default forwardRef(Hero);

const HeroContainer = styled.div`
  margin-top: 106px;
  height: calc(100% - 106px);
  min-height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  color: white;
  background-color: #331f6f;
  background-image: url(${Macbook}), url(${Nodes}), url(${LandingBackground}),
    url(${Worldwide});
  background-size: 35%, 80%, cover, cover;
  background-position: 93%, 175% 60%, right, right;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;

  @media (max-width: 1770px) and (min-width: 1601px) {
    background-size: 30%, 80%, cover, cover;
  }

  @media (max-width: 1600px) and (min-width: 1471px) {
    background-size: 25%, 80%, cover, cover;
  }

  @media (max-width: 1470px) and (min-width: 601px) {
    margin-top: 87px;
    padding-top: 100px;
    height: calc(100% - 87px);
    align-items: center;
    justify-content: start;
    background-size: 40%, 0%, cover, cover;
    background-position: bottom 100px right 50%, center, center, center;
  }

  @media (max-width: 600px) {
    margin-top: 87px;
    padding-top: 100px;
    height: calc(100% - 87px);
    align-items: center;
    justify-content: start;
    background-size: 95%, 0%, cover, cover;
    background-position: bottom 100px right 50%, center, center, center;
  }
`;

const H1 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 450%;
  margin-left: 7%;

  @media (max-width: 1470px) and (min-width: 701px) {
    font-size: 300%;
    margin-left: 0;
  }

  @media (max-width: 700px) {
    font-size: 250%;
    margin-left: 0;
    width: 85%;
    text-align: center;
  }
`;
const H3 = styled.h3`
  margin: 0;
  font-size: 200%;
  margin-left: 7%;

  @media (max-width: 1470px) and (min-width: 601px) {
    font-size: 150%;
    margin-left: 0;
  }

  @media (max-width: 600px) {
    font-size: 100%;
    font-weight: 200;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
`;
const A = styled.a`
  // wrapper for Button - moved some of Button's styles here
  height: 57px;
  width: 204px;
  user-select: none;
  margin-left: 7%;
  margin-top: 2%;

  @media (max-width: 1470px) and (min-width: 601px) {
    margin-left: 0;
    margin-top: 10px;
  }

  @media (max-width: 600px) {
    height: 57px;
    width: 204px;
    margin-left: 0px;
    margin-top: 40px;
  }
`;
const Button = styled.button`
  height: 100%;
  width: 100%;
  font-size: 105%;
  display: relative;
  border: solid white;
  border-radius: 5px;
  background-color: transparent;
  color: white;

  -webkit-transition-duration: 0.4s; // Safari
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: white;
      border: solid white;
      color: #37227a;
    }
  }
`;
