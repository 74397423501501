/** Entry point for QueryIT API.
 *  This API provides a hierarchical way to create, access, update, and delete data from the firestore backend.
 *  All frontend interaction with the backend goes through hear.
 *
 * The entire API is class based (which both lets us mimic the database structure and abstract data access if migration from firebase is desired in the future.)
 */
import { v4 as uuid } from "uuid";
import { auth, storage, PERSISTENCE, projectStorage, db } from "./firebase";
import { StorageFile } from "./interfaces";

import { ProjectData, Projects } from "./projects";
import { Users } from "./users";
import { Companies } from "./companies";
import { FormUsers } from "./users/formusers";
import { Interests } from "./interest";
import { DevLogger } from "./devlogger";
import { SystemInfo } from "./systeminfo";
import { PatchNotes } from "./patchnotes";
import { FeedbackSet } from "./feedback";

import {
  createUserWithEmailAndPassword,
  linkWithPhoneNumber,
} from "firebase/auth";

import { APIStorage } from "./storage";
import { APIAuthentication } from "./authentication";

/** Root level queryit object. Imported by frontend files as "import queryit from "@queryit/api"" */
class QueryIt {
  projects: Projects;
  users: Users;
  companies: Companies;
  formusers: FormUsers;
  interests: Interests;
  devlogger: DevLogger;
  systeminfo: SystemInfo;
  patchnotes: PatchNotes;
  feedback: FeedbackSet;

  auth: APIAuthentication;
  storage: APIStorage;

  PERSISTENCE: Object;

  constructor() {
    this.auth = auth;
    this.projects = new Projects(db.collection("projects"));
    this.users = new Users(db.collection("users"));
    this.companies = new Companies(db.collection("companies"));
    this.formusers = new FormUsers(db.collection("formusers"));
    this.interests = new Interests(db.collection("interests"));
    this.devlogger = new DevLogger(db.collection("devlogger"));
    this.systeminfo = new SystemInfo(db.collection("systeminfo"));
    this.patchnotes = new PatchNotes(db.collection("patchnotes"));
    this.feedback = new FeedbackSet(db.collection("feedback"));

    this.storage = storage;
    this.PERSISTENCE = PERSISTENCE;
  }

  startTransaction() {
    return db.startTransaction();
  }

  getProjectStorage(id: string) {
    return projectStorage("projects"); // Used to be "id", but we have one bucket for all projects
  }

  // TODO: Add to internal auth object
  async createUserWithEmail(email: string) {
    const issuedPassword = uuid();
    const { user } = await createUserWithEmailAndPassword(
      this.auth.getWorker(),
      email,
      issuedPassword
    );
    await this.users.doc(user.uid).set({ issuedPassword: issuedPassword }); // other elements will be updated in post
    // That includes destroying this password if the user is SSO only
    return { user: user };
  }

  async createUserWithPhone(phone: string) {
    // First we'll generate the issued password (we'll use it to generate the unique email string lol)
    const issuedPassword = uuid();
    // Then we'll fetch and generate our unique email string
    const email = APIAuthentication.getMobileOnlyEmail(
      issuedPassword.replaceAll("-", "")
    );
    // Now do user create
    const { user } = await createUserWithEmailAndPassword(
      this.auth.getWorker(),
      email,
      issuedPassword
    );
    // Then add the information to the user doc (issued password and mobileOnly ID - the portion of the email after the plus sign)
    await this.users.doc(user.uid).set({
      issuedPassword: issuedPassword,
      mobileOnly: issuedPassword.replaceAll("-", ""),
      phone: phone,
    }); // other elements will be updated in post
    // NOTE: Actual phone number auth linking needs to happen in post
    // Now send back (this account is NOT eligible for SSO)
    return { user: user };
  }
}

export default new QueryIt();

export { StorageFile, APIStorage, APIAuthentication };
export { deleteField } from "firebase/firestore";
