import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import {
  InfoOutlined,
  HelpOutlineOutlined,
  WarningAmberOutlined,
  CheckCircleOutlined,
} from "@mui/icons-material";

import {
  GenericField,
  LocalityField,
  BooleanField,
  SpreadSelectField,
  SignatureField,
  LinkField,
} from "../../../ui/inputs2";
import AdvancedTable from "../../../ui/tableadv";

import {
  SelectForm,
  AdhocForm,
  TableField2,
  ParagraphField,
  DocLink,
  UsersSelectInput,
  TableCalcField,
  TableRadioCells,
  DataCalcField,
  QueryFileFieldRevisions,
  QueryPhotoField,
  StaticImageField,
  TimeTrackerField,
  QueryFileField,
} from "./queryfields";

import { log_error } from "../../../../tools/logger";

import { useFieldValidation } from "../../../../hooks/fields";
import { UserDataContext } from "../../../../App";
import { ShowFieldErrorsContext } from ".";
import { PackageField } from "./packagedoc";
import { QueryStatusContext } from "../query";

export const FieldGenerator = React.memo(
  ({
    field,
    data,
    onChange,
    setFieldChange,
    onChangeGenerator,
    withLabel,
    editabilityConditions,
    status,
    setQueryValidationErrors,
    autoComplete,
    embedded,
    isDefault = false,
    queryid,
    ...props
  }) => {
    const userData = useContext(UserDataContext);
    const queryStatus = useContext(QueryStatusContext);
    const showError = useContext(ShowFieldErrorsContext);
    let readyComponent;

    // Resolve field type
    let fldType;
    if (field.type?.conditionalOn) {
      fldType = field.type[data[field.type.conditionalOn]];
    } else {
      fldType = field.type;
    }

    // Resolve field editability
    const statusMakesEditable = field.editableWhile.some(
      (st) =>
        (status === "new" && st === "new") || queryStatus?.is_equivalent(st)
    );

    const editable = editabilityConditions?.forceEdit
      ? true
      : editabilityConditions?.forceReadOnly
      ? false // Readonly condition is also necessary ONLY for old system (I forget why, may be false)
      : !editabilityConditions?.creatorOnly && status === "created"
      ? false
      : !editabilityConditions?.approversOnly && queryStatus.is_approval()
      ? false
      : Array.isArray(field.editableWhile)
      ? statusMakesEditable
      : // Deprecated support
        field.editableWhile[status === "created" ? "new" : "open"];

    const onFieldUpdate = useCallback(
      (e) => {
        const { value } = e?.target ? e.target : { value: e };
        if (onChangeGenerator) {
          return onChangeGenerator(field.id)(value);
        } else {
          return onChange(value);
        }
      },
      [onChange, onChangeGenerator, field]
    );

    const setFieldUpdate = useCallback(
      (callback) => {
        setFieldChange((ex) => ({
          ...ex,
          [field.id]: callback(ex[field.id] ?? {}),
        }));
      },
      [setFieldChange, field]
    );

    let options;

    const error = useFieldValidation(
      field,
      data[field.id],
      setQueryValidationErrors,
      status
    );

    const demonstratedError = showError ? error : undefined;

    // Add noBottomPad to props when embedded is true
    if (embedded) {
      props.noBottomPad = true;
    }

    switch (fldType) {
      case "number":
      case "string":
      case "textarea":
        readyComponent = (
          <GenericField
            data={data[field.id]}
            onChange={onFieldUpdate}
            label={withLabel ? field.name : ""}
            disabled={!editable}
            charLimit={
              field.required?.validations?.character_range?.constraint?.max ??
              field.characterLimit
            }
            multiLine={fldType === "textarea"}
            noWidthCap={fldType === "textarea"}
            number={fldType === "number"}
            fill={true}
            maxWidth={field.width}
            error={demonstratedError}
            {...props}
          />
        );
        break;
      case "date":
      case "time":
        readyComponent = (
          <LocalityField
            data={data[field.id]}
            onChange={onFieldUpdate}
            label={withLabel ? field.name : ""}
            useDate={fldType == "date"}
            useTime={fldType == "time"}
            disablePast={field.disablePast ? true : false}
            disableFuture={field.disableFuture ? true : false}
            disabled={!editable}
            error={demonstratedError}
            fill={true}
            {...props}
          />
        );
        break;
      case "checkbox":
        readyComponent = (
          <BooleanField
            data={data[field.id]}
            onChange={onFieldUpdate}
            label={withLabel ? field.name : ""}
            disabled={!editable}
            error={demonstratedError}
            checkbox
            {...props}
          />
        );
        break;
      case "select":
        readyComponent = (
          <SelectForm
            data={data}
            field={field}
            onChange={onFieldUpdate}
            withLabel={withLabel}
            editable={editable}
            error={demonstratedError}
            maxWidth={field.width}
            {...props}
          />
        );
        break;
      case "multiselect":
        readyComponent = (
          <SelectForm
            data={data}
            field={field}
            onChange={onFieldUpdate}
            withLabel={withLabel}
            editable={editable}
            error={demonstratedError}
            maxWidth={field.width}
            multi
            {...props}
          />
        );
        break;
      case "adhoc":
        readyComponent = (
          <AdhocForm
            data={data}
            field={field}
            onChange={onFieldUpdate}
            withLabel={withLabel}
            editable={editable}
            error={demonstratedError}
            {...props}
          />
        );
        break;
      case "radio":
        options = field.options;
        readyComponent = (
          <SpreadSelectField
            data={data[field.id]}
            options={
              options &&
              Object.keys(options)
                .sort()
                .reduce((acc, k) => ({ ...acc, [k]: options[k] }), {})
            }
            onChange={onFieldUpdate}
            label={field.name}
            disabled={!editable}
            error={demonstratedError}
            {...props}
          />
        );
        break;
      case "boxset":
        options = field.options;
        readyComponent = (
          <SpreadSelectField
            data={data[field.id]}
            options={
              options &&
              Object.keys(options)
                .sort()
                .reduce((acc, k) => ({ ...acc, [k]: options[k] }), {})
            }
            onChange={onFieldUpdate}
            disabled={!editable}
            label={withLabel ? field.name : ""}
            error={demonstratedError}
            multi
            {...props}
          />
        );
        break;
      case "table":
        readyComponent = (
          <TableField2
            data={data}
            field={field}
            onFieldChange={onFieldUpdate}
            editable={editable}
            staticTable={false}
            withLabel={withLabel}
            status={status}
            editabilityConditions={editabilityConditions}
            error={demonstratedError}
            queryid={queryid}
            {...props}
          />
        );
        break;
      case "statictable":
        readyComponent = (
          <TableField2
            data={data}
            field={field}
            onFieldChange={onFieldUpdate}
            editable={editable}
            staticTable={true}
            withLabel={withLabel}
            status={status}
            editabilityConditions={editabilityConditions}
            error={demonstratedError}
            {...props}
          />
        );
        break;
      case "tableadv":
        readyComponent = (
          <OverflowContainer>
            <AdvancedTable
              {...props}
              disabled={!editable}
              data={data[field.id]}
              onChange={onFieldUpdate}
              setChange={setFieldUpdate}
              contextualData={data}
              schema={field.schema}
              label={field.name}
              withLabel={withLabel}
              queryData={{ status: status, data: data }}
              error={demonstratedError}
            />
          </OverflowContainer>
        );
        break;
      case "message":
        if (
          field.icon !== "nothing" &&
          field.icon !== "" &&
          field.icon !== undefined
        ) {
          const IconComponent = iconOptions[field.icon] || InfoOutlined;
          readyComponent = (
            <Tooltip
              title={
                <span style={{ fontSize: "1.25em" }}>{field.default}</span>
              }
            >
              <IconComponent style={{ color: field.color }} />
            </Tooltip>
          );
        } else {
          readyComponent = (
            <ParagraphField style={field.style ? field.style : {}}>
              {new Function("query", "return `" + field.default + "`")(data)}
            </ParagraphField>
          );
        }
        break;
      case "staticdoc":
        readyComponent = (
          <DocLink
            data={
              data[field.id]
                ? data[field.id]
                : field?.file && field.file[0]
                ? field.file[0]
                : null
            }
            label={field.name}
            withLabel={withLabel}
          />
        );
        break;
      case "staticimage":
        readyComponent = (
          <StaticImageField
            data={
              data[field.id]
                ? data[field.id]
                : field?.staticImg && field.staticImg[0]
                ? field.staticImg[0]
                : null
            }
            label={field.name}
            withLabel={withLabel}
          />
        );
        break;
      case "link":
        readyComponent = (
          <LinkField
            data={data[field.id]}
            label={field.name}
            withLabel={withLabel}
            text={field.text ? field.text : undefined}
            click={field.click}
            onChange={onFieldUpdate}
            disabled={!editable}
          />
        );
        break;
      case "files":
        readyComponent = field.enableRevisions ? (
          <QueryFileFieldRevisions
            label={field.name}
            bucket="projects"
            onFieldUpdate={onFieldUpdate}
            data={
              data[field.id] != ""
                ? data[field.id]
                : { data: undefined, revisions: undefined }
            }
            field={field}
            disabled={!editable}
            error={demonstratedError}
          />
        ) : (
          <QueryFileField
            label={field.name}
            bucket="projects"
            onFieldUpdate={onFieldUpdate}
            data={data[field.id] != "" ? data[field.id] : undefined}
            disabled={!editable}
            error={demonstratedError}
          />
        );
        break;
      case "photos":
        readyComponent = (
          <QueryPhotoField
            label={field.name}
            bucket="projects"
            onChange={onFieldUpdate}
            files={data[field.id] != "" ? data[field.id] : undefined}
            disabled={!editable}
            error={demonstratedError}
          />
        );
        break;
      case "arbcalc":
        readyComponent = (
          <DataCalcField
            data={data}
            field={field}
            onFieldChange={onFieldUpdate}
            withLabel={withLabel}
            disabled={!editable}
          />
        );
        break;
      case "userlist":
        readyComponent = (
          <UsersSelectInput
            multi={field.multi ?? true}
            editable={editable}
            label={field.name}
            withLabel={withLabel}
            data={data[field.id]}
            onChange={onFieldUpdate}
            autoComplete={autoComplete}
            error={demonstratedError}
          />
        );
        break;
      // Table Specific Inputs
      case "rowcalc":
        readyComponent = (
          <TableCalcField
            data={data}
            field={field}
            onFieldChange={onFieldUpdate}
          />
        );
        break;
      case "tableradio":
        readyComponent = (
          <TableRadioCells
            data={data[field.id]}
            onFieldChange={onFieldUpdate}
            options={field.options}
            editable={editable}
          />
        );
        break;
      case "signature":
        readyComponent = (
          <SignatureField
            label={withLabel ? field.name : ""}
            onChange={onFieldUpdate}
            data={data[field.id]}
            error={demonstratedError}
            drawable
            signingUser={
              field.anonymousSignature
                ? undefined
                : {
                    id: userData.id,
                    name: `${userData.name.first} ${userData.name.last}`,
                  }
            }
            disabled={!editable}
          />
        );
        break;
      case "package":
        readyComponent = (
          <PackageField
            field={field}
            label={withLabel ? field.name : ""}
            data={data[field.id]}
            queryData={data}
            setChange={setFieldUpdate}
            editable={editable}
            editabilityConditions={editabilityConditions}
          />
        );
        break;
      case "timetracker":
        readyComponent = (
          <TimeTrackerField
            field={field}
            label={withLabel ? field.name : ""}
            data={data[field.id]}
            queryData={data}
            onFieldUpdate={onFieldUpdate}
            demonstratedError={demonstratedError}
            queryid={queryid}
            editable={editable}
            status={status}
          />
        );
        break;
      case "hidden":
        return null;
      default:
        return null;
    }

    return readyComponent;
  },
  fieldMemoizeChecker
);

/**
 * Here's the plan.
 *
 * Basically, most props are fine to just do their shallow compare and call it a day, so we'll let them
 *
 * But data... the need to re-render based on ALL data provided to the field generator is VERY type conditional
 *
 * So let's represent that in our memoize! :)
 *
 * NOTE: There are some field cases with a "?", which indicates that I'm not certain they will behave properly in direct comparison
 */
function fieldMemoizeChecker(previousProps, nextProps) {
  // First check if any of the object props have changed
  if (
    Object.keys(previousProps).sort().join(",") !==
    Object.keys(nextProps).sort().join(",")
  ) {
    return false;
  }
  // Then let's do the shallow compare we've always done (by default)
  let propsChanged = false;
  Object.keys(previousProps).forEach((key) => {
    if (key === "data") {
      return;
    }
    if (previousProps[key] !== nextProps[key]) {
      propsChanged = true;
    }
  });
  if (propsChanged) {
    return false;
  }
  // Now finish with the data comparison (assuming it's necessary)
  const field = nextProps.field; // The same as prevProps.field
  // Then fetch old and new data
  const prevData = previousProps.data; // The same as prevProps.data
  const nextData = nextProps.data; // The same as prevProps.data
  // Now do the comparison
  if (!field) {
    return true;
  }
  // Resolve field type
  let fldType;
  if (field.type.conditionalOn) {
    if (
      nextData[field.type.conditionalOn] !== prevData[field.type.conditionalOn]
    ) {
      return false;
    }
    fldType = field.type[nextData[field.type.conditionalOn]];
  } else {
    fldType = field.type;
  }
  switch (fldType) {
    case "string":
    case "textarea":
    case "number":
    case "date":
    case "time":
    case "radio":
    case "tableradio":
    case "rowcalc":
    case "arbcalc":
    case "signature": // ?
    case "table": // ?
    case "statictable": // ?
      if (prevData[field.id] !== nextData[field.id]) {
        return false;
      }
      break;
    case "select":
    case "adhoc":
    case "checkbox":
      if (prevData[field.id] !== nextData[field.id]) {
        return false;
      }
      if (prevData[field.conditionalOn] !== nextData[field.conditionalOn]) {
        return false;
      }
      break;
    case "tableadv": // ?
      // Here the whole data object is an injectable which it is built to handle
      // So let's send it all and tell it to have fun :)
      if (prevData[field.id] !== nextData[field.id]) {
        return false;
      }
      if (previousProps.status !== nextProps.status) {
        return false;
      }
      break;
    case "package":
      return false; // TODO: Add proper diff checking here
    case "timetracker":
    case "boxset":
    case "multiselect":
    case "userlist":
    case "files": // ?
    case "photos": // ?
      if (
        Array.isArray(prevData[field.id]) &&
        Array.isArray(nextData[field.id])
      ) {
        if (
          prevData[field.id].sort().join(",") !==
          nextData[field.id].sort().join(",")
        ) {
          return false;
        }
      } else {
        if (prevData[field.id] !== nextData[field.id]) {
          return false;
        }
      }
      break;
    case undefined:
      // Always return false if we have a field with undefined typing
      return false;
    case "staticdoc":
    case "staticimage":
    case "message":
    case "link":
    case "hidden":
      // Always return true on static fields
      break;
    default:
      return prevData[field.id] !== nextData[field.id]; // default behavior for un-covered fields
  }
  return true;
}

const OverflowContainer = styled.div`
  overflow-x: auto;
`;

const iconOptions = {
  info: InfoOutlined,
  help: HelpOutlineOutlined,
  warning: WarningAmberOutlined,
  success: CheckCircleOutlined,
};
