import React, { useState } from "react";
import QRCode from "react-qr-code";
import styled from "@emotion/styled";
import { Button2 } from "../../../ui/buttons";
import { useSearchParams } from "react-router-dom";
import { QUERYVIEWS, ROUTEKEYS } from "../../../../common/query";

export default ({ qrCodeLink }) => {
  const [prepPrintButton, setPrepPrintButton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <PrintContainer>
      <QRCode value={qrCodeLink} size={100} />
      {!prepPrintButton && (
        <ButtonContainer>
          <Button2
            label="Print"
            style={{ marginTop: "10px" }}
            onClick={() => {
              setPrepPrintButton(true);
              setTimeout(() => window.print(), 400); // Time to let state update
              setTimeout(() => setPrepPrintButton(false), 500); // Then show print button again
            }}
          />
          <Button2
            label="Cancel"
            style={{ marginTop: "10px", marginLeft: "10px" }}
            onClick={() =>
              setSearchParams((ex) => {
                ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.QUERY);
                return ex;
              })
            }
          />
        </ButtonContainer>
      )}
    </PrintContainer>
  );
};

const PrintContainer = styled.div`
  display: flex;
  width: 99%;
  height: 99%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media print {
    & {
      background-color: white;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      padding: 15px;
      font-size: 14px;
      line-height: 18px;
      z-index: 2000;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  // maybe unnecessary
  @media (max-width: 260px) {
    flex-direction: column;
  }
`;
