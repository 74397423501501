import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import {
  Add,
  FilterList,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Print,
  Search,
  Schedule,
  Settings,
  Check,
  Clear,
} from "@mui/icons-material";
import { Collapse, Tooltip } from "@mui/material";

import { ActionTile } from "../../actions/tiles";
import { StatusFilterDropdown } from "./statuspicker";

import { is_overdue, parse_db_timestamp } from "../../../tools";
import {
  CurrentProjectContext,
  FilteringActiveContext,
  ProjectUsersContext,
  SelectedSchemaContext,
} from "..";
import { useSearchParams } from "react-router-dom";
import { QUERYVIEWS, SUBQUERYVIEWS } from "../../../common/query";
import { UserContext } from "../../../App";
import { useUserPermissions } from "../../../hooks/users";

export const ControlPanel = ({
  setFiltersPaneVisible,
  settings,
  queryFilterManager,
  actions,
  printAction,
  editAction,
  isEditMode,
  disableConfirm,
}) => {
  // Fetch ctx info
  const projectData = useContext(CurrentProjectContext);
  const selectedSchema = useContext(SelectedSchemaContext);
  const filteringActive = useContext(FilteringActiveContext);
  // const projectUsers = useContext(ProjectUsersContext);
  const user = useContext(UserContext);
  const permissions = useUserPermissions(user);

  const [viewingActions, setViewingActions] = useState(false);

  // Get search param state
  const [searchParams, setSearchParams] = useSearchParams();

  if (isMobile) {
    return null;
  } // The control panel will be hidden on mobile

  function toggleFilters() {
    setFiltersPaneVisible((ex) => !ex);
  }

  // Process actions ahead of render into 3 counts
  const actionCounts = {
    response: actions?.filter((a) => a.type === "responses").length,
    task: actions?.filter((a) => a.type === "tasks").length,
    approval: actions?.filter((a) => a.type === "approvals").length,
  };

  return (
    <>
      <ControlBar>
        <NewButton
          withOptions={settings.newAdditionalOptions}
          onClick={() => {
            setSearchParams({ nq: `${projectData.id}-${selectedSchema?.id}` });
          }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <Add style={{ fontSize: 19 }} />
            New
          </span>
          {settings.newAdditionalOptions && (
            <NewAdditonalOptions>Meow</NewAdditonalOptions>
          )}
        </NewButton>
        <SearchBar
          filteringActive={filteringActive}
          textSearchFilterManager={queryFilterManager}
          toggleFilters={toggleFilters}
        />
        <StatusFilterDropdown filterManager={queryFilterManager} />
        <OverdueOnlyFilterIconButton filterManager={queryFilterManager} />
        {actions && Object.values(actionCounts).some((a) => a > 0) && (
          <ActionCounters onClick={() => setViewingActions((ex) => !ex)}>
            {Object.keys(actionCounts)
              .filter((k) => actionCounts[k] > 0)
              .map((k) => (
                <ActionTypeCount key={k}>
                  {`${ACTION_LABELS[k]}:`}
                  <ActionCircle>{actionCounts[k]}</ActionCircle>
                </ActionTypeCount>
              ))}
            {!viewingActions && <KeyboardArrowDown />}
            {viewingActions && <KeyboardArrowUp />}
          </ActionCounters>
        )}
        {printAction && (
          <Tooltip title="Print">
            <ControlBarIconButton onClick={printAction}>
              <Print />
            </ControlBarIconButton>
          </Tooltip>
        )}

        {permissions?.admin && isEditMode && (
          <>
            <Tooltip title="Confirm">
              <ControlBarIconButton
                disabled={disableConfirm}
                onClick={() => {
                  editAction("confirm");
                }}
              >
                <Check style={{ color: "green" }} />
              </ControlBarIconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <ControlBarIconButton onClick={() => editAction("cancel")}>
                <Clear style={{ color: "red" }} />
              </ControlBarIconButton>
            </Tooltip>
          </>
        )}
        {permissions?.admin && !isEditMode && (
          <Tooltip title="Edit">
            <ControlBarIconButton onClick={() => editAction("edit")}>
              <Settings />
            </ControlBarIconButton>
          </Tooltip>
        )}
      </ControlBar>
      <ActionViewer
        viewingActions={viewingActions}
        actions={actions}
        projectId={projectData.id}
      />
    </>
  );
};

const ActionViewer = ({ viewingActions, actions, projectId }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Collapse in={viewingActions}>
      <ActionSelectorBar>
        {actions
          ?.sort((a) => a.dueDate)
          .map((a) => (
            <ActionTile
              key={a.id}
              title={a.query?.dynamicId ?? a.queriesId}
              status={a.status}
              type={a.type}
              due={
                a.type === "tasks"
                  ? parse_db_timestamp(a.dueDate)
                  : parse_db_timestamp(a.query?.dueDate)
              }
              description={a.type === "tasks" && a.dynamicId ? a.dynamicId : ""}
              onClick={() => {
                let ar = SUBQUERYVIEWS.DEFAULT;
                switch (a.type) {
                  case "responses":
                    ar = SUBQUERYVIEWS.RESPONSE;
                    break;
                  case "approvals":
                    ar = SUBQUERYVIEWS.APPROVAL;
                    break;
                  case "tasks":
                    ar = SUBQUERYVIEWS.TASK;
                    break;
                }
                setSearchParams({
                  qq: `${projectId}-${a.queriesId}`,
                  qr: QUERYVIEWS.ACTIONS,
                  ar,
                });
              }}
              overdue={is_overdue(
                a.type === "tasks"
                  ? parse_db_timestamp(a.dueDate)
                  : parse_db_timestamp(a.query?.dueDate)
              )}
            />
          ))}
      </ActionSelectorBar>
    </Collapse>
  );
};

export const SearchBar = ({
  filteringActive,
  toggleFilters,
  textSearchFilterManager,
}) => {
  const filterState = textSearchFilterManager
    ? textSearchFilterManager.useFilterState()
    : () => {};

  const FilteringButton = (
    <Tooltip title={`Filter Queries${filteringActive ? " (Active)" : ""}`}>
      <ControlBarIconButton onClick={toggleFilters}>
        <FilterList
          sx={{ color: filteringActive ? "secondary.main" : "inherit" }}
        />
      </ControlBarIconButton>
    </Tooltip>
  );

  return (
    <FilterSection>
      {textSearchFilterManager && (
        <SearchBarInputContainer>
          <Search style={{ width: "20px", height: "20px" }} />
          <SearchBarInput
            placeholder="Search..."
            value={filterState.filterString ?? ""}
            onChange={(e) =>
              textSearchFilterManager.setFilterString(e.target.value)
            }
          />
          {FilteringButton}
        </SearchBarInputContainer>
      )}
      {!textSearchFilterManager && FilteringButton}
    </FilterSection>
  );
};

export const OverdueOnlyFilterIconButton = ({ filterManager, title }) => {
  // Fetch filter state
  const filterState = filterManager?.useFilterState();
  const filterScheme = filterManager?.useFilterScheme();
  return (
    <>
      {filterManager && (
        <Tooltip title={title ?? "Show Overdue Queries Only"}>
          <ControlBarIconButton
            onClick={() =>
              filterManager.setFilterProperty(
                filterScheme?.find((f) => f.index === "overdue"),
                !(filterState?.dataFields?.overdue ?? false)
              )
            }
          >
            <Schedule
              sx={{
                color: filterState?.dataFields?.overdue
                  ? "secondary.main"
                  : "inherit",
                fontSize: "20px",
              }}
            />
          </ControlBarIconButton>
        </Tooltip>
      )}
    </>
  );
};

const ACTION_LABELS = {
  task: "Tasks",
  approval: "Approvals",
  response: "Responses",
};

const ControlBar = styled.div`
  display: flex;
  min-height: 40px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: -15px;

  color: ${(props) => props.theme.text};
`;

const ControlBarButton = styled.button`
  height: 25px;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.step100};
  border-radius: 5px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.step150};
    cursor: pointer;
  }
`;

const NewButton = styled(ControlBarButton)`
  width: ${(props) => (props.withOptions ? 80 : 60)}px;
  padding: 0 6px;
  justify-content: space-between;
`;

const NewAdditonalOptions = styled.div``;

const SearchBarInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 5px;

  background-color: ${(props) => props.theme.step100};

  padding: 0 3px;
  margin-left: 10px;
`;

const SearchBarInput = styled.input`
  background-color: unset;
  border: none;

  color: ${(props) => props.theme.text};
  opacity: 0.9;

  width: 70px;

  &:focus {
    width: 170px;
  }

  transition: width 0.2s ease-in-out;
`;

const FilterSection = styled.div``;

const ActionCounters = styled.div`
  margin-left: 10px;
  height: 25px;
  background-color: ${(props) => props.theme.step100};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 6px;
  user-select: none;

  &:hover {
    background-color: ${(props) => props.theme.step150};
    cursor: pointer;
  }
`;

const ActionTypeCount = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  font-size: 14px;
`;

const ActionCircle = styled.div`
  width: 14px;
  height: 14px;
  font-size: 12px;
  border-radius: 50%;
  background-color: orange;

  margin: 0 4px;
  line-height: 0.7;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ControlBarIconButton = styled(ControlBarButton)`
  width: 30px;
`;

const ActionSelectorBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  width: fit-content;
  background-color: ${(props) => props.theme.step50};
  max-height: 110px;
  padding: 10px 0;
  overflow: hidden;
  flex-flow: row wrap;
  gap: 20px;
`;
