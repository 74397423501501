import React from "react";
import styled from "styled-components";

import Diagram from "../graphics/how-it-works-diagram.png";

import { forwardRef } from "react";

function How({}, ref) {
  return (
    <HowItWorksContainer ref={ref}>
      <TextContainer>
        <H1>How it works?</H1>
        <P>
          Use Query-It to standardize & digitize your business process. We
          configure & map required data attributes and workflow within Query-It.
          Access is device agnostic (web, mobile, tablet, connected worker,
          etc.) and analytics & performance metrics are available in real-time.
          Transform the way you work through Query-It.
        </P>
      </TextContainer>
      <Image src={Diagram} />
    </HowItWorksContainer>
  );
}

export default forwardRef(How);

const HowItWorksContainer = styled.div`
  min-height: 833px; // 77%;
  width: 100%;
  background-color: #37227a;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    min-height: 765px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
`;
const TextContainer = styled.div`
  margin-left: 5%;
  width: 35%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 90%;
    margin-left: 0%;
  }
`;
const Image = styled.img`
  height: 815px;
  width: 793px;

  @media (min-width: 993px) and (max-width: 1250px) {
    // scale 80%
    height: 652px;
    width: 634.4px;
  }

  @media (min-width: 531px) and (max-width: 992px) {
    // scale 60%
    height: 489px;
    width: 475.8px;
  }

  @media (max-width: 530px) {
    // mobile mockup dimensions
    height: auto;
    width: 93vw;
  }
`;
const H1 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 400%;
  color: white;

  @media (max-width: 768px) {
    font-size: 275%;
    margin-top: 60px;
    text-align: center;
  }
`;
const P = styled.p`
  width: 100%;
  text-align: left;
  color: white;

  @media (max-width: 768px) {
    text-align: center;
    line-height: 30px;
  }
`;
