import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Typography, Box, Slider } from "@mui/material";

// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from "slate-react";
import { createEditor } from "slate";
import { Element, Leaf } from "../../project/query/comments/sidebar";
import { Badge } from "../../statics2/badges";
import { Commendation } from "../../statics2/graphics/commend";
import { GenericField } from "../inputs2";

import q from "@queryit/api"; //WATCH PERMS here
import { Button2 } from "../buttons";

export const AnnouncementContent = ({ data }) => {
  // Build renderers for slateJS
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  return (
    <ContentContainer>
      <Box pl={1}>
        <Slate editor={editor} value={data}>
          <Editable
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            autoFocus
          />
        </Slate>
      </Box>
    </ContentContainer>
  );
};

export const BadgesContent = ({ data }) => {
  return (
    <ContentContainer>
      <Typography
        sx={{
          fontSize: "30px",
          display: "flex",
          fontWeight: "bold",
        }}
      >
        {data.header}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          display: "flex",
        }}
      >
        {data.text}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: "10px",
        }}
      >
        {Array.isArray(data?.badges) &&
          data.badges.map((badge, j) => (
            <Badge
              key={badge.id}
              name={badge.name}
              amountNeeded={0}
              amountObtained={1}
              flair={badge.flair}
              artUrl={badge.url}
              info={badge?.info}
              textColour="black"
            />
          ))}
      </Box>
    </ContentContainer>
  );
};

export const CustomContent = ({ data }) => {
  return (
    <ContentContainerCustom
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        width={`${data.width}%`}
        height={`${data.height}%`}
        src={data.src}
        style={{ border: "none" }}
      ></iframe>
    </ContentContainerCustom>
  );
};

export const CommendContent = ({ data }) => {
  return (
    <ContentContainerCustom>
      <Typography
        sx={{
          fontSize: "28px",
          display: "flex",
          fontWeight: "bold",
          justifyContent: "center",
        }}
      >
        You Received a Commendation!
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {data.message}
      </Typography>
      <Commendation />
    </ContentContainerCustom>
  );
};

export const PatchNoteContent = ({ data }) => {
  return (
    <ContentContainerCustom>
      <Typography
        sx={{
          fontSize: "30px",
          display: "flex",
          fontWeight: "bold",
          justifyContent: "center",
        }}
      >
        Patch Notes
      </Typography>
      <object
        data={data.pdf}
        type="application/pdf"
        width="100%"
        height="90%"
      ></object>
    </ContentContainerCustom>
  );
};

export const CommentContent = ({ data }) => {
  return (
    <ContentContainer>
      <Typography>{data.comments}</Typography>
    </ContentContainer>
  );
};

const MARKS = [
  { value: 1, label: "1" },
  { value: 2, label: "" },
  { value: 3, label: "" },
  { value: 4, label: "" },
  { value: 5, label: "5" },
  { value: 6, label: "" },
  { value: 7, label: "" },
  { value: 8, label: "" },
  { value: 9, label: "" },
  { value: 10, label: "10" },
];

// this one is a little special
export const FeedbackInput = ({ data, closeView }) => {
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState("\n\n\n");

  const handleCommentChange = (event) => {
    const newComment = event.target.value;
    setComment(newComment);
  };

  const handleSubmit = async () => {
    await q.feedback.add({
      userID: data.id,
      name: data.name,
      rating: rating,
      comments: comment,
    });
    closeView(true);
  };

  return (
    <ContentContainer>
      <Typography
        sx={{
          fontSize: "30px",
          display: "flex",
          fontWeight: "bold",
        }}
      >
        Give Feedback
      </Typography>
      <Typography
        sx={{
          paddingTop: "20px",
          paddingBottom: "40px",
        }}
      >
        How would you rate your experience with Query-It?
      </Typography>
      <SliderWrapper>
        <Slider
          sx={{ width: "80%" }}
          valueLabelDisplay="on"
          defaultValue={5}
          step={1}
          marks={MARKS}
          min={1}
          max={10}
          onChange={(e) => setRating(e.target.value)}
        />
      </SliderWrapper>
      <Typography
        sx={{
          paddingTop: "20px",
        }}
      >
        Tell us more about your experience.
      </Typography>
      <SliderWrapper>
        <GenericField
          multiLine={true}
          data={comment}
          onChange={handleCommentChange}
          style={{ width: "100%", paddingRight: "10px" }}
        />
      </SliderWrapper>
      <SliderWrapper>
        <Button2 label={"Submit Feedback"} onClick={handleSubmit} />
      </SliderWrapper>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  width: 100%;
  padding: 6px;
  align-items: center;
`;

const ContentContainerCustom = styled.div`
  width: 100%;
  height: 400px;
  padding: 6px;
`;

const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
