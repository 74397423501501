import { BaseCollection, BaseDocument, BaseData } from "../../base"

interface ProjectUserData extends BaseData {
  name: {
    first: string
    last: string
  }
  email: string
  location : {
    country: string
    state: string
    city: string
  }
  profilePhoto: string
  displayBadge: {
    selectedBadge: string
    flair: string
    project: string
    projectId: string
    id: string
    amountNeeded: number
    url: string
  }
  points: number
}

export class ProjectUsers extends BaseCollection<ProjectUserData> {
  // These are stored in the users subcollection of a project.
  projectUser(id: string): ProjectUser {
    return this.doc(id) as ProjectUser
  }
}

type ProjectUser = BaseDocument<ProjectUserData>
