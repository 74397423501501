import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { Check, KeyboardArrowLeftRounded } from "@mui/icons-material";

import { QueryContent } from "../query";
import { QueryFields } from ".";
import { Button2 } from "../../../ui/buttons";

import { noop, parse_db_timestamp } from "../../../../tools";
import { Input } from "../../../ui/inputs";
import { TableHeader } from "./queryfields";
import { FileField, SelectField } from "../../../ui/inputs2";
import { StatusDot } from "../../../ui/decorations";

import { formatDate } from "../../../ui/display";
import {
  FFTable,
  FFTableBody,
  FFTableCell,
  FFTableHead,
  FFTableCellCenterContent,
  FFTableRow,
} from "../../../ui/table";
import { UserDataContext } from "../../../../App";
import { ProjectQueriesContext } from "../..";
import { FieldGenerator } from "./generator";
import { log_error } from "../../../../tools/logger";
import AdvancedTable from "../../../../components/ui/tableadv";

export const PackageDocContext = React.createContext(undefined);
export const PackageDocSetterContext = React.createContext(noop);

export const PackageField = ({
  data,
  queryData,
  field,
  setChange,
  editable,
}) => {
  // Counter for package attachment index
  let attachNo = 0;

  return (
    <Input label={field.name} style={{ overflowX: "auto", marginRight: 10 }}>
      <FFTable>
        <FFTableHead>
          <tr>
            <TableHeader width={"120px"}>Attachment No.</TableHeader>
            <TableHeader width={"300px"}>Title</TableHeader>
            <TableHeader>Data</TableHeader>
            <TableHeader width={"80px"}>Status</TableHeader>
            <TableHeader width={"260px"}>Last Modified</TableHeader>
          </tr>
        </FFTableHead>
        <FFTableBody>
          {field?.schema?.map((pkg) => {
            let pkgIndex = undefined;
            if (pkg.indexer) {
              pkgIndex = pkg.indexer;
            } else {
              pkgIndex = ++attachNo;
            }
            return (
              <FFTableRow key={`pkg-row-${pkg.id}`}>
                <FFTableCell key={"pkg-ind"}>{pkgIndex}</FFTableCell>
                <FFTableCell key={"pkg-name"}>{pkg.title}</FFTableCell>
                <FFTableCell key={"pkg-btn"}>
                  <PackageFieldResolver
                    fieldId={field.id}
                    packageEntry={pkg}
                    data={data?.[pkg.id]}
                    queryData={queryData}
                    setFieldChange={setChange}
                    editable={editable}
                  />
                </FFTableCell>
                <FFTableCell key={"pkg-status"} center={true}>
                  <StatusDot cmplt={data?.[pkg.id]?.complete} />
                </FFTableCell>
                <FFTableCell key={"pkg-modified"}>
                  {data?.[pkg.id]?.modified
                    ? `${formatDate(
                        parse_db_timestamp(data?.[pkg.id]?.modified?.date)
                      )} by ${data?.[pkg.id]?.modified?.name}`
                    : "Never"}
                </FFTableCell>
              </FFTableRow>
            );
          })}
        </FFTableBody>
      </FFTable>
    </Input>
  );
};

export const EditPackageField = ({ field, onEditChange }) => {
  const [tableData, setTableData] = useState({ columns: field.schema });

  const packageEditorSchema = {
    body: {
      columns: [
        {
          name: "Id",
          id: "id",
          type: "text",
        },
        {
          name: "Title",
          id: "title",
          type: "text",
        },
        {
          name: "Type",
          id: "type",
          type: "select",
          options: {
            doc: "doc",
            file: "file",
            reference: "reference",
          },
        },
        {
          name: "Indexer",
          id: "indexer",
          type: "text",
        },
      ],
    },
    options: { showSimpleHeader: true },
  };

  useEffect(() => {
    if (tableData) {
      onEditChange(tableData.columns);
    }
  }, [tableData]);

  const onChange = useCallback((callback) => {
    setTableData((ex) => ({
      ...ex,
      columns: callback({ body: ex?.columns })?.body,
    }));
  }, []);

  return (
    <AdvancedTable
      key="package-editor-table"
      label={field.name}
      schema={packageEditorSchema}
      data={{ body: tableData.columns }}
      setChange={onChange}
    />
  );
};

export const PackageDoc = ({
  data,
  setQueryData,
  setFieldChange,
  status,
  editabilityConditions,
  setStatusMessage,
}) => {
  const userData = useContext(UserDataContext);
  const packageDoc = useContext(PackageDocContext);
  const setPackageDoc = useContext(PackageDocSetterContext);

  const setDocChange = (callback) => {
    setFieldChange((ex) => ({
      ...ex,
      [packageDoc?.fieldId]: {
        ...ex?.[packageDoc?.fieldId],
        [packageDoc?.doc?.id]: callback(
          ex?.[packageDoc.fieldId]?.[packageDoc?.doc?.id] ?? {}
        ),
      },
    }));
  };

  const onDocChange = (fieldId) => (value) => {
    setDocChange((ex) => ({
      ...ex,
      data: { ...ex?.data, [fieldId]: value },
      modified: {
        date: new Date(),
        name: userData?.name?.first + " " + userData?.name?.last,
      },
    }));
  };

  return (
    <QueryContent>
      <div style={{ marginBottom: "10px" }}>
        <Button2
          startIcon={<KeyboardArrowLeftRounded />}
          onClick={() => setPackageDoc(undefined)}
          label="Go Back"
          style={{ marginTop: "10px", marginLeft: "5px" }}
          size="small"
        />
        {true && (
          <Button2
            startIcon={<Check />}
            onClick={() => {
              // Set complete
              setDocChange((ex) => ({ ...ex, complete: true }));
              // Show acknowledgement
              setStatusMessage(
                `Package Document '${packageDoc?.doc?.title}' completed`
              );
              // Leave as well!
              setPackageDoc(undefined);
            }}
            label="Mark Complete"
            style={{ marginTop: "10px", marginLeft: "5px" }}
            size="small"
          />
        )}
      </div>
      <QueryFields
        data={data?.data ?? {}}
        fieldSet={packageDoc?.doc?.fields ?? []}
        onFieldChange={onDocChange}
        setFieldChange={setDocChange}
        status={status}
        editabilityConditions={editabilityConditions}
      />
    </QueryContent>
  );
};

////////////////////////////////
// TYPES OF PACKAGE DOCUMENTS //
////////////////////////////////

const PackageFieldResolver = ({
  data,
  queryData,
  packageEntry,
  editable,
  fieldId,
  setFieldChange,
}) => {
  const userData = useContext(UserDataContext);

  const setPackageDoc = useContext(PackageDocSetterContext);

  const onChange = (value) => {
    setFieldChange((ex) => ({
      ...ex,
      [packageEntry?.id]: {
        ...ex?.[packageEntry?.id],
        data: value,
        modified: {
          date: new Date(),
          name: userData?.name?.first + " " + userData?.name?.last,
        },
        complete:
          value && (value?.length > 0 || value?.data !== undefined)
            ? true
            : false,
      },
    }));
  };

  let finalComponent = null;

  switch (packageEntry.type) {
    case "file":
      finalComponent = (
        <FileField
          files={data?.data ?? []}
          onChange={onChange}
          mini
          disabled={!editable}
        />
      );
      break;
    case "doc":
      finalComponent = (
        <Button2
          size="small"
          label={`${editable ? "Edit" : "View"} Document`}
          onClick={() => setPackageDoc({ doc: packageEntry, fieldId: fieldId })}
        />
      );
      break;
    case "reference":
      finalComponent = (
        <DataLinkSelectField
          value={data}
          onChange={onChange}
          queryData={queryData}
          schemaId={packageEntry?.schemaId}
          linkedDataId={packageEntry?.linkedDataId}
          dataType={packageEntry?.dataType}
          label={packageEntry?.label}
          filter={packageEntry?.filter}
          disabled={!editable}
        />
      );
      break;
    default:
      break;
  }

  return <FFTableCellCenterContent>{finalComponent}</FFTableCellCenterContent>;
};

const DataLinkSelectField = ({
  value,
  queryData,
  onChange,
  schemaId,
  linkedDataId,
  dataType = "string",
  label, // function for labelling external queries in selection
  filter, // function for filtering external queries in selection
  disabled,
}) => {
  const queries = useContext(ProjectQueriesContext);

  // Now we'll use memo to filter the queries to what we care for
  const selectableQueries = useMemo(() => {
    if (!queries) return [];
    let st1 = queries.filter((q) => q?.schemaId === schemaId);
    // Now build and apply filter if it exists
    // TODO: Make filter a data structure like it says in implementation doc
    try {
      const filterFunc = new Function("thisQuery", "externalQuery", filter);
      return st1.filter((q) => filterFunc(queryData, q));
    } catch (e) {
      log_error("Error in filter function for DataLinkSelectField", e);
      return st1;
    }
  }, [queries, schemaId, filter, queryData]);

  const visibleSelection = useMemo(() => {
    if (selectableQueries.length === 0) return {};
    // Now we'll make the labeller
    try {
      const labelFunc = new Function("externalQuery", label);
      return selectableQueries.reduce(
        (acc, q) => ({ ...acc, [q.id]: labelFunc(q) }),
        {}
      );
    } catch (e) {
      log_error("Error in label function for DataLinkSelectField", e);
      return selectableQueries.reduce(
        (acc, q) => ({ ...acc, [q.id]: q.id }),
        {}
      );
    }
  }, [selectableQueries, label]);

  const GeneratedDataComponent = (
    <FieldGenerator
      field={{
        type: dataType,
        name: "Data Preview",
        id: "datap",
        editableWhile: [],
        // TODO: Make this support more field types properly
      }}
      onChange={onChange}
      data={{
        // Show the linked data if it exists, otherwise show the deposited data, else none
        datap:
          (Object.keys(value?.data ?? {}).includes("data")
            ? value?.data?.data
            : value?.data) ?? "",
      }}
      withLabel
      editabilityConditions={{ forceEdit: !disabled, forceReadOnly: disabled }}
    />
  );

  // Modified onChange to capture both the data and queryId for the data
  const onChangeCapture = (value) => {
    // value here will be a queryId
    // Get the actual data from selectableQueries
    const selectedQuery = selectableQueries.find((q) => q.id === value);
    // Then iterate for linkedDataId
    let data = selectedQuery;
    linkedDataId?.split(".").forEach((index) => (data = data?.[index]));
    // Now run the onChange
    onChange({ queryId: value, data: data });
  };

  return (
    <>
      <SelectField
        label="Select Linked Data"
        data={value?.data?.queryId ?? ""}
        options={visibleSelection}
        onChange={(e) => {
          const { value } = e.target;
          onChangeCapture(value);
        }}
        disabled={disabled}
        editable
        editabilityConditions={{ forceEdit: true }}
      />
      {GeneratedDataComponent}
    </>
  );
};
