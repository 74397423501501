import React, { useState, useEffect, useMemo } from "react";
import q from "@queryit/api";
import { InteractiveTable } from "../../../ui/table";
import { Tile } from "../../../ui/containers";
import { useUsers } from "../../../../hooks/users";

export default () => {
  const [errors, setErrors] = useState(undefined);
  // All users is nessesary because the error returns only the user ID, and devlog is not project specific, so to find more user information all users are needed
  const [allUsers, addUser] = useUsers();

  useEffect(() => {
    let mounted = true;
    const unsub = q.devlogger.listen((errors) => {
      if (mounted) setErrors(errors);
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, []);

  const devlogUsers = useMemo(() => {
    if (errors === undefined) {
      return undefined;
    }
    
    return errors.map((error) => {
      const user = allUsers.find((user) => user.id === error.user);
      return {
        ...error,
        user: user ? user.email : error.user,
      };
    });
  }, [allUsers, errors]);

  return (
    <Tile>
      <InteractiveTable data={devlogUsers} columns={errorTableColumns} />
    </Tile>
  );
};

// time and date, user, message
const errorTableColumns = [
  { name: "Date", index: "timestamp", specialFormat: "date" },
  { name: "Time", index: "timestamp", specialFormat: "time" },
  { name: "User", index: "user" },
  { name: "Message", index: "error" },
];
