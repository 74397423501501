import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import CSSTransition from "react-transition-group/CSSTransition";
import { isMobile } from "react-device-detect";
import { ThemeProvider } from "@mui/material/styles";
import {
  Button,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  Divider,
  Tabs,
  Tab,
  Badge,
  Divider as MenuDivider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  AssignmentTurnedIn,
  Close,
  Comment,
  FormatListBulleted,
  Forum,
  MoreHoriz,
  People,
  Save,
  Settings,
  LiveHelp,
  AssignmentReturn,
  History,
} from "@mui/icons-material";

import { MuiPanelThemeContext } from "../../../App";
import { ModalPrompt, ModalContent, ModalFooter } from "../../ui/containers";
import EBound from "../../ui/errorbounds";
import { useComments } from "../../../hooks/queries";

import { PDFViewer } from "@react-pdf/renderer";
import { useHotkey } from "../../../hooks/system";
import { useSearchParams } from "react-router-dom";
import { ProjectReferenceContext, UserPermissionsContext } from "..";

import {
  PackageDocContext,
  PackageDocSetterContext,
} from "./fields/packagedoc";

import { QUERYVIEWS, ROUTEKEYS, SUBQUERYVIEWS } from "../../../common/query";

const useBadgeStyles = makeStyles({
  badge: {
    fontSize: 8,
    height: 16,
    minWidth: 16,
    width: 16,
    borderRadius: "50%",
    backgroundColor: "white",
    color: "purple",
    border: `1px solid purple`,
  },
});

const confirmationModalDefaults = {
  open: false,
  title: undefined,
  message: undefined,
  confirm: () => {},
};

const QueryViewDetails = {
  [QUERYVIEWS.QUERY]: {
    icon: <FormatListBulleted></FormatListBulleted>,
    tooltip: "Query",
  },
  [QUERYVIEWS.NEGOTIATOR]: {
    icon: <Forum></Forum>,
    tooltip: "Contract Negotiator",
  },
  [QUERYVIEWS.EVALUATOR]: {
    icon: <AssignmentTurnedIn></AssignmentTurnedIn>,
    tooltip: "Evaluations",
  },
  [QUERYVIEWS.EXTERNAL_FORM]: {
    icon: <AssignmentReturn></AssignmentReturn>,
    tooltip: "External Forms",
  },
  [QUERYVIEWS.CLARIFICATIONS]: {
    icon: <LiveHelp></LiveHelp>,
    tooltip: "Clarifications",
  },
  [QUERYVIEWS.ACTIONS]: {
    icon: <People></People>,
    tooltip: "Actions",
  },
  [QUERYVIEWS.REVISIONS]: {
    icon: <History></History>,
    tooltip: "File Revisions",
  },
};

export const QueryStatusContext = React.createContext(undefined);
export const AutoCompleteUserlistContext = React.createContext(false);

export default ({
  title,
  status,
  metaTitle,
  isOverdue,
  children,
  queryFormProps,
  schemaData, // So that query can be opened from any schema
  defaultView,
  dirty,
  saveQuery,
  deleteQuery,
  cancelQuery,
  commentsOpen,
  setCommentsOpen,
  query,
  statusMessage,
  setStatusMessage, // Status message data for status banner
}) => {
  // Modal States
  const [dirtyModal, setDirtyModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    ...confirmationModalDefaults,
  }); // add message to activate

  // this should be a search path to be pushed "on top" of the current query
  const [customRoute, setCustomRoute] = useState(undefined);

  // Fetch the state for the query status
  const queryStatus = useContext(QueryStatusContext);

  // Package doc state management injected at highest level
  const [packageDoc, setPackageDoc] = useState(undefined);

  // Render Management
  const cancelRender = useRef(false);

  // Search hook
  const [searchParams, setSearchParams] = useSearchParams();

  // Add hotkey
  useHotkey("Escape", () => beginCloseQuery());

  // Get (query) view state
  const [search] = useSearchParams();
  const queryRoute = search.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW);

  // Animation
  const [anim, setAnim] = useState(false);
  useEffect(() => setAnim(true), []);

  const [bannerAnim, setBannerAnim] = useState(false);
  // Animate incoming status messages
  useEffect(() => {
    setBannerAnim(!!statusMessage);
    if (statusMessage) {
      setTimeout(() => {
        setBannerAnim(false);
      }, 3000);
    }
  }, [statusMessage]);
  // Clear status message after animation
  useEffect(() => {
    if (!bannerAnim) {
      setTimeout(() => setStatusMessage(""), 500);
    }
  }, [bannerAnim, setStatusMessage]); // On banner close, clear message

  // Close Controls
  const forceClose = useRef(false);
  const beginCloseQuery = useCallback(
    (force = false) => {
      if (force) forceClose.current = true;
      if (setCommentsOpen) setCommentsOpen(false); // Close comments on begining close
      setAnim(false);
    },
    [setCommentsOpen]
  );

  // Set the default (query) view state
  useEffect(() => {
    const queryRoute = searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW);
    if (!queryRoute) {
      setSearchParams((ex) => {
        ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.QUERY);
        return ex;
      });
    }
  }, [searchParams, setSearchParams]);

  // Function for changing the (query) view state
  const changeQView = (newView) => {
    setSearchParams((ex) => {
      ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, newView);
      return ex;
    });
  };

  // Set the default (actions) view state
  useEffect(() => {
    const actionsRoute = searchParams.get(ROUTEKEYS.QUERY_PANE_SUB_VIEW);
    if (!actionsRoute) {
      setSearchParams((ex) => {
        // Safety check in the setter
        if (ex.get(ROUTEKEYS.QUERY_PANE_SUB_VIEW) === undefined) {
          ex.set(ROUTEKEYS.QUERY_PANE_SUB_VIEW, SUBQUERYVIEWS.DEFAULT);
        }
        return ex;
      });
    }
  }, [searchParams, setSearchParams]);

  const hasFiles = useMemo(() => {
    return schemaData?.fields?.some(checkFileType);
  }, [schemaData?.fields]);

  // Now use schema to setup available views
  // DEFECT: This really should be a useMemo or something similar, but other rerenders may be out of control...
  const determineViewState = () => {
    const potentialViewStates = [QUERYVIEWS.QUERY];

    // Always show actions if schema type or config
    if (
      schemaData.settings?.enableTasks ||
      ["approval", "response"].includes(schemaData.type) ||
      queryStatus.approval_stage_count() > 0 ||
      schemaData.settings?.useDueDate ||
      schemaData.settings?.useDistributionList
    ) {
      potentialViewStates.push(QUERYVIEWS.ACTIONS);
    }

    // Always show the forms pane if there are forms in the Query
    if (schemaData?.formCompositions) {
      potentialViewStates.push(QUERYVIEWS.EXTERNAL_FORM);
    }

    // Different view potentials with new vs not new
    // eslint-disable-next-line no-empty
    if (status === "new") {
    } else {
      if (schemaData?.formCompositions && queryFormProps?.hasIssuedForms) {
        potentialViewStates.push(QUERYVIEWS.NEGOTIATOR);
        // Then check if clarifications are available
        if (
          schemaData?.formCompositions &&
          schemaData?.formCompositions?.some(
            (fc) => fc?.settings?.allowClarifications !== false
          )
        ) {
          potentialViewStates.push(QUERYVIEWS.CLARIFICATIONS);
        }
        // And if evaluations are available (will be blank before it's evaluation time)
        // DEFECT: Need to move evaluation flag to actions? Or something like that?
        if (
          queryStatus?.currentStatus.statusType === "evaluate" ||
          queryFormProps?.hasEvaluations
        ) {
          potentialViewStates.push(QUERYVIEWS.EVALUATOR);
        }
      }
      // If there are file fields, show the revisions view
      if (hasFiles) {
        potentialViewStates.push(QUERYVIEWS.REVISIONS);
      }
    }

    if (potentialViewStates.includes(defaultView)) {
      setSearchParams((ex) => {
        ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, defaultView);
        return ex;
      });
    }

    return potentialViewStates;
  };

  // View States
  const [availableViewStates] = useState(determineViewState());

  // Get theme
  const panelTheme = useContext(MuiPanelThemeContext);

  const queryDidClose = useCallback(() => {
    searchParams.delete(ROUTEKEYS.NEW_QUERY_ID);
    searchParams.delete(ROUTEKEYS.EXISTING_QUERY_ID);
    searchParams.delete("formViewId");
    searchParams.delete("messenger");
    searchParams.delete("fc");
    searchParams.delete(ROUTEKEYS.QUERY_PANE_MAIN_VIEW);
    searchParams.delete(ROUTEKEYS.QUERY_PANE_SUB_VIEW);
    if (customRoute) {
      setSearchParams(customRoute);
      setCustomRoute(undefined);
    } else {
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, customRoute]);

  const executeCloseQuery = useCallback(() => {
    if (dirty && !forceClose.current) {
      setDirtyModal(true);
    } else {
      queryDidClose();
    }
  }, [queryDidClose, dirty]);

  // User Permissions evaluation
  // const userCanDeleteQuery = userCanDeleteQuery || userIsAdmin;

  return (
    <ThemeProvider theme={panelTheme}>
      <PackageDocContext.Provider value={packageDoc}>
        <PackageDocSetterContext.Provider value={setPackageDoc}>
          <QueryBlur onClick={() => beginCloseQuery()} />
          <Modal
            open={dirtyModal}
            onClose={() => {
              setDirtyModal(false);
              setAnim(true);
            }}
          >
            <DialogContent>
              <ModalPrompt>
                <ModalContent>
                  <h2>Unsaved Changes</h2>
                  <p>
                    There are unsaved changes in your query. Would you like to
                    save?
                  </p>
                </ModalContent>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setDirtyModal(false);
                      setAnim(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setDirtyModal(false);
                      queryDidClose();
                    }}
                  >
                    Discard
                  </Button>
                  {status !== "new" ? (
                    <Button
                      onClick={() => {
                        setDirtyModal(false);
                        saveQuery();
                        queryDidClose();
                      }}
                    >
                      Save
                    </Button>
                  ) : null}
                </ModalFooter>
              </ModalPrompt>
            </DialogContent>
          </Modal>
          <Modal
            open={confirmationModal.open}
            onClose={() => {
              setConfirmationModal({ ...confirmationModalDefaults });
            }}
          >
            <DialogContent style={{ display: "flex", overflowY: "overlay" }}>
              <ModalPrompt>
                <ModalContent>
                  <h2>{confirmationModal.title}</h2>
                  <p>{confirmationModal.message}</p>
                </ModalContent>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setConfirmationModal({ ...confirmationModalDefaults });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      confirmationModal.confirm();
                      setConfirmationModal({ ...confirmationModalDefaults });
                    }}
                  >
                    OK
                  </Button>
                </ModalFooter>
              </ModalPrompt>
            </DialogContent>
          </Modal>
          <CSSTransition
            in={anim}
            timeout={200}
            classNames="query"
            onExited={executeCloseQuery}
          >
            <QueryDiv
              style={{
                width:
                  schemaData && schemaData.customWidth && !isMobile
                    ? `${schemaData.customWidth}px`
                    : undefined,
              }}
            >
              {availableViewStates.length > 1 && !isMobile && (
                <FloatingIconButtonContainer>
                  {availableViewStates.map((thisViewState) => (
                    <ViewStateButton
                      key={`view-state-${thisViewState}`}
                      myViewState={thisViewState}
                      viewDetails={QueryViewDetails[thisViewState]}
                      selected={queryRoute === thisViewState}
                    />
                  ))}
                </FloatingIconButtonContainer>
              )}
              <QueryHeaderComponent
                title={title}
                status={status}
                metaTitle={metaTitle}
                beginCloseQuery={beginCloseQuery}
                saveQuery={saveQuery}
                isOverdue={isOverdue}
                deleteQuery={deleteQuery}
                cancelQuery={cancelQuery}
                setConfirmationModal={setConfirmationModal}
                cancelRender={cancelRender}
                commentsOpen={commentsOpen}
                setCommentsOpen={
                  schemaData.settings?.enableComments && status !== "new"
                    ? setCommentsOpen
                    : undefined
                } // Only provide the setter if comments are enabled :)
                availableViewStates={availableViewStates}
                query={query}
                schemaId={schemaData.id}
                schemaData={schemaData}
              />
              {/* This is where we do mobile support for query view selecting */}
              {availableViewStates.length > 1 &&
                isMobile &&
                availableViewStates.includes(queryRoute) && (
                  <Tabs
                    value={queryRoute}
                    onChange={(e, c) => {
                      changeQView(c);
                    }}
                  >
                    {availableViewStates.map((thisViewState) => (
                      <Tab
                        key={`view-state-${thisViewState}`}
                        label={thisViewState}
                        value={thisViewState}
                      />
                    ))}
                  </Tabs>
                )}
              <CSSTransition
                in={bannerAnim}
                timeout={3600}
                classNames="querybanner"
              >
                <QueryStatusBanner>{statusMessage}</QueryStatusBanner>
              </CSSTransition>
              <EBound area="Query Pane Internal Pane Render">
                {!cancelRender.current &&
                  React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                      return React.cloneElement(child, {
                        beginClose: beginCloseQuery,
                        setDirtyModal: setDirtyModal,
                        setConfirmationModal: setConfirmationModal,
                        setCustomRoute: setCustomRoute,
                        dirty: dirty,
                        viewState: queryRoute,
                      });
                    }
                  })}
              </EBound>
            </QueryDiv>
          </CSSTransition>
        </PackageDocSetterContext.Provider>
      </PackageDocContext.Provider>
    </ThemeProvider>
  );
};

// recursively search through schemaData to find file fields
const checkFileType = (obj) => {
  if (obj?.type === "files" && obj.enableRevisions) {
    return true;
  }

  if (Array.isArray(obj?.section)) {
    return obj.section.some(checkFileType);
  }

  if (Array.isArray(obj?.columns)) {
    return obj.columns.some(checkFileType);
  }

  if (Array.isArray(obj?.nested)) {
    return obj.nested.some(checkFileType);
  }

  return false;
};

const QueryHeaderComponent = React.memo(
  ({
    title,
    status,
    metaTitle,
    beginCloseQuery,
    saveQuery,
    isOverdue,
    deleteQuery,
    cancelQuery,
    cancelRender,
    setConfirmationModal,
    commentsOpen,
    setCommentsOpen,
    query,
    schemaId,
    schemaData,
  }) => {
    const classes = useBadgeStyles();
    const [viewQueryHeadMoreAnchor, setViewQueryHeadMoreAnchor] =
      useState(false);
    const [
      viewQueryHeadAdminSettingsAnchor,
      setViewQueryHeadAdminSettingsAnchor,
    ] = useState(false);

    const userPermissions = useContext(UserPermissionsContext);

    // Fetch search params for if we're moving to duplicate!
    const [searchParams, setSearchParams] = useSearchParams();

    // Also fetch the project for its ID
    const project = useContext(ProjectReferenceContext);

    const executeDeleteQuery = () => {
      beginCloseQuery(true);
      cancelRender.current = true;
      deleteQuery();
    };

    const executeCancelQuery = () => {
      beginCloseQuery(true);
      cancelRender.current = true;
      cancelQuery();
    };

    const executeDuplicateQuery = () => {
      beginCloseQuery(true);
      cancelRender.current = true;
      setSearchParams({
        nq: `${project.ref.id}-${schemaId}`,
        cp: `${project.ref.id}-${query.ref.id}`,
      });
    };

    // Comments for badge with number of comments
    const comments = useComments(query);

    return (
      <>
        <QueryHeader>
          {/* This tag should use the status color when available, otherwise accent color */}
          <QueryStatusInfo>
            {(!isOverdue || !isMobile) && (
              <QueryStatusTag>{status?.toUpperCase()}</QueryStatusTag>
            )}
            {isOverdue && (
              <QueryStatusTag
                color="#d90909"
                style={{ marginLeft: isMobile ? 4 : 0 }}
              >
                OVERDUE
              </QueryStatusTag>
            )}
            <QueryTitleContainer>
              <QueryTitle>{title}</QueryTitle>
              <MetaTitleData>{metaTitle}</MetaTitleData>
            </QueryTitleContainer>
          </QueryStatusInfo>
          <div style={{ display: "flex" }}>
            {/* Admin only "settings gear" to modify schema in-line */}
            {userPermissions.canEditSchema && !isMobile && (
              <Tooltip title="Admin Settings">
                <QueryHeaderIconButton
                  onClick={(e) =>
                    setViewQueryHeadAdminSettingsAnchor(e.currentTarget)
                  }
                >
                  <Settings />
                </QueryHeaderIconButton>
              </Tooltip>
            )}
            {setCommentsOpen !== undefined && (
              <Tooltip title="Comments">
                <QueryHeaderIconButton
                  onClick={() => setCommentsOpen(!commentsOpen)}
                >
                  <Badge
                    color="secondary"
                    badgeContent={comments?.length}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    classes={{ badge: classes.badge }}
                  >
                    <Comment />
                  </Badge>
                </QueryHeaderIconButton>
              </Tooltip>
            )}
            {status !== "new" && (
              <Tooltip title="Save">
                <QueryHeaderIconButton onClick={() => saveQuery()}>
                  <Save />
                </QueryHeaderIconButton>
              </Tooltip>
            )}
            <Tooltip title="More">
              <QueryHeaderIconButton
                onClick={(e) => setViewQueryHeadMoreAnchor(e.currentTarget)}
              >
                <MoreHoriz />
              </QueryHeaderIconButton>
            </Tooltip>
            <Tooltip title="Close">
              <QueryHeaderIconButton onClick={() => beginCloseQuery()}>
                <Close />
              </QueryHeaderIconButton>
            </Tooltip>
          </div>
        </QueryHeader>
        <Menu
          open={!!viewQueryHeadMoreAnchor}
          onClose={() => setViewQueryHeadMoreAnchor(undefined)}
          anchorEl={viewQueryHeadMoreAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {schemaData.settings?.enablePersonalFields && (
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.FIELDS);
                  return ex;
                });
                setViewQueryHeadMoreAnchor(undefined);
              }}
            >
              Edit Personal Fields
            </MenuItem>
          )}
          {schemaData.settings?.enablePersonalDefaults && (
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.DEFAULTS);
                  return ex;
                });
                setViewQueryHeadMoreAnchor(undefined);
              }}
            >
              Edit Personal Defaults
            </MenuItem>
          )}
          {(schemaData.settings?.enablePersonalDefaults ||
            schemaData.settings?.enablePersonalFields) && <MenuDivider />}
          {status !== "new" && userPermissions.canDuplicateQuery && (
            <MenuItem
              onClick={() => {
                executeDuplicateQuery();
                setViewQueryHeadMoreAnchor(undefined);
              }}
            >
              Duplicate Query
            </MenuItem>
          )}
          {deleteQuery && userPermissions.canDeleteQuery && (
            <MenuItem
              onClick={() => {
                setConfirmationModal({
                  ...DELETE_QUERY_CONFIRMATION,
                  confirm: () => executeDeleteQuery(),
                });
                setViewQueryHeadMoreAnchor(undefined);
              }}
            >
              Delete Query
            </MenuItem>
          )}
          {cancelQuery && userPermissions.canCancelQuery && (
            <MenuItem
              onClick={() => {
                setConfirmationModal({
                  ...CANCEL_QUERY_CONFIRMATION,
                  confirm: () => executeCancelQuery(),
                });
                setViewQueryHeadMoreAnchor(undefined);
              }}
            >
              Cancel Query
            </MenuItem>
          )}
          {searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW) !==
            QUERYVIEWS.PRINT && (
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.PRINT);
                  return ex;
                });
                setViewQueryHeadMoreAnchor(undefined);
              }}
            >
              Print Query
            </MenuItem>
          )}
          {searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW) ===
            QUERYVIEWS.PRINT &&
            !isMobile && (
              <MenuItem
                onClick={() => {
                  setSearchParams((ex) => {
                    ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.QUERY);
                    return ex;
                  });
                  setViewQueryHeadMoreAnchor(undefined);
                }}
              >
                Cancel Printing
              </MenuItem>
            )}
          {searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW) !==
            QUERYVIEWS.QR_CODE &&
            status !== "new" && ( // Don't show QR code for new queries
              <MenuItem
                onClick={() => {
                  setSearchParams((ex) => {
                    ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.QR_CODE);
                    return ex;
                  });
                  setViewQueryHeadMoreAnchor(undefined);
                }}
              >
                QR Code
              </MenuItem>
            )}
          {searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW) ===
            QUERYVIEWS.QR_CODE && (
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.QUERY);
                  return ex;
                });
                setViewQueryHeadMoreAnchor(undefined);
              }}
            >
              Cancel QR Code
            </MenuItem>
          )}
        </Menu>
        {(userPermissions.canEditSchema ||
          userPermissions.canEditSchemaDefaults ||
          userPermissions.canEditSchemaDynamicID) && (
          <Menu
            open={!!viewQueryHeadAdminSettingsAnchor}
            onClose={() => setViewQueryHeadAdminSettingsAnchor(undefined)}
            anchorEl={viewQueryHeadAdminSettingsAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(
                    ROUTEKEYS.QUERY_PANE_MAIN_VIEW,
                    QUERYVIEWS.ADMIN_FIELDS
                  );
                  return ex;
                });
                setViewQueryHeadAdminSettingsAnchor(undefined);
              }}
              disabled={!userPermissions.canEditSchema}
            >
              Edit Schema Fields
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(
                    ROUTEKEYS.QUERY_PANE_MAIN_VIEW,
                    QUERYVIEWS.ADMIN_DEFAULTS
                  );
                  return ex;
                });
                setViewQueryHeadAdminSettingsAnchor(undefined);
              }}
              disabled={!userPermissions.canEditSchemaDefaults}
            >
              Edit Schema Defaults
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(
                    ROUTEKEYS.QUERY_PANE_MAIN_VIEW,
                    QUERYVIEWS.ADMIN_VALIDATION_CONTROLS
                  );
                  return ex;
                });
                setViewQueryHeadAdminSettingsAnchor(undefined);
              }}
              disabled={!userPermissions.canEditSchemaValidationControls}
            >
              Edit Field Validations
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSearchParams((ex) => {
                  ex.set(
                    ROUTEKEYS.QUERY_PANE_MAIN_VIEW,
                    QUERYVIEWS.ADMIN_DYNAMIC_ID
                  );
                  return ex;
                });
                setViewQueryHeadAdminSettingsAnchor(undefined);
              }}
              disabled={!userPermissions.canEditSchemaDynamicID}
            >
              Edit Schema Dynamic ID
            </MenuItem>
            {status !== "new" && (
              <MenuItem
                onClick={() => {
                  setSearchParams((ex) => {
                    ex.set(
                      ROUTEKEYS.QUERY_PANE_MAIN_VIEW,
                      QUERYVIEWS.ADMIN_QUERY_DATA
                    );
                    return ex;
                  });
                  setViewQueryHeadAdminSettingsAnchor(undefined);
                }}
                disabled={!userPermissions.canForceEditQuery}
              >
                Edit Query Data
              </MenuItem>
            )}
            <Divider />
            <MenuItem disabled={true}>Configuration</MenuItem>
          </Menu>
        )}
      </>
    );
  }
);

const ViewStateButton = React.memo(({ myViewState, viewDetails, selected }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentState = searchParams.get(ROUTEKEYS.QUERY_PANE_MAIN_VIEW);

  const onClick = useCallback(() => {
    setSearchParams((ex) => {
      ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, myViewState);
      ex.set(ROUTEKEYS.QUERY_PANE_SUB_VIEW, SUBQUERYVIEWS.DEFAULT); // Reset actions view state to default
      return ex;
    });
  }, [setSearchParams, myViewState]);

  return (
    <Tooltip title={viewDetails.tooltip} placement="left">
      <MiniIconButtonShell
        onClick={onClick}
        selected={currentState === myViewState}
      >
        {viewDetails.icon}
      </MiniIconButtonShell>
    </Tooltip>
  );
});

const DELETE_QUERY_CONFIRMATION = {
  title: "Delete Confirmation",
  message:
    "Are you sure you want to delete this query? This action is not reversible.",
  open: true,
};

const CANCEL_QUERY_CONFIRMATION = {
  title: "Cancel Confirmation",
  message:
    "Are you sure you want to cancel this query? The query will not be open to its standard workflow following this action.",
  open: true,
};

const QueryDiv = styled.div`
  position: relative;

  height: ${isMobile ? "100%" : "85%"};
  width: ${isMobile ? "100%" : "72%"};
  background: white;
  border-radius: 4px;

  left: ${isMobile ? "0" : "14%"};
  top: ${isMobile ? "0" : "70px"};

  display: flex;
  flex-direction: column;
  flex-grow: 4;
  margin-bottom: 80px;

  z-index: 1200;

  font-family: "Roboto", sans-serif;

  opacity: 0; // Default before first animation

  &.query-enter {
    opacity: 0;
    transform: translateY(32px);
  }
  &.query-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.3s, transform 0.3s;
  }
  &.query-enter-done {
    opacity: 1;
  }
  &.query-exit {
    opacity: 1;
    transform: translateY(0px);
  }
  &.query-exit-active {
    opacity: 0;
    transform: translateY(32px);
    transition: opacity 0.2s, transform 0.2s;
  }
  &.query-exit-done {
    opacity: 0;
  }
`;

export const Logo = styled.img`
  height: 50px;
  max-width: 65%;
  object-fit: contain;
  margin: 8px;
`;

export const LogoDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 66px;
  min-width: 66px;
`;

const QueryStatusInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
`;

const QueryTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
`;

export const QueryStatusTag = styled.div`
  background: ${(props) => (props.color ? props.color : "#901D81")};
  border-radius: 6px;
  color: white;
  font-size: 12px;

  width: fit-content;
  height: fit-content;
  padding: 4px 6px;
  margin-right: 4px;
  margin-left: 8px;
`;

const QueryHeaderIconButton = styled(IconButton)`
  padding: 6px !important;
`;

export const QueryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  // box-shadow: 0 4px 8px -2px gray;
  border-bottom: 1px solid #c2c2c2;

  border-radius: 4px 4px 0px 0px;

  z-index: 4;
`;

/** These are used by state-aware queries to provide data-aware content. (e.g new_query, existing_query) */

export const QueryContent = styled.div`
  margin-left: 5px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const QuerySection = styled.div`
  margin-bottom: 32px;
  border-top: solid 1px #ddd;
  width: 100%;

  :first-of-type {
    border-top: none;
  }
`;

export const QuerySectionHeader = styled.div`
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 2px;
`;

export const QueryButtons = styled.div`
  width: 100%;
  flex: 0 0 50px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  overflow: visible;
  padding-right: 40px;
`;

export const QueryContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

export const QueryBlur = styled.button`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  border: none;
  // &:hover {
  //   cursor: pointer;
  // }
  &:focus {
    outline: none;
  }

  background: rgba(20, 20, 20, 0.85);
`;

export const ModalStyle = {
  content: {
    width: isMobile ? "100%" : "20%",
    height: isMobile ? "100%" : "fit-content",
    top: isMobile ? "0" : "40%",
    left: isMobile ? "0" : "40%",
    fontFamily: "ubuntu",
  },
  overlay: {
    backgroundColor: "rgba(20, 20, 20, 0.7)",
    zIndex: 3000,
  },
};

export const FloatingIconButtonContainer = styled.div`
  background: white;
  width: 32px;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  flex-direction: column;

  position: absolute;
  left: -50px;
  z-index: 1200;
`;

export const MiniIconButtonShell = styled.div`
  border-radius: 4px;
  width: 26px;
  height: 26px;
  padding: 3px;
  margin: 1px 0;

  background: ${(props) => (props.selected ? "#c5c5c5" : "white")};

  &:hover {
    background: #a3a3a3;
    cursor: pointer;
  }
`;

export const QueryPDFViewer = styled(PDFViewer)`
  width: 99%;
  height: 99%;
`;

export const QueryStatusBanner = styled.div`
  position: absolute;
  top: 44px;
  left: 10px;

  opacity: 0; // Before animating!

  width: fit-content;
  height: 32px;
  background: ${(props) => props.theme.palette.primary.main};
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

  padding: 0 5px;

  z-index: 3;

  &.querybanner-enter {
    opacity: 1;
    transform: translateY(-39px);
  }

  &.querybanner-enter-active {
    transform: translateY(0px);
    transition: transform 0.3s;
  }

  &.querybanner-enter-done {
    opacity: 1;
  }

  &.querybanner-exit {
    opacity: 1;
    transform: translateY(0px);
  }

  &.querybanner-exit-active {
    opacity: 1;
    transform: translateY(-39px);
    transition: transform 0.3s;
  }

  &.querybanner-exit-done {
    opacity: 0;
  }
`;

export const LinkedQueryString = styled.div`
  margin: 6px 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const QueryTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
`;

const MetaTitleData = styled(QueryTitle)`
  font-size: 11px;
  color: grey;

  // Hide on smaller displays that won't have the room
  @media (max-width: 680px) {
    display: none;
  }
`;
