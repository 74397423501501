import {
  // Types
  CollectionReference,
  DocumentReference,
  Query,
  DocumentSnapshot,
  QuerySnapshot,
  WriteBatch,
  // Functions
  getDoc,
  getDocs,
  DocumentData,
  onSnapshot,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  collectionGroup,
  writeBatch,
  // class initializer functions
  getCountFromServer,
  doc,
  collection,
  query,
  where,
  orderBy,
  UpdateData,
  Firestore,
  WhereFilterOp,
  QueryConstraint,
  WithFieldValue,
  SetOptions,
} from "firebase/firestore";

/** Basic datatype that all others inherit from. */
export interface BaseData {
  id: string;
}

/** Any type that will have a .get and .listen method. Needed for typescript.  */
type GettableType = DocumentReference | Query;
type GettableSnapshotType = QuerySnapshot | DocumentSnapshot;

/** This includes documents, collections, and queries (see firestore types.) Anything with a .get and .listen */
export abstract class Gettable<T extends BaseData> {
  abstract ref: GettableType;

  // Listen for realtime updates from firebase
  // This needs to be abstracted in order to overload onSnapshot differently
  abstract listen(
    observer: (data: T | T[]) => void,
    error: (err: any) => void
  ): CancelListener;

  // Big abstraction used in other gettables
  abstract snapshotToData(snapshot: GettableSnapshotType): T | T[];

  // Add an identifier for Query-It API custom bases
  get isQueryItCustomBase() {
    return true;
  }
}

type GettableSingularType = DocumentReference;

export abstract class GettableSingular<T extends BaseData> extends Gettable<T> {
  abstract ref: GettableSingularType;

  async get(): Promise<T> {
    const snapshot = await getDoc(this.ref);
    return this.snapshotToData(snapshot);
  }

  async exists(): Promise<boolean> {
    const res = await getDoc(this.ref);
    return res.exists();
  }

  // Listen for realtime updates from firebase
  listen(
    observer: (data: T) => void,
    error: (err: any) => void = (e) => {
      throw e;
    }
  ): CancelListener {
    return onSnapshot(
      this.ref,
      (snapshot: DocumentSnapshot) => {
        observer(this.snapshotToData(snapshot));
      },
      (err: any) => {
        error(err);
      }
    );
  }

  snapshotToData(snapshot: DocumentSnapshot): T {
    if (snapshot.exists()) {
      return { id: snapshot.id, ...snapshot.data() } as T;
    } else {
      return {} as T;
    }
  }
}

/** This is a subset of GettableType, specifically only queries and collections (that encompass ARRAYS of data. ) */
type GettablePluralType = CollectionReference | Query;

/** Implements common snapshot=>data for all plural types of data.*/
export abstract class GettablePlural<T extends BaseData> extends Gettable<T> {
  abstract ref: GettablePluralType;

  async get(): Promise<T[]> {
    const snapshot = await getDocs(this.ref);
    return this.snapshotToData(snapshot);
  }

  // Listen for realtime updates from firebase
  listen(
    observer: (data: T[]) => void,
    error: (err: any) => void = (e) => {
      throw e;
    }
  ): CancelListener {
    return onSnapshot(
      this.ref,
      (snapshot: QuerySnapshot) => {
        observer(this.snapshotToData(snapshot));
      },
      (err: any) => {
        error(err);
      }
    );
  }

  listenForSnapshot(
    observer: (snapshot: QuerySnapshot) => void,
    error: (err: any) => void = (e) => {
      throw e;
    }
  ): CancelListener {
    return onSnapshot(
      this.ref,
      (snapshot: QuerySnapshot) => {
        observer(snapshot);
      },
      (err: any) => {
        error(err);
      }
    );
  }

  snapshotToData(snapshot: QuerySnapshot): T[] {
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as T));
  }

  async count(): Promise<number> {
    return (await getCountFromServer(this.ref)).data().count;
  }
}

/** Queries can be read, but not added to. */
export class BaseQuery<T extends BaseData> extends GettablePlural<T> {
  ref: Query;

  constructor(ref: Query) {
    super();
    this.ref = ref;
  }
}

/** Collections can be added to and read. */
export class BaseCollection<T extends BaseData> extends GettablePlural<T> {
  ref: CollectionReference | Query;

  constructor(ref: CollectionReference | Query | BaseCollection<any>) {
    super();
    // First we handle the "BaseCollection" case
    if ("isQueryItCustomBase" in ref) {
      this.ref = ref.ref;
    } else {
      // Then this case is for a new collection reference or Query
      this.ref = ref;
    }
  }

  /** Add a new piece of data to a collection. */
  async add(data: T): Promise<DocumentReference | null> {
    if (this.ref.type === "query") {
      throw new Error(
        "Cannot add to a query, please instantiate the associated collection instead"
      );
    }
    return addDoc(this.ref as CollectionReference, data);
  }

  /** Add a new piece of data to a collection with a specific id */
  // Note that we do not support doc with undefined id, use `add` for that case
  new(id: string) {
    if (this.ref.type === "query") {
      throw new Error(
        "Cannot add to a query, please instantiate the associated collection instead"
      );
    }
    return setDoc(doc(this.ref as CollectionReference, id), {} as T);
  }

  // Hop down the doc tree from collection to a doc in collection
  doc(id: string | undefined): BaseDocument<T> {
    if (id !== undefined) {
      return new BaseDocument<T>(doc(this.ref as CollectionReference, id));
    }
    return new BaseDocument<T>(doc(this.ref as CollectionReference));
  }

  orderBy(index?: string) {
    if (index) {
      return query(this.ref as CollectionReference, orderBy(index));
    } else {
      return query(this.ref as CollectionReference, orderBy("id"));
    }
  }

  where(field: string, opStr: string, value: any) {
    return new BaseCollection(
      query(
        this.ref as CollectionReference,
        where(field, opStr as WhereFilterOp, value)
      )
    );
  }

  compoundQuery(
    ...queryArguments: QueryConstraint[]
  ): BaseCollection<BaseData> {
    return new BaseCollection(
      query(this.ref as CollectionReference, ...queryArguments)
    );
  }

  get db(): BaseDatabase {
    return new BaseDatabase(this.ref.firestore);
  }

  /** This is a common use case for transforming data, say we get an array of users, this will create a map indexed by their ID. */
  static indexById<T extends BaseData>(datas: T[]): { [key: string]: T } {
    const byId: { [key: string]: T } = {};
    for (let data of datas) {
      byId[data.id] = data;
    }
    return byId;
  }
}

/** A single piece of data, e.g. a query or an action. */
export class BaseDocument<T extends BaseData> extends GettableSingular<T> {
  ref: DocumentReference;
  constructor(ref: DocumentReference) {
    super();
    this.ref = ref;
  }

  async set(data: any, options: SetOptions = {}): Promise<void> {
    return setDoc(this.ref as DocumentReference<T>, data, options);
  }

  /** Overwrite specific fields, we don't need to pass unchanged fields back in. */
  async update(data: any): Promise<void> {
    return updateDoc(this.ref as DocumentReference<T>, data);
  }

  // TODO: add update for map with dot notation

  async delete(): Promise<void> {
    return deleteDoc(this.ref);
  }

  // Hop down the doc tree from a doc to its sub-collections
  collection(path: string): BaseCollection<BaseData> {
    return new BaseCollection<BaseData>(collection(this.ref, path));
  }

  get db(): BaseDatabase {
    return new BaseDatabase(this.ref.firestore);
  }

  get path() {
    return this.ref.path;
  }
}

export class BaseDatabase {
  ref: Firestore;

  constructor(ref: Firestore) {
    this.ref = ref;
  }

  // Now we can use our friendly and familiar initializers to get collection references and run queries
  collection(path: string) {
    return new BaseCollection(collection(this.ref, path));
  }

  doc(path: string, id: string) {
    return new BaseDocument(doc(this.ref, path, id));
  }

  collectionGroup(index: string) {
    return new BaseCollection(collectionGroup(this.ref, index));
  }

  startTransaction() {
    return new FBBatch(this.ref);
  }
}

export class FBBatch {
  ref: WriteBatch;
  addableDocs: BaseDocument<any>[] = [];

  constructor(ref: Firestore) {
    this.ref = writeBatch(ref);
  }

  /**
   * Issue a doc ref for a new document under the collection. This document MUST be set under the batch before committing else it will be lost.
   *
   * @param collection
   * @returns
   */
  add(collection: BaseCollection<any>): BaseDocument<any> {
    // Create the doc blank (for that juicy auto-id)
    const newDoc = collection.doc(undefined);
    this.addableDocs.push(newDoc);
    // Return the doc ref so that it can be added to the batch once ready
    return newDoc;
  }

  set(
    ref: DocumentReference | BaseDocument<any>,
    data: { [key: string]: any } = {}
  ): void {
    let cleanRef: DocumentReference;
    // Convert BaseDocument into ref
    if ("isQueryItCustomBase" in ref) {
      cleanRef = ref.ref;
      // Check if this doc was in addableDocs, if so, remove it
      const index = this.addableDocs.indexOf(ref);
      if (index > -1) {
        this.addableDocs.splice(index, 1);
      }
    } else {
      cleanRef = ref;
    }
    // Do the set in the batch
    this.ref.set(cleanRef, data);
  }

  update(
    ref: DocumentReference | BaseDocument<any>,
    data: { [key: string]: any }
  ): void {
    let cleanRef: DocumentReference;
    // Convert BaseDocument into ref
    if ("isQueryItCustomBase" in ref) {
      cleanRef = ref.ref;
    } else {
      cleanRef = ref;
    }
    this.ref.update(cleanRef, data);
  }

  delete(ref: DocumentReference | BaseDocument<any>): void {
    let cleanRef: DocumentReference;
    // Convert BaseDocument into ref
    if ("isQueryItCustomBase" in ref) {
      cleanRef = ref.ref;
    } else {
      cleanRef = ref;
    }
    // Do the delete in the batch
    this.ref.delete(cleanRef);
  }

  commit(): Promise<void> {
    if (this.addableDocs.length > 0) {
      console.warn("You have unadded docs in your batch, they will be ignored");
    }
    return this.ref.commit();
  }
}

/** A callback that removes a listener. This is important to prevent memory leaks. */
export type CancelListener = () => void;
