import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { PrintableField } from "../fields";
import { useUserLookup } from "../../../../../hooks/users";

import { PDFConstants, PDFStyles } from "../style";
import { APPROVAL_SCHEMA_DEFAULT } from "../../actions/approvals/view";
import PrintQueryHeader from "../../../../print/header";

const fillableBlankApprovals = [
  { id: "blank-approval-0", data: {} },
  { id: "blank-approval-1", data: {} },
];

export default ({ schemaData, approvals, queryData, projectLogo }) => {
  const [author, authorRef] = useUserLookup(
    queryData ? queryData.creator : undefined
  );
  approvals = approvals ? approvals : fillableBlankApprovals;
  const approvalSchema = schemaData.approvalFields ?? APPROVAL_SCHEMA_DEFAULT;

  return (
    <Page>
      <PrintQueryHeader
        projectLogo={projectLogo}
        author={author}
        title={schemaData.name}
        id={queryData ? queryData.dynamicId ?? queryData.id : undefined}
        typeTitle="Approvals for"
      />
      {/* This is where the body of the approvals start */}
      <View style={PDFStyles.body}>
        {approvals
          ? // Compatibility with new Approval structure
            (approvals.length ? approvals : [approvals])
              .sort((app) => app.stage)
              .map((app) => (
                <View style={PDFStyles.query} key={app.id}>
                  {approvalSchema.map((fld) => {
                    return (
                      <View
                        style={PDFStyles.queryRow}
                        key={fld.nested ? fld.nested[0].name : fld.name}
                      >
                        {fld.nested &&
                          fld.nested.map((nstfld) => (
                            <View
                              key={nstfld.id}
                              style={[
                                PDFStyles.queryCol,
                                {
                                  width:
                                    PDFConstants.BODY_WIDTH / fld.nested.length,
                                },
                              ]}
                            >
                              <PrintableField
                                field={nstfld}
                                fieldData={app.data[nstfld.id]}
                                data={app.data}
                                width={
                                  PDFConstants.BODY_WIDTH / fld.nested.length
                                }
                              />
                            </View>
                          ))}
                        {!fld.nested && (
                          <View
                            key={fld.id}
                            style={[
                              PDFStyles.queryCol,
                              { width: PDFConstants.BODY_WIDTH },
                            ]}
                          >
                            <PrintableField
                              field={fld}
                              fieldData={app.data[fld.id]}
                              data={app.data}
                              width={PDFConstants.BODY_WIDTH}
                            />
                          </View>
                        )}
                      </View>
                    );
                  })}
                  {/* Also add the signature */}
                  <View style={PDFStyles.queryRow}>
                    <View
                      style={[
                        PDFStyles.queryCol,
                        { width: PDFConstants.BODY_WIDTH },
                      ]}
                    >
                      <PrintableField
                        field={{
                          type: "signature",
                          id: "signature",
                          name: "Approval Signature",
                        }}
                        fieldData={app.signature}
                        data={app}
                        width={PDFConstants.BODY_WIDTH}
                      />
                    </View>
                  </View>
                </View>
              ))
          : null}
      </View>
      <View style={PDFStyles.footer} fixed>
        <Text style={PDFStyles.footerText}>
          This form was generated by Query-It on{" "}
          {`${
            String(new Date().getDate()).padStart(2, "0") +
            "/" +
            String(new Date().getMonth() + 1).padStart(2, "0") +
            "/" +
            String(new Date().getFullYear())
          }`}
        </Text>
        {/* There's a bottom right place here for another logo */}
      </View>
    </Page>
  );
};
