// Hook to fetch all feedback docs
import { useState, useEffect } from "react";
import q from "@queryit/api";

export const useFeedbackSet = () => {
  const [feedback, setFeedback] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    const unsub = q.feedback.listen((feed) => {
      if (mounted) setFeedback(feed);
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, []);

  return feedback;
};
