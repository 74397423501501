// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-c9bc500a5d/5/.yarn/berry/cache/css-loader-npm-6.8.1-30d84b4cf1-10c0.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-c9bc500a5d/5/.yarn/berry/cache/css-loader-npm-6.8.1-30d84b4cf1-10c0.zip/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-c9bc500a5d/5/.yarn/berry/cache/css-loader-npm-6.8.1-30d84b4cf1-10c0.zip/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./sol.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"sol\";\n  src: local(\"sol\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n", "",{"version":3,"sources":["webpack://./app/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,6EAAoD;AACtD","sourcesContent":["@font-face {\n  font-family: \"sol\";\n  src: local(\"sol\"), url(./sol.ttf) format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
