import { BaseCollection, BaseDocument, BaseData } from "../base";

interface FeedbackData extends BaseData {
  userID: string;
  name: {
    first: string;
    last: string;
  }
  rating: number;
  comments?: string;
}

//feedback will be a collection of documents, with each document being an individual feedback piece
export class FeedbackSet extends BaseCollection<FeedbackData> {
  feedback(id: string): Feedback {
    return this.doc(id) as Feedback;
  }
}

type Feedback = BaseDocument<FeedbackData>;
