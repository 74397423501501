import { BaseCollection, BaseDocument, BaseData } from "../../../base";

export interface MessageData extends BaseData {
  message: string;
}

export class Messages extends BaseCollection<MessageData> {
  message(id: string): Message {
    return this.doc(id) as Message;
  }
}

type Message = BaseDocument<MessageData>;
