export const INPUT_TYPES = {
  // Basics
  string: { label: "Text", category: "Basics" },
  number: { label: "Number", category: "Basics" },
  textarea: { label: "Text Area", category: "Basics" },
  date: { label: "Date", category: "Basics" },
  time: { label: "Time", category: "Basics" },
  checkbox: { label: "Single Checkbox (Boolean)", category: "Basics" },
  // Tables
  table: { label: "Table", category: "Tables" },
  statictable: { label: "Static Table", category: "Tables" },
  tableadv: { label: "Advanced Table", category: "Tables" },
  package: { label: "Document Package", category: "Tables" },
  // Dropdowns
  select: { label: "Dropdown", category: "Dropdowns" },
  userlist: { label: "Users Dropdown List", category: "Dropdowns" },
  radio: { label: "Radio Buttons", category: "Dropdowns" },
  boxset: { label: "Checkbox Set", category: "Dropdowns" },
  adhoc: { label: "Adhoc Dropdown", category: "Dropdowns" },
  // Complex Types
  arbcalc: { label: "Arbitrary Calculation", category: "Complex" },
  multi: { label: "Multi-Typed", category: "Complex" },
  timetracker: { label: "Time Tracker", category: "Complex" },
  // Statics
  message: { label: "Static Message", category: "Statics" },
  staticimage: { label: "Static Image", category: "Statics" },
  staticdoc: { label: "Static Document Reference", category: "Statics" },
  link: { label: "Link", category: "Statics" },
  // File-Based
  files: { label: "Attachment", category: "Files" },
  photos: { label: "Photo Gallery Attachments", category: "Files" },
  signature: { label: "Signature", category: "Files" },
};

export const PERSONAL_FIELD_EDITOR_TYPES = {
  // Only allow a few for the user to select in the Personal Field Editor
  // Basics
  string: { label: "Text", category: "Basics" },
  number: { label: "Number", category: "Basics" },
  textarea: { label: "Text Area", category: "Basics" },
  date: { label: "Date", category: "Basics" },
  time: { label: "Time", category: "Basics" },
  checkbox: { label: "Single Checkbox (Boolean)", category: "Basics" },
  // Dropdowns
  select: { label: "Dropdown", category: "Dropdowns" },
  boxset: { label: "Checkbox Set", category: "Dropdowns" },
  radio: { label: "Radio Buttons", category: "Dropdowns" },
  // Complete Types
  timetracker: { label: "Time Tracker", category: "Complex" },
  // File-Based
  files: { label: "Attachment", category: "Files" },
  photos: { label: "Photo Gallery Attachments", category: "Files" },
};

export const ADVANCED_TABLE_INPUT_TYPES = {
  text: "text",
  number: "number",
  select: "select",
  mselect: "multi select",
  date: "date",
  checkbox: "checkbox",
  radio: "radio",
  rowcalc: "Row Calculation",
  rowindex: "row index",
}

export const STD_TABLE_INPUT_TYPES = {
  tableradio: "Table Radio Buttons (Table Only)",
  rowindex: "Row Indexer (Table Only)",
};