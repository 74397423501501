import React, { useState, useContext } from "react";
import { FormDataContext } from ".";

import { FormContent } from ".";
import { parse_on_fields } from "../../../tools/forms";
import { QueryFields } from "../../project/query/fields";

export default () => {
  const formData = useContext(FormDataContext);

  const data = {};
  parse_on_fields(
    formData?.previewData ?? {},
    (fld) => (data[fld.id] = fld.default)
  );

  return (
    <FormContent>
      <QueryFields fieldSet={formData.previewData} data={data} preview />
    </FormContent>
  );
};
