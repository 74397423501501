import { useState, useEffect, useContext, useRef } from "react";
import { SelectedSchemaContext } from "../components/project";
import { QueryStatusContext } from "../components/project/query/query";
import { field_is_empty } from "../tools/forms";
import { log_error } from "../tools/logger";

/** Field validation hook. Used for field requirement and field input binding
 *
 * @param {*} field  The field's schema
 * @param {*} data  The field's data
 * @param {*} setQueryValidationErrors  The broader form's error state, used to backtrace error state of the form as a whole
 * @param {*} status The query's status at the moment, which controls when validation on the field is run
 */
export const useFieldValidation = (
  field,
  data,
  setQueryValidationErrors,
  status
) => {
  // Setup the error state for the field
  const [error, setError] = useState(undefined);
  const errorStateFound = useRef(false);

  // We'd also like to pull in the working schema if we can (for the custom schema)
  // const schema = useContext(SelectedSchemaContext);
  const queryStatus = useContext(QueryStatusContext);

  // Effect to propagate error up a larger chain (if it is configured to do so)
  useEffect(() => {
    // We add a check to make sure that we don't propagate before an error is actually found (don't need to update the chain)
    if (error && !errorStateFound.current) {
      errorStateFound.current = true;
    }
    // Now do the propagation
    if (setQueryValidationErrors && errorStateFound.current) {
      setQueryValidationErrors((ex) => ({
        ...ex,
        [field.id]: error !== undefined,
      }));
    }
  }, [error, setQueryValidationErrors, field]);

  // Effect to check the field's validity
  useEffect(() => {
    // Handle commit behaviour for file fields
    if (
      field &&
      field.type === "files" &&
      field.enableRevisions &&
      data?.commited !== true
    ) {
      // Comitted must be true when enableRevisions exists in order to submits
      setError("Please commit file changes.");
      return;
    }

    // Now handle field requirement check
    if (field && field.required) {
      // If the field type does not require validation
      if (["message"].includes(field.type)) {
        return;
      }

      // Check for preset first
      switch (field.required?.validator?.preset) {
        case undefined: // Undefined defaults to populated
        case "populated":
          // If it is required, check when it's required, and if we are at or past that status
          if (
            !field.required?.status ||
            queryStatus.status_difference(field.required?.status ?? "new") > 0
          ) {
            // The required status is undefined, "", or upcoming, so we'll return here!
            setError(undefined);
            break;
          }
          // Now we know the field is required
          if (field_is_empty(field, data)) {
            // We only care to check if there is data
            setError(
              field.required?.validator?.message ??
                "This field cannot be left blank at this stage"
            );
            return;
          }
          break;
        case "custom":
          // We'll interpret and run the custom function here
          try {
            var validationFunc = new Function(
              "data",
              field.required.validator.func
            );
            if (!validationFunc(data)) {
              setError(
                field.required.validator.message ?? "This field is not valid"
              );
              return;
            }
          } catch (e) {
            // Just give up and call it valid
            log_error("Could not run custom validation on field " + field.id);
          }
          break;
        default:
          // If the validator preset is wrong, this field is always valid
          break;
      }

      // Check validations from the front-end editor
      const validations = field.required?.validations;
      if (
        validations &&
        !Object.keys(validations).every((key) => {
          const validationFunc = new Function("data", validations[key].func);
          if (
            (!["string", "textarea"].includes(field.type) && data === "") ||
            (data != null && validationFunc(data))
          ) {
            return true;
          }
          setError(
            validations[key]?.message ||
              field.required?.validator?.message ||
              "This field is not valid"
          );
          return false;
        })
      ) {
        return;
      }
      // If the nothing returns, then it passes!
    }
    setError(undefined);
    return;
  }, [data, field, status, queryStatus]);

  return error;
};
