import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { useUserLookup } from "../../../../../hooks/users";

import { PDFStyles } from "../style";
import PrintQueryHeader from "../../../../print/header";

export default ({ schemaData, queryData, projectLogo, comments }) => {
  const [author, authorRef] = useUserLookup(
    queryData ? queryData.creator : undefined
  );

  return (
    <Page>
      <PrintQueryHeader
        projectLogo={projectLogo}
        author={author}
        title={schemaData.name}
        id={queryData ? queryData.dynamicId ?? queryData.id : undefined}
        typeTitle="Comments for"
      />
      <View style={PDFStyles.body}>
        {comments && comments.length > 0 ? (
          comments.map((comment, index) => (
            <View key={index} style={{ marginBottom: 10 }}>
              <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                Commented by:{" "}
                {`${comment.user.name.first} ${comment.user.name.last}`}
              </Text>
              <Text style={{ fontSize: 11 }}>
                On:{" "}
                {new Date(comment.sent.seconds * 1000).toLocaleDateString(
                  "en-US"
                )}
              </Text>
              <Text style={{ fontSize: 11, marginTop: 2 }}>
                Comment: {comment.message[0].children[0].text}
              </Text>
            </View>
          ))
        ) : (
          <Text>No comments available</Text>
        )}
      </View>
      <View style={PDFStyles.footer} fixed>
        <Text style={PDFStyles.footerText}>
          This form was generated by Query-It on{" "}
          {`${
            String(new Date().getDate()).padStart(2, "0") +
            "/" +
            String(new Date().getMonth() + 1).padStart(2, "0") +
            "/" +
            String(new Date().getFullYear())
          }`}
        </Text>
        {/* There's a bottom right place here for another logo */}
      </View>
    </Page>
  );
};
