import React, { useState } from "react";

import { QuerySection, QuerySectionHeader } from "../query";
import { Inputs } from "../../../ui/inputs2";
import { FieldGenerator } from "./generator";

import { log_error } from "../../../../tools/logger";
import { DataLinkProvider } from "../../../ui/datalink";

export const ShowFieldErrorsContext = React.createContext(false);

// NOTE: I know that "onFieldChange" and "setFieldChange" are the same, but setFieldChange is new and experimental, so just chill for a bit
export const QueryFields = React.memo(
  React.forwardRef(
    (
      {
        data,
        fieldSet,
        onFieldChange,
        setFieldChange,
        status,
        queries,
        project,
        setQueryValidationErrors,
        noHeaders,
        editabilityConditions,
        queryData,
        // Flags
        preview,
      },
      ref
    ) => {
      // Data link state
      const [dataLinkReferences, setDataLinkReferences] = useState({});

      const fields = [];
      const sections = [];

      // Adjust variables for flags
      if (preview) {
        status = "readonly";
        editabilityConditions = {
          ...editabilityConditions,
          forceReadOnly: true,
        };
        setFieldChange = undefined;
        onFieldChange = () => () => {};
      }

      if (!fieldSet) {
        return null;
      }

      for (let field of fieldSet) {
        // In our schema, a nested array means they should appear side by side.
        // No section heading means to be included in the "Fields" header
        if (field.section) {
          if (field.doRender) {
            // DEFECT: When this render calculation is performed, also wipe fields if no render
            try {
              if (!Function("queryData", field.doRender)(queryData)) {
                continue; // Skip whole section for section conditional
              }
            } catch (e) {
              log_error("Evalauting conditional section failed");
              continue; // Skip anyway because its a mess
            }
          }
          // Otherwise go to a regular section
          sections.push(
            <QuerySection
              key={`sec-${field.name.toLowerCase().replaceAll(" ", "-")}`}
            >
              {!noHeaders && (
                <QuerySectionHeader>{field.name}</QuerySectionHeader>
              )}
              {field.section.map((fld) => {
                return (
                  <Inputs
                    key={`inp-wrapper-${
                      fld.nested ? `nst-${fld.nested[0].id}` : fld.id
                    }`}
                  >
                    {(fld.nested ? fld.nested : [fld]).map((_field) => (
                      <FieldGenerator
                        key={`query-field-${_field.id}`}
                        field={_field}
                        data={data}
                        onChangeGenerator={onFieldChange}
                        setFieldChange={setFieldChange}
                        withLabel
                        editabilityConditions={editabilityConditions}
                        status={status}
                        setQueryValidationErrors={setQueryValidationErrors}
                        queries={queries}
                        project={project}
                        queryid={queryData?.id}
                        schema={queryData?.schemaId}
                      />
                    ))}
                  </Inputs>
                );
              })}
            </QuerySection>
          );
        } else {
          fields.push(
            <Inputs
              key={`inp-wrapper-${
                field.nested ? `nst-${field.nested[0].id}` : field.id
              }`}
            >
              {(field.nested ? field.nested : [field]).map((_field) => (
                <FieldGenerator
                  key={`query-field-${_field.id}`}
                  field={_field}
                  data={data}
                  onChangeGenerator={onFieldChange}
                  setFieldChange={setFieldChange}
                  withLabel
                  editabilityConditions={editabilityConditions}
                  status={status}
                  setQueryValidationErrors={setQueryValidationErrors}
                  queries={queries}
                  project={project}
                  queryid={queryData?.id}
                  schema={queryData?.schemaId}
                />
              ))}
            </Inputs>
          );
        }
      }
      if (fields.length > 0) {
        sections.unshift(
          <QuerySection key="sec-fields-general">
            {!noHeaders && <QuerySectionHeader>Fields</QuerySectionHeader>}
            {fields}
          </QuerySection>
        );
      }
      return (
        <>
          {/* Referential div always at top of query fields display :) */}
          <div ref={ref}></div>
          <DataLinkProvider
            references={dataLinkReferences}
            setReferences={setDataLinkReferences}
            localContext={queryData}
          >
            {sections}
          </DataLinkProvider>
        </>
      );
    }
  )
);
