import * as XLSX from "xlsx-js-style";
import { clean_phone_number } from "../../../../tools";

// DEFECT: This code is not layed out super well (needs re-labeling and re-organizing)

export const downloadUserTemplateWorkbook = (tableBodyColumns, tableName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([[]], { cellStyles: true });

  // 1. Layout the headings
  XLSX.utils.sheet_add_aoa(ws, [
    tableBodyColumns.map((col) => ({
      t: "s",
      v: col.title,
      s: { font: { bold: true } },
    })),
  ]);
  // 2. Now generate data rows with formatting based on the column datatype
  const dataEntryRow = tableBodyColumns.map((col) => {
    switch (col.type) {
      case "string":
        return { t: "s", v: "" };
      case "boolean":
        return { t: "b", v: "", s: { font: { italic: true } } };
      case "select":
        return { t: "s", v: "", s: { font: { italic: true } } };
      default:
        return { t: "s", v: "", s: { font: { italic: true } } };
    }
  });

  // 2a. Iterate 100 times to format the blank rows
  for (let i = 0; i < 100; i++) {
    XLSX.utils.sheet_add_aoa(ws, [dataEntryRow], { origin: -1 }); // origin -1 starts at the next undefined row
  }

  // 3. Add small index tables for dropdowns
  let tableColumnIndexer = tableBodyColumns.length + 2;

  tableBodyColumns
    .filter((col) => col.type === "select")
    .forEach((col) => {
      // Add a little table to index the options for the row
      // It'll be a table with a heading and then a row for each option

      // First add the heading
      let miniOptionTable = [];
      miniOptionTable.push({
        t: "s",
        v: col.title + " - Option Set",
        s: { font: { bold: true } },
      });

      // Then iterate and add the options (we'll add their titles and read those back too)
      Object.values(col.options).forEach((option) => {
        miniOptionTable.push({ t: "s", v: option });
      });

      // And finish by appending the whole thing to the book
      XLSX.utils.sheet_add_aoa(
        ws,
        miniOptionTable.map((val) => [val]),
        { origin: { c: tableColumnIndexer, r: 3 } }
      );

      // Increment the table column indexer
      tableColumnIndexer = tableColumnIndexer + 2;
    });

  // 4. Then apply sheet style
  ws["!cols"] = tableBodyColumns.map((col) => ({
    wpx: col.type === "select" ? 180 : 100,
  }));
  // Then do the table column indexers as well
  ws["!cols"] = [
    ...ws["!cols"],
    ...(tableBodyColumns.filter((col) => col.type === "select").length > 0
      ? [{ wpx: 100 }]
      : []),
    ...tableBodyColumns
      .filter((col) => col.type === "select")
      .map((col) => [{ wpx: 100 }, { wpx: 180 }])
      .reduce((a, b) => a.concat(b), []),
  ];

  // Right before the end of generation, let's plug the sheet into the book
  XLSX.utils.book_append_sheet(wb, ws, "Exported_Queries");

  // Finish by savefile'ing the workbook
  XLSX.writeFile(wb, `${tableName.replaceAll(" ", "_")}-template.xlsx`);

  return;
};

export const uploadUserTemplateRows = (event, tableBodyColumns, setChange) => {
  // 1. Error checks
  if (event.target?.files?.length < 1) {
    return;
  }

  const file = event.target.files[0];

  if (file.name.split(".").pop() !== "xlsx") {
    return;
  }

  // TODO: Migrate these functions to auth API
  // Validate email formatting
  const validateEmail = (userIdentifier) => {
    if (!userIdentifier) {
      return false;
    }
    // Check the userIdentifier to see if it's a valid phone or email
    if (userIdentifier.includes("@") && userIdentifier.includes(".")) {
      // This is a "valid" email
      return true;
    } else {
      return false;
    }
  };

  // Validate phone formatting
  const validatePhone = (row) => {
    let phone = row["Phone"];
    if (!phone) {
      return false;
    }
    let phoneCandidate = clean_phone_number(phone.toString());

    // Now check length is 10 and all numbers
    if (phoneCandidate.length === 10 && phoneCandidate.match(/^[0-9]+$/)) {
      // This is a valid phone number
      row["Phone"] = phoneCandidate;
      return true;
    } else {
      return false;
    }
  };

  // 2. Setup Reader
  const reader = new FileReader();
  let count = 0;
  reader.onload = (e) => {
    // Read file
    const data = e.target.result;
    const xlsxFile = XLSX.read(data);

    // Now let's start parsing it and use it to write some data!
    const sheet = xlsxFile.Sheets["Exported_Queries"];
    const rows = XLSX.utils.sheet_to_json(sheet);

    // Add a collector for users to add
    let usersToAdd = [];

    // Let's start iterating over the rows, and we'll collect them in the appropriate body data format
    rows.some((row) => {
      // Make sure we have first and last name and one of email or phone
      if (
        !(
          row["First Name"] !== "" &&
          row["Last Name"] !== "" &&
          row["First Name"] !== undefined &&
          row["Last Name"] !== undefined &&
          ((row["Email"] !== "" && row["Email"] !== undefined) ||
            (row["Phone"] !== "" && row["Phone"] !== undefined))
        )
      ) {
        // We won't read any more rows after this :)
        return true;
      }
      let validData = true;
      // if Force SSO is on, then the email must be correctly formatted
      if (row["Force SSO"] !== false) {
        if (!validateEmail(row["Email"])) {
          validData = false;
        }
      } else {
        // if Force SSO is not on, then either the email or the phone number must be correctly formatted
        if (!validateEmail(row["Email"]) && !validatePhone(row)) {
          validData = false;
        }
      }

      // Now acknowledge row data and add the row
      if (validData) {
        let rowData = {};
        tableBodyColumns.forEach((col) => {
          let colLabel = col.title;
          if (row[colLabel] !== undefined) {
            // Capture the data
            let dt = row[colLabel];
            // If colLabel is the email, let's make sure it's lowercase!
            if (colLabel === "Email") {
              dt = dt?.toLowerCase();
            }
            // And trim all data
            if (typeof dt === "string") {
              dt = dt.trim();
            }
            // Finally throw the data in :)
            rowData[colLabel] = dt;
          }
        });

        // Then add the row to the setter
        usersToAdd.push(rowData);
        count++;
      }
    });

    // Now that we've processed everything, update the user set
    // DEFECT: This is a temp fix, whole file needs some love imo
    setChange(usersToAdd);
    return count;
  };

  // Trigger the reader
  reader.readAsArrayBuffer(file);
  return;
};
