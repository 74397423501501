import React, { useEffect, useState } from "react";

import { FormControl, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GenericField } from "./inputs2";

const useInputStyles = makeStyles((theme) => {
  return {
    outline: {
      "& .MuiInput-underline:before": {
        borderBottomColor: (props) =>
          props.contrast ? theme.palette.text.primary : theme.palette.grey[300],
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: (props) =>
            props.contrast
              ? theme.palette.text.primary
              : theme.palette.grey[300],
        },
      },
      "& .MuiSelect-icon": {
        color: (props) =>
          props.contrast ? theme.palette.text.primary : theme.palette.grey[300],
      },
    },
  };
});

export const MobileInput = ({
  data,
  onChange,
  disabled,
  contrast,
  ...props
}) => {
  const [country, setCountry] = useState("+1");
  const [number, setNumber] = useState("");

  const classStyle = useInputStyles({ contrast });

  useState(() => {
    if (data) {
      // Check if there is a phone number. If there is we can assume it'll have a country code
      // Provided there is a plus
      if (data.indexOf("+") > -1) {
        // Get the last 10 digits for the number
        const num = data.slice(-10);
        setNumber(num);
        // Get the country code
        setCountry(data.replaceAll(num, ""));
      } else {
        // No country code provided
        setNumber(data);
      }
    }
  }, []);

  // Backprop of clean change
  useEffect(() => {
    if (onChange) {
      onChange({ target: { value: country + number } });
    }
  }, [number, country, onChange]);

  return (
    <FormControl
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "6px",
        alignItems: "flex-end",
      }}
      size="small"
    >
      <Select
        value={country}
        size="small"
        variant="outlined"
        style={{ height: "38px", marginBottom: "8px" }}
        inputProps={{
          style: { height: 0 },
          className: classStyle.outline,
        }}
        renderValue={(value) => value}
        className={classStyle.outline}
        onChange={(e) => setCountry(e.target.value)}
        disabled={disabled}
      >
        {AVAILABLE_COUNTRIES.map((country, i) => {
          return (
            <MenuItem key={i} value={country.code}>
              {country.name} ({country.code})
            </MenuItem>
          );
        })}
      </Select>
      <GenericField
        label="Phone"
        data={number}
        onChange={(e) => setNumber(e.target.value)}
        disabled={disabled}
        contrast={contrast}
        style={{ paddingBottom: 0, ...(props?.style ?? {}) }}
        {...props}
      />
    </FormControl>
  );
};

const AVAILABLE_COUNTRIES = [
  { name: "United States", code: "+1" },
  { name: "Canada", code: "+1" },
  { name: "United Kingdom", code: "+44" },
];
