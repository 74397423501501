import React, { useState } from "react";
import { Modal, DialogContent } from "@mui/material";

import { ProjectRouteContainer } from "../../../project";
import { Button, Button2 } from "../../../ui/buttons";
import {
  ModalContent,
  ModalFooter,
  ModalPrompt,
  Tile,
} from "../../../ui/containers";
import { GenericField } from "../../../ui/inputs2";
import { InteractiveTable } from "../../../ui/table";

import q from "@queryit/api";

import { useAllProjects } from "../../../../hooks/projects";
import { useSnackbar } from "../../../../hooks/system";

export default ({ user }) => {
  const [addingProject, setAddingProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");

  const [projects, newProject] = useAllProjects();
  const { callSnackbar } = useSnackbar();

  const handleProjectCreation = () => {
    newProject(newProjectName).then((_) => {
      callSnackbar(`${newProjectName} Successfully Created`);
      setNewProjectName("");
      setAddingProject(false);
    });
  };

  return (
    <ProjectRouteContainer>
      <Modal
        open={addingProject}
        onClose={() => {
          setAddingProject(false);
        }}
      >
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <GenericField
                label={"Project Name"}
                data={newProjectName}
                onChange={(e) => {
                  const { value } = e.target;
                  setNewProjectName(value);
                }}
              />
            </ModalContent>
            <ModalFooter>
              <Button text={"Create"} onClick={() => handleProjectCreation()} />
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      <Tile>
        <Button2
          label="Add Project"
          onClick={() => {
            setAddingProject(true);
          }}
          style={{ width: "fit-content", marginBottom: "10px" }}
        />
        <InteractiveTable
          data={projects}
          columns={[
            { name: "Name", index: "name" },
            {
              name: "Add Self to Project",
              component: (row) => (
                <Button2
                  onClick={async () => {
                    console.log("Adding a user to the project");
                    let result = null;
                    try {
                      result = await q.projects
                        .project(row.id)
                        .users.doc(user.ref.id)
                        .set({});
                    } catch (e) {
                      console.error("Error: " + e);
                    }
                    callSnackbar("Successfully Added Self to Project ");
                  }}
                  label="Add"
                />
              ),
            },
            {
              name: "Add Self to Project Silently",
              component: () => <p>woof</p>,
            },
          ]}
          allowOrdering={true}
          // CollapseComponent={(row) => <UserEditForm user={row} users={[]} /> }
        />
      </Tile>
    </ProjectRouteContainer>
  );
};

const ProjectEditForm = () => {
  return <>Edit the Project</>;
};
