import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css, ThemeProvider } from "styled-components";

import EBound from "../../../ui/errorbounds";
import themes from "../../../../themes";

import {
  CurrentProjectContext,
  ProjectQueriesContext,
  ProjectRouteContainer,
  SelectedSchemaContext,
  //   FilteredQueriesState
} from "../../../project";

import { UserDataContext, LoadingBoundary } from "../../../../App";

import q from "@queryit/api";

import { isMobile } from "react-device-detect";
import { ChartResolver } from "../../../ui/charts";
import { CircularProgress } from "@mui/material";

export default ({
  // setFiltersPaneVisible,
  project,
  // projectActions,
  // aggregateData,
}) => {
  const selectedSchema = useContext(SelectedSchemaContext);
  const userData = useContext(UserDataContext);
  const [theme, setTheme] = useState(undefined);

  const [countSchema, setCountSchema] = useState(undefined);
  const [totalQueries, setTotalQueries] = useState(undefined);
  const [projectQueries, setProjectQueries] = useState(undefined);
  const [userLocations, setUserLocations] = useState(undefined);
  const [data, setData] = useState([]);

  const [showLocationChart, setShowLocationChart] = useState(false);

  const addUserLocation = (uid, location) =>
    setUserLocations({ ...(userLocations ?? {}), [uid]: location });

  const hasUserLocation = (uid) =>
    userLocations !== undefined && !!userLocations[uid];

  useEffect(() => {
    if (userData?.theme) {
      setTheme(themes[userData.theme]);
    }
    // This timeout allows us to set the theme state's initial value as undefined without causing a permanent blank page for users without a theme
    setTimeout(() => {
      if (!userData?.theme) {
        setTheme(themes.purple);
      }
    }, 300);
  }, [userData]);

  // Query data fetch useEffect - filter by current schema
  useEffect(() => {
    if (project && project.queries && selectedSchema && selectedSchema?.id) {
      // Mount query listener
      let mounted = true;
      const unsub = project.queries
        .where("schemaId", "==", selectedSchema.id)
        .listen((queries) => {
          if (mounted) {
            // Cache queries
            setProjectQueries([...queries]);
          }
        });

      return () => {
        unsub();
        mounted = false;
        setUserLocations(undefined);
      };
    }
  }, [project, selectedSchema]);

  useEffect(() => {
    if (project && project.schemas) {
      // Count schema documents
      project.schemas.count().then((count) => {
        setCountSchema(count);
      });
    }
    if (project && project.queries) {
      // Count total number of queries
      project.queries.count().then((count) => {
        setTotalQueries(count);
      });
    }
  }, [project]);

  useEffect(() => {
    const addLocations = async () => {
      await Promise.all(
        projectQueries.map(async (query) => {
          if (query.creator && !hasUserLocation(query.creator)) {
            const userData = await q.users.user(query.creator).get();

            // blank entries For indexing user count
            addUserLocation(query.creator, userData?.location ?? "");
          }
        })
      );
      setShowLocationChart(true);
    };

    if (projectQueries) {
      // Fetch user locations
      addLocations();
    }
  }, [projectQueries]);

  // Main data useEffect
  useEffect(() => {
    if (projectQueries && userLocations && showLocationChart) {
      const _data = projectQueries.map((query) => {
        return {
          ...query,
          data: {
            ...query.data,
            creator_location: userLocations[query.creator]
              ? userLocations[query.creator].country
              : "",
          },
        };
      });
      setData([..._data]);
    }
  }, [projectQueries, userLocations, showLocationChart]);

  if (theme === undefined) {
    return <LoadingBoundary />;
  }

  const NumberChart = ({ title, children }) => {
    return (
      <Chart key={`admin-chart-${title}`}>
        <EBound area="Rendered Chart">
          <DataTitle>{title}</DataTitle>
          <DataNumber>{children}</DataNumber>
        </EBound>
      </Chart>
    );
  };

  // STATIC INTERNAL ANALYTICS SCHEMA
  // Client counts
  // Schema counts - queries per schema
  // Queries generated
  // Queries created by country - users.user(creator).location
  // Users by country - projectUsers[i].location
  // Badge integration?
  const chartcomponents = [
    <NumberChart key="admin-chart-schemas" title="Project Schemas:">
      {countSchema !== undefined ? (
        `${countSchema}`
      ) : (
        <CircularProgress key="admin-chart-schemas-loading" />
      )}
    </NumberChart>,
    <NumberChart key="admin-chart-queries" title="Schema Queries:">
      {projectQueries !== undefined ? (
        `${projectQueries.length}${projectQueries.length >= 100 ? "+" : ""}`
      ) : (
        <CircularProgress key="admin-chart-queries-loading" />
      )}
    </NumberChart>,
    <NumberChart key="admin-chart-users" title="Schema Users:">
      {userLocations !== undefined ? (
        `${Object.keys(userLocations).length}`
      ) : (
        <CircularProgress key="admin-chart-users-loading" />
      )}
    </NumberChart>,
    <NumberChart key="admin-chart-users" title="Project Queries:">
      {totalQueries !== undefined ? (
        `${totalQueries}`
      ) : (
        <CircularProgress key="admin-chart-users-loading" />
      )}
    </NumberChart>,
    <ChartResolver
      key="admin-chart-creatorlocations"
      data={data}
      theme={theme}
      chartSchema={{
        type: "location",
        field: "creator_location",
        id: "creator_location",
        name: "Query Creators",
      }}
    />,
  ];

  // // We don't want to use ALL actions though, just the ones for this schema should show here :)
  // const [actions, setActions] = useState([]);
  // const [filteredQueries, setFilteredQueries] = useState([]);

  // const chartsImages = useRef({});

  // const [previewPrint, setPreviewPrint] = useState(false);

  // const [editMode, setEditMode] = useState(false);
  // const [chartSchemaSetPreview, setChartSchemaSetPreview] = useState(undefined);

  // let printComponent;

  // Apply migration to add chartSchema IDs
  // useEffect(() => {
  //   if (selectedSchema && project) {
  //     if (
  //       selectedSchema?.analyticsSchema &&
  //       !selectedSchema?.analyticsSchema[0].id
  //     ) {
  //       // Well then let's fix that
  //       log_warning("Chart Schemas are missing IDs, applying migration...");
  //       project.schemas.schema(selectedSchema.id).update({
  //         analyticsSchema: selectedSchema.analyticsSchema.map((schema) => ({
  //           ...schema,
  //           id: schema.name.toLowerCase().replaceAll(" ", "-"),
  //         })),
  //       });
  //     }
  //   }
  // }, [selectedSchema, project]);

  // Pre-filter the queries here so we can use them in the charts and actions calcs
  // useEffect(() => {
  //   if (queries && selectedSchema && filters) {
  //     setFilteredQueries(filters.$applyFilters(queries));
  //   }
  // }, [queries, selectedSchema, filters]);

  // Small filter to take in project actions and just count the ones from this schema
  // useEffect(() => {
  //   if (projectActions && queries) {
  //     const schemaActions = projectActions.filter((action) =>
  //       queries.map((q) => q.id).includes(action.queriesId)
  //     );
  //     setActions(schemaActions);
  //   }
  // }, [projectActions, queries]);

  // const chartBlobCollector = useCallback(
  //   (name, url) =>
  //     (chartsImages.current = { ...chartsImages.current, [name]: url }),
  //   [chartsImages]
  // );

  // const handleEditAction = (action) => {
  //   if (action === "edit") {
  //     setEditMode(true);
  //   } else if (action === "confirm") {
  //     if (chartSchemaSetPreview) {
  //       selectedSchema.analyticsSchema = chartSchemaSetPreview;
  //       project.schemas
  //         .schema(selectedSchema.id)
  //         .update({ analyticsSchema: chartSchemaSetPreview });
  //     }
  //     setEditMode(false);
  //   } else if (action === "cancel") {
  //     setEditMode(false);
  //   }
  // };

  return (
    <ProjectRouteContainer>
      {/* {previewPrint && (
        <PrintPreview
          onClose={() => setPreviewPrint(false)}
          data={queries}
          actions={actions}
          chartSchemaSet={selectedSchema?.analyticsSchema ?? []}
          selectedSchema={selectedSchema}
          projectName={projectData?.name}
        />
      )} */}
      {/* <ControlPanel
        isAnalytics
        setFiltersPaneVisible={setFiltersPaneVisible}
        settings={{
          broad: "extended",
        }}
        // printAction={() => saveAnalyticsPDFAsync(selectedSchema, chartsImages.current, `${project?.name}-${selectedSchema?.name}-analytics.pdf`)}
        printAction={() => setPreviewPrint(true)}
        editAction={handleEditAction}
        isEditMode={editMode}
      /> */}
      {projectQueries && <Charts>{chartcomponents}</Charts>}
    </ProjectRouteContainer>
  );
};

// const Charts = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   flex: 1 0 100%;
//   margin-bottom: ${isMobile ? "70px" : "0"};
// `;
const Charts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${isMobile &&
  css`
    width: 100%;
  `}
`;

const Chart = styled.div`
  background: ${(props) => props.theme.tileBackground};
  border-radius: 4px;
  padding: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
  box-sizing: border-box;

  height: fit-content;
  min-height: 200px;
  min-width: ${(props) =>
    props.width ? props?.width?.toString() + "px" : "300px"};

  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  color: ${(props) => props.theme.text};

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  ${isMobile &&
  css`
    margin-left: 0;
  `}

  @media (orientation:landscape) {
    margin-left: 12px;
  }
`;

const DataNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12vh;
  flex: 3;

  overflow-wrap: anywhere;

  color: ${(props) => props.theme.text};
`;

const DataTitle = styled.div`
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
  user-select: none;
`;
