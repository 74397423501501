import React from "react";
import { Page, View, Text, Image } from "@react-pdf/renderer";

import { PrintableField } from "../fields";
import { useUserLookup } from "../../../../../hooks/users";

import { PDFConstants, PDFStyles } from "../style";
import {
  TASK_SCHEMA_CLOSURE,
  TASK_SCHEMA_DEFAULT,
  TASK_SCHEMA_METADATA,
  TASK_SCHEMA_METADATA_CLOSED,
} from "../../actions/tasks";
import PrintQueryHeader from "../../../../print/header";

const fillableBlankTasks = [
  { id: "blank-task-0", data: {} },
  { id: "blank-task-1", data: {} },
];

export default ({ schemaData, tasks, users, queryData, projectLogo }) => {
  const [author, authorRef] = useUserLookup(
    queryData ? queryData.creator : undefined
  );
  tasks = tasks ? tasks : fillableBlankTasks;

  return (
    <Page>
      <PrintQueryHeader
        projectLogo={projectLogo}
        author={author}
        title={schemaData.name}
        id={queryData ? queryData.dynamicId ?? queryData.id : undefined}
        typeTitle="Tasks for"
      />
      {/* This is where the body of the approvals start */}
      <View style={PDFStyles.body}>
        {tasks?.map((tsk) => (
          <View style={PDFStyles.query} key={tsk.id}>
            {[...TASK_SCHEMA_METADATA, ...TASK_SCHEMA_METADATA_CLOSED].map(
              (fld) => {
                return (
                  <View
                    style={PDFStyles.queryRow}
                    key={fld.nested ? fld.nested[0].name : fld.name}
                  >
                    {fld.nested &&
                      fld.nested.map((nstfld) => (
                        <View
                          key={nstfld.id}
                          style={[
                            PDFStyles.queryCol,
                            {
                              width:
                                PDFConstants.BODY_WIDTH / fld.nested.length,
                            },
                          ]}
                        >
                          <PrintableField
                            field={nstfld}
                            fieldData={tsk?.[nstfld.id]}
                            data={tsk}
                            width={PDFConstants.BODY_WIDTH / fld.nested.length}
                            users={users}
                          />
                        </View>
                      ))}
                    {!fld.nested && (
                      <View
                        key={fld.id}
                        style={[
                          PDFStyles.queryCol,
                          { width: PDFConstants.BODY_WIDTH },
                        ]}
                      >
                        <PrintableField
                          field={fld}
                          fieldData={tsk?.[fld.id]}
                          data={tsk}
                          width={PDFConstants.BODY_WIDTH}
                          users={users}
                        />
                      </View>
                    )}
                  </View>
                );
              }
            )}
            {TASK_SCHEMA_DEFAULT.map((fld) => {
              return (
                <View
                  style={PDFStyles.queryRow}
                  key={fld.nested ? fld.nested[0].name : fld.name}
                >
                  {fld.nested &&
                    fld.nested.map((nstfld) => (
                      <View
                        key={nstfld.id}
                        style={[
                          PDFStyles.queryCol,
                          {
                            width: PDFConstants.BODY_WIDTH / fld.nested.length,
                          },
                        ]}
                      >
                        <PrintableField
                          field={nstfld}
                          fieldData={tsk.data[nstfld.id]}
                          data={tsk.data}
                          width={PDFConstants.BODY_WIDTH / fld.nested.length}
                        />
                      </View>
                    ))}
                  {!fld.nested && (
                    <View
                      key={fld.id}
                      style={[
                        PDFStyles.queryCol,
                        { width: PDFConstants.BODY_WIDTH },
                      ]}
                    >
                      <PrintableField
                        field={fld}
                        fieldData={tsk.data[fld.id]}
                        data={tsk.data}
                        width={PDFConstants.BODY_WIDTH}
                      />
                    </View>
                  )}
                </View>
              );
            })}
            {TASK_SCHEMA_CLOSURE.map((fld) => {
              return (
                <View
                  style={PDFStyles.queryRow}
                  key={fld.nested ? fld.nested[0].name : fld.name}
                >
                  {fld.nested &&
                    fld.nested.map((nstfld) => (
                      <View
                        key={nstfld.id}
                        style={[
                          PDFStyles.queryCol,
                          {
                            width: PDFConstants.BODY_WIDTH / fld.nested.length,
                          },
                        ]}
                      >
                        <PrintableField
                          field={nstfld}
                          fieldData={tsk.closure?.[nstfld.id]}
                          data={tsk.closure}
                          width={PDFConstants.BODY_WIDTH / fld.nested.length}
                        />
                      </View>
                    ))}
                  {!fld.nested && (
                    <View
                      key={fld.id}
                      style={[
                        PDFStyles.queryCol,
                        { width: PDFConstants.BODY_WIDTH },
                      ]}
                    >
                      <PrintableField
                        field={fld}
                        fieldData={tsk.closure?.[fld.id]}
                        data={tsk.closure}
                        width={PDFConstants.BODY_WIDTH}
                      />
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        ))}
      </View>
      <View style={PDFStyles.footer} fixed>
        <Text style={PDFStyles.footerText}>
          This form was generated by Query-It on{" "}
          {`${
            String(new Date().getDate()).padStart(2, "0") +
            "/" +
            String(new Date().getMonth() + 1).padStart(2, "0") +
            "/" +
            String(new Date().getFullYear())
          }`}
        </Text>
        {/* There's a bottom right place here for another logo */}
      </View>
    </Page>
  );
};
