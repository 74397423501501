import React, { useState } from "react";
import styled from "styled-components";
import Link from "../ui/link";
import { isMobile } from "react-device-detect";
import { CircularProgress } from "@mui/material";

import { useLocation } from "react-router-dom";

// This is one of our components that changes radically on mobile!
export default ({ options, root, projectData, user }) => {
  // const [viewMoreOptions, setViewMoreOptions] = useState(false); // Mobile only

  const location = useLocation(); // Causes re-render on route change

  if (isMobile) {
    const moreOptions = [];
    return (
      <MobileNavBarDiv>
        {options &&
          options.map((opt) => {
            if (!opt.mobile) {
              moreOptions.push(opt);
              return;
            }
            return (
              <MobileNavLink
                key={`nav-${opt.dest}`}
                to={root ? `${root}${opt.dest}` : opt.dest}
                selected={window.location.href.includes(opt.dest)}
              >
                <MobileNavTile
                  selected={window.location.href.includes(opt.dest)}
                >
                  <NavIcon>{opt.icon}</NavIcon>
                </MobileNavTile>
              </MobileNavLink>
            );
          })}
        {/* <MobileNavButton>
        <MobileNavTile>
          <NavIcon className="material-icons">more_horiz</NavIcon>
        </MobileNavTile>
      </MobileNavButton> */}
      </MobileNavBarDiv>
    );
  } else {
    return (
      <NavBarDiv>
        <Splitter />
        {!options && (
          <div
            style={{
              display: "flex",
              flex: "2 1 100%",
              height: "70%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {options &&
          options.map((opt) => {
            if (opt.condition && !opt.condition(projectData, user)) {
              return null;
            }
            if (!opt.dest) {
              if (opt.exec) {
                return (
                  <NavButtonPseudoLink
                    key={`nav-${opt.name.toLowerCase().replaceAll(" ", "-")}`}
                    onClick={opt.exec}
                  >
                    <NavBox selected={window.location.href.includes(opt.dest)}>
                      <NavIcon>{opt.icon}</NavIcon>
                      {opt.name}
                    </NavBox>
                  </NavButtonPseudoLink>
                );
              } else {
                return null;
              }
            }
            return (
              <NavLink
                key={`nav-${opt.dest}`}
                to={root ? `${root}${opt.dest}` : opt.dest}
                selected={window.location.href.includes(opt.dest)}
              >
                <NavBox selected={window.location.href.includes(opt.dest)}>
                  <NavIcon>{opt.icon}</NavIcon>
                  {opt.name}
                </NavBox>
              </NavLink>
            );
          })}
      </NavBarDiv>
    );
  }
};

const NavBarDiv = styled.div`
  background-color: ${(props) => props.theme.step100};
  height: 100%;
  width: 220px;

  overflow-y: auto;
`;

const Splitter = styled.div`
  height: 48px;
`;

const NavIcon = styled.div`
  margin-right: 6px;

  max-width: 20px;
  font-size: 24px;

  display: flex;
  align-items: center;
`;

const NavLink = styled(Link)`
  width: 100%;
  height: 50px;
  flex-shrink: 0;

  display: flex;

  color: ${(props) => props.theme.textAlt};
  justify-content: flex-start;
  align-items: center;

  font-family: ${(props) => props.theme.font};
  font-size: 20px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.step100Alt
        ? props.theme.step100Alt
        : props.theme.step150
      : props.theme.step100};
`;

const NavButtonPseudoLink = styled.div`
  width: 100%;
  height: 50px;
  flex-shrink: 0;

  display: flex;

  color: ${(props) => props.theme.textAlt};
  justify-content: flex-start;
  align-items: center;

  font-family: ${(props) => props.theme.font};
  font-size: 20px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.step100Alt
        ? props.theme.step100Alt
        : props.theme.step150
      : props.theme.step100};

  // Extra settings for pretending
  cursor: pointer;
`;

const NavBox = styled.div`
  border-left: 4px solid
    ${(props) => (props.selected ? props.theme.titleSub : props.theme.step100)};
  margin-left: 6px;
  padding-left: 5px;
  height: 90%;
  width: 189px;

  margin-top: 5%;
  margin-bottom: 5%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

// MOBILE STYLING STARTS HERE

const MobileNavBarDiv = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: ${(props) => props.theme.step50};

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  -webkit-box-shadow: 0px 3px 7px 3px #000000;
  box-shadow: 0px 3px 7px 4px #000000;
`;

const MobileNavTile = styled.div`
  color: ${(props) => props.theme.text};
`;

const MobileNavLink = styled(Link)`
  background-color: ${(props) =>
    props.selected ? props.theme.step100 : "unset"};
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const MobileNavButton = styled.div`
  background-color: ${(props) =>
    props.selected ? props.theme.step100 : "unset"};
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
