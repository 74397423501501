import React from "react";
import styled from "styled-components";
import { Navigate } from "react-router-dom";
import { Button2 } from "../ui/buttons";

import { EULAFormatted } from "../account/eula";

import q from "@queryit/api";

export default ({ user, userData }) => {
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (userData.eula) {
    return <Navigate to="/app" />;
  }

  return (
    <EulaPage>
      <Container>
        <Paragraph>
          <EULAFormatted />
        </Paragraph>
        <ButtonSection>
          <Button2
            label={`Accept`}
            onClick={() => {
              user.update({ ...userData, eula: true });
            }}
          />
          <Button2 label={`Decline`} onClick={() => q.auth.signOut()} />
        </ButtonSection>
      </Container>
    </EulaPage>
  );
};

const EulaPage = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.background};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  max-height: 90%;

  background: #fff;
  border-radius: 4px;
`;

const Paragraph = styled.div`
  color: black;
  height: 85%;
  overflow-y: scroll;
  background: white;
  border-radius: 8px;
  padding: 10px;

  font-family: ${(props) => props.theme.font};

  // -webkit-user-modify: read-write-plaintext-only;
`;

const ButtonSection = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
