import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { SVGElementToPrint } from "./svgconvert";

// This should probably be moved...
import { PDFStyles } from "../project/query/print/style";

const QUERYITICON =
  "https://firebasestorage.googleapis.com/v0/b/queryit-30aea.appspot.com/o/logo.png?alt=media&token=5dbb2c7d-9d8d-43f8-94bd-ae23dc7c27a3";

export default ({
  projectLogo,
  author,
  title,
  id,
  qrCode,
  typeTitle = "ID:",
}) => {
  return (
    <View style={PDFStyles.header} fixed>
      <View style={PDFStyles.headerRow}>
        <View style={PDFStyles.headerWrap}>
          <Image
            src={QUERYITICON}
            source={{
              method: "GET",
              header: { "Access-Control-Allow-Origin": "*" },
              body: "",
            }}
            style={PDFStyles.icon}
          />
          {qrCode && (
            <SVGElementToPrint
              refId={qrCode}
              width={PDFStyles.icon.height}
              height={PDFStyles.icon.height}
              style={PDFStyles.qrCode}
            />
          )}
        </View>
        {projectLogo && (
          <Image
            src={projectLogo}
            source={{
              method: "GET",
              header: { "Access-Control-Allow-Origin": "*" },
              body: "",
            }}
            style={PDFStyles.icon}
          />
        )}
      </View>
      <View style={PDFStyles.headerRow}>
        <Text>{title}</Text>
        {id && (
          <Text style={{ fontSize: 8 }}>
            {typeTitle} {id}
          </Text>
        )}
        {author && author.name && (
          <Text style={PDFStyles.detailText}>
            Author: {author.name.first} {author.name.last}
          </Text>
        )}
      </View>
    </View>
  );
};
