import React, { useState } from "react";
import styled from "@emotion/styled";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { useHotkey } from "../../hooks/system";
import { FullPageContainer } from "../ui/containers";

export default () => {
  const [ret, setRet] = useState(false);

  useHotkey("Escape", () => setRet(true));

  if (ret) {
    return <Navigate to="/app/projects" />;
  }

  return (
    <FullPageContainer>
      <Container
        style={{
          height: "fit-content",
          marginTop: "18px",
          marginLeft: "14px",
        }}
      >
        <Paragraph>
          If you have any difficulties using our software, please contact us at{" "}
          <a href="mailto:support@queryitapp.com" style={{ color: "unset" }}>
            support@queryitapp.com
          </a>
          . You can also reach out to us to request new features or report
          defects.
        </Paragraph>
        <Paragraph style={{ fontSize: 10, marginTop: 20 }}>
          Version: {process.env.APP_VERSION}
        </Paragraph>
      </Container>
    </FullPageContainer>
  );
};

const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 18px;
  margin-left: 14px;
  width: ${isMobile ? "100%" : "50%"};

  background: ${(props) => props.theme.palette.background.step50};
  border-radius: 4px;
`;

const Paragraph = styled.div`
  color: ${(props) => props.theme.palette.text.primary};

  font-family: ${(props) => props.theme.palette.font};
`;
