import React, { useState } from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";

import Nav from "./nav";
import { LogoMark } from "../ui/logos";
import { MobileDropdown, MobileDropdownRowLink } from "../ui/mobile";
import { KeyboardArrowDown } from "@mui/icons-material";

export const Header = ({
  name,
  user,
  projects,
  mode,
  userData,
  photoLink,
  unread,
  setSearchActive,
}) => {
  const [projectSelect, setProjectSelect] = useState(false);

  // Also monitor path to decide whether to show project selected
  const location = useLocation();

  const hideProjects =
    location &&
    ["notifications", "actions"].some((s) => location.pathname.includes(s));

  return (
    <HeaderDiv>
      <HeaderSection>
        <LogoMark />
        {!isMobile && !hideProjects && (
          <>
            <HeaderTitleProject>{name}</HeaderTitleProject>
            {(projects.length > 1 || mode === "admin") && (
              <KeyboardArrowDown
                sx={{
                  borderLeft: "0.5px solid",
                  paddingLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setProjectSelect((ex) => !ex)}
              />
            )}
          </>
        )}
      </HeaderSection>
      {projectSelect && !isMobile && (
        <>
          <ProjectSelect
            projects={projects}
            mode={mode}
            setProjectSelect={setProjectSelect}
          />
          <ProjectClickHide
            onClick={() => {
              setProjectSelect(false);
            }}
          />
        </>
      )}
      {projectSelect && isMobile && (
        <MobileDropdown title="Projects" close={() => setProjectSelect(false)}>
          {mode === "admin" && (
            <MobileDropdownRowLink
              to="/app/admin/manage"
              selected={window.location.href.includes("/manage")}
            />
          )}
          {projects.map((proj) => (
            <MobileDropdownRowLink
              key={`nav-projects-${proj.id}`}
              to={`/${mode === "admin" ? "app/admin" : "app"}/projects/${
                proj.id
              }`}
              title={proj.name}
              selected={window.location.href.includes(`/projects/${proj.id}`)}
            />
          ))}
        </MobileDropdown>
      )}
      <Nav
        userData={userData}
        user={user}
        photoLink={photoLink}
        unread={unread}
        setProjectSelect={setProjectSelect}
        setSearchActive={setSearchActive}
        hideSearch={name === ""}
      />
    </HeaderDiv>
  );
};

const ProjectSelect = ({ projects, mode, setProjectSelect }) => {
  return (
    <ProjectSelectDiv>
      {mode === "admin" && (
        <ProjectSelectDivTile
          key={`nav-projects-noproj}`}
          to={`/app/admin/manage`}
          onClick={() => {
            setProjectSelect(false);
          }}
          selected={window.location.href.includes(`/manage`)}
        >
          <b>Administration Dashboard</b>
        </ProjectSelectDivTile>
      )}
      {projects.map((proj) => {
        return (
          <ProjectSelectDivTile
            key={`nav-projects-${proj.id}`}
            to={`/${mode === "admin" ? "app/admin" : "app"}/projects/${
              proj.id
            }`}
            onClick={() => {
              setProjectSelect(false);
            }}
            selected={window.location.href.includes(`/projects/${proj.id}`)}
          >
            {proj.name}
          </ProjectSelectDivTile>
        );
      })}
    </ProjectSelectDiv>
  );
};

const HeaderDiv = styled.div`
  background: ${(props) => props.theme.step50};
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;

  padding: 0px 12px;
  box-sizing: border-box;

  align-items: center;
  color: ${(props) => props.theme.textHeading};

  justify-content: space-between;
  box-shadow: 0px 1px 4px black;
`;

export const HeaderSection = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectProjectKarat = styled.div`
  user-select: none;
  cursor: pointer;
  justify-self: flex-start;
  color: ${(props) => props.theme.textHeading};
  border-left: 1px solid ${(props) => props.theme.textHeading};

  ${isMobile &&
  css`
    position: absolute;
    left: 12px;
  `}
`;

const HeaderTitleProject = styled.div`
  color: ${(props) => props.theme.textHeading};
  font-size: 20px;
  font-family: ${(props) => props.theme.font};
  margin-left: 12px;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 8px;

  user-select: none;
`;

const ProjectSelectDiv = styled.div`
  position: absolute;
  top: ${isMobile ? "120px" : "54px"};
  left: 55px;
  width: fit-content;
  min-width: 100px;
  height: fit-content;
  // min-height: 100px;
  padding: 5px;
  z-index: 100;
  background: ${(props) => props.theme.step50};
  color: ${(props) => props.theme.textHeading};
  font-family: ${(props) => props.theme.font};

  padding: 6px;

  -moz-border-radius: 5px; /* this works only in camino/firefox */
  -webkit-border-radius: 5px; /* this is just for Safari */

  -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
`;

const ProjectSelectDivTile = styled(Link)`
  font-size: 18px;
  margin: 7px 10px;
  padding-left: 4px;
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.textHeading};
  border-left: 3px solid
    ${(props) => (props.selected ? props.theme.titleSub : props.theme.step50)};
`;

const ProjectClickHide = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
`;
