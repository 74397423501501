import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { GenericField } from "../../../ui/inputs2";
import { Button2 } from "../../../ui/buttons";
import { QuerySection, QuerySectionHeader } from "../query";

import ExistingClarification from "./existing";

export const ClarificationList = ({
  clarifications,
  answering,
  updateClarification,
  userData,
}) => {
  if (clarifications === undefined) {
    return null;
  }

  if (clarifications?.length < 1) {
    return (
      <Typography
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No clarifications have been submitted yet. Check back later
      </Typography>
    );
  }

  return (
    <QuerySection>
      {clarifications.map((clr) => {
        return (
          <Accordion key={`clar-${clr.id}`}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Chip
                label={clr.subject}
                style={{ backgroundColor: clr.answer ? "green" : "red" }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <AccordionContainer>
                <Clarification
                  clarification={clr}
                  answering={answering}
                  userData={userData}
                  updateClarification={updateClarification}
                />
              </AccordionContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </QuerySection>
  );
};

// DEFECT: This is probably a duplicate
export const FormClarifications = ({
  clarifications,
  answering,
  updateClarification,
  userData,
}) => {
  if (!clarifications || clarifications.length < 1) {
    return null;
  }

  return (
    <QuerySection>
      <QuerySectionHeader>Submitted Clarifications</QuerySectionHeader>

      {clarifications.map((clr) => {
        return (
          <Accordion key={`clar-${clr.id}`}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Chip
                label={clr.subject}
                style={{ backgroundColor: clr.answer ? "green" : "red" }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <AccordionContainer>
                <ExistingClarification
                  clarification={clr}
                  answering={answering}
                  userData={userData}
                  updateClarification={updateClarification}
                />
              </AccordionContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </QuerySection>
  );
};

const Clarification = ({
  clarification,
  userData,
  updateClarification,
  answering,
}) => {
  const [newAnswer, setNewAnswer] = useState({
    text: "",
    time: new Date(),
    author: userData ? { name: userData.name, email: userData.email } : {},
  });

  return (
    <>
      <FormFlow>
        <GenericField
          label={"Question"}
          data={clarification.question}
          disabled
          multiLine
          style={{
            minWidth: "45%",
          }}
        />
        <GenericField
          label={"Answer"}
          onChange={(e) => {
            const { value } = e.target;
            setNewAnswer((ex) => ({ ...ex, text: value }));
          }}
          data={
            clarification.answer
              ? clarification.answer.text
              : answering
              ? newAnswer.text
              : "Not answered yet"
          }
          disabled={!answering || !!clarification.answer}
          multiLine
          style={{
            minWidth: "45%",
          }}
        />
      </FormFlow>
      {answering && !clarification.answer && (
        <Button2
          label={"Submit Answer"}
          style={{ maxWidth: 190 }}
          onClick={() =>
            updateClarification(clarification.id, {
              ...clarification,
              answer: newAnswer,
            })
          }
        />
      )}
    </>
  );
};

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const FormFlow = styled.div`
  display: flex;
`;
