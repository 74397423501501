import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { parse_on_fields } from "../../../../../tools/forms";

import { QueryContent, QuerySection, QuerySectionHeader } from "../../query";
import { Inputs } from "../../../../ui/inputs2";

import { SaveQueryBar } from "../../workflow";

import {
  useSchema,
  useSchemaStatusSet,
  useSchemaCustomizations,
} from "../../../../../hooks/projects";
import {
  EditableFieldResolver,
  NewFieldPlaceholder,
  DragHoverPlaceHolder,
  NewSectionPlaceHolder,
  EditableSectionHeaderResolver,
} from "./fields";
import { log_warning } from "../../../../../tools/logger";
import { generate_tempid } from "../../../../../tools";
import { CircularProgress } from "@mui/material";
import { UserContext } from "../../../../../App";
import { useHotkey } from "../../../../../hooks/system";

export const StatusContext = React.createContext([]);

export default ({ project, schemaId, setStatusMessage, custom }) => {
  const [schema, schemaData] = useSchema(schemaId, project);
  const [schemaFields, setSchemaFields] = useState(undefined);
  const statusSet = useSchemaStatusSet(schemaData);

  const [previewMode, setPreviewMode] = useState(false); // TODO: Add a preview mode before saving

  const [inDragState, setInDragState] = useState(false);
  const [dragIsOverDropableArea, setDragIsOverDropableArea] = useState(false);
  const [dropData, setDropData] = useState({
    topIndex: undefined,
    rowIndex: undefined,
    secIndex: undefined,
  });
  const [fieldBeingDragged, setFieldBeingDragged] = useState(undefined);

  const currentUser = useContext(UserContext);
  const userCustomSchema = useSchemaCustomizations(
    project,
    schema,
    currentUser.ref.id
  );

  const [showSave, setShowSave] = useState(false);

  useHotkey("Escape", () => {});

  const validateFields = () => {
    const errors = [];
    const fieldIds = new Set();

    parse_on_fields(schemaFields, (fld) => {
      // The checks in the if (!custom) block do not apply to personal (custom) fields
      if (!custom) {
        // check that no field has a generated ID
        if (is_random_insert_id(fld.id)) {
          errors.push(
            "You have generated ids in your schema. This is not allowed."
          );
        }
        // Check that no field IDs are duplicated
        if (fieldIds.has(fld.id)) {
          errors.push(
            "You have duplicated ids in your schema. This is not allowed."
          );
        } else {
          fieldIds.add(fld.id);
        }

        // Check that no table column IDs are duplicated
        if (fld.type === "table") {
          const tableColumnIds = new Set();
          fld?.columns.forEach((col) => {
            if (tableColumnIds.has(col.id)) {
              errors.push(
                "You have duplicated column ids in a table of your schema. This is not allowed."
              );
            } else {
              tableColumnIds.add(col.id);
            }
          });
        }
      }
      // Check that fields that have List Options have no duplicate labels
      // Note: since options is an object, duplicate values (the key) can't exist
      if (fld.options !== undefined) {
        const labels = new Set();
        Object.values(fld.options).forEach((lbl) => {
          if (labels.has(lbl)) {
            errors.push(
              "You have duplicated labels in a List Option of your schema. This is not allowed."
            );
          } else {
            labels.add(lbl);
          }
        });
      }
      // for the version mapping, each status must be mapped to a version. Duplicate values are allowed though
      if (fld.type === "files" && fld.enableRevisions === true) {
        const statusKeys = statusSet.map((st) => st.status);
        const versionMap = fld?.versionMap;
        if (
          !versionMap ||
          versionMap.some((row) => Object.keys(row).some((val) => !val)) ||
          versionMap.some((row) => !statusKeys.includes(row.status))
        ) {
          errors.push(
            "You have a status without a prefix. Every status in the schema must be assigned a version prefix."
          );
        }
      }
      // TODO: Check if field configuration is valid
    });

    errors.forEach((message) => {
      log_warning(message);
    });

    return errors.length === 0;
  };

  // Then we need a save function
  const saveChanges = () => {
    log_warning("Save Attempted!");
    // Before we can save, validate fields
    if (!validateFields()) {
      return;
    }

    // Now attempt save
    if (custom) {
      // If custom, then we are updating the user within the schema's users collection
      schema.users.user(currentUser.ref.id).set(
        {
          customFields: schemaFields,
        },
        { merge: true }
      );
    } else {
      schema.update({ fields: schemaFields });
    }
    setStatusMessage("Changes Saved!");
  };

  // We need to parse in the schema data and create a temp object for the fields
  useEffect(() => {
    if (custom) {
      const customFields = userCustomSchema?.customFields;
      setSchemaFields(customFields);
    } else {
      setSchemaFields(schemaData?.fields);
    }
  }, [schemaData, userCustomSchema, custom]);

  // Function for inserting blank fields
  const insertField = (topIndex, rowIndex, secIndex = undefined) => {
    const resolveNestOrSoloField = (field) => {
      return field?.nested
        ? // Handle case where the row is already a nest
          {
            nested: [
              // Row fields before our target
              ...(field?.nested ?? []).slice(0, rowIndex),
              // Our target
              create_blank_field(),
              // Row fields after our target
              ...(field?.nested ?? []).slice(rowIndex),
            ],
          }
        : // Handle case where the row was previously a solo field
          {
            nested:
              rowIndex === 0
                ? // Case where we're inserting before the solo field
                  [create_blank_field(), field]
                : // Case where we're inserting after the solo field
                  [field, create_blank_field()],
          };
    };

    return () => {
      setShowSave(true);
      setSchemaFields((ex) => [
        // top-level indices before our target
        ...(ex ?? []).slice(0, topIndex),
        // our target (list if rowIndex is defined), section object if secIndex is defined
        secIndex !== undefined
          ? // Define (or read) section
            {
              ...(ex?.[topIndex] ?? {}),
              section: [
                // Section fields before our target
                ...(ex?.[topIndex]?.section ?? []).slice(0, secIndex),
                // Section field of our target (list if rowIndex is defined)
                rowIndex !== undefined
                  ? // Define (or read) row
                    resolveNestOrSoloField(ex?.[topIndex]?.section?.[secIndex])
                  : // Solo field in section
                    create_blank_field(),
                // Section fields after our target
                ...(ex?.[topIndex]?.section ?? []).slice(secIndex + 1),
              ],
            }
          : // Not a section, so let's determine if its a row or a solo-field
          rowIndex !== undefined
          ? // Nested, so let's add to list
            resolveNestOrSoloField(ex?.[topIndex])
          : // Solo field
            create_blank_field(),
        // top-level indeces after our target
        ...(ex ?? []).slice(topIndex + 1),
      ]);
    };
  };

  const moveField = useCallback(
    (e, data, fieldToMove, oldTopIndex, oldRowIndex, oldSecIndex) => {
      setFieldBeingDragged(fieldToMove);

      if (e.type === "mousedown") {
        //drag onStart
        setInDragState(true);
      } else if (e.type === "mouseup") {
        // drag onStop
        setInDragState(false);

        if (dragIsOverDropableArea) {
          //move field to dropped area (defined in the dropData state) from it's old position
          const topIndex = dropData.topIndex;
          const rowIndex = dropData.rowIndex;
          const secIndex = dropData.secIndex;

          // Now let's run our logic for adjusting the schema
          setShowSave(true);
          setSchemaFields((ex) => {
            // First deep copy the schema
            const schemaCopy = JSON.parse(JSON.stringify(ex));

            // Now do math for a row adjustment (offset because of the preceeding delete for adding)
            let rowAdjustment =
              topIndex === oldTopIndex &&
              secIndex === oldSecIndex &&
              rowIndex >= oldRowIndex
                ? -1
                : 0;

            ////////////
            // DELETE //
            ////////////

            // Define cleanup funcs
            const cleanNest = (field, oldRowIndex) => {
              // NOTE: wrap return in an array for expansion operator (fixes undefined issues)
              // If the field is a nest, determine if it's at least 2 fields after removal
              // If not it's no longer a nest after our removal
              if (field?.nested?.length > 2) {
                // If so, remove the row from the nest
                return [
                  {
                    nested: [
                      // Row fields before our target
                      ...(field?.nested ?? []).slice(0, oldRowIndex),
                      // Row fields after our target
                      ...(field?.nested ?? []).slice(oldRowIndex + 1),
                    ],
                  },
                ];
              } else {
                // If just one field would be left, send that back instead :)
                return [field?.nested?.[oldRowIndex === 0 ? 1 : 0] ?? {}];
              }
            };

            // Run op
            ex = [
              // top-level indices before our target
              ...(ex ?? []).slice(0, oldTopIndex),
              // our target (list if rowIndex is defined), section object if secIndex is defined
              ...(oldSecIndex !== undefined
                ? // Read section
                  [
                    {
                      ...(ex?.[oldTopIndex] ?? {}),
                      section: [
                        // Section fields before our target
                        ...(ex?.[oldTopIndex]?.section ?? []).slice(
                          0,
                          oldSecIndex
                        ),
                        // Section field of our target (list if rowIndex is defined)
                        ...(oldRowIndex !== undefined
                          ? cleanNest(
                              ex?.[oldTopIndex]?.section?.[oldSecIndex],
                              oldRowIndex
                            )
                          : []),
                        // Section fields after our target
                        ...(ex?.[oldTopIndex]?.section ?? []).slice(
                          oldSecIndex + 1
                        ),
                      ],
                    },
                  ]
                : // Not a section, so let's determine if its a row or a solo-field
                oldRowIndex !== undefined
                ? // Nested, so let's remove from list
                  cleanNest(ex?.[oldTopIndex], oldRowIndex)
                : // Solo field
                  []),
              // top-level indeces after our target
              ...(ex ?? []).slice(oldTopIndex + 1),
            ];

            ////////////
            // INSERT //
            ////////////

            // Supporting Insert logic
            const resolveNestOrSoloField = (field) => {
              return field?.nested
                ? // Handle case where the row is already a nest
                  {
                    nested: [
                      // Row fields before our target
                      ...(field?.nested ?? []).slice(
                        0,
                        rowIndex + rowAdjustment
                      ),
                      // Our target
                      fieldToMove,
                      // Row fields after our target
                      ...(field?.nested ?? []).slice(rowIndex + rowAdjustment),
                    ],
                  }
                : // Handle case where the row was previously a solo field
                  {
                    nested:
                      rowIndex === 0
                        ? // Case where we're inserting before the solo field
                          [fieldToMove, field]
                        : // Case where we're inserting after the solo field
                          [field, fieldToMove],
                  };
            };

            // Now check if we've removed the last field from a row by moving
            const secAdjustment =
              topIndex === oldTopIndex &&
              ex?.[topIndex]?.section?.length ===
                schemaCopy?.[topIndex]?.section?.length
                ? 0
                : secIndex > oldSecIndex
                ? -1
                : 0;

            // Run op
            if (rowIndex === -1) {
              // Then insert new row
              ex = [
                // top-level indices before our target
                ...(ex ?? []).slice(0, topIndex),
                {
                  ...(ex?.[topIndex] ?? {}),
                  section: [
                    ...ex?.[topIndex]?.section.slice(0, secIndex),
                    fieldToMove,
                    ...ex?.[topIndex]?.section.slice(secIndex),
                  ],
                },

                // top-level indeces after our target
                ...(ex ?? []).slice(topIndex + 1),
              ];
            } else {
              ex = [
                // top-level indices before our target
                ...(ex ?? []).slice(0, topIndex),
                // our target (list if rowIndex is defined), section object if secIndex is defined
                secIndex !== undefined
                  ? // Define (or read) section
                    secIndex !== -1
                    ? {
                        ...(ex?.[topIndex] ?? {}),
                        section: [
                          // Section fields before our target
                          ...(ex?.[topIndex]?.section ?? []).slice(
                            0,
                            secIndex + secAdjustment
                          ),
                          // Section field of our target (list if rowIndex is defined)
                          rowIndex !== undefined
                            ? // Define (or read) row
                              resolveNestOrSoloField(
                                ex?.[topIndex]?.section?.[
                                  secIndex + secAdjustment
                                ]
                              )
                            : // Solo field in section
                              fieldToMove,
                          // Section fields after our target
                          ...(ex?.[topIndex]?.section ?? []).slice(
                            secIndex + secAdjustment + 1
                          ),
                        ],
                      }
                    : {
                        //new row at top
                        ...(ex?.[topIndex] ?? {}),
                        section: [fieldToMove, ...ex?.[topIndex]?.section],
                      }
                  : // Not a section, so let's determine if its a row or a solo-field
                  rowIndex !== undefined
                  ? // Nested, so let's add to list
                    resolveNestOrSoloField(ex?.[topIndex])
                  : // Solo field
                    fieldToMove,
                // top-level indeces after our target
                ...(ex ?? []).slice(topIndex + 1),
              ];
            }

            // Return the new schema
            return ex;
          });

          // Set state for next field dragged
          setDragIsOverDropableArea(false);
          setFieldBeingDragged(undefined);
        }
      }
    },
    [dropData, dragIsOverDropableArea]
  );

  // Function for deleting fields
  const deleteField = (topIndex, rowIndex, secIndex = undefined) => {
    const cleanNest = (field, rowIndex) => {
      // NOTE: wrap return in an array for expansion operator (fixes undefined issues)
      // If the field is a nest, determine if it's at least 2 fields after removal
      // If not it's no longer a nest after our removal
      if (field?.nested?.length > 2) {
        // If so, remove the row from the nest
        return [
          {
            nested: [
              // Row fields before our target
              ...(field?.nested ?? []).slice(0, rowIndex),
              // Row fields after our target
              ...(field?.nested ?? []).slice(rowIndex + 1),
            ],
          },
        ];
      } else {
        // If just one field would be left, send that back instead :)
        return [field?.nested?.[rowIndex === 0 ? 1 : 0] ?? {}];
      }
    };

    return () => {
      setShowSave(true);
      setSchemaFields((ex) => [
        // top-level indices before our target
        ...(ex ?? []).slice(0, topIndex),
        // our target (list if rowIndex is defined), section object if secIndex is defined
        ...(secIndex !== undefined
          ? // Read section
            [
              {
                ...(ex?.[topIndex] ?? {}),
                section: [
                  // Section fields before our target
                  ...(ex?.[topIndex]?.section ?? []).slice(0, secIndex),
                  // Section field of our target (list if rowIndex is defined)
                  ...(rowIndex !== undefined
                    ? cleanNest(ex?.[topIndex]?.section?.[secIndex], rowIndex)
                    : []),
                  // Section fields after our target
                  ...(ex?.[topIndex]?.section ?? []).slice(secIndex + 1),
                ],
              },
            ]
          : // Not a section, so let's determine if its a row or a solo-field
          rowIndex !== undefined
          ? // Nested, so let's remove from list
            cleanNest(ex?.[topIndex], rowIndex)
          : // Solo field
            []),
        // top-level indeces after our target
        ...(ex ?? []).slice(topIndex + 1),
      ]);
    };
  };

  // Function for editing fields
  const editField = (topIndex, rowIndex, secIndex = undefined) => {
    return (callback) => {
      setShowSave(true);
      setSchemaFields((ex) => [
        // top-level indices before our target
        ...(ex ?? []).slice(0, topIndex),
        // our target (list if rowIndex is defined), section object if secIndex is defined
        secIndex !== undefined
          ? // Read section
            {
              ...(ex?.[topIndex] ?? {}),
              section: [
                // Section fields before our target
                ...(ex?.[topIndex]?.section ?? []).slice(0, secIndex),
                // Section field of our target (list if rowIndex is defined)
                rowIndex !== undefined
                  ? {
                      nested: [
                        // Row fields before our target
                        ...(
                          ex?.[topIndex]?.section?.[secIndex]?.nested ?? []
                        ).slice(0, rowIndex),
                        // Our target
                        callback(
                          ex?.[topIndex]?.section?.[secIndex]?.nested?.[
                            rowIndex
                          ]
                        ),
                        // Row fields after our target
                        ...(
                          ex?.[topIndex]?.section?.[secIndex]?.nested ?? []
                        ).slice(rowIndex + 1),
                      ],
                    }
                  : callback(ex?.[topIndex]?.section?.[secIndex]),
                // Section fields after our target
                ...(ex?.[topIndex]?.section ?? []).slice(secIndex + 1),
              ],
            }
          : // Not a section, so let's determine if its a row or a solo-field
          rowIndex !== undefined
          ? // Nested, so let's remove from list
            {
              nested: [
                // Row fields before our target
                ...(ex?.[topIndex]?.nested ?? []).slice(0, rowIndex),
                // Our target
                callback(ex?.[topIndex]?.nested?.[rowIndex]),
                // Row fields after our target
                ...(ex?.[topIndex]?.nested ?? []).slice(rowIndex + 1),
              ],
            }
          : // Solo field
            callback(ex?.[topIndex]),
        // top-level indeces after our target
        ...(ex ?? []).slice(topIndex + 1),
      ]);
    };
  };

  const duplicateField = (topIndex, rowIndex, secIndex = undefined) => {
    const resolveNest = (field) => {
      return field?.nested
        ? // Handle case where the row is already a nest
          {
            nested: [
              // Row fields before our target
              ...(field?.nested ?? []).slice(0, rowIndex + 1),
              // Our target
              create_duplicate_field(field.nested[rowIndex]),
              // Row fields after our target
              ...(field?.nested ?? []).slice(rowIndex + 1),
            ],
          }
        : // Handle case where the row was previously a solo field
          {
            nested:
              rowIndex === 0
                ? // Case where we're inserting before the solo field
                  [create_duplicate_field(field), field]
                : // Case where we're inserting after the solo field
                  [field, create_duplicate_field(field)],
          };
    };

    return () => {
      setShowSave(true);
      setSchemaFields((ex) => [
        // top-level indices before our target
        ...(ex ?? []).slice(0, topIndex),
        // our target (list if rowIndex is defined), section object if secIndex is defined
        {
          ...(ex?.[topIndex] ?? {}),
          section: [
            // Section fields before our target
            ...(ex?.[topIndex]?.section ?? []).slice(0, secIndex),
            resolveNest(ex?.[topIndex]?.section?.[secIndex]),
            // Section fields after our target
            ...(ex?.[topIndex]?.section ?? []).slice(secIndex + 1),
          ],
        },
        // top-level indeces after our target
        ...(ex ?? []).slice(topIndex + 1),
      ]);
    };
  };

  const insertSection = () => {
    return () => {
      setShowSave(true);
      setSchemaFields((ex) => [
        ...(ex ?? []),
        { section: [], name: "NEW SECTION" },
      ]);
    };
  };

  const updateSectionName = (newName, index) => {
    if (newName) {
      setShowSave(true);
      setSchemaFields((ex) => {
        let secs = 0;
        return ex.map((field, i) => {
          if (field.section) {
            if (secs === index) {
              secs++;
              return { ...field, name: newName };
            }
            secs++;
          }
          return field;
        });
      });
    }
  };

  const deleteSection = (index) => {
    let schemaFieldsCopy = schemaFields.slice();
    schemaFieldsCopy.splice(index, 1);
    setShowSave(true);
    setSchemaFields((ex) => {
      let secs = 0;
      return ex
        .map((field, i) => {
          if (field.section) {
            if (secs === index) {
              secs++;
              return undefined;
            }
            secs++;
          }
          return field;
        })
        .filter((field) => field !== undefined);
    });
  };

  const moveSection = (upOrDown, index) => {
    let schemaFieldsCopy = schemaFields.slice();
    let temp = schemaFieldsCopy[index];
    if (upOrDown === "up" && schemaFieldsCopy[index - 1]) {
      schemaFieldsCopy[index] = schemaFieldsCopy[index - 1];
      schemaFieldsCopy[index - 1] = temp;
    } else if (upOrDown === "down" && schemaFieldsCopy[index + 1]) {
      schemaFieldsCopy[index] = schemaFieldsCopy[index + 1];
      schemaFieldsCopy[index + 1] = temp;
    }
    setShowSave(true);
    setSchemaFields(schemaFieldsCopy);
  };

  let fields = [];
  let sections = [];
  // Now build the view
  (schemaFields ?? []).forEach((field, topIndex) => {
    // In our schema, a nested array means they should appear side by side.
    // No section heading means to be included in the "Fields" header
    if (field.section) {
      // NOTE: We skip section conditionals here. Maybe they could be configured here as well?
      // Check if its a form section
      if (field.formId) {
        // Skip form section
        return;
      }
      // Otherwise go to a regular section
      sections.push(
        <QuerySection
          style={{ display: "flex", flexDirection: "column", rowGap: "4px" }}
          key={`sec-${field.name.toLowerCase().replaceAll(" ", "-")}-${
            sections.length
          }`}
        >
          <EditableSectionHeaderResolver
            name={field.name}
            updateName={updateSectionName}
            index={sections?.length}
            deleteSection={deleteSection}
            moveSection={moveSection}
            isLastSection={sections?.length === schemaFields?.length - 1}
          />
          {inDragState ? (
            <DragHoverPlaceHolder
              hidden
              setIsDroppable={setDragIsOverDropableArea}
              setDropData={setDropData}
              topIndex={topIndex}
              rowIndex={0}
              secIndex={-1}
              fieldBeingDragged={fieldBeingDragged}
            />
          ) : null}
          {field.section.map((fld, secIndex) => {
            return (
              <DropAllign key={`inp-wrapper-${secIndex}`}>
                <Inputs
                  style={{
                    minHeight: "10px",
                  }}
                >
                  <NewFieldPlaceholder hidden orientation="horizontal" />
                </Inputs>
                <Inputs
                  key={`inp-wrapper-${secIndex}`}
                  style={{
                    alignItems: "flex-start",
                    marginTop: "4px",
                    overflow: "none",
                    gap: "3px",
                  }}
                >
                  {inDragState ? (
                    <DragHoverPlaceHolder
                      hidden
                      setIsDroppable={setDragIsOverDropableArea}
                      setDropData={setDropData}
                      topIndex={topIndex}
                      rowIndex={0}
                      secIndex={secIndex}
                      nest={fld.nested}
                      fieldBeingDragged={fieldBeingDragged}
                    />
                  ) : (
                    <NewFieldPlaceholder
                      hidden
                      addFieldCallback={insertField(topIndex, 0, secIndex)}
                    />
                  )}
                  {(fld.nested ? fld.nested : [fld]).map(
                    (_field, nestIndex) => (
                      <React.Fragment key={`query-field-${nestIndex}`}>
                        <EditableFieldResolver
                          key={`query-field-${nestIndex}`}
                          field={_field}
                          onFieldDelete={deleteField(
                            topIndex,
                            fld.nested ? nestIndex : undefined,
                            secIndex
                          )}
                          onFieldChange={editField(
                            topIndex,
                            fld.nested ? nestIndex : undefined,
                            secIndex
                          )}
                          onFieldDuplicate={duplicateField(
                            topIndex,
                            fld.nested ? nestIndex : undefined,
                            secIndex
                          )}
                          onFieldDrag={moveField}
                          topIndex={topIndex}
                          rowIndex={fld.nested ? nestIndex : undefined}
                          secIndex={secIndex}
                          isBeingDragged={inDragState}
                          custom={custom}
                        />
                        {inDragState ? (
                          <DragHoverPlaceHolder
                            hidden
                            setIsDroppable={setDragIsOverDropableArea}
                            setDropData={setDropData}
                            topIndex={topIndex}
                            rowIndex={nestIndex + 1}
                            secIndex={secIndex}
                            nest={fld.nested}
                            fieldBeingDragged={fieldBeingDragged}
                          />
                        ) : (
                          <NewFieldPlaceholder
                            hidden
                            key={`nfp-follows-${_field.id}`}
                            addFieldCallback={insertField(
                              topIndex,
                              nestIndex + 1,
                              secIndex
                            )}
                          />
                        )}
                      </React.Fragment>
                    )
                  )}
                </Inputs>
                {inDragState ? (
                  <DragHoverPlaceHolder
                    hidden
                    setIsDroppable={setDragIsOverDropableArea}
                    setDropData={setDropData}
                    topIndex={topIndex}
                    rowIndex={-1}
                    secIndex={secIndex + 1}
                    fieldBeingDragged={fieldBeingDragged}
                  />
                ) : null}
              </DropAllign>
            );
          })}
          {/* Finish with a new field added */}
          <Inputs style={{ marginTop: "8px", overflow: "none" }}>
            {!inDragState ? (
              <NewFieldPlaceholder
                key={`new-field-placeholder`}
                addFieldCallback={insertField(
                  topIndex,
                  undefined,
                  field.section?.length ?? 0
                )}
              />
            ) : null}
          </Inputs>
        </QuerySection>
      );
    } else {
      fields.push(
        <Inputs
          key={`inp-wrapper-${topIndex}`}
          style={{
            alignItems: "flex-start",
            marginTop: "4px",
            overflow: "none",
          }}
        >
          {inDragState ? (
            <DragHoverPlaceHolder
              hidden
              setIsDroppable={setDragIsOverDropableArea}
              setDropData={setDropData}
              topIndex={topIndex}
              rowIndex={0}
              secIndex={undefined}
              fieldBeingDragged={fieldBeingDragged}
            />
          ) : (
            <NewFieldPlaceholder
              hidden
              addFieldCallback={insertField(topIndex, 0)}
            />
          )}
          {(field.nested ? field.nested : [field]).map((_field, nestIndex) => (
            <React.Fragment key={`query-field-${nestIndex}`}>
              <EditableFieldResolver
                field={_field}
                onFieldDelete={deleteField(
                  topIndex,
                  field.nested ? nestIndex : undefined
                )}
                onFieldChange={editField(
                  topIndex,
                  field.nested ? nestIndex : undefined
                )}
                onFieldDuplicate={duplicateField(
                  topIndex,
                  field.nested ? nestIndex : undefined
                )}
                onFieldDrag={moveField}
                topIndex={topIndex}
                rowIndex={undefined}
                secIndex={undefined}
                isBeingDragged={inDragState}
              />
              {inDragState ? (
                <DragHoverPlaceHolder
                  hidden
                  setIsDroppable={setDragIsOverDropableArea}
                  setDropData={setDropData}
                  topIndex={topIndex}
                  rowIndex={nestIndex + 1}
                  secIndex={undefined}
                  fieldBeingDragged={fieldBeingDragged}
                />
              ) : (
                <NewFieldPlaceholder
                  hidden
                  key={`nfp-follows-${_field.id}`}
                  addFieldCallback={insertField(topIndex, nestIndex + 1)}
                />
              )}
            </React.Fragment>
          ))}
        </Inputs>
      );
    }
  });

  // Always render this so we can add new sections :)
  sections.push(
    <QuerySection key="sec-general-rahhhhhh">
      <Inputs style={{ marginTop: "8px", overflow: "none" }}>
        <NewSectionPlaceHolder insertSectionCallback={insertSection()}>
          {" "}
        </NewSectionPlaceHolder>
      </Inputs>
    </QuerySection>
  );

  // Always render this section so we can add new fields :)
  {
    !custom &&
      sections?.unshift(
        <QuerySection
          key="sec-fields-general-rahhhhhh"
          style={{ display: "flex", flexDirection: "column", rowGap: "4px" }}
        >
          <QuerySectionHeader>Fields</QuerySectionHeader>
          {fields}
          {/* Finish with a new field added */}
          <Inputs style={{ marginTop: "8px", overflow: "none" }}>
            <NewFieldPlaceholder
              key={`new-field-placeholder-fields`}
              addFieldCallback={insertField(schemaFields?.length ?? 0)}
            />
          </Inputs>
        </QuerySection>
      );
  }

  return (
    <>
      {schemaData ? (
        <StatusContext.Provider value={statusSet}>
          <QueryContent
            style={{ paddingTop: custom ? "20px" : 0, overflow: "auto" }}
          >
            {sections}
          </QueryContent>
          {showSave && <SaveQueryBar saveQuery={saveChanges} />}
        </StatusContext.Provider>
      ) : (
        <Centerer>
          <CircularProgress />
        </Centerer>
      )}
    </>
  );
};

const BLANK_FIELD = {
  id: "",
  name: "New Field",
  type: "string",
  editableWhile: [],
};

const create_blank_field = () => ({
  ...BLANK_FIELD,
  id: generate_random_insert_id(),
});

const create_duplicate_field = (field) => ({
  ...field,
  id: field.id + "_duplicate",
});

const generate_random_insert_id = () => {
  return "rdxj_" + generate_tempid().substring(0, 10);
};

export const is_random_insert_id = (id) => {
  return !!id && id.startsWith("rdxj_") && id.length === 15;
};

const DropAllign = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Centerer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
