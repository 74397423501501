import React from "react";

import { Close, Send } from "@mui/icons-material";
import { IconButton, Tooltip, Paper } from "@mui/material";
import { QueryStatusTag } from "../../query";
import { TaskDataForm, validateTask } from ".";
import { TitleEditor, TaskHeading } from "./details";

export default ({
  newTask,
  setNewTask,
  createTask,
  taskSchema,
  setNewTaskCreated,
  setStatusMessage,
  assigneeRequired,
}) => {
  return (
    <Paper elevation={3} sx={{ height: "100%" }}>
      <TaskHeading>
        <div style={{ display: "flex", alignItems: "center" }}>
          <QueryStatusTag>NEW</QueryStatusTag>
          <div style={{ marginTop: "2px", marginLeft: "4px" }}>
            <TitleEditor
              taskData={newTask}
              setTaskData={setNewTask}
              editing={true}
            />
          </div>
        </div>
        <div>
          <Tooltip title="Cancel">
            <IconButton onClick={() => setNewTask(undefined)}>
              <Close />
            </IconButton>
          </Tooltip>
          <Tooltip title="Create New Task">
            <IconButton
              onClick={() => {
                const error = validateTask(newTask, assigneeRequired);
                if (error) {
                  setStatusMessage(error);
                  return;
                }
                createTask();
                setNewTask(undefined);
                setNewTaskCreated(true);
              }}
            >
              <Send />
            </IconButton>
          </Tooltip>
        </div>
      </TaskHeading>
      <TaskDataForm
        taskData={newTask}
        setTaskData={setNewTask}
        schema={taskSchema}
      />
    </Paper>
  );
};
