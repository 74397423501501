import React, { useContext, useState } from "react";
import q from "@queryit/api";
import { useSearchParams } from "react-router-dom";
import styled, { ThemeProvider, ThemeContext } from "styled-components";
// UI
import { DataTable } from "../../../ui/table";
import { Button2, ButtonSection } from "../../../ui/buttons";
import { BooleanField, GenericField } from "../../../ui/inputs2";
import {
  Checkbox,
  DialogContent,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Modal,
} from "@mui/material";
import {
  ModalContent,
  ModalFooter,
  ModalNotify,
  ModalPrompt,
} from "../../../ui/containers";
import {
  materialTheme,
  materialThemeContrast,
} from "../../../../themes/mui_theme";
import { ProjectRouteContainer } from "../../../project";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { panelTheme } from "../../../../themes/mui_theme_panels";
// Hooks
import { useUsers } from "../../../../hooks/users";
import { useAllProjects } from "../../../../hooks/projects";
// Download/upload
import { downloadUserTemplateWorkbook, uploadUserTemplateRows } from "./xlsx";

import Edit from "./edit";
import { useSnackbar } from "../../../../hooks/system";

export default () => {
  const [users, addUser] = useUsers();
  const [addingUser, setAddingUser] = useState(false);
  const [newUser, setNewUser] = useState(emptyUser);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const [error, setError] = useState("");

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [xlsxUserData, setXlsxUserData] = useState([]);

  const [projects, newProject] = useAllProjects();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [showProjectSelector, setShowProjectSelector] = useState(undefined);

  const { callSnackbar } = useSnackbar();
  const toggleProjectSelected = (p) => {
    if (selectedProjects.includes(p)) {
      setSelectedProjects((projs) => projs.filter((id) => id !== p));
    } else {
      setSelectedProjects((projs) => [...projs, p]);
    }
  };

  const addUsersToProjects = async () => {
    try {
      const userRefs = await Promise.all(
        xlsxUserData.map(
          async ({
            Email = "",
            Phone = "",
            "First Name": first,
            "Last Name": last,
            "Force SSO": forceSSO,
          }) => {
            try {
              const userRef = await addUser(
                Email,
                Phone,
                { first, last },
                forceSSO === true ? true : undefined
              );
              return userRef;
            } catch (e) {
              console.error("Error adding user:", e.message);
              return null;
            }
          }
        )
      );

      const successfulUserRefs = userRefs.filter((u) => u);

      await Promise.all(
        selectedProjects.map(async (proj) => {
          const projectAddPromises = successfulUserRefs.map((userRef) => {
            return q.projects
              .project(proj)
              .users.doc(userRef.ref.id)
              .set({})
              .catch((e) => {
                console.error(
                  `Error adding user ${userRef.ref.id} to project:`,
                  e
                );
                return null;
              });
          });
          try {
            await Promise.all(projectAddPromises);
          } catch (e) {
            console.error(`Error adding all users to project ${proj}:`, e);
          }
        })
      );
      callSnackbar("Successfully Added XLSX Users to Projects.");
      return successfulUserRefs;
    } catch (e) {
      console.error("Error in adding users to all selected projects: ", e);
      callSnackbar("Error in adding user to selected projects.");
    }
  };

  const theme = useContext(ThemeContext);

  const onChange = (ind) => (e) => {
    const { value } = e.target;
    setNewUser((ex) => ({ ...ex, [ind]: value }));
  };

  const createUser = async () => {
    if (!newUser.email && !newUser.phone) {
      setError("Please fill in at least one of the following:\nEmail / Phone");
    } else if (newUser.sso && !newUser.email) {
      setError("Email required for SSO");
    } else {
      try {
        await addUser(
          newUser.email,
          newUser.phone,
          { first: newUser.first, last: newUser.last },
          newUser.sso
        ).then(() => {
          callSnackbar("Successfully Created User.");
        });
      } catch (e) {
        console.error("Failed to create user: ", e);
        callSnackbar("User Create Failed.");
      }
      setError("");
      setAddingUser(false);
      setNewUser(emptyUser);
    }
  };

  return (
    <ProjectRouteContainer>
      <Modal
        open={addingUser}
        onClose={() => {
          setAddingUser(false);
          setNewUser(emptyUser);
        }}
      >
        <DialogContent>
          <ModalNotify>
            <ThemeProvider theme={materialThemeContrast(theme)}>
              <GenericField
                label={"First Name"}
                data={newUser.first}
                onChange={onChange("first")}
              />
              <GenericField
                label={"Last Name"}
                data={newUser.last}
                onChange={onChange("last")}
              />
              <GenericField
                label={"Email"}
                data={newUser.email}
                onChange={onChange("email")}
              />
              <GenericField
                label={"Phone"}
                data={newUser.phone}
                onChange={onChange("phone")}
              />
              <BooleanField
                checkbox
                label={"Force User SSO"}
                data={newUser.sso}
                onChange={onChange("sso")}
              />
              {error && (
                <p style={{ color: "red", marginLeft: "8px" }}>{error}</p>
              )}
              <br />
              <Button2 label={"Create"} onClick={createUser} />
            </ThemeProvider>
          </ModalNotify>
        </DialogContent>
      </Modal>

      <Modal
        open={confirmationModalOpen}
        onClose={() => {
          setConfirmationModalOpen(false);
        }}
      >
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>{"Confirm Submission"}</h2>
              <ProjectSelectContainer>
                {/* The menu for selecting from */}
                <Menu
                  anchorEl={showProjectSelector}
                  open={showProjectSelector !== undefined}
                  onClose={() => setShowProjectSelector(undefined)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuList dense style={{ padding: 0, minWidth: "140px" }}>
                    {projects?.map((project) => {
                      const isActive = selectedProjects.includes(project.id);
                      return (
                        <MenuItem
                          key={project.id}
                          onClick={() => toggleProjectSelected(project.id)}
                          style={{ paddingLeft: "4px" }}
                          selected={isActive}
                        >
                          <>
                            <Checkbox checked={isActive} />
                            <ListItemText primary={project.name} />
                          </>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
                {/* Then the visible element */}
                <ProjectSelectorClickable
                  onClick={(e) => setShowProjectSelector(e.currentTarget)}
                >
                  <ProjectSelectorColor
                    color={selectedProjects.length > 0 ? "#901D81" : undefined}
                  />
                  <ProjectSelectorText placeholds={selectedProjects.length < 1}>
                    Select Projects...
                  </ProjectSelectorText>
                </ProjectSelectorClickable>
              </ProjectSelectContainer>
              <p>{`You are about to add ${xlsxUserData.length} users. This action CANNOT be undone. Select projects to add the users to`}</p>
            </ModalContent>
            <ModalFooter>
              <Button2
                onClick={() => {
                  setConfirmationModalOpen(false);
                }}
                label={"Cancel"}
              >
                Cancel
              </Button2>
              <Button2
                onClick={async () => {
                  setConfirmationModalOpen(false);
                  await addUsersToProjects();
                }}
                label={"OK"}
              >
                OK
              </Button2>
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>

      {/* Popup */}
      {selectedUser && (
        <MuiThemeProvider theme={panelTheme(materialTheme(theme))}>
          <Edit
            selectedUser={selectedUser}
            onClickAway={() => setSelectedUser(undefined)}
          />
        </MuiThemeProvider>
      )}

      <ButtonSection>
        <Button2
          label="Add User"
          onClick={() => {
            setAddingUser(true);
          }}
        />
        <Button2
          label="Download Template"
          onClick={() => downloadUserTemplateWorkbook(userDataColumns, "Users")}
        />
        <UploadWrapper>
          {" "}
          {/* This implementation is still a little scuffed ("button" deadzones from before are still there, although I tried to make theem smaller) but it works for now. */}
          <Button2
            style={{ position: "absolute", top: 0 }}
            label="Upload Filled Template"
          />
          <div style={{ position: "absolute", top: "15%", left: "2%" }}>
            <label
              htmlFor="uploadTemplate"
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                fontSize: "22.5px",
                opacity: 0,
              }}
            >
              Upload Filled Template
            </label>
            {/* hidden file upload input */}
            <input
              type="file"
              id="uploadTemplate"
              style={{ display: "none" }}
              onChange={(e) => {
                uploadUserTemplateRows(e, userDataColumns, setXlsxUserData);
                setConfirmationModalOpen(true);
              }}
            />
          </div>
        </UploadWrapper>
      </ButtonSection>
      {users && (
        <DataTable
          data={users}
          columns={userTableColumns}
          onRowClick={(e) => {
            setSearchParams((ex) => {
              ex.set("user", e.row._id);
              return ex;
            });
          }}
        />
      )}
    </ProjectRouteContainer>
  );
};

const emptyUser = {
  first: "",
  last: "",
  email: "",
  phone: "",
};

const userTableColumns = [
  { headerName: "ID", index: "id", sortable: true, width: 300 },
  { headerName: "Email", index: "email", sortable: true, width: 250 },
  { headerName: "First Name", index: "name.first", sortable: true, width: 200 },
  { headerName: "Last Name", index: "name.last", sortable: true, width: 200 },
  {
    headerName: "EULA Accepted",
    index: "eula",
    stylePreset: "boolean",
    sortable: true,
    width: 150,
  },
  {
    headerName: "User Welcomed",
    index: "welcomed",
    stylePreset: "boolean",
    sortable: true,
    width: 150,
  },
  {
    headerName: "User Welcome Expired",
    index: "expired",
    stylePreset: "boolean",
    sortable: true,
    width: 150,
  },
];

const userDataColumns = [
  { title: "First Name", type: "text", id: "first_name" },
  { title: "Last Name", type: "text", id: "last_name" },
  { title: "Email", type: "text", id: "email" },
  { title: "Phone", type: "text", id: "phone" },
  { title: "Force SSO", type: "boolean", id: "force_sso" },
];

const UploadWrapper = styled.div`
  position: relative;
  width: 240px;
`;

const DownloadButton = styled.a`
  padding: 8px 16px !important;
  height: fit-content;
  width: fit-content;
  background: ${(props) =>
    props.toggleOn
      ? props.theme.button.active
      : props.theme.button.inactive} !important;
  color: ${(props) =>
    props.theme.button.inactiveText
      ? props.theme.button.inactiveText
      : props.theme.textAlt} !important;

  font-family: ${(props) => props.theme.font} !important;
  font-size: 14px !important;
  font-weight: 500;
  display: flex;
  align-items: center;

  min-width: 50px;
  justify-content: center;
  border-radius: 25px !important;

  user-select: none;
  text-decoration: none;

  white-space: nowrap;
  flex-shrink: 0;

  margin-left: 8px;
  :first-of-type {
    margin-left: 0;
  }

  transition: all 0.1s;
  :hover:not([disabled]) {
    cursor: pointer;
    transform: scale(1.05);
  }

  :active:not([disabled]) {
    transform: scale(0.95);
  }

  &[disabled] {
    background: ${(props) => props.theme.button.disabled};
    color: ${(props) => props.theme.textDisabled};
  }
`;

const ProjectSelectorClickable = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 160px;
  height: 25px;
  margin-left: 10px;

  overflow: hidden;

  align-items: center;
  justify-content: flex-start;

  background-color: ${(props) => props.theme.step100};
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.theme.step150};
    cursor: pointer;
  }
`;

const ProjectSelectorText = styled.div`
  margin-left: 4px;
  display: block;
  overflow: hidden;

  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${(props) => props.theme.text};
  opacity: ${(props) => (props.placeholds ? "0.7" : "0.9")};
`;

const ProjectSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  height: 40px;
  width: fit-content;
`;

const ProjectSelectorColor = styled.div`
  margin: 4px;
  margin-left: 8px;
  width: 10px;
  height: 10px;
  display: flex;
  flex-shrink: 0;

  border-radius: 20%;

  background-color: ${(props) => props.color ?? "grey"};
`;
