import { useState, useContext, useEffect } from "react";
import { QueryDataContext } from ".";

/**
 * This is definitely a different design pattern, but we need access to some live data and we
 * need to split it up into multiple, digestable components. It is also imperrative that we
 * do not use the full context provided to the root component all the time, as it may change and
 * cause insufferable rerenders. We can control that flow with these hooks though!
 */

export const useQueryDataStatus = () => {
  // First get queryData context
  const queryData = useContext(QueryDataContext);

  // Now manage state & update when needed
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    if (queryData) {
      setStatus(queryData.status);
    }
  }, [queryData]);

  return status;
};
