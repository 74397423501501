import React, { useState, useEffect, useContext } from "react";

import { Typography, Box, IconButton, Tooltip, Link } from "@mui/material";
import { Stop, Close, Replay, Save, Launch } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useQueryUnified } from "../../../hooks/queries";
import { TimerContext } from "../../../App";
import { styled } from "@mui/material/styles";
import { ConfirmationModal } from "../../ui/modals";
import { useSchema } from "../../../hooks/projects";
import { parse_on_fields } from "../../../tools/forms";

// WARNING: Timer assumes that query is editable. Make sure this is managed wherever it is used.
export default ({ user, project, userData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { timer, setTimer } = useContext(TimerContext);
  const [query, queryData] = useQueryUnified(
    { queryId: timer?.queryId },
    {},
    project
  );

  // NOTE: schemaContext is available, but this widget is running while toggling between schemas
  const [schema, schemaData] = useSchema(queryData?.schemaId, project);
  const [openSave, setOpenSave] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // useEffect for actual timer logic
  useEffect(() => {
    let interval = null;
    if (timer.start) {
      interval = setInterval(() => {
        setTimer((prev) => ({
          ...prev,
          elapsed: prev.elapsed + 1,
        }));
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [setTimer, timer.start]);

  //useEffect to manage website title in browser tab
  useEffect(() => {
    if (timer.start !== false) {
      document.title = `${formatTime(timer.elapsed)} • Query It`;
    } else {
      document.title = "Query It";
    }
  }, [timer.start, timer.elapsed]);

  if (!timer.on) return null;

  const handleSend = async () => {
    const startTime = new Date(timer.startTime);
    const queryDataCopy = { ...queryData };
    // now lets handle the difficult case where the timer is nested in a table
    // rather computation heavy, TODO: find the best method of "backtracking" to the parent
    if (timer?.nestedId) {
      let parentTable;
      parse_on_fields(schemaData.fields, (fld) => {
        if (
          fld.columns &&
          fld.columns.some((col) => col.id === timer.fieldId)
        ) {
          parentTable = fld.id;
        }
      });
      if (parentTable) {
        await query.update({
          ...queryDataCopy,
          lastEditedBy: userData?.id,
          data: {
            ...queryDataCopy.data,
            [parentTable]: queryDataCopy.data[parentTable].map((row) => {
              if (row.id === timer.nestedId) {
                return {
                  ...row,
                  [timer.fieldId]: {
                    start: startTime,
                    end: new Date(startTime.getTime() + timer.elapsed * 1000),
                  },
                };
              }
              return row;
            }),
          },
        });
      }
    } else {
      await query.update({
        ...queryDataCopy,
        lastEditedBy: userData?.id,
        data: {
          ...queryDataCopy.data,
          [timer.fieldId]: {
            start: startTime,
            end: new Date(startTime.getTime() + timer.elapsed * 1000),
          },
        },
      });
    }
    setTimer((prev) => ({
      ...prev,
      start: false,
      on: false,
      startTime: Date.now(),
      elapsed: 0,
      queryId: "",
      fieldId: "",
      nestedId: "",
      queryPath: "",
    }));
    setOpenSave(false);
  };

  return (
    <FloatingTimer>
      {/** Time Entry Save Confirmation */}
      <ConfirmationModal
        open={openSave}
        onConfirm={handleSend}
        onCancel={() => {
          setOpenSave(false);
        }}
        body="Do you want to save this time entry? This will write to the query assigned to in the timer."
      />
      {/** Time Entry Delete Confirmation */}
      <ConfirmationModal
        open={openDelete && !openSave} //lets make sure both modals dont show somehow
        onConfirm={() => {
          setTimer((prev) => ({
            ...prev,
            elapsed: 0,
            queryId: "",
            fieldId: "",
            nestedId: "",
            queryPath: "",
            on: false,
            start: false,
          }));
          setOpenDelete(false);
        }}
        onCancel={() => {
          setOpenDelete(false);
        }}
        body="Are you sure you want to close the timer? This time entry will be lost."
      />
      <Box
        sx={{
          maxWidth: "200px",
          wrap: "wrap",
          textOverflow: "ellipsis",
          margin: "5px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ fontSize: "14px", color: "white", fontWeight: "bold" }}
        >
          Timer Entry
        </Typography>
        <StyledLink
          component="button"
          variant="body"
          onClick={() => {
            setSearchParams({ qq: timer.queryPath });
          }}
        >
          <span>
            {queryData?.dynamicId ? queryData?.dynamicId : timer.queryId}
          </span>
          <Launch
            sx={{
              fontSize: "18px",
              marginLeft: "4px",
              verticalAlign: "middle",
            }}
          />
        </StyledLink>
      </Box>
      <Typography variant="h4" sx={{ color: "white", margin: "5px" }}>
        {formatTime(timer.elapsed)}
      </Typography>
      <Box sx={{ margin: "10px" }}>
        <Tooltip
          title="Close Timer"
          onClick={() => {
            setOpenDelete(true);
          }}
        >
          <StyledIconButton>
            <Close sx={{ fontSize: "16px" }} />
          </StyledIconButton>
        </Tooltip>
        {timer.start ? (
          <Tooltip
            title="Stop Time Entry"
            onClick={() => {
              setTimer((prev) => ({
                ...prev,
                start: false,
              }));
            }}
          >
            <StyledIconButton>
              <Stop sx={{ fontSize: "24px" }} />
            </StyledIconButton>
          </Tooltip>
        ) : (
          <Tooltip
            title="Start New Time Entry"
            onClick={() => {
              setTimer((prev) => ({
                ...prev,
                startTime: Date.now(),
                start: true,
                elapsed: 0,
              }));
            }}
          >
            <StyledIconButton>
              <Replay sx={{ fontSize: "24px" }} />
            </StyledIconButton>
          </Tooltip>
        )}
        <Tooltip title="Save Time Entry" placement="top">
          <span>
            <StyledIconButton
              disabled={timer.start || timer.elapsed === 0}
              onClick={() => {
                setOpenSave(true);
              }}
            >
              <Save sx={{ fontSize: "16px" }} />
            </StyledIconButton>
          </span>
        </Tooltip>
      </Box>
    </FloatingTimer>
  );
};

// timer formatter 00:00:00
export const formatTime = (seconds) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hrs.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

const FloatingTimer = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: -5,
  left: -5,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 8,
  padding: 15,
  boxShadow: theme.shadows[3],
  zIndex: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledIconButton = styled(IconButton)({
  background: "rgba(255, 255, 255, 0.2)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
  },
  margin: "0 8px",
  "&[disabled]": {
    opacity: 0.4,
  },
});

const StyledLink = styled(Link)({
  fontSize: "14px",
  color: "white",
  textAlign: "center",
  display: "inline-flex",
  alignItems: "center",
  margin: "5px",
});
