import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import Form from "./form";
import Acknowledgement from "./acknowledgement";
import Preview from "./preview";
import Clarifications from "./clarifications";
import Negotiator from "./negotiator";
import Rejection from "./rejection";
import Settings from "./settings";
import Completed from "./completed";

import { panelTheme } from "../../../themes/mui_theme_panels";
import { materialTheme } from "../../../themes/mui_theme";
import themes from "../../../themes";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import CSSTransition from "react-transition-group/CSSTransition";

export const SUBMISSIONVIEWS = {
  SUBMISSION: "submission",
  REJECTION: "rejection",
  ACKNOWLEDGEMENT: "acknowledgement",
  CLARIFICATION: "clarification",
  NEGOTIATOR: "negotiator",
  PREVIEW: "preview",
  COMPLETED: "completed",
  SETTINGS: "settings",
};

export const FormCompositeContext = React.createContext(undefined);
export const FormDataContext = React.createContext(undefined);

export default ({
  navOptions,
  formComposite,
  formData,
  formSchemas,
  formDatasets,
}) => {
  const panelThemeInstance = panelTheme(materialTheme(themes.purple));
  // States for user feedback and prettiness
  const [statusMessage, setStatusMessage] = useState("");
  const [bannerAnim, setBannerAnim] = useState(false);

  // Animate incoming status messages
  useEffect(() => {
    setBannerAnim(!!statusMessage);
    if (statusMessage) {
      setTimeout(() => {
        setBannerAnim(false);
      }, 3000);
    }
  }, [statusMessage]);

  // Clear status message after animation
  useEffect(() => {
    if (!bannerAnim) {
      setTimeout(() => setStatusMessage(""), 500);
    }
  }, [bannerAnim, setStatusMessage]); // On banner close, clear message

  // TODO: formData should also be contextualized
  return (
    <FormContainerDiv>
      <MuiThemeProvider theme={panelThemeInstance}>
        <FormCompositeContext.Provider value={formComposite}>
          <FormDataContext.Provider value={formData}>
            <Routes>
              <Route element={<NavOptionWall navOptions={navOptions} />}>
                <Route
                  path=""
                  element={<Navigate to={SUBMISSIONVIEWS.SUBMISSION} />}
                />
                {/* Now the actual routes */}
                <Route
                  path={SUBMISSIONVIEWS.ACKNOWLEDGEMENT}
                  element={<Acknowledgement formDates={{}} />}
                />
                <Route
                  path={SUBMISSIONVIEWS.SUBMISSION}
                  element={
                    <Form
                      formSchemas={formSchemas}
                      setStatusMessage={setStatusMessage}
                    />
                  }
                />
                <Route path={SUBMISSIONVIEWS.PREVIEW} element={<Preview />} />
                <Route
                  path={SUBMISSIONVIEWS.CLARIFICATION}
                  element={<Clarifications />}
                />
                <Route
                  path={SUBMISSIONVIEWS.NEGOTIATOR}
                  element={<Negotiator formSchemas={formSchemas} />}
                />
                <Route
                  path={SUBMISSIONVIEWS.REJECTION}
                  element={<Rejection />}
                />
                <Route path={SUBMISSIONVIEWS.SETTINGS} element={<Settings />} />
                {/* Finally we have the confirmation view for when you've confirmed your form (not an integrated view like the others) */}
                <Route
                  path={SUBMISSIONVIEWS.COMPLETED}
                  element={<Completed />}
                />
              </Route>
            </Routes>
            <BannerContainer>
              <CSSTransition
                in={bannerAnim}
                timeout={3600}
                classNames="formbanner"
              >
                <FormStatusBanner>{statusMessage}</FormStatusBanner>
              </CSSTransition>
            </BannerContainer>
          </FormDataContext.Provider>
        </FormCompositeContext.Provider>
      </MuiThemeProvider>
    </FormContainerDiv>
  );
};

const NavOptionWall = ({ navOptions }) => {
  // Grab the last segment of url
  const currentView = useLocation();

  // Then make sure it's in the navOptions
  if (
    navOptions.includes(currentView.pathname.split("/").pop()) ||
    navOptions.length < 1
  ) {
    return <Outlet />;
  }

  return <Navigate to={navOptions[0]} />;
};

const FormStatusBanner = styled.div`
  position: absolute;
  top: 10px;
  left: 75px;

  opacity: 0; // Before animating!

  width: fit-content;
  height: 32px;
  font-family: ${(props) => props.theme.palette.font};
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.text.secondary};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;

  padding: 0 5px;

  z-index: 3;

  &.formbanner-enter {
    opacity: 1;
    transform: translateY(-29px);
  }

  &.formbanner-enter-active {
    transform: translateY(0px);
    transition: transform 0.3s;
  }

  &.formbanner-enter-done {
    opacity: 1;
  }

  &.formbanner-exit {
    opacity: 1;
    transform: translateY(0px);
  }

  &.formbanner-exit-active {
    opacity: 1;
    transform: translateY(-44px);
    transition: transform 0.3s;
  }

  &.formbanner-exit-done {
    opacity: 0;
  }
`;

const FormContainerDiv = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  flex-grow: 1;
  flex-direction: row;

  margin-top: 20px;

  justify-content: center;

  box-sizing: border-box;

  position: relative;
  overflow: auto;

  font-family: ${(props) => props.theme.palette.font};
  color: ${(props) => props.theme.palette.text.primary};
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 60%;
  min-width: 500px;

  height: fit-content;
  min-height: 400px;

  border-radius: 8px;

  background: white;

  margin-bottom: 20px;
  padding: 10px;

  color: black;
`;

export const BannerContainer = styled.div`
  position: absolute;

  width: 60%;
  min-width: 500px;

  height: fit-content;
  min-height: 40px;

  border-radius: 8px;
`;
