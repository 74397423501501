import { BaseCollection, BaseDocument, BaseData } from "../../base";

export interface UserRequestData extends BaseData {
  name: {
    first: string;
    last: string;
  };
  email: string;
  requestedBy: string;
  requestedOn: string;
}

export class UserRequests extends BaseCollection<UserRequestData> {
  userrequest(id: string) {
    return this.doc(id) as UserRequest;
  }
}

type UserRequest = BaseDocument<UserRequestData>;
