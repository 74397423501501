import { BaseCollection, BaseDocument, BaseData } from "../../base"

interface PopupData extends BaseData {
  name: string
  projectId: string
  data: any
  received: number
  opened: number
  date: Date
}

export class Popups extends BaseCollection<PopupData> {
  popup(id: string): Popup {
    return this.doc(id) as Popup
  }
}

type Popup = BaseDocument<PopupData>