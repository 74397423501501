import React from "react";
import styled from "styled-components";

import Bisn from "../graphics/bisn.png";
import Thermon from "../graphics/thermon.png";
import Cito from "../graphics/cito.png";

export default () => {
  return (
    <ClientsContainer>
      <H1>Clients & Partners</H1>
      <ClientsBar>
        <Client>
          <img src={Bisn} />
          <P>
            Automated tracking & approvals for health and safety work processes
            like Hazard Identifications, Job Safety Analyses, and Toolbox talks
          </P>
        </Client>
        <Client>
          <img src={Thermon} />
          <P>
            Using us for automation of Quality & Manufacturing work processes
            including QC checklists, Inspection & Repair forms, etc.
          </P>
        </Client>
        <Client>
          <img src={Cito} />
          <P>
            Selected Query-It to develop an end-to-end Procurement module for
            automation of work processes including Material / Service
            Requisitions, Request for Proposals (RFP), etc.
          </P>
        </Client>
      </ClientsBar>
    </ClientsContainer>
  );
};

const ClientsContainer = styled.div`
  min-height: 500px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
const ClientsBar = styled.div`
  height: 40%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
const Client = styled.div`
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: black;

  @media (max-width: 1100px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;
const H1 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 300%;
  color: #37227a;

  @media (max-width: 1100px) and (min-width: 381px) {
    font-size: 250%;
    margin-top: 90px;
    margin-bottom: 70px;
  }

  @media (max-width: 380px) {
    font-size: 200%;
    margin-top: 90px;
    margin-bottom: 70px;
  }
`;
const P = styled.p`
  @media (max-width: 1100px) {
    text-align: center;
  }
`;
