export function log_warning(message: string): void {
  console.log(`[WARNING] ${message}`);
}

export function log_error(message: string): void {
  // TODO: Maybe push to DB as well?
  console.log(`[ERROR] ${message}`);
}

export function warn_deprecated(): void {
  log_warning(
    "This function is deprecated. Please implement the alternative at your earliest convenience"
  );
}
