import React from "react";

import {
  Modal,
  DialogContent,
  Button,
  Snackbar,
  SnackbarContent,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { ModalPrompt, ModalContent, ModalFooter } from "./containers";

import { useTheme } from "@mui/material/styles";

export const ConfirmationModal = ({
  open,
  onConfirm,
  onCancel,
  body = "Are you sure?",
}) => (
  <Modal open={open} onClose={onCancel}>
    <DialogContent>
      <ModalPrompt>
        <ModalContent>
          <h2>Confirm Action</h2>
          <p>{body}</p>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm}>OK</Button>
        </ModalFooter>
      </ModalPrompt>
    </DialogContent>
  </Modal>
);
